import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';

const PutAccessRightsSchema = z.object({
    success: z.literal(true),
});

export interface PutAccessRightsDataParams {
    customers: number[];
    regions: number[];
    stores: number[];
}

export interface PutAccessRightsParams {
    userId: number;
    data: PutAccessRightsDataParams;
}

export const mutatePuAccessRights = async (values: PutAccessRightsParams) => {
    const response = await makeMutation(ApiEndpoints.putAccessRights(values.userId), values.data, true, 'PUT');
    return PutAccessRightsSchema.parse(response);
};

export const PuAccessRightsErrorCodes = {};
