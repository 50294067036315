const FOOD_SAFETY_STATISTICS_FILTER = 'FOOD_SAFETY_STATISTICS_FILTER';

interface FoodSafetyStatisticsFilter {
    week: number;
    year: number;
}

export const setFoodSafetyStatisticsFilter = (filter: FoodSafetyStatisticsFilter) => {
    localStorage.setItem(FOOD_SAFETY_STATISTICS_FILTER, JSON.stringify(filter));
};
export const hasFoodSafetyStatisticsFilter = () => Boolean(localStorage.getItem(FOOD_SAFETY_STATISTICS_FILTER));
export const getFoodSafetyStatisticsFilter = () => {
    const value = localStorage.getItem(FOOD_SAFETY_STATISTICS_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearFoodSafetyStatisticsFilter = () => {
    localStorage.removeItem(FOOD_SAFETY_STATISTICS_FILTER);
};
