import Main from 'components/main/main';
import Header from 'components/header/header';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import DataSource from './components/dataSource/dataSource';
import Filters from './components/filters/filters';
import OrderBy from './components/orderBy/orderBy';
import CardList from './components/cardList/cardList';
import { DashboardPeriod, DashboardDataSource, DashboardAlarmPriority } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { alarmDashboardFilter } from 'store/filters/actions';
import SearchBoxControlled from 'components/searchBox/searchBoxControlled';
import _debounce from 'lodash/debounce';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 1214,
        padding: `0 ${theme.spacing(2)}`,
        alignSelf: 'center',
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 700,
        lineHeight: theme.typography.pxToRem(32),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(3),
        },
    },
    verticalSpacer: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    filtersAndSortRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(1.5),
        },
    },
}));

const DashboardScreen: React.FC = () => {
    const dispatch = useDispatch();
    const { dataSource, period, priority, alarmType, searchPhrase, sortDirection } = useSelector((state: AppState) => state.filters.alarmDashboard);
    const [debouncedSearchPhrase, setDbouncedSearchPhrase] = React.useState('');

    const classes = useStyles();
    const { t } = useTranslation();

    const orderByLabel = dataSource === 'duration' ? t('dashboard.dataSource.duration.label') : t('dashboard.dataSource.occurrence.label');

    const toggleOrder = () => {
        const changedSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, changedSortDirection));
    };

    const handleDataSourceChange = (dataSource: DashboardDataSource) => {
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, sortDirection));
    };

    const handlePeriodChange = (period: DashboardPeriod) => {
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, sortDirection));
    };

    const handlePriorityChange = (priority: DashboardAlarmPriority) => {
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, sortDirection));
    };

    const handleAlarmTypeChange = (alarmType: string | undefined) => {
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, sortDirection));
    };

    const handleSearchPhraseChange = (searchPhrase: string) => {
        dispatch(alarmDashboardFilter(dataSource, period, priority, alarmType, searchPhrase, sortDirection));
    };

    const debouncedHandleChange = React.useMemo(
        () =>
            _debounce((value) => {
                setDbouncedSearchPhrase(value);
            }, 500),
        [],
    );

    React.useEffect(() => {
        debouncedHandleChange(searchPhrase);
    }, [debouncedHandleChange, searchPhrase]);

    return (
        <>
            <Header />
            <SearchBoxControlled value={searchPhrase} onChange={handleSearchPhraseChange} />
            <Main>
                <Box flexDirection={'column'} display={'flex'} flexGrow={1}>
                    <Box className={classes.container}>
                        <div className={classes.title}>{t('dashboard.title')}</div>
                        <Box className={classes.verticalSpacer} />

                        <DataSource value={dataSource} onChange={handleDataSourceChange} />

                        <Box className={classes.verticalSpacer} />
                        <HorizontalSeparator />
                        <Box className={classes.verticalSpacer} />

                        <Box className={classes.filtersAndSortRow}>
                            <Filters
                                periodValue={period}
                                selectedAlarmType={alarmType}
                                selectedPriority={priority}
                                onAlarmTypeChange={handleAlarmTypeChange}
                                onPriorityChange={handlePriorityChange}
                                onPeriodChange={handlePeriodChange}
                            />
                            <OrderBy order={sortDirection} label={orderByLabel} onClick={toggleOrder} />
                        </Box>

                        <Box className={classes.verticalSpacer} />

                        {!!dataSource && !!alarmType && !!period && (
                            <CardList alarmType={alarmType} period={period} dataSource={dataSource} priority={priority} searchPhrase={debouncedSearchPhrase} sortDirection={sortDirection} />
                        )}
                    </Box>
                </Box>
            </Main>
        </>
    );
};

export default DashboardScreen;
