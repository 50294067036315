import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { DatePicker } from '@material-ui/pickers';
import { DatePickerWithArrowsProps } from 'components/datePickerWithArrows/datePickerWithArrowsProps';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    chevron: {
        fill: theme.customPalette.colors.brand.light,
        margin: `0 ${theme.spacing(0.5)}`,
        '&[disabled]': {
            opacity: 0.2,
        },
    },
    input: {
        '& > div': {
            background: theme.customPalette.background.white,
        },
        '& input': {
            width: 60,
            height: theme.spacing(3),
            padding: `0 ${theme.spacing(1)}`,
            fontSize: theme.typography.pxToRem(12),
        },
        '& button': {
            color: theme.customPalette.colors.brand.light,
            margin: theme.spacing(-2),
        },
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none',
        },
    },
}));

const DatePickerWithArrows: React.FC<DatePickerWithArrowsProps> = ({ availableData, date, setDate }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleDayChange = (addition: number) => {
        setDate(date.plus({ days: addition }));
    };

    const arrowLeftIsDisabled = date.ordinal === availableData.from.ordinal;
    const arrowRightIsDisabled = date.ordinal === availableData.to.ordinal;

    return (
        <Box alignItems="center" display="flex">
            <IconButton className={classes.chevron} disabled={arrowLeftIsDisabled} id="test-picker-button-left" size="small" onClick={() => handleDayChange(-1)}>
                <ChevronLeft />
            </IconButton>
            <DatePicker
                cancelLabel={t('datePicker.cancel')}
                className={classes.input}
                clearable
                clearLabel=""
                format={DateTimeFormat.DATE_FORMAT()}
                id="test-picker-input"
                inputVariant="outlined"
                maxDate={availableData.to}
                minDate={availableData.from}
                okLabel={t('datePicker.ok')}
                placeholder="10/10/2018"
                value={date}
                onChange={(date) => (date ? setDate(date) : undefined)}
            />
            <IconButton className={classes.chevron} disabled={arrowRightIsDisabled} size="small" onClick={() => handleDayChange(+1)}>
                <ChevronRight />
            </IconButton>
        </Box>
    );
};

export default DatePickerWithArrows;
