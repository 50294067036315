import { Box } from '@material-ui/core';
import { LegendLabelProps } from 'components/legendLabel/legendLabelProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface StylesProps extends Pick<LegendLabelProps, 'color'> {}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.primary,
        alignItems: 'center',
    },
    square: {
        width: theme.spacing(2),
        minWidth: theme.spacing(2),
        height: theme.spacing(2),
        margin: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
        borderRadius: theme.typography.pxToRem(2),
        backgroundColor: ({ color }: StylesProps) => color,
    },
}));

const LegendLabel: React.FC<LegendLabelProps> = ({ color, text }) => {
    const classes = useStyles({ color });
    return (
        <div className={classes.root}>
            <div className={classes.square} />
            <Box mr={1}>{text}</Box>
        </div>
    );
};

export default LegendLabel;
