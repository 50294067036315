import { AppState } from '../store';
import { getReportingStoreLive } from '../api/fetchers/getReportingStoreLive';
import { makeStyles } from '@material-ui/core/styles';
import { QueryKeys } from '../api/queryKeys';
import { useHandledQuery } from '../shared/useHandledQuery/useHandledQuery';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import AlarmsHeader from './layout/alarmsHeader';
import Box from '@material-ui/core/Box';
import CanvasHeader from './layout/canvasHeader';
import Content from 'components/content/content';
import Grid from '@material-ui/core/Grid';
import Header from 'components/header/header';
import LayoutCanvas from './layout/canvas';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import Konva from 'konva';
import AlarmsBody from './layout/alarmsBody';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            '& #header:fullscreen, & #navigation:fullscreen': {
                display: 'none',
            },
        },
    };
});

const LayoutScreen: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();
    const stageRef = React.useRef<any>();

    const { storeId } = useSelector((state: AppState) => state.store);

    const [defaultScale, setDefaultScale] = React.useState<number>(1);
    const [currentScale, setCurrentScale] = React.useState<number>(1);

    React.useEffect(() => {
        setCurrentScale(defaultScale);
    }, [defaultScale]);

    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
    const [highlightedAsset, setHighlightedAsset] = React.useState<number | null>(null);
    const [isAlarmsColumnOpen, setIsAlarmsColumnOpen] = React.useState<boolean>(true);
    const [isOffline, setIsOffline] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isFullscreen) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    }, [isFullscreen]);

    const { data, refetch } = useHandledQuery([QueryKeys.getReportingStoreIdLive(storeId || 0)], () => getReportingStoreLive(storeId || 0), {
        refetchInterval: 1000 * 60 * 2,
        refetchIntervalInBackground: true,
        enabled: Boolean(storeId),
    });

    const goOnline = () => {
        setIsOffline(false);
        refetch();
    };

    const goOffline = () => {
        setIsOffline(true);
    };

    React.useEffect(() => {
        window.addEventListener('online', goOnline);
        window.addEventListener('offline', goOffline);
        return () => {
            window.removeEventListener('online', goOnline);
            window.removeEventListener('offline', goOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data) {
        return null;
    }

    const store = data.data;

    const zoom = (ratio: number, relative: boolean) => {
        if (stageRef && stageRef.current) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();
            const pointer = {
                x: stage.width() / 2,
                y: stage.height() / 2,
            };
            const mousePointTo = {
                x: (pointer.x - stage.x()) / oldScale,
                y: (pointer.y - stage.y()) / oldScale,
            };
            const newScale = relative ? oldScale * ratio : defaultScale * ratio;
            stage.scale({ x: newScale, y: newScale });
            const newX = pointer.x - mousePointTo.x * newScale;
            const newY = pointer.y - mousePointTo.y * newScale;
            const newPos = {
                x: newX,
                y: newY,
            };
            stage.position(newPos);
            setCurrentScale(newScale);
        }
    };

    const onWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        const scaleBy = 1.1;

        if (stageRef && stageRef.current) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();

            const pointer = stage.getPointerPosition();

            const mousePointTo = {
                x: (pointer.x - stage.x()) / oldScale,
                y: (pointer.y - stage.y()) / oldScale,
            };

            let direction = e.evt.deltaY > 0 ? -1 : 1;

            const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

            stage.scale({ x: newScale, y: newScale });

            const newX = pointer.x - mousePointTo.x * newScale;
            const newY = pointer.y - mousePointTo.y * newScale;

            const newPos = {
                x: newX,
                y: newY,
            };
            stage.position(newPos);
            setCurrentScale(newScale);
        }
    };

    return (
        <div className={classes.root}>
            {!isFullscreen && (
                <>
                    <Header />
                    <Navigation />
                </>
            )}
            <Main>
                <Content>
                    <Grid container spacing={0} style={{ boxShadow: theme.customElevation.insetBorder }}>
                        <Grid item style={{ background: isOffline ? 'linear-gradient(270deg, rgba(255, 0, 0, 0.49) 0%, rgba(255, 255, 255, 0) 28.11%)' : 'unset' }} xs={isAlarmsColumnOpen ? 9 : 12}>
                            <Box p={1.5} pl={0.25} pr={2}>
                                <CanvasHeader
                                    alarmsCount={store.alarms.length}
                                    displayAlarmsButton={!isAlarmsColumnOpen}
                                    isOffline={isOffline}
                                    measuredOn={store.measuredOn}
                                    openAlarmsColumn={() => setIsAlarmsColumnOpen(true)}
                                    refetch={refetch}
                                />
                            </Box>
                        </Grid>
                        {isAlarmsColumnOpen && (
                            <Grid
                                item
                                style={{
                                    boxShadow: '-1px 0 0 rgb(0 0 0 / 8%)',
                                }}
                                xs={3}
                            >
                                <AlarmsHeader alarmsCount={store.alarms.length} closeAlarmsColumn={() => setIsAlarmsColumnOpen(false)} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container>
                        <Grid item xs={isAlarmsColumnOpen ? 9 : 12}>
                            <LayoutCanvas
                                currentZoom={currentScale / defaultScale}
                                getDefaultScale={(scale: number) => setDefaultScale(scale)}
                                highlightedAsset={highlightedAsset}
                                isFullscreen={isFullscreen}
                                isOffline={isOffline}
                                ref={stageRef}
                                store={store}
                                toggleFullscreen={() => setIsFullscreen((prev) => !prev)}
                                zoomFunction={zoom}
                                onWheel={onWheel}
                            />
                        </Grid>
                        {isAlarmsColumnOpen && (
                            <Grid
                                item
                                style={{
                                    boxShadow: '-1px 0 0 rgb(0 0 0 / 8%)',
                                }}
                                xs={3}
                            >
                                <AlarmsBody handleHighlightedAsset={(value) => setHighlightedAsset(value)} isFullscreen={isFullscreen} store={store} />
                            </Grid>
                        )}
                    </Grid>
                </Content>
            </Main>
        </div>
    );
};

export default LayoutScreen;
