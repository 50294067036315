import * as z from 'zod';
import { FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import { TFunction } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

export const customValidationMessages = {
    passwordsMustBeSame: 'passwordsMustBeSame',
    invalidPhoneNumber: 'invalidPhoneNumber',
};

export const translateCustomValidation = (t: TFunction, input?: string) => {
    if (input === customValidationMessages.passwordsMustBeSame) {
        return t('validations.passwordsMustBeSame');
    } else if (input === customValidationMessages.invalidPhoneNumber) {
        return t('validations.invalidPhoneNumber');
    }
    return input;
};

const errorMap =
    (t: TFunction): z.ZodErrorMap =>
    (error, ctx) => {
        if (error.message) {
            return { message: error.message };
        }
        switch (error.code) {
            case 'invalid_string':
                return { message: t('validations.invalidString') };
            case 'too_small':
                // @ts-ignore
                return { message: t('validations.tooSmall', { count: error.minimum }) };
            case 'too_big':
                // @ts-ignore
                return { message: t('validations.tooBig', { count: error.maximum }) };
            default:
                return { message: ctx.defaultError };
        }
    };

export const useHandledForm = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    schema?: z.ZodSchema<unknown>,
    useFormProps?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues> => {
    const { t } = useTranslation();
    return useForm({ ...useFormProps, resolver: schema ? zodResolver(schema, { errorMap: errorMap(t) }) : undefined });
};
