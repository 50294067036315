import * as z from 'zod';
import { Box } from '@material-ui/core';
import { handleSimpleSpecificEndpointErrorsToForm } from 'shared/errors/handleStandardApiErrors';
import { mutatePostUser, postUserErrorCodes, PostUserParams } from 'api/mutations/postUser';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validateNameInput } from 'shared/useHandledForm/rules';
import Button from 'components/button/button';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useLanguages from 'shared/useLanguages/useLanguages';
import useRoles from 'shared/useRoles/useRoles';

const formValuesSchema = z.object({
    firstName: z.string().min(1).max(64).regex(validateNameInput),
    lastName: z.string().min(1).max(64).regex(validateNameInput),
    username: z.string().min(1).max(64),
    emailAddress: z.string().email(),
    role: z.string().nonempty(),
    languageId: z.number().min(1),
});

interface FormValuesProps extends Omit<PostUserParams, 'userId'> {}

const UserCreateForm: React.FC = () => {
    const { t } = useTranslation();
    const roles = useRoles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { languages } = useLanguages();
    const queryClient = useQueryClient();
    const { mutate, isSuccess, data, isError, error, isLoading } = useHandledMutation(mutatePostUser, undefined, postUserErrorCodes);
    const { handleSubmit, control, getValues, reset, setError } = useHandledForm(formValuesSchema);

    const onSubmit = async (values: FormValuesProps) => {
        mutate(values);
    };

    React.useEffect(() => {
        if (isSuccess && data) {
            const { emailAddress } = getValues();
            enqueueSnackbar(t('userCreate.form.successSubmit', { emailAddress }));
            reset({
                firstName: '',
                lastName: '',
                username: '',
                emailAddress: '',
                role: '',
            });
            queryClient.removeQueries([QueryKeys.adminUsers()]);
            history.push(routes.userDetail.path(data.data.id));
        }
        if (isError) {
            handleSimpleSpecificEndpointErrorsToForm(error, enqueueSnackbar, t, postUserErrorCodes, Object.keys(getValues()), setError);
        }
    }, [isSuccess, isError, enqueueSnackbar, t, error, getValues, reset, setError, queryClient, data, history]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldRHF control={control} label={t('userCreate.form.firstName.label')} name="firstName" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userCreate.form.lastName.label')} name="lastName" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userCreate.form.username.label')} name="username" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userCreate.form.emailAddress.label')} name="emailAddress" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userCreate.form.role.label')} name="role" select>
                {roles.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.language.label')} name="languageId" select>
                {languages.map(({ languageId, label }) => (
                    <MenuItem key={languageId} value={languageId}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={3} />
            <Box alignItems="center" display="flex" mt={2}>
                <Button isLoading={isLoading} isPrimary size="large" type="submit">
                    {t('userCreate.form.createNewUser.label')}
                </Button>
            </Box>
        </form>
    );
};

export default UserCreateForm;
