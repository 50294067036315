import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import ContentTabs from 'components/contentTabs/contentTabs';
import EnergyConsumption from 'screens/energy/consumption';
import EnergyPower from 'screens/energy/power';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';

const EnergyScreen: React.FC = () => {
    const { t } = useTranslation();
    const items = [
        {
            id: 'energyConsumption',
            path: routes.energyConsumption.path(),
            text: t('energy.consumption.title'),
        },
        {
            id: 'energyPower',
            path: routes.energyPower.path(),
            text: t('energy.power.title'),
        },
    ];
    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Switch>
                    <Route component={EnergyConsumption} exact path={routes.energyConsumption.pattern} />
                    <Route component={EnergyPower} exact path={routes.energyPower.pattern} />
                </Switch>
            </Main>
        </>
    );
};

export default EnergyScreen;
