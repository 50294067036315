const ENERGY_CONSUMPTION_FILTER = 'ENERGY_CONSUMPTION_FILTER';

interface EnergyConsumptionFilter {
    dateTo: number;
    dateFrom: number;
    energyMetricType: string;
    dateIntervalType: string;
}

export const setEnergyConsumptionFilter = (filter: EnergyConsumptionFilter) => {
    localStorage.setItem(ENERGY_CONSUMPTION_FILTER, JSON.stringify(filter));
};
export const hasEnergyConsumptionFilter = () => Boolean(localStorage.getItem(ENERGY_CONSUMPTION_FILTER));
export const getEnergyConsumptionFilter = () => {
    const value = localStorage.getItem(ENERGY_CONSUMPTION_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearEnergyConsumptionFilter = () => {
    localStorage.removeItem(ENERGY_CONSUMPTION_FILTER);
};
