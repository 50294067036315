export const SET_STORE_ID = 'SET_STORE_ID';

export interface StoreState {
    storeId: number | null;
}

export interface SetStoreIdActionTypes {
    type: typeof SET_STORE_ID;
    storeId: number | null;
}

export type StoreActionTypes = SetStoreIdActionTypes;
