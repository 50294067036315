import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Period from './period';
import Priority from './priority';
import AlarmType from './alarmType';
import React from 'react';
import { DashboardAlarmPriority, DashboardPeriod } from 'screens/dashboard/types';

type Props = {
    periodValue: DashboardPeriod;
    selectedAlarmType: string | undefined;
    selectedPriority: DashboardAlarmPriority;
    onAlarmTypeChange: (value: string) => void;
    onPriorityChange: (value: DashboardAlarmPriority) => void;
    onPeriodChange: (value: DashboardPeriod) => void;
};

const Filters: React.FC<Props> = ({ periodValue, selectedAlarmType, selectedPriority, onAlarmTypeChange, onPriorityChange, onPeriodChange }) => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const gap = isSmDown ? theme.spacing(2) : theme.spacing(4);

    return (
        <Box gridGap={gap} display={'flex'} flexWrap={'wrap'}>
            <Period value={periodValue} onChange={onPeriodChange} />

            <Priority selectedPriority={selectedPriority} onChange={onPriorityChange} />

            <AlarmType selectedAlarmType={selectedAlarmType} onChange={onAlarmTypeChange} />
        </Box>
    );
};

export default Filters;
