import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';

const PatchNotificationEnableSchema = z.object({
    success: z.literal(true),
});

export interface PatchNotificationEnableParams {
    contactId: number;
}

export const mutatePatchNotificationEnable = async (values: PatchNotificationEnableParams) => {
    const { contactId } = values;
    const response = await makeMutation(ApiEndpoints.patchNotificationEnable(contactId), undefined, true, 'PATCH');
    return PatchNotificationEnableSchema.parse(response);
};

export const patchNotificationEnableErrorCodes = {};
