import { TableOptions, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import NoResults from 'components/noResults/noResults';
import React from 'react';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    pageCount: number;
}

const ManualTable = <T extends Record<string, unknown>>({ pageCount, columns, data, isFetching, onSort, onFilter, onPageIndexChange }: React.PropsWithChildren<TableProperties<T>>) => {
    const classes = useStyles();

    const {
        getTableProps,
        getTableBodyProps,
        rows,
        state: { pageIndex },
        prepareRow,
    } = useTable<T>(
        {
            columns,
            data,
            pageCount,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    React.useEffect(() => {
        onPageIndexChange(pageIndex);
    }, [pageIndex, onPageIndexChange]);

    return (
        <>
            <table {...getTableProps()} className={classes.root}>
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row, i) => {
                        prepareRow(row);
                        const { key, role } = row.getRowProps();

                        return (
                            <React.Fragment key={key}>
                                <tr className={classes.tableRow} role={role}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={classes.tableCell} style={cell.column.customStyles}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default ManualTable;
