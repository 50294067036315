import { TwoLinesAxisTickProps } from 'components/chart/components/twoLinesAxisTick/twoLinesAxisTickProps';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import React from 'react';

const TwoLinesAxisTick: React.FC<TwoLinesAxisTickProps> = ({ x, y, payload }) => {
    if (typeof payload.value === 'number') {
        return null;
    }
    const array = payload.value.split(DateTimeFormat.DATE_INTERVAL_SEPARATOR);
    return (
        <g transform={`translate(${x},${y + 40}) rotate(270, -10, 10)`}>
            <text dy={16} fill="#000" fontSize="12" x={0} y={0}>
                <tspan textAnchor="middle" x="0">
                    {array[0]}
                </tspan>
                <tspan dy="14" textAnchor="middle" x="0">
                    {array[1]}
                </tspan>
            </text>
        </g>
    );
};

export default TwoLinesAxisTick;
