import * as z from 'zod';

export const ReportingStoreLiveStructureStoreAssetSchema = z.object({
    assetId: z.number(),
    shortName: z.string(),
    longName: z.string(),
    setpoint: z.string().nullable(),
    altSetpoint: z.string().nullable(),
    zone: z.string().optional(),
    dataseries: z.object({
        Temp: z
            .object({
                value: z.number().nullable(),
                unit: z.string().nullable(),
            })
            .optional(),
        AltSetpoints: z
            .object({
                value: z.number().nullable(),
                unit: z.string().nullable(),
            })
            .optional(),
        Defrost: z
            .object({
                value: z.number().nullable(),
                unit: z.string().nullable(),
            })
            .optional(),
        Door: z
            .object({
                value: z.number().nullable(),
                unit: z.string().nullable(),
            })
            .optional(),
    }),
});

export const StaticPolyItemSchema = z.object({
    type: z.literal('BackgroundElement'),
    position: z.object({
        x: z.number(),
        y: z.number(),
    }),
    style: z.object({
        fillcolor: z.string(),
        outlinecolor: z.string(),
        outlinewidth: z.number(),
    }),
    points: z.array(z.object({ x: z.number(), y: z.number() })),
});

export const StaticTextItemSchema = z.object({
    type: z.literal('Label'),
    position: z.object({
        x: z.number(),
        y: z.number(),
    }),
    style: z.object({
        fillcolor: z.string(),
        size: z.number(),
    }),
    text: z.string(),
});

export const ReportingStoreLiveStructureStoreSchema = z.object({
    measuredOn: z.string(),
    storeId: z.number(),
    assets: z.array(ReportingStoreLiveStructureStoreAssetSchema),
    alarms: z.array(
        z.object({
            assetId: z.number().nullable(),
            event: z.string(),
            can: z.number(),
            zone: z.string(),
            position: z.string(),
            priority: z.number(),
            startOn: z.string(),
            endOn: z.string().nullable(),
            // startOn: z.string(),
            // endOn: z.string().nullable(),
            comment: z.string().nullable(),
        }),
    ),
    layout: z
        .object({
            version: z.number(),
            canvas: z.object({
                dimensions: z.object({
                    width: z.number(),
                    height: z.number(),
                }),
            }),
            staticItems: z.array(z.discriminatedUnion('type', [StaticPolyItemSchema, StaticTextItemSchema])).optional(),
            items: z.array(
                z.object({
                    shortname: z.string().nullable().optional(),
                    name: z.string().nullable().optional(),
                    assetId: z.number().nullable(),
                    position: z.object({
                        x: z.number(),
                        y: z.number(),
                    }),
                    dimensions: z.object({
                        width: z.number(),
                        height: z.number(),
                    }),
                    style: z.object({
                        shape: z.string().optional(),
                        color: z.string().optional(),
                    }),
                }),
            ),
        })
        .optional()
        .nullable(),
});

export const ReportingStoreLiveStructureSchema = z.object({
    data: ReportingStoreLiveStructureStoreSchema,
});

export type StaticPolyItem = z.infer<typeof StaticPolyItemSchema>;
export type StaticTextItem = z.infer<typeof StaticTextItemSchema>;
export type ReportingStoreLiveStructure = z.infer<typeof ReportingStoreLiveStructureSchema>;
export type ReportingStoreLiveStructureStore = z.infer<typeof ReportingStoreLiveStructureStoreSchema>;
export type ReportingStoreLiveStructureStoreAsset = z.infer<typeof ReportingStoreLiveStructureStoreAssetSchema>;
