import { Box, useTheme } from '@material-ui/core';
import { Cell, IdType, TableOptions, useExpanded, useRowSelect, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import { useTranslation } from 'react-i18next';
import chartColors from 'theme/chartColors';
import Checkbox from '@material-ui/core/Checkbox';
import HeadingTwo from 'components/typography/headingTwo/headingTwo';
import React, { Dispatch, SetStateAction } from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    shownMeterNamesIds: number[];
    handleShownMeterNamesIds: Dispatch<SetStateAction<number[]>>;
}

const EnergyMeterTable = <T extends Record<string, unknown>>({ columns, data, shownMeterNamesIds, handleShownMeterNamesIds }: React.PropsWithChildren<TableProperties<T>>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds },
        toggleRowSelected,
    } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Cell: ValueDisplay,
            },
            initialState: {
                selectedRowIds: shownMeterNamesIds.reduce((object, current) => ({ ...object, [current]: true }), {}) as Record<IdType<T>, boolean>,
            },
        },
        useSortBy,
        useExpanded,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    customStyles: {
                        width: theme.typography.pxToRem(60),
                    },
                    Cell: ({ row }: Cell<T>) => {
                        return (
                            <div>
                                <Checkbox {...row.getToggleRowSelectedProps()} style={{ color: chartColors[row.index] }} />
                            </div>
                        );
                    },
                },
                ...columns,
            ]);
        },
    );

    const classes = useStyles();

    React.useEffect(() => {
        toggleRowSelected('0', true);
    }, [toggleRowSelected]);

    React.useEffect(() => {
        const newState = Object.keys(selectedRowIds).map((id) => parseInt(id));
        if (shownMeterNamesIds.toString() !== newState.toString()) {
            handleShownMeterNamesIds(newState);
        }
    }, [selectedRowIds, handleShownMeterNamesIds, shownMeterNamesIds]);

    return (
        <>
            <Box ml={9.25} mt={4}>
                <HeadingTwo>{t('energyMeterTable.title')}</HeadingTwo>
            </Box>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} />
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        const { key, role } = row.getRowProps();
                        return (
                            <React.Fragment key={key}>
                                <tr className={classes.tableRow} role={role}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={classes.tableCell}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default EnergyMeterTable;
