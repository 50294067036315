import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { makeStyles } from '@material-ui/core/styles';
import { StoreHeaderInformationProps } from 'components/storeHeaderInformation/storeHeaderInformationProps';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.customPalette.text.primary,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        margin: `0 ${theme.spacing(1.5)}`,
        flexWrap: 'wrap',
        lineHeight: 1.1,
        [theme.breakpoints.up('md')]: {
            margin: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        },
    },
    separator: {
        height: theme.typography.pxToRem(16),
        width: theme.typography.pxToRem(1),
        background: '#CCC',
        margin: `0 ${theme.spacing(1)}`,
        [theme.breakpoints.up('md')]: {
            height: theme.typography.pxToRem(24),
        },
    },
    arrowSeparator: {
        position: 'relative',
        top: 1,
    },
}));

const StoreHeaderInformation: React.FC<StoreHeaderInformationProps> = ({ arrowIcons, data: { customer, region, street, town, zipCode, anlage } }) => {
    const classes = useStyles();
    const parts = [customer, region, street, town, zipCode, anlage];
    return (
        <div className={clsx(classes.root, 'test-store-information')}>
            {parts.map((part, key) => (
                <React.Fragment key={`${part}-${key}`}>
                    <div>{part}</div>
                    <>
                        {key !== parts.length - 1 && (
                            <>
                                {arrowIcons ? (
                                    <div className={classes.arrowSeparator}>
                                        <ArrowRightIcon />
                                    </div>
                                ) : (
                                    <div className={classes.separator} />
                                )}
                            </>
                        )}
                    </>
                </React.Fragment>
            ))}
        </div>
    );
};

export default StoreHeaderInformation;
