import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ReportingMobileListItemProps } from 'components/reportingMobileListItem/reportingMobileListItemProps';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Temperature from 'components/table/components/temperature/temperature';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { InView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottomColor: theme.customPalette.border,
        borderBottomWidth: theme.typography.pxToRem(1),
        borderBottomStyle: 'solid',
    },
    value: {
        color: theme.customPalette.text.primary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
    },
    label: {
        color: theme.customPalette.text.secondary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(8),
    },
    hourTable: {
        borderCollapse: 'collapse',
        lineHeight: 0.8,
        width: '100%',
        '& th': {
            color: theme.customPalette.text.secondary,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(8),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(6 / 8),
        },
        '& td': {
            width: 'calc(100% / 8)',
            textAlign: 'center',
            padding: `0 ${theme.spacing(0.5)}`,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
        },
    },
}));

const ReportingMobileListItem: React.FC<ReportingMobileListItemProps> = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!data || !data.hours) {
        return null;
    }

    const row = {
        original: data,
    };
    return (
        <InView triggerOnce={false}>
            {({ ref, inView }) => (
                // @ts-ignore
                <Box className={classes.root} p={2} ref={ref}>
                    {inView ? (
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={3}>
                                <div className={classes.label}>{t('foodSafety.reporting.table.heading.coolingPosition')}</div>
                                <div className={classes.value}>
                                    <Link to={routes.asset.path(data.id)}>{data.longName}</Link>
                                </div>
                            </Grid>
                            <Grid item sm={8} xs={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <div className={classes.label}>{t('foodSafety.reporting.table.heading.case')}</div>
                                        <div className={classes.value}>
                                            <ValueDisplay value={data.zone} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={classes.label}>{t('foodSafety.reporting.table.heading.position')}</div>
                                        <div className={classes.value}>
                                            <ValueDisplay value={data.shortName} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={classes.label}>{t('foodSafety.reporting.table.heading.setPoint')}</div>
                                        <div className={classes.value}>
                                            <ValueDisplay value={data.setPoint} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={classes.label}>{t('foodSafety.reporting.table.heading.alt')}</div>
                                        <div className={classes.value}>
                                            <ValueDisplay value={data.setPointAlt} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.label}>{t('foodSafety.reporting.table.heading.avgTemp')}</div>
                                        <table className={classes.hourTable}>
                                            <thead>
                                                <tr>
                                                    <th>00</th>
                                                    <th>01</th>
                                                    <th>02</th>
                                                    <th>03</th>
                                                    <th>04</th>
                                                    <th>05</th>
                                                    <th>06</th>
                                                    <th>07</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h0} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h1} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h2} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h3} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h4} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h5} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h6} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h7} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th>08</th>
                                                    <th>09</th>
                                                    <th>10</th>
                                                    <th>11</th>
                                                    <th>12</th>
                                                    <th>13</th>
                                                    <th>14</th>
                                                    <th>15</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h8} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h9} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h10} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h11} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h12} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h13} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h14} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h15} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th>16</th>
                                                    <th>17</th>
                                                    <th>18</th>
                                                    <th>19</th>
                                                    <th>20</th>
                                                    <th>21</th>
                                                    <th>22</th>
                                                    <th>23</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h16} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h17} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h18} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h19} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h20} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h21} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h22} />
                                                    </td>
                                                    <td>
                                                        <Temperature row={row} value={data.hours.h23} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div style={{ height: 179 }}></div>
                    )}
                </Box>
            )}
        </InView>
    );
};

export default ReportingMobileListItem;
