import { DayHeadingWithIconsProps } from 'components/table/components/dayHeadingWithIcons/dayHeadingWithIconsProps';
import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import Box from '@material-ui/core/Box';
import LightSeparator from 'components/table/components/lightSeparator/lightSeparator';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    iconOuter: {
        width: theme.typography.pxToRem(15),
        textAlign: 'center',
    },
}));

const DayHeadingWithIcons: React.FC<DayHeadingWithIconsProps> = ({ text }) => {
    const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column">
            <div>{text}</div>
            <div>
                <Box alignItems="center" display="flex">
                    <span className={classes.iconOuter}>
                        <SunIcon />
                    </span>
                    <LightSeparator type={LightSeparatorType.vertical} />
                    <span className={classes.iconOuter}>
                        <MoonIcon />
                    </span>
                </Box>
            </div>
        </Box>
    );
};

export default DayHeadingWithIcons;
