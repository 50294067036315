const FOOD_SAFETY_REPORTING_FILTER = 'FOOD_SAFETY_REPORTING_FILTER';

interface FoodSafetyReportingFilter {
    date: number;
}

export const setFoodSafetyReportingFilter = (filter: FoodSafetyReportingFilter) => {
    localStorage.setItem(FOOD_SAFETY_REPORTING_FILTER, JSON.stringify(filter));
};
export const hasFoodSafetyReportingFilter = () => Boolean(localStorage.getItem(FOOD_SAFETY_REPORTING_FILTER));
export const getFoodSafetyReportingFilter = () => {
    const value = localStorage.getItem(FOOD_SAFETY_REPORTING_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearFoodSafetyReportingFilter = () => {
    localStorage.removeItem(FOOD_SAFETY_REPORTING_FILTER);
};
