import { Bar, CartesianGrid, BarChart as RechartsBarChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CommonChartProps } from 'components/chart/commonChartProps';
import { useTheme } from '@material-ui/core';
import chartColors from 'theme/chartColors';
import ChartTooltip from 'components/chart/components/chartTooltip/chartTooltip';
import OneLineAxisTick from 'components/chart/components/oneLineAxisTick/oneLineAxisTick';
import React from 'react';
import TwoLinesAxisTick from 'components/chart/components/twoLinesAxisTick/twoLinesAxisTick';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import usePrepareChartDescriptionData from 'components/chart/usePrepareChartDescriptionData';

const HALF_DAY_IN_MS = (24 * 60 * 60 * 1000) / 2;

const BarChart: React.FC<CommonChartProps> = ({ daysDiffCount, data, meterNames, shownMeterNamesIds, unit }) => {
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const { sundays, ticks, tickCSSProperties, chartMargin, dateHasIntervalFormat } = usePrepareChartDescriptionData(data, daysDiffCount);
    if (!data.length) {
        return null;
    }
    const ticksShownCount = 15;
    const tickInterval = Math.floor(data.length / ticksShownCount);
    return (
        <ResponsiveContainer height={isSmUp ? 369 : 200} width="100%">
            <RechartsBarChart data={data} margin={chartMargin}>
                <CartesianGrid fill="rgba(248, 248, 249, 1)" stroke={theme.customPalette.border} strokeDasharray="4 4" vertical={false} />
                {!dateHasIntervalFormat &&
                    sundays.map((sunday) => {
                        const x1 = sunday.startOf('day').minus({ hours: 12 }).toMillis();
                        const x2 = sunday.endOf('day').minus({ hours: 12 }).toMillis();
                        return <ReferenceArea key={sunday.toString()} fill="black" fillOpacity={0.08} ifOverflow="extendDomain" x1={x1} x2={x2} />;
                    })}
                <XAxis
                    dataKey="xDataKey"
                    domain={dateHasIntervalFormat ? [0, 'auto'] : [`dataMin - ${HALF_DAY_IN_MS}`, `dataMax + ${HALF_DAY_IN_MS}`]}
                    interval={tickInterval}
                    tick={({ x, y, payload }) => (dateHasIntervalFormat ? <TwoLinesAxisTick payload={payload} x={x} y={y} /> : <OneLineAxisTick payload={payload} x={x} y={y} />)}
                    ticks={ticks}
                    type={dateHasIntervalFormat || data.length === 1 ? 'category' : 'number'}
                />
                <YAxis stroke={theme.customPalette.border} tick={tickCSSProperties} tickFormatter={(value) => `${value}\u00A0${unit}`} />
                <Tooltip content={(props) => <ChartTooltip props={props} unit={unit} />} />
                {meterNames.map((meterName, i) => (shownMeterNamesIds.includes(i) ? <Bar key={meterName} dataKey={meterName} fill={chartColors[i]} isAnimationActive={false} /> : null))}
            </RechartsBarChart>
        </ResponsiveContainer>
    );
};

export default BarChart;
