import createTheme from '@material-ui/core/styles/createTheme';
import customPalette from './palette';

const spacing = (factor: number) => `${0.5 * factor}rem`;

const FONT_SIZE = 16;

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 800,
            md: 960,
            lg: 1600,
            xl: 1920,
        },
    },
    spacing,
    typography: {
        fontFamily: ['Fira sans', 'sans-serif'].join(','),
        fontSize: FONT_SIZE,
    },
    palette: {
        primary: {
            main: customPalette.colors.brand.light,
        },
    },
});

theme.typography.pxToRem = (size: number) => `${size / FONT_SIZE}rem`;

theme.customPalette = {
    ...customPalette,
};

theme.customElevation = {
    default: `0 0 ${theme.typography.pxToRem(1)} ${theme.customPalette.boxShadow}`,
    insetBorder: `inset 0 ${theme.typography.pxToRem(-1)} 0 0 ${theme.customPalette.border}`,
    drag: `0 0 ${theme.typography.pxToRem(4)} ${theme.customPalette.border}, 0 ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)} ${theme.customPalette.borderDarker}`,
};

theme.overrides = {
    MuiPickersModal: {
        dialogRoot: {
            width: 'auto',
        },
    },
    MuiCssBaseline: {
        '@global': {
            'html, body': {
                background: theme.customPalette.background.dark,
            },
            a: {
                color: theme.customPalette.colors.brand.light,
                textDecoration: 'none',
            },
        },
    },
    MuiMenu: {
        paper: {
            maxHeight: '40vh',
        },
    },
    MuiPopover: {
        paper: {
            boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.24)`,
        },
    },
    MuiList: {
        padding: {
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
        },
    },
    MuiListItem: {
        gutters: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(1.25),
            paddingRight: theme.spacing(2),
        },
    },
    MuiListItemText: {
        primary: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 500,
        },
    },
    MuiListItemIcon: {
        root: {
            color: theme.customPalette.text.primary,
            minWidth: 'auto',
            marginRight: theme.spacing(1.25),
        },
    },
    MuiCheckbox: {
        root: {
            color: theme.customPalette.text.primary,
        },
        checked: {
            color: theme.customPalette.text.primary,
        },
    },
    MuiDialog: {
        paper: {
            boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.24)`,
        },
        paperWidthSm: {
            width: theme.spacing(46),
        },
    },
    MuiDialogActions: {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(2),
        },
    },
    MuiDialogTitle: {
        root: {
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(1),
            '& h2': {
                fontSize: theme.typography.pxToRem(14),
                fontWeight: 500,
            },
        },
    },
    MuiDialogContent: {
        root: {
            paddingTop: 0,
            paddingBottom: theme.spacing(1),
        },
    },
    MuiBackdrop: {
        root: {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))',
            backgroundColor: 'unset',
            backdropFilter: 'blur(16px)',
        },
    },
};

export default theme;

export type ThemeType = typeof theme;
