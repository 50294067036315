import i18next from 'i18next';

class DateTimeFormat {
    static DATE_INTERVAL_SEPARATOR = ' - ';

    static FULL_24_WITHOUT_SECONDS = () => i18next.t('dateTimeFormat.FULL_24_WITHOUT_SECONDS');

    static DATE_FORMAT = () => i18next.t('dateTimeFormat.DATE_FORMAT');
}

export default DateTimeFormat;
