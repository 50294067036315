import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import ContentTabs from 'components/contentTabs/contentTabs';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import MyProfileUserSettings from 'screens/myProfile/userSetting';
import MyProfileHelp from 'screens/myProfile/help';

const MyProfileScreen: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const items = [
        {
            id: 'myProfileUserSettings',
            path: routes.myProfileUserSettings.path(),
            text: t('user.userSettings.title'),
        },
        {
            id: 'myProfileHelp',
            path: routes.myProfileHelp.path(),
            text: t('user.userHelp.title'),
        },
    ];


    const goBackAction = () => {
        history.push(routes.default.path());
    };

    return (
        <>
            <Header goBackAction={goBackAction} goBackText={t('home')} />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Switch>
                    <Route component={MyProfileUserSettings} exact path={routes.myProfileUserSettings.pattern} />
                    <Route component={MyProfileHelp} exact path={routes.myProfileHelp.pattern} />
                </Switch>
            </Main>
        </>
    );
};

export default MyProfileScreen;
