import { LanguageId } from 'shared/useLanguages/languageId';
import { setLanguageShortCode } from 'localStorage/language';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useLanguages = () => {
    const { t, i18n } = useTranslation();

    const languages = useMemo(
        () => [
            {
                languageId: LanguageId.english,
                label: t('language.english'),
                shortCode: 'en',
            },
            {
                languageId: LanguageId.german,
                label: t('language.german'),
                shortCode: 'de',
            },
        ],
        [t],
    );

    const getLanguageCodeByLanguageId = (languageId: LanguageId) => {
        return languages.find((item) => item.languageId === languageId)?.shortCode;
    };

    const getLanguageIdByCode = (shortCode: string) => {
        const found = languages.find((item) => item.shortCode === shortCode);
        return found ? found.languageId : LanguageId.english;
    };

    const changeLanguageByLanguageId = (languageId: LanguageId) => {
        const languageCode = getLanguageCodeByLanguageId(languageId);
        if (languageCode) {
            setLanguageShortCode(languageCode);
            i18n.changeLanguage(languageCode);
        }
    };

    return { languages, changeLanguageByLanguageId, getLanguageIdByCode, getLanguageCodeByLanguageId };
};

export default useLanguages;
