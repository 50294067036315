import { Link, matchPath, useLocation } from 'react-router-dom';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Box, Grid } from '@material-ui/core';
import { ContentTabsProps } from 'components/contentTabs/contentTabsProps';
import Button from 'components/button/button';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import AlarmTimeDurationPicker from 'components/alarmTimeDurationPicker/alarmTimeDurationPicker';
import { DateTime } from 'luxon';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import { routes } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import { useStyles } from './styles';
import { AppState } from 'store';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { alarmReportDateFrom, alarmReportDateTo } from 'store/filters/actions';
import { DateTimeWithTimezone } from 'shared/utils/dateWithTimezone';

interface Props extends ContentTabsProps {
    dataRefreshed: string | null;
    isFetching: boolean;
    refetch: () => void;
}

const AlarmHeader: React.FC<Props> = ({ items, dataRefreshed, isFetching, refetch }: Props) => {
    const { t } = useTranslation();
    const { dateFrom, dateTo } = useSelector((state: AppState) => state.filters.alarmReport);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const classes = useStyles();
    const activeItem = items.find((item) => !!matchPath(pathname, { path: item.path }) || (item.parentPath && !!matchPath(pathname, { path: item.parentPath })));

    const handleDateFrom = (date: MaterialUiPickersDate) => {
        if (!date) {
            return;
        }
        dispatch(alarmReportDateFrom(date));
    };

    const handleDateTo = (date: MaterialUiPickersDate) => {
        if (!date) {
            return;
        }
        dispatch(alarmReportDateTo(date));
    };

    const renderAlarmDataRefreshed = () => {
        if (!dataRefreshed) {
            return null;
        }

        const luxonDataRefreshed = DateTime.fromISO(dataRefreshed, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        const dataRefreshedFormatted = luxonDataRefreshed.toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS());
        const luxonNow = DateTime.now();
        const minutesDiff = luxonNow.diff(luxonDataRefreshed, 'minutes').minutes;

        return (
            <div className={clsx(classes.date, minutesDiff > 15 && classes.oldDate)}>
                {t('dataRefreshed')}: <strong>{dataRefreshedFormatted}</strong>
            </div>
        );
    };

    return (
        <Box className={classes.root}>
            <Grid className={classes.gridWrapper} container spacing={2}>
                <Grid className={classes.gridTabWrapper} item md={4} sm={6} xs={12}>
                    <Box alignItems="center" display="flex">
                        <Tabs
                            classes={{
                                root: classes.tabs,
                                indicator: classes.indicator,
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            value={activeItem && activeItem.id}
                        >
                            {items.map((item) => (
                                <Tab
                                    key={item.id}
                                    classes={{
                                        wrapper: classes.tabWrapper,
                                        selected: classes.selected,
                                    }}
                                    className={classes.tab}
                                    component={Link}
                                    id={`test-${item.id}`}
                                    label={item.text}
                                    to={item.path}
                                    value={item.id}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Grid>
                {pathname === routes.alarmListView.path() && (
                    <>
                        <Grid className={classes.gridTimeDurationWrapper} item md={4} sm={6} xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AlarmTimeDurationPicker />
                            </Box>
                        </Grid>
                        <Grid className={classes.gridDataRefreshWrapper} item md={4} sm={6} xs={12}>
                            <Box className={classes.dateRefreshedWrapper}>
                                {renderAlarmDataRefreshed()}
                                <Button icon={<RefreshIcon className={clsx(classes.icon)} />} isLoading={isFetching} onClick={refetch}>
                                    {t('refresh')}
                                </Button>
                            </Box>
                        </Grid>
                    </>
                )}
                {pathname === routes.alarmTableView.path() && (
                    <Grid className={classes.gridDatePickersWrapper} item md={4} sm={6} xs={12}>
                        <div className={classes.dateRange}>
                            <DatePicker
                                cancelLabel={t('datePicker.cancel')}
                                className={classes.input}
                                format={DateTimeFormat.DATE_FORMAT()}
                                inputVariant="outlined"
                                label={t('intervalPickerDialog.from')}
                                maxDate={dateTo ? dateTo.startOf('day') : undefined}
                                okLabel={t('datePicker.ok')}
                                value={dateFrom}
                                onChange={handleDateFrom}
                            />
                            <DatePicker
                                cancelLabel={t('datePicker.cancel')}
                                className={classes.input}
                                format={DateTimeFormat.DATE_FORMAT()}
                                inputVariant="outlined"
                                label={t('intervalPickerDialog.to')}
                                maxDate={DateTimeWithTimezone}
                                minDate={dateFrom.startOf('day')}
                                okLabel={t('datePicker.ok')}
                                value={dateTo}
                                onChange={handleDateTo}
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AlarmHeader;
