import React from 'react';
import { ReportingStoreLiveStructureStore } from '../../model/reportingStoreLive/schema';
import Box from '@material-ui/core/Box';
import Alarm from '../../components/alarm/alarm';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { DateTime } from '../../shared/luxon/luxon';

interface IAlarmsBody {
    store: ReportingStoreLiveStructureStore;
    isFullscreen: boolean;
    handleHighlightedAsset: (value: number | null) => void;
}

const useStyles = makeStyles((theme) => {
    const TAB_HEIGHT = {
        small: theme.spacing(3),
        medium: theme.spacing(4),
        large: theme.spacing(5),
    };
    return {
        indicator: {
            background: theme.customPalette.colors.brand.light,
        },
        tabs: {
            boxShadow: '0 1px 0 0 rgb(0 0 0 / 8%)',
            minHeight: TAB_HEIGHT.small,
            [theme.breakpoints.up('sm')]: {
                minHeight: TAB_HEIGHT.medium,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: TAB_HEIGHT.large,
            },
        },
        tab: {
            minHeight: TAB_HEIGHT.small,
            [theme.breakpoints.up('sm')]: {
                minHeight: TAB_HEIGHT.medium,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: TAB_HEIGHT.large,
            },
            height: '100%',
            minWidth: 'auto',
            textTransform: 'none',
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
            color: theme.customPalette.colors.brand.light,
            '&$selected': {
                color: theme.customPalette.text.primary,
            },
        },
        selected: {},
        tabWrapper: {
            flexDirection: 'row',
            '& svg': {
                marginTop: theme.typography.pxToRem(6),
                marginRight: theme.spacing(1),
            },
        },
    };
});

const AlarmsBody: React.FC<IAlarmsBody> = ({ store, isFullscreen, handleHighlightedAsset }) => {
    const { t } = useTranslation();
    const items = [
        { id: 'all', text: t('alarmsBody.all') },
        { id: 'prio1', text: t('alarmsBody.prio1') },
        { id: 'prio2', text: t('alarmsBody.prio2') },
        { id: 'closed', text: t('alarmsBody.closed') },
    ];
    const [activeItem, setActiveItem] = React.useState<string>(items[0].id);
    const classes = useStyles();
    return (
        <>
            <Tabs
                centered
                classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                }}
                indicatorColor="primary"
                textColor="primary"
                value={activeItem}
                variant="fullWidth"
            >
                {items.map((item) => (
                    <Tab
                        key={item.id}
                        classes={{
                            wrapper: classes.tabWrapper,
                            selected: classes.selected,
                        }}
                        className={classes.tab}
                        label={item.text}
                        value={item.id}
                        onClick={() => setActiveItem(item.id)}
                    />
                ))}
            </Tabs>
            <Box p={2} pb={1} style={{ maxHeight: `calc(100vh - ${isFullscreen ? '120px' : '230px'})`, overflowY: 'scroll' }}>
                {store.alarms
                    .filter(({ priority, endOn }) => {
                        if (activeItem === 'all') {
                            return true;
                        }
                        if (activeItem === 'prio1') {
                            return priority === 1;
                        }
                        if (activeItem === 'prio2') {
                            return priority === 2;
                        }
                        if (activeItem === 'closed') {
                            return !!endOn;
                        }
                        return true;
                    })
                    .sort((a, b) => {
                        return DateTime.fromJSDate(new Date(a.startOn)) > DateTime.fromJSDate(new Date(b.startOn)) ? -1 : 1;
                    })
                    .map((item) => {
                        const relatedAsset = store.assets.find((assetItem) => assetItem.assetId === item.assetId);
                        const key = (Math.random() + 1).toString(36).substring(7);
                        return (
                            <Alarm
                                key={key}
                                assetId={item.assetId}
                                comment={item.comment}
                                description={item.event}
                                endOn={item.endOn}
                                handleHighlightedAsset={handleHighlightedAsset}
                                longName={relatedAsset?.longName}
                                shortName={relatedAsset?.shortName}
                                startOn={item.startOn}
                                status={item.priority}
                                temperature={store.assets?.find((asset) => asset.assetId === item.assetId)?.dataseries?.Temp?.value || undefined}
                                title={item.position}
                            />
                        );
                    })}
            </Box>
        </>
    );
};

export default AlarmsBody;
