import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.primary,
        textAlign: 'left',
        width: '100%',
        borderCollapse: 'collapse',
    },
    tableHeading: {
        background: theme.customPalette.background.white,
        position: 'sticky',
        top: 0,
        fontWeight: 500,
        color: theme.customPalette.text.secondary,
        verticalAlign: 'top',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        userSelect: 'none',
        [theme.breakpoints.up('sm')]: {
            '&:first-child': {
                paddingLeft: theme.spacing(2),
            },
            '&:last-child': {
                paddingRight: theme.spacing(2),
            },
        },
    },
    tableRow: {
        '& td': {
            padding: `${theme.spacing(11 / 8)} ${theme.spacing(1)}`,
            [theme.breakpoints.up('sm')]: {
                '&:first-child': {
                    paddingLeft: theme.spacing(2),
                },
                '&:last-child': {
                    paddingRight: theme.spacing(2),
                },
            },
        },
        '&:first-child $tableCell': {
            borderTop: 'none',
        },
    },
    tableRowClickable: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f7f9ff',
        },
    },
    tableHeadingRow: {
        '&:first-child $tableHeading': {
            paddingTop: theme.spacing(1.5),
            paddingBottom: 0,
        },
        '&:last-child $tableHeading': {
            paddingTop: theme.spacing(6 / 8),
            paddingBottom: theme.spacing(1.5),
            boxShadow: theme.customElevation.insetBorder,
        },

        '&:last-child:last-child $tableHeading': {
            paddingTop: theme.spacing(12 / 8),
        },
    },
    tableCell: {
        borderTopColor: theme.customPalette.border,
        borderTopWidth: theme.typography.pxToRem(1),
        borderTopStyle: 'solid',
    },
    weekTable: {
        margin: theme.spacing(2),
        borderCollapse: 'collapse',
        lineHeight: 0.8,
        width: '100%',
        '& th': {
            width: 'calc(100% / 7)',
            color: theme.customPalette.text.secondary,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(10),
            paddingBottom: theme.spacing(1),
        },
        '& td': {
            textAlign: 'center',
            padding: `0 ${theme.spacing(0.5)}`,
        },
    },
    hourTable: {
        borderCollapse: 'collapse',
        lineHeight: 0.8,
        width: '100%',
        '& th': {
            color: theme.customPalette.text.secondary,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(10),
            padding: `0 ${theme.spacing(0.5)} ${theme.spacing(1)}`,
            width: 'calc(100% / 24)',
            textAlign: 'right',
            '& span': {
                color: theme.customPalette.text.primary,
            },
        },
        '& th[colspan]': {
            textAlign: 'left',
        },
        '& td': {
            padding: `0 ${theme.spacing(0.5)}`,
            textAlign: 'right',
            fontSize: theme.typography.pxToRem(12),
        },
    },
    weekTableValueOuter: {
        display: 'flex',
        alignItems: 'center',
    },
    sortIcon: {
        position: 'absolute',
    },
    hourTableOuterCell: {
        padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(8)}`,
    },
    svgIcon: {
        fill: 'currentColor',
    },
    dragIcon: {
        fill: theme.customPalette.placeholder,
    },
    tableHeadingMove: {
        width: theme.typography.pxToRem(50),
    },
}));
