export enum ExportButtonType {
    email = 'email',
    excel = 'excel',
    csv = 'csv',
    pdf = 'pdf',
}

interface ExportButtonProps {
    type: ExportButtonType;
    text: string;
    action: () => void;
    key: string;
    isLoading?: boolean;
}

export interface ExportButtonsProps {
    buttons: ExportButtonProps[];
    disabled: boolean;
    showLabel?: boolean;
}
