import { ApiMutationResponseSchema, ApiQueryResponseSchema } from 'shared/network/apiResponseSchema';
import { FilterParameter, QueryHeader, QueryParameter, SortParameter } from 'api/types';
import { getToken } from 'localStorage/auth';
import { httpCodes } from 'shared/network/httpCodes';
import { logout } from 'store/auth/actions';
import { TFunction } from 'i18next';
import ResponseFormatError from 'shared/errors/responseFormatError';
import settings from 'settings';
import StandardApiError from 'shared/errors/standardApiError';

export const makeMutation = async (url: string, values?: unknown, withAuth: boolean = false, method: string = 'POST') => {
    const token = getToken();
    const finalUrl = `${settings.apiVersion}${url}`;
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (token && withAuth) {
        headers['X-Auth-Token'] = token;
    }
    const res = await fetch(finalUrl, {
        method,
        headers,
        body: values ? JSON.stringify(values) : undefined,
    });
    const json = await res.json();
    const parsedResponse = ApiMutationResponseSchema.safeParse(json);
    if (parsedResponse.success === false) {
        throw new ResponseFormatError(parsedResponse, res);
    } else {
        if (parsedResponse.success && parsedResponse.data.success) {
            return parsedResponse.data;
        } else {
            throw new StandardApiError(parsedResponse.data, res);
        }
    }
};

export const makeFileMutation = async (url: string, values?: unknown, withAuth: boolean = false, method: string = 'POST') => {
    const token = getToken();
    const finalUrl = `${settings.apiVersion}${url}`;
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (token && withAuth) {
        headers['X-Auth-Token'] = token;
    }
    const res = await fetch(finalUrl, {
        method,
        headers,
        body: values ? JSON.stringify(values) : undefined,
    });
    return {
        blob: await res.blob(),
        fileName: res.headers.get('content-disposition')?.split('filename=')[1],
    };
};

const getQueryResponse = async (
    url: string,
    withAuth: boolean = false,
    sort: SortParameter = undefined,
    filter: FilterParameter = undefined,
    query: QueryParameter = undefined,
    requestHeaders: QueryHeader = {},
    queryParams: string[] = [],
) => {
    const token = getToken();
    if (sort) {
        queryParams.push(`orderBy=${encodeURIComponent(sort.map((item) => `${item.column}[${item.direction}]`).join(','))}`);
    }
    if (filter && filter.length) {
        queryParams.push(
            `filterBy=${encodeURIComponent(
                filter
                    .map((item) => {
                        if (Array.isArray(item.value)) {
                            return item.value.map((subValue) => `(${item.column}[${item.operator}]${subValue})`).join('or');
                        }
                        return item.operator === 'like' ? `(${item.column}[${item.operator}]%${item.value}%)` : `(${item.column}[${item.operator}]${item.value})`;
                    })
                    .join('and'),
            )}`,
        );
    }
    if (query) {
        queryParams.push(`q=${query}`);
    }

    const hasQueryParams = !!queryParams.length;

    return await fetch(`${settings.apiVersion}${url}${hasQueryParams ? `?${queryParams.join('&')}` : ''}`, {
        headers:
            withAuth && token
                ? {
                      ...requestHeaders,
                      'X-Auth-Token': token,
                  }
                : requestHeaders,
    });
};

export interface FileData {
    blob: Blob;
    fileName?: string;
}

export const makeFileQuery = async (url: string, withAuth: boolean = false, sort: SortParameter = undefined, filter: FilterParameter = undefined): Promise<FileData> => {
    const res = await getQueryResponse(url, withAuth, sort, filter);
    if (!res.ok) {
        throw new StandardApiError(null, res);
    }
    return {
        blob: await res.blob(),
        fileName: res.headers.get('content-disposition')?.split('filename=')[1],
    };
};

export const makeQuery = async (
    url: string,
    withAuth: boolean = false,
    sort: SortParameter = undefined,
    filter: FilterParameter = undefined,
    query: QueryParameter = undefined,
    headers: Object = {},
    queryParams: string[] = [],
) => {
    const res = await getQueryResponse(url, withAuth, sort, filter, query, headers, queryParams);
    const json = await res.json();
    const parsedResponse = ApiQueryResponseSchema.safeParse(json);
    if (res.status === httpCodes['401']) {
        logout();
    }
    if (parsedResponse.success === false) {
        throw new ResponseFormatError(parsedResponse, res);
    } else {
        if (parsedResponse.success && parsedResponse.data.success) {
            return parsedResponse.data;
        } else {
            throw new StandardApiError(parsedResponse.data, res);
        }
    }
};

export type EndpointSpecificErrors = Record<string, { code: string; translation: (t: TFunction) => string }>;

export type MutationResponse = {
    success: boolean;
};
