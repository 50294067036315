import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Content from 'components/content/content';
import ContentHeader from 'components/contentHeader/contentHeader';
import ExportSingleButton from 'components/exportButtonSingle';
import { ExportButtonSingleType } from 'components/exportButtonSingle/exportButtonSingleProps';
import LoadingBar from 'components/loadingBar/loadingBar';
import ManualTable from 'components/table/instances/manualTable';
import FootNote from 'components/typography/footNote';
import FootNoteSemiBold from 'components/typography/footNoteSemiBold';
import ParagraphMedium from 'components/typography/paragraphMedium';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { LanguageCode } from 'shared/enums/languageCode';
import { ManualTableAccessor } from 'types/users/manualTableAccessors';

export interface ManualProps {
    name: string;
    uploadedAt: string;
    files: Array<{
        name: string;
        lang: string;
        path: string;
        fileName: string;
    }>;
}

const Help: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.down('sm'));
    const isFetching = false;

    const manuals: ManualProps[] = [
        {
            name: t('user.userHelp.userManual'),
            uploadedAt: t('user.userHelp.uploaded', { date: '21.3.2023' }),
            files: [
                {
                    name: t('user.userHelp.documents.cz'),
                    lang: LanguageCode.cz,
                    path: '/assets/manuals/CARRIER-WEB-e-SERVICE-UZIVATELSKY-MANUAL-cze.pdf',
                    fileName: 'CARRIER-WEB-e-SERVICE-UZIVATELSKY-MANUAL-cze.pdf',
                },
                {
                    name: t('user.userHelp.documents.de'),
                    lang: LanguageCode.de,
                    path: '/assets/manuals/CARRIER-WEB-e-SERVICE-REPORTING-APP-BENUTZERHANDBUCH-de.pdf',
                    fileName: 'CARRIER-WEB-e-SERVICE-REPORTING-APP-BENUTZERHANDBUCH-de.pdf',
                },
                {
                    name: t('user.userHelp.documents.en'),
                    lang: LanguageCode.en,
                    path: '/assets/manuals/CARRIER-WEB-e-SERVICE-USER-MANUAL-eng.pdf',
                    fileName: 'CARRIER-WEB-e-SERVICE-USER-MANUAL-eng.pdf',
                },
            ],
        },
    ];

    const columns = React.useMemo<Column[]>(() => {
        return [
            {
                Header: 'Name',
                accessor: ManualTableAccessor.name,
                Cell: ({ row }: Cell) => {
                    return <FootNoteSemiBold>{row.values[ManualTableAccessor.name]}</FootNoteSemiBold>;
                },
            },
            {
                Header: 'Uploaded Date',
                accessor: ManualTableAccessor.uploadedAt,
                customStyles: {
                    width: '10%',
                },
            },
            {
                Header: 'Manuals',
                accessor: ManualTableAccessor.files,
                Cell: ({ row }: Cell) => {
                    return (
                        <Box display="flex" flexDirection={isSmUp ? 'column' : 'row-reverse'}>
                            {row.values[ManualTableAccessor.files].map(({ name, fileName, path }: ManualProps[ManualTableAccessor.files][0]) => {
                                return (
                                    <>
                                        <ExportSingleButton
                                            key={name}
                                            button={{
                                                type: ExportButtonSingleType.pdf,
                                                text: name,
                                                action: () => {},
                                            }}
                                            disabled={false}
                                            href={path}
                                            target="_blank"
                                        />
                                    </>
                                );
                            })}
                        </Box>
                    );
                },
                customStyles: {
                    width: '20%',
                },
            },
        ];
    }, [t, isSmUp]);

    return (
        <>
            <Content>
                <LoadingBar isFetching={isFetching} />
                <ContentHeader>
                    <Box padding="20px">
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <ParagraphMedium>{t('user.userHelp.manual')}</ParagraphMedium>
                            </Grid>
                            <Grid item>
                                <FootNote>{t('user.userHelp.manualDescription')}</FootNote>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentHeader>
                <ManualTable columns={columns} data={manuals} isFetching={false} pageCount={0} onFilter={() => {}} onPageIndexChange={() => {}} onSort={() => {}} />
            </Content>
        </>
    );
};

export default Help;
