import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const CenteredLayout: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item md={5} sm={8} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default CenteredLayout;
