import { TFunction } from 'i18next';

// TODO: connect to table functionality
export const BadRequestErrorWithPaginationSortingFilteringErrorCodes = {
    // pagination
    INVALID_RANGE_HEADER: {
        code: 'INVALID_RANGE_HEADER',
        translation: (t: TFunction) => t('pagination.INVALID_RANGE_HEADER'),
    },

    // ordering
    UNEXPECTED_ORDER_CRITERION: {
        code: 'UNEXPECTED_ORDER_CRITERION',
        translation: (t: TFunction) => t('ordering.UNEXPECTED_ORDER_CRITERION'),
    },
    UNEXPECTED_ORDER_DIRECTION: {
        code: 'UNEXPECTED_ORDER_DIRECTION',
        translation: (t: TFunction) => t('ordering.UNEXPECTED_ORDER_DIRECTION'),
    },
    INVALID_ORDER_DATA_FORMAT: {
        code: 'INVALID_ORDER_DATA_FORMAT',
        translation: (t: TFunction) => t('ordering.INVALID_ORDER_DATA_FORMAT'),
    },

    // filtering
    INVALID_FORMAT_OF_DATA_IN_FILTER: {
        code: 'INVALID_FORMAT_OF_DATA_IN_FILTER',
        translation: (t: TFunction) => t('filtering.INVALID_FORMAT_OF_DATA_IN_FILTER'),
    },
    DATA_IN_FILTER_ARE_DUPLICATED: {
        code: 'DATA_IN_FILTER_ARE_DUPLICATED',
        translation: (t: TFunction) => t('filtering.DATA_IN_FILTER_ARE_DUPLICATED'),
    },
    FILTERING_OPERATOR_IN_FILTER_IS_NOT_SUPPORTED: {
        code: 'FILTERING_OPERATOR_IN_FILTER_IS_NOT_SUPPORTED',
        translation: (t: TFunction) => t('filtering.FILTERING_OPERATOR_IN_FILTER_IS_NOT_SUPPORTED'),
    },
    INVALID_FILTER_OPERATOR: {
        code: 'INVALID_FILTER_OPERATOR',
        translation: (t: TFunction) => t('filtering.INVALID_FILTER_OPERATOR'),
    },
    FILTER_OPERATOR_IS_NOT_SUPPORTED: {
        code: 'FILTER_OPERATOR_IS_NOT_SUPPORTED',
        translation: (t: TFunction) => t('filtering.FILTER_OPERATOR_IS_NOT_SUPPORTED'),
    },
    FILTER_VALUE_NOT_ALLOWED_AFTER_NULL_OPERATOR: {
        code: 'FILTER_VALUE_NOT_ALLOWED_AFTER_NULL_OPERATOR',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_NOT_ALLOWED_AFTER_NULL_OPERATOR'),
    },
    FILTER_VALUE_EXPECTED_AFTER_COMPARISON_OPERATOR: {
        code: 'FILTER_VALUE_EXPECTED_AFTER_COMPARISON_OPERATOR',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_EXPECTED_AFTER_COMPARISON_OPERATOR'),
    },
    FILTER_VALUE_IS_NOT_TIME: {
        code: 'FILTER_VALUE_IS_NOT_TIME',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_TIME'),
    },
    FILTER_VALUE_IS_TOO_SMALL_TIME: {
        code: 'FILTER_VALUE_IS_TOO_SMALL_TIME',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_SMALL_TIME'),
    },
    FILTER_VALUE_IS_TOO_BIG_TIME: {
        code: 'FILTER_VALUE_IS_TOO_BIG_TIME',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_BIG_TIME'),
    },
    FILTER_VALUE_IS_NOT_DATETIME: {
        code: 'FILTER_VALUE_IS_NOT_DATETIME',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_DATETIME'),
    },
    FILTER_VALUE_IS_TOO_SMALL_DATETIME: {
        code: 'FILTER_VALUE_IS_TOO_SMALL_DATETIME',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_SMALL_DATETIME'),
    },
    FILTER_VALUE_IS_NOT_STRING: {
        code: 'FILTER_VALUE_IS_NOT_STRING',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_STRING'),
    },
    FILTER_VALUE_EXPECTED_AFTER_LIKE_OPERATOR: {
        code: 'FILTER_VALUE_EXPECTED_AFTER_LIKE_OPERATOR',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_EXPECTED_AFTER_LIKE_OPERATOR'),
    },
    FILTER_VALUE_STRING_IS_EMPTY: {
        code: 'FILTER_VALUE_STRING_IS_EMPTY',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_STRING_IS_EMPTY'),
    },
    FILTER_VALUE_IS_NOT_INT: {
        code: 'FILTER_VALUE_IS_NOT_INT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_INT'),
    },
    FILTER_VALUE_IS_TOO_SMALL_INT: {
        code: 'FILTER_VALUE_IS_TOO_SMALL_INT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_SMALL_INT'),
    },
    FILTER_VALUE_IS_TOO_BIG_INT: {
        code: 'FILTER_VALUE_IS_TOO_BIG_INT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_BIG_INT'),
    },
    FILTER_VALUE_EXPECTED_AFTER_EQUAL_OPERATOR: {
        code: 'FILTER_VALUE_EXPECTED_AFTER_EQUAL_OPERATOR',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_EXPECTED_AFTER_EQUAL_OPERATOR'),
    },
    FILTER_VALUE_IS_NOT_FLOAT: {
        code: 'FILTER_VALUE_IS_NOT_FLOAT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_FLOAT'),
    },
    FILTER_VALUE_IS_TOO_SMALL_FLOAT: {
        code: 'FILTER_VALUE_IS_TOO_SMALL_FLOAT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_SMALL_FLOAT'),
    },
    FILTER_VALUE_IS_TOO_BIG_FLOAT: {
        code: 'FILTER_VALUE_IS_TOO_BIG_FLOAT',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_BIG_FLOAT'),
    },
    FILTER_VALUE_IS_NOT_OF_ENUM: {
        code: 'FILTER_VALUE_IS_NOT_OF_ENUM',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_OF_ENUM'),
    },
    FILTER_VALUE_IS_NOT_DATE: {
        code: 'FILTER_VALUE_IS_NOT_DATE',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_DATE'),
    },
    FILTER_VALUE_IS_TOO_SMALL_DATE: {
        code: 'FILTER_VALUE_IS_TOO_SMALL_DATE',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_SMALL_DATE'),
    },
    FILTER_VALUE_IS_TOO_BIG_DATE: {
        code: 'FILTER_VALUE_IS_TOO_BIG_DATE',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_TOO_BIG_DATE'),
    },
    FILTER_VALUE_IS_NOT_BOOLEAN: {
        code: 'FILTER_VALUE_IS_NOT_BOOLEAN',
        translation: (t: TFunction) => t('filtering.FILTER_VALUE_IS_NOT_BOOLEAN'),
    },
};
