import * as z from 'zod';

export const LogsReportingItemSchema = z.object({
    id: z.number(),
    occurredOn: z.string(),
    reportType: z.string(),
    eventType: z.string(),
    contact: z.string(),
    lastReportedDay: z.string(),
    storeId: z.number(),
    storeName: z.string(),
});

export type LogsReportingItem = z.infer<typeof LogsReportingItemSchema>;

export const LogsReportingSchema = z.object({ data: z.array(LogsReportingItemSchema), metadata: z.object({ count: z.number() }) });

export type LogsReporting = z.infer<typeof LogsReportingSchema>;
