import { Box } from '@material-ui/core';
import { Capabilities } from 'shared/useCapabilities/capabilities';
import { getOrganizationStructure } from 'api/fetchers/getOrganizationStructure';
import { OrganizationStructureDetailProps } from 'components/organizationStructure/organizationStructureDetail/organizationStructureDetailProps';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Button from 'components/button/button';
import List from 'components/organizationStructure/list/list';
import LoadingBar from 'components/loadingBar/loadingBar';
import React from 'react';
import SearchBox from 'components/organizationStructure/searchBox/searchBox';
import useCapabilities from 'shared/useCapabilities/useCapabilities';

const OrganizationStructureDetail: React.FC<OrganizationStructureDetailProps> = () => {
    const { t } = useTranslation();
    const { id: userIdRaw } = useParams<{ id: string }>();
    const userId = parseInt(userIdRaw);
    const { isAuthorized } = useCapabilities();
    const history = useHistory();

    const [filter, setFilter] = React.useState<string | null>(null);

    const { data, isFetching } = useHandledQuery(
        [QueryKeys.getOrganizationStructure(), userId, true, undefined, filter || null],
        () => getOrganizationStructure(userId, true, undefined, undefined, filter || undefined),
        { staleTime: 0 },
    );

    return (
        <>
            <LoadingBar isFetching={isFetching} />
            <SearchBox setFilter={setFilter} />
            <List allowEdit={false} data={data} />
            <BottomBar>
                <Box display="flex">
                    {isAuthorized(Capabilities.ORGANIZATION_STRUCTURE_LIST_ALL) && (
                        <Button id="test-edit-access" isPrimary={true} size="medium" onClick={() => history.push(routes.userAccessesEdit.path(userId))}>
                            {t('organizationStructure.bar.editAccesses')}
                        </Button>
                    )}
                </Box>
            </BottomBar>
        </>
    );
};

export default OrganizationStructureDetail;
