import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { ReportType } from 'shared/enums/reportType';

const PatchNotificationReportTypeEnableSchema = z.object({
    success: z.literal(true),
});

export interface PatchNotificationReportTypeEnableParams {
    contactId: number;
    reportType: ReportType;
}

export const mutatePatchNotificationReportTypeEnable = async (values: PatchNotificationReportTypeEnableParams) => {
    const { contactId, reportType } = values;
    const response = await makeMutation(ApiEndpoints.patchNotificationReportTypeEnable(contactId, reportType), undefined, true, 'PATCH');
    return PatchNotificationReportTypeEnableSchema.parse(response);
};

export const patchNotificationReportTypeEnableErrorCodes = {};
