import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Main: React.FC = ({ children }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('sm'));
    const contentPadding = isMdUp ? 3 : 0;
    return (
        <main>
            <Box display="flex" flexWrap="wrap" p={contentPadding}>
                {children}
            </Box>
        </main>
    );
};

export default Main;
