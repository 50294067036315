import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            maxWidth: '100%',
        },
    };
});

const ContentHeader: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Box px={1} py={0.75}>
            <Grid className={classes.root} container spacing={3}>
                {children}
            </Grid>
        </Box>
    );
};

export default ContentHeader;
