import { ChartTooltipProps } from 'components/chart/components/chartTooltip/chartTooltipProps';
import { DateTime } from 'shared/luxon/luxon';
import { makeStyles } from '@material-ui/core';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.customPalette.background.semiTransparentBlack,
        borderRadius: theme.spacing(4 / 8),
        color: theme.customPalette.background.white,
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(10),
    },
}));

const ChartTooltip: React.FC<ChartTooltipProps> = ({ props, unit }) => {
    const classes = useStyles();
    const { payload } = props;
    let date;
    if (payload && payload[0]) {
        const { xDataKey } = payload[0].payload;
        if (typeof xDataKey === 'number') {
            date = DateTime.fromMillis(xDataKey).toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS());
        } else {
            date = xDataKey;
        }
    }
    return (
        <div className={classes.root}>
            {date}
            <br />
            {payload &&
                payload.map((data, i: number) => {
                    const { value, dataKey } = data;
                    if (typeof value === 'string' || typeof value === 'number') {
                        return (
                            <React.Fragment key={value && dataKey && dataKey.toString() + value.toString()}>
                                {typeof value === 'number' ? value.toLocaleString() : value} {unit}
                                {payload.length - 1 !== i && <> &#47; </>}
                            </React.Fragment>
                        );
                    }
                    return null;
                })}
        </div>
    );
};

export default ChartTooltip;
