import Content from 'components/content/content';
import ContentFilter from 'components/contentFilter/contentFilter';
import EnergyPowerPicker from 'components/energyPowerPicker/energyPowerPicker';
import React from 'react';
import EnergyPowerBody from '../../components/energyPowerBody/energyPowerBody';

const Power: React.FC = () => {
    return (
        <>
            <ContentFilter>
                <EnergyPowerPicker />
            </ContentFilter>
            <Content>
                <EnergyPowerBody />
            </Content>
        </>
    );
};

export default Power;
