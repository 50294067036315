import { makeStyles } from '@material-ui/core/styles';

export const useEnergyConstumptionPickerStyles = makeStyles((theme) => ({
    metricTypeOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: theme.typography.pxToRem(110),
            marginBottom: 'auto',
        },
    },
    intervalTypeOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginBottom: 'auto',
            width: theme.typography.pxToRem(90),
        },
    },
    dateFromOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginRight: theme.spacing(1),
        '& p': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: theme.typography.pxToRem(110),
        },
    },
    dateToOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginLeft: theme.spacing(1),
        '& p': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            width: theme.typography.pxToRem(110),
        },
    },
    chevron: {
        fill: theme.customPalette.colors.brand.light,
        margin: `0 ${theme.spacing(0.5)}`,
        '&[disabled]': {
            opacity: 0.2,
        },
    },
    input: {
        width: '100%',
        '& > div': {
            background: theme.customPalette.background.white,
        },
        '& input': {
            width: '100%',
            height: theme.spacing(3),
            padding: `0 ${theme.spacing(1)}`,
            fontSize: theme.typography.pxToRem(12),
        },
        '& button': {
            color: theme.customPalette.colors.brand.light,
            margin: theme.spacing(-2),
        },
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none',
        },
        '& .MuiInputLabel-outlined': {
            fontSize: theme.typography.pxToRem(14),
            marginLeft: theme.spacing(-6 / 8),
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,.08)',
        },
        '& legend': {
            marginLeft: theme.spacing(-4 / 8),
        },
    },
}));
