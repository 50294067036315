import { mutateRequestPasswordReset as mutateRequestPasswordResetFn, requestPasswordResetErrorCodes } from 'api/mutations/requestPasswordReset';
import { ResetPasswordByAdminProps } from 'components/resetPasswordByAdmin/resetPasswordByAdminProps';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import React from 'react';

const ResetPasswordByAdmin: React.FC<ResetPasswordByAdminProps> = ({ user }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = React.useState<boolean>(false);
    const { mutate, isError, isSuccess, isLoading } = useHandledMutation(mutateRequestPasswordResetFn, undefined, requestPasswordResetErrorCodes);

    const { username, firstName, lastName, emailAddress } = user;

    React.useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t('resetPasswordByAdmin.successSubmit', { emailAddress }));
            setResetPasswordDialogOpen(false);
        }
        if (isError) {
            enqueueSnackbar(t('resetPasswordByAdmin.error'));
        }
    }, [isSuccess, enqueueSnackbar, t, emailAddress, isError]);

    const handleSubmit = () => {
        mutate({ username });
    };

    return (
        <>
            <Button size="large" onClick={() => setResetPasswordDialogOpen(true)}>
                {t('resetPasswordByAdmin.title')}
            </Button>
            <ConfirmationDialog
                handleClose={() => setResetPasswordDialogOpen(false)}
                handleSubmit={handleSubmit}
                open={resetPasswordDialogOpen}
                question={t('resetPasswordByAdmin.question', { fullName: `${firstName} ${lastName}` })}
                submitIsLoading={isLoading}
                submitText={t('resetPasswordByAdmin.title')}
            />
        </>
    );
};

export default ResetPasswordByAdmin;
