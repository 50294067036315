export enum Capabilities {
    LIST_INVITATIONS = 'listInvitations',
    SEND_INVITATION = 'sendInvitation',
    LIST_USERS = 'listUsers',
    READ_USER = 'readUser',
    UPDATE_USER = 'updateUser',
    DELETE_USER = 'deleteUser',
    DISABLE_USER = 'disableUser',
    ENABLE_USER = 'enableUser',
    UPDATE_ENERGY_POWER_REPORT = 'updateEnergyPowerReport',
    UPDATE_ENERGY_CONSUMPTION_REPORT = 'updateEnergyConsumptionReport',
    UPDATE_FOOD_SAFETY_REPORT = 'updateFoodSafetyReport',
    UPDATE_FOOD_SAFETY_STATISTICS_REPORT = 'updateFoodSafetyStatisticsReport',
    ORGANIZATION_STRUCTURE_LIST = 'organizationStructureList',
    ORGANIZATION_STRUCTURE_LIST_ALL = 'organizationStructureListAll',
    ENERGY_METRIC_LIST_ALL = 'energyMetricListAll',
    USER_LOGS_LIST_ALL = 'userLogsListAll',
    REPORT_LOGS_LIST_ALL = 'reportLogsListAll',
    REPORT_SETTINGS_CREATE = 'reportSettingsCreate',
    REPORT_SETTINGS_CREATE_FOR_OTHER_USER = 'reportSettingsCreateForOtherUser',
    REPORT_SETTINGS_LIST_ALL_USERS = 'reportSettingsListAllUsers',
    IMPORT_LOGS_LIST_ALL = 'importLogsListAll',
    READ_ALARM_DASHBOARD = 'readAlarmDashboard',
}
