import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, QueryParameter, SortParameter } from 'api/types';
import { LogsReportingSchema } from 'model/logsReporting/schema';
import { makeQuery } from 'api/api';

export const getLogsReporting = async (dataRange: { from: number; to: number }, sort?: SortParameter, filter?: FilterParameter, query?: QueryParameter) => {
    const { from, to } = dataRange;
    const headers = {
        Range: `items=${from}-${to}`,
    };
    const response = await makeQuery(ApiEndpoints.logsReporting(), true, sort, filter, query, headers);
    return LogsReportingSchema.parse(response);
};
