import { ReactComponent as ArrowSortDownIcon } from 'assets/icons/arrow-sort-down.svg';
import { ReactComponent as ArrowSortUpIcon } from 'assets/icons/arrow-sort-up.svg';
import { HeaderGroup } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import React from 'react';

const TableHead = <T extends object>({ headerGroups, movable }: { headerGroups: HeaderGroup<T>[]; movable?: boolean }) => {
    const classes = useStyles();
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.tableHeadingRow}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className={classes.tableHeading} style={column.customStyles}>
                            <div {...column.getSortByToggleProps()}>
                                {column.render('Header')}
                                {column.isSorted ? <span className={classes.sortIcon}>{column.isSortedDesc ? <ArrowSortUpIcon /> : <ArrowSortDownIcon />}</span> : ''}
                            </div>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default TableHead;
