import { Box, makeStyles, MenuItem } from '@material-ui/core';
import TextField from 'components/textField/textField';
import { useTranslation } from 'react-i18next';
import { DashboardPeriod } from 'screens/dashboard/types';

type Item = {
    label: string;
    value: DashboardPeriod;
};

type Props = {
    value: DashboardPeriod;
    onChange: (value: DashboardPeriod) => void;
};

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(16),
    },
    input: {
        minWidth: 110,
    },
}));

const Period: React.FC<Props> = ({ value, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const data: Item[] = [
        {
            label: t('dashboard.period.data.7days'),
            value: 'last7days',
        },
        {
            label: t('dashboard.period.data.14days'),
            value: 'last14days',
        },
        {
            label: t('dashboard.period.data.30days'),
            value: 'last30days',
        },
        {
            label: t('dashboard.period.data.60days'),
            value: 'last60days',
        },
        {
            label: t('dashboard.period.data.90days'),
            value: 'last90days',
        },
    ];

    return (
        <Box gridGap={10} display={'flex'} alignItems={'center'}>
            <Box className={classes.label}>{`${t('dashboard.period.label')}:`}</Box>
            <Box className={classes.input}>
                <TextField value={value} name="period" select size="small" onChange={(event) => onChange(event.target.value as DashboardPeriod)}>
                    {data.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </Box>
    );
};

export default Period;
