import * as z from 'zod';

export const LogsUserItemSchema = z.object({
    id: z.number(),
    eventType: z.string(),
    occurredOn: z.string(),
    username: z.string(),
    emailAddress: z.string(),
});

export type LogsUserItem = z.infer<typeof LogsUserItemSchema>;

export const LogsUserSchema = z.object({ data: z.array(LogsUserItemSchema), metadata: z.object({ count: z.number() }) });

export type LogsUser = z.infer<typeof LogsUserSchema>;
