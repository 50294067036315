import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatusProps } from './statusProps';
import { StatusType } from './statusType';
import clsx from 'clsx';
import React from 'react';
import WaterIcon from 'assets/icons/water.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.primary,
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    circle: {
        width: theme.spacing(2),
        minWidth: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: '50%',
        opacity: ({ opacity }: StatusProps) => {
            return opacity;
        },
        margin: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
        background: ({ type }: StatusProps) => {
            if (type === StatusType.warning) {
                return theme.customPalette.colors.warning;
            } else if (type === StatusType.error) {
                return theme.customPalette.colors.error;
            } else if (type === StatusType.success) {
                return theme.customPalette.colors.success;
            } else if (type === StatusType.defrosting) {
                return `url(${WaterIcon}) no-repeat`;
            } else {
                return 'transparent';
            }
        },
        // for filter explanation see see https://codepen.io/sosuke/pen/Pjoqqp
        filter: ({ type }: StatusProps) => (type === StatusType.defrosting ? 'invert(37%) sepia(97%) saturate(1200%) hue-rotate(192deg) brightness(101%) contrast(108%)' : 'unset'),
    },
    square: {
        borderRadius: theme.typography.pxToRem(2),
    },
    inactive: {
        opacity: 0.5,
    },
}));

const Status: React.FC<StatusProps> = ({ type, text, isSquare, opacity }) => {
    const classes = useStyles({ type, opacity });
    return (
        <div className={classes.root}>
            <div className={clsx(classes.circle, isSquare && classes.square)} />
            {text && <Box mr={1}>{text}</Box>}
        </div>
    );
};

export default Status;
