import { LightSeparatorProps } from 'components/table/components/lightSeparator/lightSeparatorProps';
import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: ({ type }: LightSeparatorProps) => (type === LightSeparatorType.horizontal ? theme.spacing(2) : theme.typography.pxToRem(1)),
        height: ({ type }: LightSeparatorProps) => (type === LightSeparatorType.horizontal ? theme.typography.pxToRem(1) : theme.spacing(2)),
        margin: ({ type }: LightSeparatorProps) => (type === LightSeparatorType.horizontal ? `${theme.spacing(0.5)} 0` : `0 ${theme.spacing(0.5)}`),
        background: theme.customPalette.background.dark,
    },
}));

const LightSeparator: React.FC<LightSeparatorProps> = ({ type }) => {
    const classes = useStyles({ type });
    return <div className={classes.root} />;
};

export default LightSeparator;
