import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const SignInSchema = z.object({
    success: z.literal(true),
    data: z.object({
        token: z.string(),
    }),
});
export interface Credentials {
    username: string;
    password: string;
}

export const mutateSignIn = async (values: Credentials) => {
    const response = await makeMutation(ApiEndpoints.signIn(), values);
    return SignInSchema.parse(response);
};

export const signInErrorCodes = {
    INVALID_CREDENTIALS: {
        code: 'INVALID_CREDENTIALS',
        translation: (t: TFunction) => t('signInForm.INVALID_CREDENTIALS'),
    },
    USER_IS_NOT_ACTIVE_YET: {
        code: 'USER_IS_NOT_ACTIVE_YET',
        translation: (t: TFunction) => t('signInForm.USER_IS_NOT_ACTIVE_YET'),
    },
    USER_IS_DISABLED: {
        code: 'USER_IS_DISABLED',
        translation: (t: TFunction) => t('signInForm.USER_IS_DISABLED'),
    },
};
