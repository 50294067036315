import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GRID_GAP, IMAGE_WIDTH } from './header';
import clsx from 'clsx';
import alarmColors from 'theme/alarmColors';
import { formatNumber } from 'screens/dashboard/helpers';

const LABEL_HEIGHT = 16;

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: GRID_GAP + IMAGE_WIDTH,
        justifyContent: 'space-between',
    },
    label: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(LABEL_HEIGHT),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    columns: {
        overflow: 'hidden',
    },
    column: {
        overflow: 'hidden',
    },
    columnOverall: {
        borderBottom: `2px dashed ${theme.customPalette.alarmDashboard.secondary}`,
        paddingBottom: theme.spacing(1),
    },
    columnStore: {
        borderBottom: `2px solid ${theme.customPalette.alarmDashboard.primary}`,
        paddingBottom: theme.spacing(1),
    },
    valueRow: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        lineHeight: theme.typography.pxToRem(20),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    valueRowStore: {
        color: theme.customPalette.alarmDashboard.primary,
    },
    value: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        lineHeight: theme.typography.pxToRem(20),
    },
    deviation: {
        marginTop: LABEL_HEIGHT,
    },
    deviationNegative: {
        color: alarmColors.bad,
    },
    deviationPositive: {
        color: alarmColors.good,
    },
}));

type Props = {
    overall: number;
    store: number;
    deviation: number;
    unit: 'minute' | 'percent';
};

const Summary: React.FC<Props> = ({ overall, store, deviation, unit }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const positiveDeviation = deviation <= 0;

    return (
        <Box display={'flex'} gridGap={16} className={classes.container}>
            <Box display={'flex'} gridGap={16} className={classes.columns}>
                <Box className={clsx(classes.column, classes.columnOverall)}>
                    <Box className={classes.label}>{t('dashboard.card.summary.overall')}:</Box>
                    <Box className={classes.valueRow}>
                        <span className={classes.value}>{formatNumber(overall, unit, t, true)}</span> {t('dashboard.card.summary.perDay')}
                    </Box>
                </Box>

                <Box className={clsx(classes.column, classes.columnStore)}>
                    <Box className={classes.label}>{t('dashboard.card.summary.store')}:</Box>
                    <Box className={clsx(classes.valueRow, classes.valueRowStore)}>
                        <span className={classes.value}>{formatNumber(store, unit, t, true)}</span> {t('dashboard.card.summary.perDay')}
                    </Box>
                </Box>
            </Box>

            <Box className={clsx(classes.value, classes.deviation, positiveDeviation ? classes.deviationPositive : classes.deviationNegative)}>{formatNumber(deviation, 'percent', t)}</Box>
        </Box>
    );
};

export default Summary;
