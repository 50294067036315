import { AppState } from 'store';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { fetchStores } from 'api/fetchers/stores';
import { HeaderProps } from 'components/header/headerProps';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { QueryKeys } from 'api/queryKeys';
import { Role } from 'model/myProfile/role';
import { routes } from 'routes/routes';
import { ReactComponent as ShopIcon } from 'assets/icons/shop.svg';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from 'components/button/button';
import clsx from 'clsx';
import ContactForm from 'components/contactForm/contactForm';
import Logo from 'assets/images/logo.svg';
import MuiButton from '@material-ui/core/Button';
import React from 'react';
import StoreHeaderInformation from 'components/storeHeaderInformation/storeHeaderInformation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UserBox from 'components/userBox/userBox';
import useCapabilities from 'shared/useCapabilities/useCapabilities';
import { Capabilities } from 'shared/useCapabilities/capabilities';

const useStyles = makeStyles((theme) => ({
    boxWithBorder: {
        background: theme.customPalette.background.white,
        boxShadow: theme.customElevation.default,
    },
    lineOne: {
        minHeight: theme.spacing(6),
        padding: `0 0 0 ${theme.spacing(2)}`,
        [theme.breakpoints.up('lg')]: {
            minHeight: theme.spacing(7),
            padding: `0 ${theme.spacing(3)}`,
        },
    },
    lineTwo: {
        display: 'flex',
        justifyContent: 'center',
        padding: `${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
    },
    logo: {
        cursor: 'pointer',
        display: 'flex',
    },
    nakedButton: {
        height: '100%',
        padding: `0 ${theme.spacing(3)}`,
        color: theme.customPalette.colors.brand.light,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'none',
        lineHeight: 1.3,
    },
    goBackIcon: {
        fill: theme.customPalette.colors.brand.light,
    },
    headerTitle: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
    },
    flex1: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
}));

const Header: React.FC<HeaderProps> = ({ headingText, goBackAction, goBackText }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const history = useHistory();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const classes = useStyles();
    const [contactFormIsOpen, setContactFormIsOpen] = React.useState<boolean>(false);
    const onAdminUsersPage = useRouteMatch(routes.adminUsers.pattern);
    const { isAuthorized } = useCapabilities();

    const { storeId } = useSelector((state: AppState) => state.store);
    const myProfile = useSelector((state: AppState) => state.auth.myProfile);

    const loggedUserIsAdmin = myProfile && myProfile.role === Role.administrator;
    const canAccessDashboard = isAuthorized(Capabilities.READ_ALARM_DASHBOARD);

    const handleLogoClick = () => {
        history.push(routes.default.path());
    };

    const handleGoToAdminLogs = () => {
        history.push(routes.adminLogsUser.path());
    };

    const handleGoToAdminUsers = () => {
        history.push(routes.adminUsers.path());
    };

    const handleGoToHelp = () => {
        history.push(routes.myProfileHelp.path());
    };

    const handleGoToChooseStore = () => {
        history.push(routes.chooseStore.path());
    };

    const handleOpenContactForm = () => {
        setContactFormIsOpen(true);
    };

    const handleCloseContactForm = () => {
        setContactFormIsOpen(false);
    };

    const handleGoToDashboard = () => {
        history.push(routes.dashboard.path());
    };

    const { data: storesDataFilteredByStoreId } = useHandledQuery(
        [QueryKeys.store(storeId), storeId],
        () => fetchStores(undefined, storeId ? [{ column: 'id', value: storeId.toString(), operator: 'eq' }] : undefined),
        {
            enabled: Boolean(storeId),
        },
    );

    const storeHeaderInformationBox = storesDataFilteredByStoreId && <StoreHeaderInformation data={storesDataFilteredByStoreId[0]} />;

    const goBackEnabled = goBackAction && goBackText;
    const goBackEnabledOrHeadingText = goBackEnabled || headingText;

    return (
        <header id="header">
            <Box className={clsx(classes.boxWithBorder, classes.lineOne)} display="flex" flexDirection="row">
                <Box className={clsx(goBackEnabledOrHeadingText && isSmUp && classes.flex1)} display="flex">
                    {goBackEnabled && (
                        <Box alignItems="center" display="flex" mr={2} onClick={goBackAction}>
                            <Button icon={<ArrowLeftIcon className={classes.goBackIcon} />}>{goBackText}</Button>
                        </Box>
                    )}
                    {headingText && (
                        <Box alignItems="center" display="flex">
                            <div className={classes.headerTitle} id="test-choose-store-title">
                                {headingText}
                            </div>
                        </Box>
                    )}
                </Box>
                {!goBackEnabledOrHeadingText && (
                    <>
                        <Box alignItems="center" display="flex" mr={1.5} onClick={handleLogoClick}>
                            <img alt={t('brand')} className={classes.logo} src={Logo} />
                        </Box>
                        {isMdUp && storeHeaderInformationBox}
                        {!isSm && (
                            <Box alignItems="center" display="flex" ml={isMdUp ? 1.5 : 'auto'} mr={0.25}>
                                <Button icon={<ShopIcon />} id="test-change-shop" isPrimary onClick={handleGoToChooseStore}>
                                    {t('changeShop')}
                                </Button>
                            </Box>
                        )}
                    </>
                )}
                {goBackEnabledOrHeadingText && isMdUp && (
                    <Box alignItems="center" className={classes.flex1} display="flex" justifyContent="center">
                        <img alt={t('brand')} className={classes.logo} src={Logo} />
                    </Box>
                )}
                <Box
                    className={clsx(goBackEnabledOrHeadingText && isMdUp && classes.flex1, goBackEnabledOrHeadingText && !isMdUp && classes.flex2)}
                    display="flex"
                    justifyContent="flex-end"
                    ml={isSmUp ? 'auto' : 0}
                >
                    {isSmUp && (
                        <Box display="flex">
                            {canAccessDashboard && (
                                <MuiButton className={classes.nakedButton} onClick={handleGoToDashboard}>
                                    {t('dashboard.menuItem')}
                                </MuiButton>
                            )}
                            {loggedUserIsAdmin && (
                                <>
                                    <MuiButton className={classes.nakedButton} id="test-logs" onClick={handleGoToAdminLogs}>
                                        {t('logs.title')}
                                    </MuiButton>
                                    <MuiButton className={classes.nakedButton} id="test-admin-users" onClick={handleGoToAdminUsers}>
                                        {t('adminUsers.title')}
                                    </MuiButton>
                                    <MuiButton className={classes.nakedButton} onClick={handleGoToHelp}>
                                        {t('user.userHelp.title')}
                                    </MuiButton>
                                </>
                            )}
                            {!loggedUserIsAdmin && (
                                <>
                                    <MuiButton className={classes.nakedButton} onClick={handleOpenContactForm}>
                                        {t('contactForm.title')}
                                    </MuiButton>
                                    <MuiButton className={classes.nakedButton} onClick={handleGoToHelp}>
                                        {t('user.userHelp.title')}
                                    </MuiButton>
                                </>
                            )}
                        </Box>
                    )}
                    <Box>
                        <UserBox openContactForm={handleOpenContactForm} />
                    </Box>
                </Box>
            </Box>
            {!isMdUp && !onAdminUsersPage && !goBackEnabled && !headingText && (
                <div className={clsx(classes.boxWithBorder, classes.lineTwo)}>
                    <Box display="inline-flex">
                        {storeHeaderInformationBox}
                        {isSm && (
                            <Box alignItems="center" display="flex" ml={0.5} mr={1.5}>
                                <Button icon={<ShopIcon />} isPrimary onClick={handleGoToChooseStore}>
                                    {t('changeShop')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </div>
            )}
            {contactFormIsOpen && <ContactForm handleClose={handleCloseContactForm} />}
        </header>
    );
};

export default Header;
