import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';

const FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE = 'FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE';

interface FoodSafetyStatisticsDateFilterType {
    dateFilterType: keyof typeof FoodSafetySegments;
}

export const setFoodSafetyStatisticsDateTypeFilter = (filter: FoodSafetyStatisticsDateFilterType) => {
    localStorage.setItem(FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE, JSON.stringify(filter));
};
export const hasFoodSafetyStatisticsDateFilterType = () => Boolean(localStorage.getItem(FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE));
export const getFoodSafetyStatisticsDateFilterType = () => {
    const value = localStorage.getItem(FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE);
    return value ? JSON.parse(value) : undefined;
};
export const clearFoodSafetyStatisticsDateFilterType = () => {
    localStorage.removeItem(FOOD_SAFETY_STATISTICS_DATE_FILTER_TYPE);
};
