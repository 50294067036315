import { StaticTextItem } from '../../model/reportingStoreLive/schema';
import React from 'react';
import { Group, Text } from 'react-konva';

const StaticTextItemToRender = (props: StaticTextItem) => {
    const { position, text, style } = props;
    return (
        <Group x={position.x} y={position.y} zIndex={10000}>
            <Text fill={style.fillcolor} fontSize={style.size} text={text} x={0} y={0} />
        </Group>
    );
};

export default StaticTextItemToRender;
