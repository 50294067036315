import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { AlarmStoreRecordsSchema } from 'model/alarmStoreRecords/schema';
import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';

export const getAlarmStoreRecords = async (storeId: number, alarmType: string, priority: DashboardAlarmPriority, period: DashboardPeriod, dataSource: DashboardDataSource) => {
    const sort = undefined;
    const filter = undefined;
    const searchPhrase = undefined;
    const requestHeaders = {};
    const queryParams = [`dataSource=${dataSource}`, `alarmType=${alarmType}`, `period=${period}`, `priority=${priority}`];

    const response = await makeQuery(ApiEndpoints.getAlarmStoreRecords(storeId), true, sort, filter, searchPhrase, requestHeaders, queryParams);
    return AlarmStoreRecordsSchema.parse(response.data);
};
