export enum StatisticsTableAccessors {
    hidden = 'hidden',
    longName = 'longName',
    zone = 'zone',
    shortName = 'shortName',
    defrostHours = 'defrostHours',
    deviation2kCount = 'deviation2kCount',
    deviation4kCount = 'deviation4kCount',
    status = 'status',
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    drag = 'drag',
    monthGoodStatus = 'monthGoodStatus',
    monthWarningStatus = 'monthWarningStatus',
    monthErrorStatus = 'monthErrorStatus',
    firstMonthOfQuarter = 'firstMonthOfQuarter',
    secondMonthOfQuarter = 'secondMonthOfQuarter',
    thirdMonthOfQuarter = 'thirdMonthOfQuarter',
}
