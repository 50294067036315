import { initReactI18next } from 'react-i18next';
import deTranslation from './de/translation.json';
import enTranslation from './en/translation.json';

import { getLanguageShortCode, hasLanguageShortCode } from 'localStorage/language';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export enum Language {
    english = 'en',
}

const instance = hasLanguageShortCode() ? i18n.use(LanguageDetector) : i18n;

instance
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: hasLanguageShortCode() ? (getLanguageShortCode() as string) : undefined,
        fallbackLng: 'en',
        returnEmptyString: false,
        debug: true,
        resources: {
            en: {
                translation: enTranslation,
            },
            de: {
                translation: deTranslation,
            },
        },
    });

export default i18n;
