import { forwardRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Header from './components/header';
import Summary from './components/summary';
import Chart from './components/chart';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        background: theme.customPalette.background.white,
        borderRadius: 4,
        '&:hover, &:focus': {
            cursor: 'pointer',
            outline: `2px solid ${theme.customPalette.alarmDashboard.primary}`,
            outlineOffset: -2,
            boxShadow: `0px 2px 8px 0px ${theme.customPalette.alarmDashboard.cardShadow}`,
        },
    },
}));

type Props = {} & React.ComponentProps<typeof Header> & React.ComponentProps<typeof Summary> & React.ComponentProps<typeof Chart>;
type Ref = HTMLDivElement;

const CardUI = forwardRef<Ref, Props>(({ town, notificationCount, region, street, system, zipCode, overall, store, deviation, data, unit, isLoading, base64icon }, ref) => {
    const classes = useStyles();

    return (
        <div className={classes.container} ref={ref}>
            <Header region={region} zipCode={zipCode} town={town} street={street} system={system} notificationCount={notificationCount} base64icon={base64icon} />
            <Box mt={2} />
            <Summary overall={overall} store={store} deviation={deviation} unit={unit} />
            <Box mt={1.5} />
            <Chart data={data} unit={unit} isLoading={isLoading} />
        </div>
    );
});

export default CardUI;
