import * as z from 'zod';
import { AppState } from 'store';
import { Box, useTheme } from '@material-ui/core';
import { Capabilities } from 'shared/useCapabilities/capabilities';
import { Column, Row, SortingRule } from 'react-table';
import { fetchNotifications } from 'api/fetchers/getNotifications';
import { fetchStoreUsers } from 'api/fetchers/storeUsers';
import { handleSimpleSpecificEndpointErrors } from 'shared/errors/handleStandardApiErrors';
import { makeStyles } from '@material-ui/core/styles';
import { mutatePatchNotificationDisable, patchNotificationDisableErrorCodes } from 'api/mutations/patchNotificationDisable';
import { mutatePatchNotificationEnable, patchNotificationEnableErrorCodes } from 'api/mutations/patchNotificationEnable';
import { mutatePatchNotificationReportTypeDisable, patchNotificationReportTypeDisableErrorCodes } from 'api/mutations/patchNotificationReportTypeDisable';
import { mutatePatchNotificationReportTypeEnable, patchNotificationReportTypeEnableErrorCodes } from 'api/mutations/patchNotificationReportTypeEnable';
import { mutatePostNotification, postNotificationErrorCodes } from 'api/mutations/postNotification';
import { NotificationConfigTableAccessors } from 'types/notificationConfig/notificationConfigTableAccessors';
import { QueryKeys } from 'api/queryKeys';
import { ReportType } from 'shared/enums/reportType';
import { TableNotificationItem } from 'types/notificationConfig/tableNotificationItem';
import { transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import Content from 'components/content/content';
import LoadingBar from 'components/loadingBar/loadingBar';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationConfigTable from 'components/table/instances/notificationConfigTable/notificationConfigTable';
import NotificationContactCell from 'components/table/components/notificationContactCell/notificationContactCell';
import NotificationDeleteCell from 'components/table/components/notificationDeleteCell/notificationDeleteCell';
import React from 'react';
import Switcher from 'components/switcher/switcher';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useCapabilities from 'shared/useCapabilities/useCapabilities';

const useStyles = makeStyles((theme) => ({
    notificationFormOuter: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(4.75),
        },
    },
    notificationAddFormInput: {
        height: theme.spacing(4),
    },
    notificationAddFormInputOuter: {
        maxWidth: theme.typography.pxToRem(320),
        width: '100%',
        display: 'flex',
        marginLeft: theme.spacing(1.5),
        '& .MuiFormHelperText-root': {
            display: 'none',
        },
    },
    notificationAddFormSelectOuter: {
        maxWidth: theme.typography.pxToRem(168),
        width: '100%',
        display: 'flex',
        marginLeft: theme.spacing(1.5),
        '& .MuiFormHelperText-root': {
            display: 'none',
        },
        '& .MuiSelect-root': {
            paddingTop: theme.spacing(6 / 8),
            paddingBottom: theme.spacing(5 / 8),
        },
    },
}));

const formValuesSchema = z.object({
    contact: z.string().nonempty(),
});

const formValuesSchemaAsAdmin = z.object({
    userId: z.number(),
    contact: z.string().nonempty(),
});

const EmailReportConfig: React.FC = () => {
    const { isAuthorized } = useCapabilities();
    const [loadStarted, setLoadStarted] = React.useState<boolean>(true);
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>();
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const {
        mutate: postNotificationMutate,
        isSuccess: postNotificationIsSuccess,
        isLoading: postNotificationIsLoading,
        isError: postNotificationIsError,
        error: postNotificationError,
    } = useHandledMutation(mutatePostNotification, undefined, postNotificationErrorCodes);
    const { mutate: patchNotificationEnableMutate, isSuccess: patchNotificationEnableIsSuccess } = useHandledMutation(mutatePatchNotificationEnable, undefined, patchNotificationEnableErrorCodes);
    const { mutate: patchNotificationDisableMutate, isSuccess: patchNotificationDisableIsSuccess } = useHandledMutation(mutatePatchNotificationDisable, undefined, patchNotificationDisableErrorCodes);
    const { mutate: patchNotificationReportTypeDisableMutate, isSuccess: patchNotificationReportTypeDisableIsSuccess } = useHandledMutation(
        mutatePatchNotificationReportTypeDisable,
        undefined,
        patchNotificationReportTypeDisableErrorCodes,
    );
    const { mutate: patchNotificationReportTypeEnableMutate, isSuccess: patchNotificationReportTypeEnableIsSuccess } = useHandledMutation(
        mutatePatchNotificationReportTypeEnable,
        undefined,
        patchNotificationReportTypeEnableErrorCodes,
    );
    const { storeId } = useSelector((state: AppState) => state.store);
    const myProfile = useSelector((state: AppState) => state.auth.myProfile);

    const authorizedToCreateForOtherUser = isAuthorized(Capabilities.REPORT_SETTINGS_CREATE_FOR_OTHER_USER);

    const { data: usersData } = useHandledQuery(
        [QueryKeys.users(), storeId as number],
        () => (storeId ? fetchStoreUsers(storeId, Boolean(authorizedToCreateForOtherUser), [{ column: 'username', direction: 'asc' }]) : undefined),
        {
            keepPreviousData: true,
            enabled: Boolean(storeId && authorizedToCreateForOtherUser),
        },
    );

    const { handleSubmit, control, reset } = useHandledForm(authorizedToCreateForOtherUser ? formValuesSchemaAsAdmin : formValuesSchema);

    const queryParams = {
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
    };

    const {
        data: notificationsData,
        isFetching: notificationsIsFetching,
        refetch: notificationsRefetch,
    } = useHandledQuery([QueryKeys.notifications(), queryParams], () => (storeId ? fetchNotifications(storeId, queryParams.sort) : undefined), {
        keepPreviousData: true,
        enabled: Boolean(storeId && myProfile),
    });

    React.useEffect(() => {
        if (notificationsIsFetching && loadStarted) {
            setLoadStarted(false);
        }
    }, [notificationsIsFetching, loadStarted]);

    const handleNotificationReportTypeState = React.useCallback(
        (enabled: boolean | null, contactId: number, reportType: ReportType) => {
            if (storeId) {
                if (enabled !== null) {
                    const params = { storeId, contactId, reportType };
                    if (enabled) {
                        patchNotificationReportTypeDisableMutate(params);
                    } else {
                        patchNotificationReportTypeEnableMutate(params);
                    }
                }
            }
        },
        [patchNotificationReportTypeDisableMutate, patchNotificationReportTypeEnableMutate, storeId],
    );

    const columns = React.useMemo<Column<TableNotificationItem>[]>(
        () => [
            {
                Header: t('setup.notifications.heading.active').toString(),
                accessor: NotificationConfigTableAccessors.enabled,
                customStyles: {
                    width: theme.typography.pxToRem(60),
                },
                Cell: ({ value, row }) =>
                    storeId && (
                        <Checkbox
                            checked={value}
                            onChange={() => {
                                const params = { storeId, contactId: row.original.id };
                                if (value) {
                                    patchNotificationDisableMutate(params);
                                } else {
                                    patchNotificationEnableMutate(params);
                                }
                            }}
                        />
                    ),
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.emailOrFax').toString(),
                accessor: NotificationConfigTableAccessors.contact,
                Cell: ({ value, row }) => <NotificationContactCell type={row.original.contactType} value={value} />,
                customStyles: {
                    minWidth: '35%',
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.username').toString(),
                accessor: NotificationConfigTableAccessors.username,
                customStyles: {
                    width: theme.typography.pxToRem(110),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.foodSafetyReportDaily').toString(),
                accessor: NotificationConfigTableAccessors.reportFoodSafetyReport,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-a" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.foodSafetyReport)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.foodSafetyStatisticsWeekly').toString(),
                accessor: NotificationConfigTableAccessors.reportFoodSafetyStatistics,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-b" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.foodSafetyStatistics)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.foodSafetyStatisticsMonthly').toString(),
                accessor: NotificationConfigTableAccessors.reportFoodSafetyStatisticsMonthly,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-b" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.foodSafetyStatisticsMonthly)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.foodSafetyStatisticsQuarterly').toString(),
                accessor: NotificationConfigTableAccessors.reportFoodSafetyStatisticsQuarterly,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-b" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.foodSafetyStatisticsQuarterly)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.energyConsumptionMonthly').toString(),
                accessor: NotificationConfigTableAccessors.reportEnergyConsumption,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-c" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.energyConsumption)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                Header: t('setup.notifications.heading.energyPowerMonthly').toString(),
                accessor: NotificationConfigTableAccessors.reportEnergyPower,
                Cell: ({ value, row }) =>
                    storeId && <Switcher className="test-switcher-d" handleChange={() => handleNotificationReportTypeState(value, row.original.id, ReportType.energyPower)} value={value} />,
                customStyles: {
                    width: theme.typography.pxToRem(220),
                },
                disableSortBy: false,
            },
            {
                id: 'actions',
                Cell: ({ row }: { row: Row<TableNotificationItem> }) => <NotificationDeleteCell contact={row.original.contact} contactId={row.original.id} refetchList={notificationsRefetch} />,
                customStyles: {
                    width: theme.typography.pxToRem(60),
                },
            },
        ],
        [t, theme.typography, patchNotificationDisableMutate, patchNotificationEnableMutate, storeId, handleNotificationReportTypeState, notificationsRefetch],
    );

    React.useEffect(() => {
        if (postNotificationIsError) {
            handleSimpleSpecificEndpointErrors(postNotificationError, enqueueSnackbar, t, postNotificationErrorCodes);
        }
    }, [enqueueSnackbar, t, postNotificationError, postNotificationIsError]);

    React.useEffect(() => {
        if (patchNotificationEnableIsSuccess || patchNotificationDisableIsSuccess || patchNotificationReportTypeEnableIsSuccess || patchNotificationReportTypeDisableIsSuccess) {
            notificationsRefetch();
        }
    }, [notificationsRefetch, patchNotificationEnableIsSuccess, patchNotificationDisableIsSuccess, patchNotificationReportTypeEnableIsSuccess, patchNotificationReportTypeDisableIsSuccess]);

    const options = usersData && usersData.data.map(({ id, username }) => ({ id, username }));

    const userSelectDefaultValue = options && myProfile && options.find(({ id }) => myProfile.id === id) ? myProfile.id : '';

    React.useEffect(() => {
        if (postNotificationIsSuccess) {
            reset({ contact: '', userId: userSelectDefaultValue });
            notificationsRefetch();
            enqueueSnackbar(t('emailReportConfig.successCreate'));
        }
    }, [postNotificationIsSuccess, notificationsRefetch, reset, enqueueSnackbar, t, userSelectDefaultValue]);

    const onSubmit = async ({ contact }: z.infer<typeof formValuesSchema>) => {
        if (storeId && myProfile) {
            postNotificationMutate({ contact, storeId, userId: myProfile.id });
        }
    };

    const onSubmitAsAdmin = async ({ contact, userId }: z.infer<typeof formValuesSchemaAsAdmin>) => {
        if (storeId) {
            postNotificationMutate({ contact, storeId, userId });
        }
    };

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    return (
        <Content>
            <LoadingBar isFetching={notificationsIsFetching} />
            <NotificationConfigTable columns={columns} data={notificationsData ? notificationsData : []} isFetching={loadStarted || notificationsIsFetching} onSort={handleSort} />
            {myProfile && (
                <BottomBar>
                    <form onSubmit={handleSubmit(authorizedToCreateForOtherUser ? onSubmitAsAdmin : onSubmit)}>
                        <Box className={classes.notificationFormOuter} display="flex">
                            {options && authorizedToCreateForOtherUser && (
                                <div className={classes.notificationAddFormSelectOuter}>
                                    <TextFieldRHF control={control} defaultValue={userSelectDefaultValue} name="userId" select size="medium">
                                        {options &&
                                            options.map(({ id, username }) => (
                                                <MenuItem key={id} value={id}>
                                                    {username}
                                                </MenuItem>
                                            ))}
                                    </TextFieldRHF>
                                </div>
                            )}
                            <div className={classes.notificationAddFormInputOuter}>
                                <TextFieldRHF control={control} id="test-new-routing" name="contact" placeholder={t('notificationAddForm.placeholder')} size="medium" />
                            </div>
                            <Box alignItems="center" display="flex" justifyContent="space-between" ml={1.5}>
                                <Button id="test-new-routing-submit" isLoading={postNotificationIsLoading} isPrimary size="medium" type="submit">
                                    {t('notificationAddForm.submit')}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </BottomBar>
            )}
        </Content>
    );
};

export default EmailReportConfig;
