import { Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SwitcherProps } from 'components/switcher/switcherProps';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface StylesProps extends Pick<SwitcherProps, 'disabled'> {}

const useStyles = makeStyles((theme) => ({
    root: {
        opacity: ({ disabled }: StylesProps) => (disabled ? 0.5 : 1),
    },
    label: {
        fontSize: theme.typography.pxToRem(12),
    },
    inactiveLabel: {
        color: theme.customPalette.placeholder,
    },
    switch: {
        // width: 42,
        // height: 26,
        // padding: 0,
        // margin: theme.spacing(1),
    },
    switchBase: {
        color: theme.customPalette.text.secondary,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.customPalette.colors.brand.light,

            '& + $track': {
                backgroundColor: theme.customPalette.border,
                opacity: 1,
                border: 'none',
            },
            '& $thumb': {
                background: theme.customPalette.colors.brand.transition,
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            // border: '6px solid #fff',
        },
    },
    thumb: {
        boxShadow: 'none',
    },
    track: {
        // borderRadius: 26 / 2,
        border: 'none',
        backgroundColor: theme.customPalette.border,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}));

const Switcher: React.FC<SwitcherProps> = ({ value, handleChange, disabled, className }) => {
    const classes = useStyles({ disabled });
    const { t } = useTranslation();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    if (value === null) {
        return <></>;
    }
    return (
        <Grid alignItems="center" className={classes.root} component="label" container spacing={1}>
            {isMdUp && (
                <Grid className={clsx(classes.label, value && classes.inactiveLabel)} item>
                    {t('switcher.off')}
                </Grid>
            )}
            <Grid item>
                <Switch
                    checked={value}
                    classes={{
                        root: classes.switch,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    className={className}
                    disabled={disabled}
                    name="checkedC"
                    onChange={handleChange}
                />
            </Grid>
            {isMdUp && (
                <Grid className={clsx(classes.label, !value && classes.inactiveLabel)} item>
                    {t('switcher.on')}
                </Grid>
            )}
        </Grid>
    );
};

export default Switcher;
