import { DayHeadingTabletProps } from 'components/table/components/dayHeadingTablet/dayHeadingTabletProps';
import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import LightSeparator from 'components/table/components/lightSeparator/lightSeparator';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    outer: {
        marginTop: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        color: theme.customPalette.text.secondary,
        '& svg': {
            fill: theme.customPalette.text.secondary,
            '&:first-child': {
                margin: `0 ${theme.spacing(0.125)} 0 ${theme.spacing(0.625)} `,
            },
            '&:last-child': {
                margin: `0 ${theme.spacing(0.625)} ${theme.spacing(0.125)} 0 `,
            },
        },
    },
}));

const DayHeadingTablet: React.FC<DayHeadingTabletProps> = ({ text }) => {
    const classes = useStyles();
    return (
        <th>
            {text}
            <span className={classes.outer}>
                <SunIcon />
                <LightSeparator type={LightSeparatorType.vertical} />
                <MoonIcon />
            </span>
        </th>
    );
};

export default DayHeadingTablet;
