import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import progressBarColors from 'theme/progressBarColors';

export interface ProgressBarProps {
    goodPercent: number;
    badPercent: number;
    veryBadPercent: number;
}

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.typography.pxToRem(120),
        [theme.breakpoints.down('md')]: {
            width: theme.typography.pxToRem(80),
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.typography.pxToRem(40),
        },
        maxWidth: '100%',
        height: theme.typography.pxToRem(16),
        borderRadius: theme.typography.pxToRem(2),
        gap: theme.typography.pxToRem(1),
    },
    good: (props: ProgressBarProps) => ({
        width: `${props.goodPercent}%`,
        height: '100%',
        background: progressBarColors.good,
        borderTopRightRadius: props.badPercent === 0 && props.veryBadPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderBottomRightRadius: props.badPercent === 0 && props.veryBadPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderTopLeftRadius: theme.typography.pxToRem(2),
        borderEndStartRadius: theme.typography.pxToRem(2),
    }),
    bad: (props: ProgressBarProps) => ({
        width: `${props.badPercent}%`,
        height: '100%',
        background: progressBarColors.bad,
        borderTopLeftRadius: props.goodPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderBottomLeftRadius: props.goodPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderTopRightRadius: props.veryBadPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderBottomRightRadius: props.veryBadPercent === 0 ? theme.typography.pxToRem(2) : 0,
    }),
    veryBad: (props: ProgressBarProps) => ({
        width: `${props.veryBadPercent}%`,
        height: '100%',
        background: progressBarColors.veryBad,
        borderTopLeftRadius: props.goodPercent === 0 && props.badPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderBottomLeftRadius: props.goodPercent === 0 && props.badPercent === 0 ? theme.typography.pxToRem(2) : 0,
        borderTopRightRadius: theme.typography.pxToRem(2),
        borderBottomRightRadius: theme.typography.pxToRem(2),
    }),
}));

const ProgressBar: FC<ProgressBarProps> = ({ goodPercent, badPercent, veryBadPercent }) => {
    const classes = useStyle({
        goodPercent,
        badPercent,
        veryBadPercent,
    });

    return (
        <Box className={classes.container}>
            {goodPercent !== 0 && <Box className={classes.good}></Box>}
            {badPercent !== 0 && <Box className={classes.bad}></Box>}
            <Box className={classes.veryBad}></Box>
        </Box>
    );
};

export default ProgressBar;
