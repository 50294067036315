import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import React from 'react';
import Button from '../../components/button/button';
import { ReactComponent as UnfoldIcon } from 'assets/icons/unfold-more-vertical.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            fill: '#267dff',
        },
        root: {
            position: 'relative',
            top: 1,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.15,
            letterSpacing: '0.24px',
        },
    };
});
const AlarmsHeader = ({ closeAlarmsColumn, alarmsCount }: { alarmsCount: number; closeAlarmsColumn: () => void }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Box p={1.5} pl={0.25} pt={2.125} px={2}>
                <Button icon={<UnfoldIcon className={clsx(classes.icon)} />} onClick={closeAlarmsColumn}>
                    {t('alarms')} ({alarmsCount})
                </Button>
            </Box>
        </div>
    );
};
export default AlarmsHeader;
