import { ContentFilterProps } from 'components/contentFilter/contentFilterProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        order: 1,
        width: '100%',
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(0.5)} 0 ${theme.spacing(2)} 0`,
        padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: 0,
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: `${theme.spacing(0.5)} 0 ${theme.spacing(2.5)} 0`,
        },
        [theme.breakpoints.up('lg')]: {
            order: 2,
            width: '50%',
            justifyContent: 'flex-end',
            margin: 0,
        },
    },
    text: {
        [theme.breakpoints.up('lg')]: {
            marginRight: theme.spacing(2.25),
        },
    },
}));

const ContentFilter: React.FC<ContentFilterProps> = ({ children, text }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.text}>{text}</div>
            <div>{children}</div>
        </div>
    );
};

export default ContentFilter;
