import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import OrganizationStructureDetail from 'components/organizationStructure/organizationStructureDetail/organizationStructureDetail';
import OrganizationStructureForm from 'components/organizationStructure/organizationStructureForm/organizationStructureForm';
import React from 'react';

const UserAccesses: React.FC = () => {
    return (
        <Switch>
            <Route component={OrganizationStructureForm} exact path={routes.userAccessesEdit.pattern} />
            <Route component={OrganizationStructureDetail} exact path={routes.userAccessesDetail.pattern} />
        </Switch>
    );
};

export default UserAccesses;
