import * as z from 'zod';

export const formValuesSchema = z.object({
    quarter: z.object({
        title: z.string(),
        value: z.number(),
    }),
    month: z.number(),
    week: z.number(),
    year: z.number(),
});
