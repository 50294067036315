import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { DateTime } from 'shared/luxon/luxon';
import { makeStyles } from '@material-ui/core/styles';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { WeekPickerWithArrowsProps } from 'components/CustomDatePickerWithArrows/CustomDatePickerWithArrowsProps';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';

import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';
import { getLanguageShortCode } from '../../localStorage/language';
import { Info, Settings } from 'luxon';
import { useTranslation } from 'react-i18next';
import { formValuesSchema } from './schema';

const useStyles = makeStyles((theme) => ({
    weekOuter: {
        width: theme.typography.pxToRem(61),
        marginRight: theme.spacing(1),
    },
    monthOuter: {
        width: 'auto',
        marginRight: theme.spacing(1),
    },
    yearOuter: {
        width: theme.typography.pxToRem(70),
    },
    chevron: {
        fill: theme.customPalette.colors.brand.light,
        margin: `0 ${theme.spacing(0.5)}`,
        '&[disabled]': {
            opacity: 0.2,
        },
    },
}));

const INPUT_NAME_QUARTER = 'quarter';
const INPUT_NAME_MONTH = 'month';
const INPUT_NAME_WEEK = 'week';
const INPUT_NAME_YEAR = 'year';

const CustomDatePickerWithArrows: React.FC<WeekPickerWithArrowsProps> = ({ availableData, date, setDate, dateFilterType = FoodSafetySegments.weeks }) => {
    const { from, to } = availableData;
    const { t } = useTranslation();

    const yearsArray: number[] = [];
    for (let i = from.year; i <= to.year; i++) {
        yearsArray.push(i);
    }

    const classes = useStyles();

    const { control, getValues, setValue, watch } = useHandledForm(formValuesSchema);

    const activeNativeQuarter = watch(INPUT_NAME_QUARTER);
    const activeNativeMonth = watch(INPUT_NAME_MONTH);
    const activeNativeWeek = watch(INPUT_NAME_WEEK);
    const activeNativeYear = watch(INPUT_NAME_YEAR);

    const getWeeksArray = () => {
        const activeYear = DateTime.fromObject({ year: activeNativeYear });
        const weeksArray = [];
        let min = 1;
        let max = activeYear.weeksInWeekYear;

        if (from.year === activeNativeYear && to.year === activeNativeYear) {
            min = from.weekNumber;
            max = to.weekNumber;
        } else if (from.year === activeNativeYear) {
            min = from.weekNumber;
            max = activeYear.weeksInWeekYear;
        } else if (to.year === activeNativeYear) {
            min = 1;
            max = to.weekNumber;
        } else if (from.year !== activeNativeYear && to.year !== activeNativeYear) {
            min = from.weekNumber;
            max = to.weekNumber;
        }

        for (let i = min; i <= max; i++) {
            weeksArray.push(i);
        }
        return weeksArray;
    };

    const getMonthsArray = () => {
        const currentLang = getLanguageShortCode();
        Settings.defaultLocale = currentLang || 'en';
        const months = Info.months('numeric');
        let min = 1;
        let max = 12;

        if (from.year === activeNativeYear && to.year === activeNativeYear) {
            min = from.month;
            max = to.month;
        } else if (from.year === activeNativeYear) {
            min = from.month;
            max = 12;
        } else if (to.year === activeNativeYear) {
            min = 1;
            max = to.month;
        } else if (from.year !== activeNativeYear && to.year !== activeNativeYear) {
            min = from.month;
            max = to.month;
        }
        return months.slice(min - 1, max).map((item) => parseInt(item));
    };

    const getQuartersArray = () => {
        const quartersArray = [1, 2, 3, 4];
        let min = 1;
        let max = 4;

        if (from.year === activeNativeYear && to.year === activeNativeYear) {
            min = from.quarter;
            max = to.quarter;
        } else if (from.year === activeNativeYear) {
            min = from.quarter;
            max = 4;
        } else if (to.year === activeNativeYear) {
            min = 1;
            max = to.quarter;
        } else if (from.year !== activeNativeYear && to.year !== activeNativeYear) {
            min = from.quarter;
            max = to.quarter;
        }
        return quartersArray.slice(min - 1, max);
    };

    const weeksArray = getWeeksArray();
    const monthsArray = getMonthsArray();
    const quartersArray = getQuartersArray();

    const arrowLeftIsDisabled =
        (weeksArray.indexOf(activeNativeWeek) === 0 && dateFilterType === 'weeks') ||
        (monthsArray.indexOf(activeNativeMonth) === 0 && dateFilterType === 'months') ||
        (quartersArray.indexOf(activeNativeQuarter) === 0 && dateFilterType === 'qs');
    const arrowRightIsDisabled =
        (weeksArray.indexOf(activeNativeWeek) === weeksArray.length - 1 && dateFilterType === 'weeks') ||
        (monthsArray.indexOf(activeNativeMonth) === monthsArray.length - 1 && dateFilterType === 'months') ||
        (quartersArray.indexOf(activeNativeQuarter) === quartersArray.length - 1 && dateFilterType === 'qs');

    const handleDateChangeClick = (direction: 'forward' | 'backward') => {
        const { week: activeNativeWeek } = getValues();

        if (dateFilterType === FoodSafetySegments.weeks) {
            if (direction === 'backward') {
                setValue(INPUT_NAME_WEEK, weeksArray[weeksArray.indexOf(activeNativeWeek) - 1]);
            } else if (direction === 'forward') {
                setValue(INPUT_NAME_WEEK, weeksArray[weeksArray.indexOf(activeNativeWeek) + 1]);
            }
        }

        if (dateFilterType === FoodSafetySegments.months) {
            if (direction === 'backward') {
                setValue(INPUT_NAME_MONTH, monthsArray[monthsArray.indexOf(activeNativeMonth) - 1]);
            } else if (direction === 'forward') {
                setValue(INPUT_NAME_MONTH, monthsArray[monthsArray.indexOf(activeNativeMonth) + 1]);
            }
        }

        if (dateFilterType === FoodSafetySegments.qs) {
            if (direction === 'backward') {
                setValue(INPUT_NAME_QUARTER, quartersArray[quartersArray.indexOf(activeNativeQuarter) - 1]);
            } else if (direction === 'forward') {
                setValue(INPUT_NAME_QUARTER, quartersArray[quartersArray.indexOf(activeNativeQuarter) + 1]);
            }
        }
    };

    const weekIsNotIncludedInWeeksArray = !weeksArray.includes(activeNativeWeek);
    const monthIsNotIncludedInMonthsArray = !monthsArray.includes(activeNativeMonth);
    const quarterIsNotIncludedInQuartersArray = !quartersArray.includes(activeNativeQuarter);

    React.useEffect(() => {
        if (activeNativeWeek && weekIsNotIncludedInWeeksArray) {
            setValue(INPUT_NAME_WEEK, weeksArray[weeksArray.length - 1]);
        }
    }, [setValue, activeNativeWeek, weeksArray, weekIsNotIncludedInWeeksArray]);

    React.useEffect(() => {
        if (activeNativeMonth && monthIsNotIncludedInMonthsArray) {
            setValue(INPUT_NAME_MONTH, monthsArray[monthsArray.length - 1]);
        }
    }, [setValue, activeNativeMonth, monthsArray, monthIsNotIncludedInMonthsArray]);

    React.useEffect(() => {
        if (activeNativeQuarter && quarterIsNotIncludedInQuartersArray) {
            setValue(INPUT_NAME_QUARTER, quartersArray[quartersArray.length - 1]);
        }
    }, [setValue, activeNativeQuarter, quartersArray, quarterIsNotIncludedInQuartersArray]);

    React.useEffect(() => {
        if (activeNativeYear > to.year) {
            setDate({
                weekAndYearDate: {
                    week: to.weekNumber,
                    year: to.year,
                },
            });
        } else {
            const nativeWeek = activeNativeWeek ? activeNativeWeek : weeksArray[weeksArray.length - 1];
            setDate({
                weekAndYearDate: {
                    week: nativeWeek,
                    year: activeNativeYear,
                },
            });
        }
    }, [activeNativeWeek, activeNativeYear, to, setDate]);

    React.useEffect(() => {
        if (activeNativeYear > to.year) {
            setDate({
                monthAndYearDate: {
                    month: to.month,
                    year: to.year,
                },
            });
        } else {
            setDate({
                monthAndYearDate: {
                    month: activeNativeMonth,
                    year: activeNativeYear,
                },
            });
        }
    }, [activeNativeMonth, activeNativeYear, to, setDate]);

    React.useEffect(() => {
        if (activeNativeYear > to.year) {
            setDate({
                weekAndYearDate: {
                    week: to.weekNumber,
                    year: to.year,
                },
            });
        } else {
            setDate({
                qsAndYearDate: {
                    quarter: activeNativeQuarter,
                    year: activeNativeYear,
                },
            });
        }
    }, [activeNativeQuarter, activeNativeYear, to, setDate]);

    if ((activeNativeWeek && weekIsNotIncludedInWeeksArray) || (activeNativeMonth && monthIsNotIncludedInMonthsArray) || (activeNativeQuarter && quarterIsNotIncludedInQuartersArray)) {
        return null;
    }

    const getMonthNameByNumber = (index: number) => {
        const currentLang = getLanguageShortCode();
        Settings.defaultLocale = currentLang || 'en';
        const months = Info.months();
        return months[index - 1];
    };

    const getQuarterNameByNumber = (index: number) => {
        const quartersArray = [
            {
                title: t('quarter.q1'),
                value: 1,
            },
            {
                title: t('quarter.q2'),
                value: 2,
            },
            {
                title: t('quarter.q3'),
                value: 3,
            },
            {
                title: t('quarter.q4'),
                value: 4,
            },
        ];
        return quartersArray.find((item) => item.value === index)?.title;
    };

    return (
        <Box alignItems="center" display="flex">
            <IconButton className={classes.chevron} disabled={arrowLeftIsDisabled} size="small" onClick={() => handleDateChangeClick('backward')}>
                <ChevronLeft />
            </IconButton>
            <Box display="flex">
                {dateFilterType === FoodSafetySegments.weeks && (
                    <div className={classes.weekOuter}>
                        <TextFieldRHF control={control} defaultValue={date?.weekAndYear?.week} name={INPUT_NAME_WEEK} select size="small">
                            {weeksArray.map((week) => {
                                return (
                                    <MenuItem key={week} value={week}>
                                        {week}
                                    </MenuItem>
                                );
                            })}
                        </TextFieldRHF>
                    </div>
                )}
                {dateFilterType === FoodSafetySegments.months && (
                    <div className={classes.monthOuter}>
                        <TextFieldRHF control={control} defaultValue={date?.monthAndYear?.month} name={INPUT_NAME_MONTH} select size="small">
                            {monthsArray.map((month) => {
                                return (
                                    <MenuItem key={month} value={month}>
                                        {getMonthNameByNumber(month)}
                                    </MenuItem>
                                );
                            })}
                        </TextFieldRHF>
                    </div>
                )}
                {dateFilterType === FoodSafetySegments.qs && (
                    <div className={classes.monthOuter}>
                        <TextFieldRHF control={control} defaultValue={date?.qsAndYear?.quarter} name={INPUT_NAME_QUARTER} select size="small">
                            {quartersArray.map((quarter) => {
                                return (
                                    <MenuItem key={quarter} value={quarter}>
                                        {getQuarterNameByNumber(quarter)}
                                    </MenuItem>
                                );
                            })}
                        </TextFieldRHF>
                    </div>
                )}
                <div className={classes.yearOuter}>
                    <TextFieldRHF control={control} defaultValue={yearsArray.includes(date?.weekAndYear?.year || 0) ? date?.weekAndYear?.year : to.year} name={INPUT_NAME_YEAR} select size="small">
                        {yearsArray.map((year) => {
                            return (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            );
                        })}
                    </TextFieldRHF>
                </div>
            </Box>
            <IconButton className={classes.chevron} disabled={arrowRightIsDisabled} size="small" onClick={() => handleDateChangeClick('forward')}>
                <ChevronRight />
            </IconButton>
        </Box>
    );
};

export default CustomDatePickerWithArrows;
