import { AppState } from 'store';
import { Capabilities } from 'shared/useCapabilities/capabilities';
import { NotificationConfigTableAccessors } from 'types/notificationConfig/notificationConfigTableAccessors';
import { SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useStyles } from 'components/table/stylesTable';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useCapabilities from 'shared/useCapabilities/useCapabilities';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
}

const NotificationConfigTable = <T extends Record<string, unknown>>({ columns, data, isFetching, onSort }: React.PropsWithChildren<TableProperties<T>>) => {
    const { isAuthorized } = useCapabilities();
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const myProfileData = useSelector((state: AppState) => state.auth.myProfile);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy },
        prepareRow,
        setHiddenColumns,
        state: { hiddenColumns },
    } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            initialState: {
                sortBy: [{ id: 'username', desc: false }],
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    React.useEffect(() => {
        if (myProfileData) {
            const columnsToHide = [];
            if (!isAuthorized(Capabilities.REPORT_SETTINGS_LIST_ALL_USERS)) {
                columnsToHide.push(NotificationConfigTableAccessors.username);
            }
            if (isXsDown) {
                columnsToHide.push(
                    NotificationConfigTableAccessors.reportFoodSafetyReport,
                    NotificationConfigTableAccessors.reportFoodSafetyStatistics,
                    NotificationConfigTableAccessors.reportFoodSafetyStatisticsMonthly,
                    NotificationConfigTableAccessors.reportFoodSafetyStatisticsQuarterly,
                    NotificationConfigTableAccessors.reportEnergyConsumption,
                    NotificationConfigTableAccessors.reportEnergyPower,
                );
            } else {
                if (!isAuthorized(Capabilities.UPDATE_FOOD_SAFETY_REPORT)) {
                    columnsToHide.push(NotificationConfigTableAccessors.reportFoodSafetyReport);
                }
                if (!isAuthorized(Capabilities.UPDATE_FOOD_SAFETY_STATISTICS_REPORT)) {
                    columnsToHide.push(NotificationConfigTableAccessors.reportFoodSafetyStatistics);
                    columnsToHide.push(NotificationConfigTableAccessors.reportFoodSafetyStatisticsMonthly);
                    columnsToHide.push(NotificationConfigTableAccessors.reportFoodSafetyStatisticsQuarterly);
                }
                if (!isAuthorized(Capabilities.UPDATE_ENERGY_CONSUMPTION_REPORT)) {
                    columnsToHide.push(NotificationConfigTableAccessors.reportEnergyConsumption);
                }
                if (!isAuthorized(Capabilities.UPDATE_ENERGY_POWER_REPORT)) {
                    columnsToHide.push(NotificationConfigTableAccessors.reportEnergyPower);
                }
            }
            if (hiddenColumns && hiddenColumns.length !== columnsToHide.length) {
                setHiddenColumns(columnsToHide);
            }
        }
    }, [columns, setHiddenColumns, myProfileData, hiddenColumns, isAuthorized, isXsDown]);

    const classes = useStyles();

    return (
        <table {...getTableProps()} className={classes.root}>
            <TableHead headerGroups={headerGroups} />
            <tbody {...getTableBodyProps()}>
                {rows.length === 0 && !isFetching && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                            <NoResults />
                        </td>
                    </tr>
                )}
                {rows.map((row, i) => {
                    prepareRow(row);
                    const { key, role } = row.getRowProps();
                    return (
                        <React.Fragment key={key}>
                            <tr className={classes.tableRow} role={role}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={classes.tableCell}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};

export default NotificationConfigTable;
