import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TemperatureProps } from 'components/table/components/temperature/temperatureProps';
import { ReactComponent as WaterIcon } from 'assets/icons/water.svg';
import clsx from 'clsx';
import NoData from 'components/noData/noData';
import React from 'react';
import settings from 'settings';

const useStyles = makeStyles((theme) => ({
    water: {
        fill: theme.customPalette.colors.brand.light,
        marginTop: theme.spacing(-0.5),
        marginBottom: theme.spacing(-0.5),
    },
    rectangle: {
        borderRadius: theme.spacing(0.25),
        color: theme.customPalette.background.white,
        paddingBottom: theme.spacing(0.25),
        padding: theme.spacing(3 / 8),
        margin: theme.spacing(-3 / 8),
        display: 'inline-block',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(-0.5),
        },
    },
    error: {
        background: theme.customPalette.colors.error,
    },
    warning: {
        background: theme.customPalette.colors.warning,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    waterIconAlone: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(-0.5),
        },
    },
    waterIconWithTemperature: {
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(-0.25),
            marginLeft: theme.spacing(-0.25),
        },
    },
}));

const Temperature: React.FC<TemperatureProps> = ({ value, row }) => {
    const classes = useStyles();
    if (value === undefined) {
        return <NoData />;
    }
    const { temperature, defrostOn, setPointAltOn } = value;
    if (defrostOn && defrostOn > settings.defrostOnThreshold) {
        return <WaterIcon className={clsx(classes.water, classes.waterIconAlone)} />;
    }

    if (temperature === null) {
        return (
            <>
                {defrostOn > 0 && <WaterIcon className={clsx(classes.water, classes.waterIconAlone)} />}
                <NoData />
            </>
        );
    }

    if (defrostOn > 0 && defrostOn <= settings.defrostOnThreshold) {
        return (
            <Box className={classes.noWrap} display="inline-block">
                <WaterIcon className={clsx(classes.water, classes.waterIconWithTemperature)} />
                {temperature}
            </Box>
        );
    }
    const { setPoint, setPointAlt } = row.original;

    const bucketDate: Date = new Date(value.bucket);
    const absDate: Date = new Date('2023-11-07T00:00:00');
    const useAbs: boolean = bucketDate < absDate;

    const getColorByDiffBetweenTemperatureAndSetpoint = (differenceBetweenTemperatureAndSetPoint: number) => {
        if (differenceBetweenTemperatureAndSetPoint > settings.temperatureDiffThresholdWarning && differenceBetweenTemperatureAndSetPoint <= settings.temperatureDiffThresholdError) {
            return <span className={clsx(classes.rectangle, classes.warning)}>{temperature}</span>;
        } else if (differenceBetweenTemperatureAndSetPoint > settings.temperatureDiffThresholdError) {
            return <span className={clsx(classes.rectangle, classes.error)}>{temperature}</span>;
        }
        return <>{temperature}</>;
    };

    if (setPoint !== null && setPointAlt !== null) {
        const setPointToCompare: number = setPointAltOn > settings.setPointAltThreshold ? setPointAlt : setPoint;
        const differenceBetweenTemperatureAndSetPoint: number = temperature - setPointToCompare;
        const temperatureToCompare: number = useAbs
            ? Math.abs(differenceBetweenTemperatureAndSetPoint)
            : differenceBetweenTemperatureAndSetPoint;
        return getColorByDiffBetweenTemperatureAndSetpoint(temperatureToCompare);
    }

    if (setPoint !== null && setPointAlt === null) {
        const differenceBetweenTemperatureAndSetPoint = temperature - setPoint;
        const temperatureToCompare = useAbs
            ? Math.abs(differenceBetweenTemperatureAndSetPoint)
            : differenceBetweenTemperatureAndSetPoint;
        return getColorByDiffBetweenTemperatureAndSetpoint(temperatureToCompare);
    }

    if (setPoint === null && setPointAlt !== null) {
        const differenceBetweenTemperatureAndSetPoint = temperature - setPointAlt;
        const temperatureToCompare = useAbs
            ? Math.abs(differenceBetweenTemperatureAndSetPoint)
            : differenceBetweenTemperatureAndSetPoint;
        return getColorByDiffBetweenTemperatureAndSetpoint(temperatureToCompare);
    }

    return <>{temperature}</>;
};

export default Temperature;
