import { TextFieldRHFProps } from 'components/textField/textFieldRHFProps';
import { translateCustomValidation } from 'shared/useHandledForm/useHandledForm';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TextField from './textField';

const TextFieldRHF: React.FC<TextFieldRHFProps> = ({ placeholder, autoFocus, multiline, rows, defaultValue, size, disabled, children, name, control, label, type, select, id }) => {
    const { t } = useTranslation();
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue: defaultValue || '',
    });
    const hasError = Boolean(error);
    return (
        <TextField
            {...inputProps}
            autoFocus={autoFocus}
            disabled={disabled}
            error={hasError}
            helperText={hasError ? translateCustomValidation(t, error?.message) : undefined}
            id={id}
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            inputRef={ref}
            label={label}
            multiline={multiline}
            placeholder={placeholder}
            rows={rows}
            select={select}
            size={size}
            type={type}
        >
            {children}
        </TextField>
    );
};

export default TextFieldRHF;
