import { AppState } from 'store';
import { Filters, SortingRule, TableOptions, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { routes } from 'routes/routes';
import { storeId } from 'store/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStyles } from 'components/table/stylesTable';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import Pagination from 'components/table/components/pagination/pagination';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
    onFilter: (rule: Filters<{}> | undefined) => void;
    pageCount: number;
}

const LogsReportingTable = <T extends Record<string, unknown>>({ columns, data, onSort, pageCount, isFetching, onFilter, onPageIndexChange }: React.PropsWithChildren<TableProperties<T>>) => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { storeId: currentStoreId } = useSelector((state: AppState) => state.store);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters, pageIndex },
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            manualFilters: true,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [{ id: 'occurredOn', desc: true }],
            },
            manualPagination: true,
            pageCount,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const handleRowClick = (rowStoreId: number) => {
        if (rowStoreId !== currentStoreId) {
            dispatch(storeId(rowStoreId));
            enqueueSnackbar(t('adminLogs.reporting.storeChanged'));
        }
        history.push(routes.setupEmailReportConfig.path());
    };

    React.useEffect(() => {
        onPageIndexChange(pageIndex);
    }, [pageIndex, onPageIndexChange]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    return (
        <>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} />
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td colSpan={headerGroups[0].headers.length}>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row, i) => {
                        prepareRow(row);
                        const { key, role } = row.getRowProps();
                        return (
                            <React.Fragment key={key}>
                                <tr className={clsx(classes.tableRow, classes.tableRowClickable)} role={role} onClick={() => handleRowClick(row.original.storeId)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={classes.tableCell} style={cell.column.customStyles}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                previousPage={previousPage}
            />
        </>
    );
};

export default LogsReportingTable;
