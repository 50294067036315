import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import ContentTabs from 'components/contentTabs/contentTabs';
import EmailReportConfig from 'screens/setup/emailReportConfig';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';

const SetupScreen: React.FC = () => {
    const { t } = useTranslation();
    const items = [
        {
            id: 'setupEmailReportConfig',
            path: routes.setupEmailReportConfig.path(),
            text: t('setup.emailReportConfig.title'),
        },
    ];
    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Switch>
                    <Route component={EmailReportConfig} exact path={routes.setupEmailReportConfig.pattern} />
                </Switch>
            </Main>
        </>
    );
};

export default SetupScreen;
