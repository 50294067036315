import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const ContactUsSchema = z.object({
    success: z.literal(true),
});
export interface ContactUsParams {
    name: string;
    emailAddress: string;
    phoneNumber: string;
    subject: string;
    message: string;
    languageId: number;
}

export const mutateContactUs = async (values: ContactUsParams) => {
    const payload = {
        ...values,
        phoneNumber: values.phoneNumber || undefined,
    };
    const response = await makeMutation(ApiEndpoints.contactUs(), payload);
    return ContactUsSchema.parse(response);
};

export const contactUsErrorCodes = {
    PARAMETER_NOT_MATCH_PATTERN: {
        code: 'PARAMETER_NOT_MATCH_PATTERN',
        translation: (t: TFunction) => t('contactUs.PARAMETER_NOT_MATCH_PATTERN'),
    },
};
