import { clearStoreId, setStoreId } from 'localStorage/store';
import { SET_STORE_ID, SetStoreIdActionTypes } from 'store/store/types';

export const storeId = (storeId: number | null): SetStoreIdActionTypes => {
    if (storeId) {
        setStoreId(storeId.toString());
    } else {
        clearStoreId();
    }
    return {
        type: SET_STORE_ID,
        storeId,
    };
};
