import { useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import useResizeObserver from 'use-resize-observer';

const useDebouncesObserverResize = (wait: any): any => {
    const [size, setSize] = useState({});
    const onResize = useMemo(() => _debounce(setSize, wait, { leading: true }), [wait]);
    const { ref } = useResizeObserver({ onResize });

    return { ref, ...size };
};

export default useDebouncesObserverResize;
