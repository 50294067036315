import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import alarmColors from 'theme/alarmColors';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: '0.24px',
        padding: 12,
        textAlign: 'center',
    },
    noAlarm: {
        background: alarmColors.good,
    },
    hasAlarm: {
        background: alarmColors.bad,
        color: 'white',
    },
}));

const AlertHeader = () => {
    const { t } = useTranslation();
    const { alarmCount } = useSelector((state: AppState) => state.filters.alarmReport);
    const classes = useStyles();
    const { wrapper, noAlarm, hasAlarm } = classes;
    const hasActiveAlarms = Boolean(alarmCount > 0);

    return (
        <div className={clsx(wrapper, hasActiveAlarms ? hasAlarm : noAlarm)}>
            <span>{hasActiveAlarms ? t('alarm.hasActive', { alarmCount }) : t('alarm.noActive')}</span>
        </div>
    );
};

export default AlertHeader;
