import { Asset, AssetBucket } from 'model/asset/schema';
import { DateTime } from 'shared/luxon/luxon';

interface chartData extends AssetBucket {
    xDataKey: number | string;
}

const useAssetChartData = (dateFrom: DateTime, dateTo: DateTime, assetData?: Asset) => {
    const data: chartData[] = assetData
        ? assetData.data.buckets.map((bucket) => {
              return {
                  xDataKey: DateTime.fromJSDate(new Date(bucket.bucket)).toMillis(),
                  ...bucket,
              };
          })
        : [];

    const ticksShownCount = 15;
    const tickInterval = Math.floor(data.length / ticksShownCount);

    return {
        tickInterval,
        data,
    };
};

export default useAssetChartData;
