import * as z from 'zod';
import { AppState } from 'store';
import { Box } from '@material-ui/core';
import { getUser } from 'api/fetchers/getUser';
import { handleSimpleSpecificEndpointErrorsToForm } from 'shared/errors/handleStandardApiErrors';
import { mutatePutUser, putUserErrorCodes, PutUserParams } from 'api/mutations/putUser';
import { postUserErrorCodes } from 'api/mutations/postUser';
import { QueryKeys } from 'api/queryKeys';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validateNameInput } from 'shared/useHandledForm/rules';
import BottomBar from 'components/bottomBar/bottomBar';
import Button from 'components/button/button';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useLanguages from 'shared/useLanguages/useLanguages';
import useRoles from 'shared/useRoles/useRoles';

const formValuesSchema = z.object({
    firstName: z.string().min(1).max(64).regex(validateNameInput),
    lastName: z.string().min(1).max(64).regex(validateNameInput),
    username: z.string().min(1).max(64),
    emailAddress: z.string().email(),
    role: z.string().nonempty(),
    languageId: z.number(),
});

interface FormValuesProps extends Omit<PutUserParams, 'userId'> {}

const UserForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { languages } = useLanguages();

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { data, refetch, isSuccess: fetchIsSuccess } = useHandledQuery(QueryKeys.userDetail(parseInt(id)), () => getUser({ id: parseInt(id) }), { enabled: !!id });

    const { mutate, variables: mutateVariables, isError: mutateIsError, error: mutateError, isLoading: mutateIsLoading } = useHandledMutation(mutatePutUser, undefined, putUserErrorCodes);

    const {
        handleSubmit,
        reset,
        control,
        formState: { isDirty },
        getValues,
        setError,
    } = useHandledForm(formValuesSchema);

    const myProfile = useSelector((state: AppState) => state.auth.myProfile);
    const userEditingHimself = myProfile && myProfile.id === parseInt(id);

    React.useEffect(() => {
        if (data && fetchIsSuccess) {
            reset(data);
        }
    }, [data, reset, fetchIsSuccess]);

    React.useEffect(() => {
        if (mutateIsError) {
            handleSimpleSpecificEndpointErrorsToForm(mutateError, enqueueSnackbar, t, postUserErrorCodes, Object.keys(getValues()), setError);
        }
    }, [enqueueSnackbar, getValues, mutateError, mutateIsError, setError, t, mutateVariables]);

    const discardChanges = () => {
        reset(data);
    };

    const onSubmit = async (values: FormValuesProps) => {
        await mutate(
            { userId: parseInt(id), ...values },
            {
                onSuccess: () => {
                    if (values) {
                        if (userEditingHimself) {
                            queryClient.refetchQueries([QueryKeys.myProfile(), true]);
                        }
                        enqueueSnackbar(t('userDetail.form.successSubmit'));
                        refetch();
                    }
                },
            },
        );
    };

    const roles = useRoles();

    if (!data || !myProfile) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldRHF control={control} label={t('userDetail.form.firstName.label')} name="firstName" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.lastName.label')} name="lastName" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.username.label')} name="username" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.emailAddress.label')} name="emailAddress" />
            <Box mb={2} />
            <TextFieldRHF control={control} disabled={userEditingHimself} label={t('userDetail.form.role.label')} name="role" select>
                {roles.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.language.label')} name="languageId" select>
                {languages.map(({ languageId, label }) => (
                    <MenuItem key={languageId} value={languageId}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={3} />
            {isDirty && (
                <BottomBar>
                    <Box display="flex">
                        <Button isLoading={mutateIsLoading} isPrimary size="medium" type="submit">
                            {t('saveChanges')}
                        </Button>
                        <Box alignItems="center" display="flex" ml={1.5}>
                            <Button size="medium" onClick={discardChanges}>
                                {t('discard')}
                            </Button>
                        </Box>
                    </Box>
                </BottomBar>
            )}
        </form>
    );
};

export default UserForm;
