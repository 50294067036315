import { Cell, Filters, SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import { useTheme, makeStyles } from '@material-ui/core';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { InView } from 'react-intersection-observer';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
    onFilter: (rule: Filters<{}> | undefined) => void;
}

const AlarmTableViewTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching, onFilter }: React.PropsWithChildren<TableProperties<T>>) => {
    const [tableRecords, setTableRecords] = React.useState<T[]>([]);
    const {
        tableView: { filters: storedFilter },
    } = useSelector((state: AppState) => state.filters.alarmReport);

    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters },
        prepareRow,
    } = useTable<T>(
        {
            initialState: {
                filters: storedFilter || [],
            },
            columns,
            data: tableRecords,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            manualFilters: true,
            getRowId,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );
    const [currentFilters, setCurrentFilters] = React.useState(filters);
    const [sortingRule, setCurrentSortingRule] = React.useState(sortBy);

    React.useEffect(() => {
        if (data) {
            setTableRecords(data);
        }
    }, [data]);

    // Handle filters
    const handleFilterChange = React.useCallback(() => {
        setCurrentFilters(filters);
    }, [filters]);

    React.useEffect(() => {
        handleFilterChange();
    }, [handleFilterChange]);

    React.useEffect(() => {
        onFilter(filters);
    }, [currentFilters]);

    // Handle sorting
    const handleSortingChange = React.useCallback(() => {
        setCurrentSortingRule(sortBy);
    }, [sortBy]);

    React.useEffect(() => {
        handleSortingChange();
    }, [handleSortingChange]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortingRule]);

    const classes = {
        ...useStyles(),
        ...makeStyles(() => ({
            tableRowDefault: {
                '& td:first-child': {
                    fontWeight: 500,
                },
            },
        }))(),
    };

    return (
        <table {...getTableProps()} className={classes.root}>
            <TableHead headerGroups={headerGroups} />
            <tbody {...getTableBodyProps()}>
                {rows.length === 0 && !isFetching && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length + (isLgUp ? 24 : 0)}>
                            <NoResults />
                        </td>
                    </tr>
                )}
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <InView key={`${row.id}-${row.index}`} triggerOnce={true}>
                            {({ ref, inView }) => (
                                <>
                                    <div ref={ref} style={{ width: '100%', position: 'absolute' }} />
                                    {inView ? (
                                        <>
                                            <tr className={clsx(classes.tableRow, classes.tableRowDefault)}>
                                                {row.cells.map((cell: Cell<T>) => {
                                                    return (
                                                        <React.Fragment key={`${cell.column.id}-${cell.row.id}`}>
                                                            <td {...cell.getCellProps()} className={`${classes.tableCell} ${cell.column.id}`} style={cell.column.customStyles}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tr>
                                        </>
                                    ) : (
                                        <div style={{ height: isLgUp ? 47 : 131 }} />
                                    )}
                                </>
                            )}
                        </InView>
                    );
                })}
            </tbody>
        </table>
    );
};

export default AlarmTableViewTable;
