import { AppState } from 'store';
import { ChooseStoreTableAccessors } from 'types/chooseStore/chooseStoreTableAccessors';
import { Column, SortingRule } from 'react-table';
import { fetchStores } from 'api/fetchers/stores';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { Store } from 'model/store/schema';
import { transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChooseStoreTable from 'components/table/instances/chooseStoreTable/chooseStoreTable';
import Content from 'components/content/content';
import Header from 'components/header/header';
import LoadingBar from 'components/loadingBar/loadingBar';
import Main from 'components/main/main';
import React from 'react';
import SearchBox from 'components/searchBox/searchBox';

const ChooseStoreScreen: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [filter, setFilter] = React.useState<string | null>(null);
    const { storeId } = useSelector((state: AppState) => state.store);

    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);

    const { data, isFetching } = useHandledQuery(
        [QueryKeys.stores(), sortingRule, filter || null],
        () => fetchStores(sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined, undefined, filter || undefined),
        { keepPreviousData: true },
    );

    const columns = React.useMemo<Column<Store>[]>(
        () => [
            {
                Header: t('chooseStore.table.heading.client').toString(),
                accessor: ChooseStoreTableAccessors.customer,
                disableSortBy: false,
            },
            {
                Header: t('chooseStore.table.heading.region').toString(),
                accessor: ChooseStoreTableAccessors.region,
                disableSortBy: false,
            },
            {
                Header: t('chooseStore.table.heading.zipCode').toString(),
                accessor: ChooseStoreTableAccessors.zipCode,
                disableSortBy: false,
            },
            {
                Header: t('chooseStore.table.heading.city').toString(),
                accessor: ChooseStoreTableAccessors.town,
                disableSortBy: false,
            },
            {
                Header: t('chooseStore.table.heading.street').toString(),
                accessor: ChooseStoreTableAccessors.street,
                disableSortBy: false,
            },
            {
                Header: t('chooseStore.table.heading.anlage').toString(),
                accessor: ChooseStoreTableAccessors.anlage,
                disableSortBy: false,
            },
        ],
        [t],
    );

    if (!data) {
        return null;
    }

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const goBackAction = () => {
        history.push(routes.default.path());
    };

    return (
        <>
            <Header goBackAction={storeId ? goBackAction : undefined} goBackText={storeId ? t('home') : undefined} headingText={t('chooseShop.title')} />
            <SearchBox setFilter={setFilter} />
            <Main>
                <Content>
                    <LoadingBar isFetching={isFetching} />
                    <ChooseStoreTable<Store> columns={columns} data={data} isFetching={isFetching} onSort={handleSort} />
                </Content>
            </Main>
        </>
    );
};

export default ChooseStoreScreen;
