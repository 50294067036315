import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { deleteNotificationErrorCodes, mutateDeleteNotification } from 'api/mutations/deleteNotification';
import { IconButton, makeStyles } from '@material-ui/core';
import { NotificationDeleteCellProps } from 'components/table/components/notificationDeleteCell/notificationDeleteCellProps';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    delete: {
        fill: theme.customPalette.colors.error,
    },
}));

const NotificationDeleteCell: React.FC<NotificationDeleteCellProps> = ({ contact, contactId, refetchList }) => {
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const {
        mutate: deleteNotificationMutate,
        isSuccess: deleteNotificationIsSuccess,
        isLoading: deleteNotificationIsLoading,
    } = useHandledMutation(mutateDeleteNotification, undefined, deleteNotificationErrorCodes);

    const handleSubmit = () => {
        deleteNotificationMutate({ contactId });
    };

    React.useEffect(() => {
        if (deleteNotificationIsSuccess) {
            refetchList();
            enqueueSnackbar(t('emailReportConfig.successDelete'));
            setDialogOpen(false);
        }
    }, [deleteNotificationIsSuccess, enqueueSnackbar, t, refetchList]);

    return (
        <>
            <IconButton className={clsx(classes.delete, 'test-remove-icon')} size="small" onClick={() => setDialogOpen(true)}>
                <DeleteIcon />
            </IconButton>
            <ConfirmationDialog
                handleClose={() => setDialogOpen(false)}
                handleSubmit={handleSubmit}
                open={dialogOpen}
                question={t('notificationDelete.question', { contact })}
                submitIsLoading={deleteNotificationIsLoading}
                submitText={t('notificationDelete.submitText')}
            />
        </>
    );
};

export default NotificationDeleteCell;
