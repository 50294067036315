import { AssetHeaderProps } from 'components/assetHeader/assetHeaderProps';
import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'shared/luxon/luxon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { routes } from 'routes/routes';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import Grid from '@material-ui/core/Grid';
import HeadingTwo from 'components/typography/headingTwo/headingTwo';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'relative',
            zIndex: 1,
            background: theme.customPalette.background.light,
            boxShadow: theme.customElevation.default,
            width: '100%',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(2),
            },
        },
        chevron: {
            fill: theme.customPalette.colors.brand.light,
            margin: `0 ${theme.spacing(0.5)}`,
            '&[disabled]': {
                opacity: 0.2,
            },
        },
        inputChosenAssetOuter: {
            width: theme.spacing(20),
        },
        dateFromOuter: {
            width: `calc(50% - ${theme.spacing(1)})`,
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: theme.typography.pxToRem(110),
            },
        },
        dateToOuter: {
            width: `calc(50% - ${theme.spacing(1)})`,
            marginLeft: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginLeft: 'auto',
                marginRight: theme.spacing(3),
                width: theme.typography.pxToRem(110),
            },
        },
        input: {
            width: '100%',
            '& > div': {
                background: theme.customPalette.background.white,
            },
            '& input': {
                width: '100%',
                height: theme.spacing(3),
                padding: `0 ${theme.spacing(1)}`,
                fontSize: theme.typography.pxToRem(12),
            },
            '& button': {
                color: theme.customPalette.colors.brand.light,
                margin: theme.spacing(-2),
            },
            '& svg': {
                width: theme.spacing(2),
                height: theme.spacing(2),
            },
            '& .MuiFormHelperText-root.Mui-error': {
                display: 'none',
            },
            '& .MuiInputLabel-outlined': {
                fontSize: theme.typography.pxToRem(14),
                marginLeft: theme.spacing(-6 / 8),
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,.08)',
            },
            '& legend': {
                marginLeft: theme.spacing(-4 / 8),
            },
        },
    };
});

const AssetHeader: React.FC<AssetHeaderProps> = ({ goBackPath, dateFrom, dateTo, setDateFrom, setDateTo, longName, shortName, availableAssets, activeAssetId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const INPUT_CHOSEN_ASSET = 'chosenAsset';
    const history = useHistory();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));

    const { control, watch, setValue } = useHandledForm();
    const watchedAvailableAsset = watch(INPUT_CHOSEN_ASSET);

    React.useEffect(() => {
        if (watchedAvailableAsset) {
            history.push(routes.asset.path(watchedAvailableAsset));
        }
    }, [watchedAvailableAsset, history]);

    React.useEffect(() => {
        setValue(INPUT_CHOSEN_ASSET, activeAssetId);
    }, [activeAssetId, setValue]);

    const handleAssetDetailChange = (addition: number) => {
        const indexOfActiveAssetId = availableAssets.findIndex(({ id }) => id === activeAssetId);
        history.push(routes.asset.path(availableAssets[indexOfActiveAssetId + addition].id));
    };

    const handleGoBack = () => {
        history.push(goBackPath || routes.foodSafetyReporting.path());
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item md={4} sm={6} xs={12}>
                    <Box alignItems="center" display="flex" ml={1}>
                        <IconButton aria-label={t('menu.close')} id="test-go-back-button" onClick={handleGoBack}>
                            <CloseIcon />
                        </IconButton>
                        <HeadingTwo>{t('assetHeader.heading.positionDetail', { longName, shortName })}</HeadingTwo>
                    </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    {availableAssets && availableAssets.length > 0 ? (
                        <Box alignItems="center" display="flex" height="100%" justifyContent={isSm ? 'flex-end' : 'center'} px={1}>
                            <IconButton className={classes.chevron} disabled={activeAssetId === availableAssets[0].id} size="small" onClick={() => handleAssetDetailChange(-1)}>
                                <ChevronLeft />
                            </IconButton>
                            <div className={classes.inputChosenAssetOuter}>
                                <TextFieldRHF control={control} defaultValue={activeAssetId} name={INPUT_CHOSEN_ASSET} select size="small">
                                    {availableAssets.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextFieldRHF>
                            </div>
                            <IconButton className={classes.chevron} disabled={activeAssetId === availableAssets[availableAssets.length - 1].id} size="small" onClick={() => handleAssetDetailChange(1)}>
                                <ChevronRight />
                            </IconButton>
                        </Box>
                    ) : null}
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box alignItems="center" display="flex" height="100%" justifyContent={isSmUp ? 'flex-end' : 'center'}>
                        <Box display="flex" flexWrap="wrap" px={isSmUp ? 0 : 2}>
                            <div className={classes.dateFromOuter}>
                                <DatePicker
                                    cancelLabel={t('datePicker.cancel')}
                                    className={classes.input}
                                    format={DateTimeFormat.DATE_FORMAT()}
                                    inputVariant="outlined"
                                    label={t('intervalPickerDialog.from')}
                                    maxDate={dateTo.startOf('day')}
                                    okLabel={t('datePicker.ok')}
                                    value={dateFrom}
                                    onChange={(date) => (date ? setDateFrom(date) : undefined)}
                                />
                            </div>
                            <div className={classes.dateToOuter}>
                                <DatePicker
                                    cancelLabel={t('datePicker.cancel')}
                                    className={classes.input}
                                    format={DateTimeFormat.DATE_FORMAT()}
                                    inputVariant="outlined"
                                    label={t('intervalPickerDialog.to')}
                                    maxDate={DateTime.now()}
                                    minDate={dateFrom.startOf('day')}
                                    okLabel={t('datePicker.ok')}
                                    value={dateTo}
                                    onChange={(date) => (date ? setDateTo(date) : undefined)}
                                />
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AssetHeader;
