import { Column, Filters, SortingRule } from 'react-table';
import { fetchUsers } from 'api/fetchers/users';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { User } from 'model/user/schema';
import { UsersTableAccessors } from 'types/users/usersTableAccessors';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Box from '@material-ui/core/Box';
import Button from 'components/button/button';
import Content from 'components/content/content';
import Header from 'components/header/header';
import LoadingBar from 'components/loadingBar/loadingBar';
import Main from 'components/main/main';
import React from 'react';
import SelectColumnFilter from 'components/table/components/selectColumnFilter/selectColumnFilter';
import settings from 'settings';
import useRoles, { getTranslatedRoleByRoleId } from 'shared/useRoles/useRoles';
import UsersTable from 'components/table/instances/usersTable/usersTable';
import UserState from 'components/userState/userState';

const AdminUsersScreen: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>();
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const [pageIndex, setPageIndex] = React.useState<number>(0);
    const roles = useRoles();

    const queryParams = {
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: filters ? transformReactTableFiltersForAPI(filters) : [],
        dataRange: {
            from: pageIndex * settings.pageSize,
            to: pageIndex * settings.pageSize + (settings.pageSize - 1),
        },
        pageIndex,
    };

    const { data, isFetching } = useHandledQuery([QueryKeys.adminUsers(), queryParams], () => fetchUsers(queryParams.dataRange, queryParams.sort, queryParams.filter), {
        keepPreviousData: true,
    });

    const columns = React.useMemo<Column<User>[]>(
        () => [
            {
                Header: t('users.table.heading.state').toString(),
                accessor: UsersTableAccessors.isDisabled,
                Cell: ({ value }) => {
                    return <UserState isDisabled={value} />;
                },
                disableSortBy: false,
                customStyles: {
                    width: '10%',
                },
            },
            {
                Header: t('users.table.heading.username').toString(),
                accessor: UsersTableAccessors.username,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.firstName').toString(),
                accessor: UsersTableAccessors.firstName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.lastName').toString(),
                accessor: UsersTableAccessors.lastName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.email').toString(),
                accessor: UsersTableAccessors.emailAddress,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.role').toString(),
                accessor: UsersTableAccessors.role,
                disableFilters: false,
                disableSortBy: false,
                Filter: ({ column }) => <SelectColumnFilter column={column} options={roles} />,
                Cell: ({ value }) => getTranslatedRoleByRoleId(value, t),
                customStyles: {
                    width: '18%',
                },
            },
        ],
        /*  with roles in deps not working */ // eslint-disable-next-line react-hooks/exhaustive-deps
        [t],
    );

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const goBackAction = () => {
        history.push(routes.default.path());
    };

    const handleGoToCreateUser = () => {
        history.push(routes.userCreate.path());
    };

    const handlePageIndexChange = (pageIndex: number) => {
        setPageIndex(pageIndex);
    };

    return (
        <>
            <Header goBackAction={goBackAction} goBackText={t('home')} headingText={t('adminUsers.title')} />
            <Main>
                <Content>
                    <LoadingBar isFetching={isFetching} />
                    <UsersTable<User>
                        columns={columns}
                        data={data ? data.data : []}
                        isFetching={isFetching}
                        pageCount={data ? Math.ceil(data.metadata.count / settings.pageSize) : 0}
                        onFilter={handleFilters}
                        onPageIndexChange={handlePageIndexChange}
                        onSort={handleSort}
                    />
                    <BottomBar>
                        <Box display="flex">
                            <Button isPrimary size="medium" type="submit" onClick={handleGoToCreateUser}>
                                {t('userCreate.title')}
                            </Button>
                        </Box>
                    </BottomBar>
                </Content>
            </Main>
        </>
    );
};

export default AdminUsersScreen;
