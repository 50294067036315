import * as z from 'zod';
import { ContactType } from 'shared/enums/contactType';

export const NotificationSchemaReports = z.object({
    energyConsumption: z.boolean().nullable(),
    energyPower: z.boolean().nullable(),
    foodSafetyReport: z.boolean(),
    foodSafetyStatistics: z.boolean(),
    foodSafetyStatisticsMonthly: z.boolean(),
    foodSafetyStatisticsQuarterly: z.boolean(),
});

export const NotificationSchema = z.object({
    contact: z.string(),
    contactType: z.nativeEnum(ContactType),
    enabled: z.boolean(),
    id: z.number(),
    username: z.string(),
    reports: NotificationSchemaReports,
});

export type Notification = z.infer<typeof NotificationSchema>;
