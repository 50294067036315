export enum ExportButtonSingleType {
    email = 'email',
    excel = 'excel',
    csv = 'csv',
    pdf = 'pdf',
}

interface ButtonProps {
    type?: ExportButtonSingleType;
    text: string;
    action: () => void;
    isLoading?: boolean;
}

export interface ExportButtonSingleProps {
    button: ButtonProps;
    disabled: boolean;
    href?: string;
    target?: string;
}
