import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminLogsEmailReport from 'screens/adminLogs/emailReport';
import AdminLogsUser from 'screens/adminLogs/user';
import ContentTabs from 'components/contentTabs/contentTabs';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';

const AdminLogsScreen: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const items = [
        {
            id: 'adminLogsUser',
            path: routes.adminLogsUser.path(),
            text: t('adminLogs.user.title'),
        },
        {
            id: 'adminLogsReport',
            path: routes.adminEmailReport.path(),
            text: t('adminLogs.report.title'),
        },
    ];

    const goBackAction = () => {
        history.push(routes.default.path());
    };

    return (
        <>
            <Header goBackAction={goBackAction} goBackText={t('home')} headingText={t('logs.title')} />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Switch>
                    <Route component={AdminLogsUser} exact path={routes.adminLogsUser.pattern} />
                    <Route component={AdminLogsEmailReport} exact path={routes.adminEmailReport.pattern} />
                </Switch>
            </Main>
        </>
    );
};

export default AdminLogsScreen;
