export enum NotificationConfigTableAccessors {
    contact = 'contact',
    contactType = 'contactType',
    enabled = 'enabled',
    id = 'id',
    reportEnergyConsumption = 'energyConsumption',
    reportEnergyPower = 'energyPower',
    reportFoodSafetyReport = 'foodSafetyReport',
    reportFoodSafetyStatistics = 'foodSafetyStatistics',
    reportFoodSafetyStatisticsMonthly = 'foodSafetyStatisticsMonthly',
    reportFoodSafetyStatisticsQuarterly = 'foodSafetyStatisticsQuarterly',
    username = 'username',
}
