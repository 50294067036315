import { EndpointSpecificErrors } from 'api/api';
import { handleStandardApiErrors } from 'shared/errors/handleStandardApiErrors';
import { QueryFunction, QueryKey, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React from 'react';
import StandardApiError from 'shared/errors/standardApiError';

export const useHandledQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    endpointSpecificErrors?: EndpointSpecificErrors,
): UseQueryResult<TData, TError> => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const result = useQuery(queryKey, queryFn, options);
    const { error } = result;
    React.useEffect(() => {
        if (error instanceof StandardApiError) {
            handleStandardApiErrors(error, enqueueSnackbar, t, history, endpointSpecificErrors, endpointSpecificErrors !== null && typeof endpointSpecificErrors !== 'undefined');
        }
    }, [error, enqueueSnackbar, t, endpointSpecificErrors, history]);

    return result;
};
