import { AlarmTimeDuration } from 'types/alarm/alarmTimeDuration';
import { useTranslation } from 'react-i18next';

const useSelectTimeDuration = () => {
    const { t } = useTranslation();
    const timeDurationArray = [
        {
            key: AlarmTimeDuration.last4,
            value: t('alarm.timeDuration.last4hours'),
        },
        {
            key: AlarmTimeDuration.last12,
            value: t('alarm.timeDuration.last12hours'),
        },
        {
            key: AlarmTimeDuration.last24,
            value: t('alarm.timeDuration.last24hours'),
        },
        {
            key: AlarmTimeDuration.last48,
            value: t('alarm.timeDuration.last48hours'),
        },
    ];

    return { timeDurationArray };
};

export default useSelectTimeDuration;
