import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const MyProfileSchema = z.object({
    success: z.literal(true),
});

export interface PutMyProfileParams {
    firstName: string;
    lastName: string;
    emailAddress: string;
    languageId: number;
}

export const mutatePutMyProfile = async (values: PutMyProfileParams) => {
    const payload = {
        ...values,
        isMarketingConsent: true,
        timeZone: 'UTC',
    };
    const response = await makeMutation(ApiEndpoints.putMyProfile(), payload, true, 'PUT');
    return MyProfileSchema.parse(response);
};

export const putMyProfileErrorCodes = {
    USERNAME_ALREADY_EXISTS: {
        code: 'USERNAME_ALREADY_EXISTS',
        translation: (t: TFunction) => t('putMyProfile.USERNAME_ALREADY_EXISTS'),
    },
    USER_FORBIDDEN_TO_EDIT_USERNAME: {
        code: 'USER_FORBIDDEN_TO_EDIT_USERNAME',
        translation: (t: TFunction) => t('putMyProfile.USER_FORBIDDEN_TO_EDIT_USERNAME'),
    },
};
