import * as z from 'zod';

export const OrganizationStructureStoreSchema = z.object({
    id: z.number(),
    name: z.string(),
    street: z.string(),
    town: z.string(),
    zipCode: z.string(),
    access: z.boolean(),
    anlage: z.string(),
});

export const OrganizationStructureRegionSchema = z.object({
    id: z.number(),
    region: z.string(),
    access: z.boolean(),
    stores: z.array(OrganizationStructureStoreSchema),
});

export const OrganizationStructureCustomerSchema = z.object({
    id: z.number(),
    customer: z.string(),
    access: z.boolean(),
    regions: z.array(OrganizationStructureRegionSchema),
});

export const OrganizationStructureSchema = z.object({ data: z.array(OrganizationStructureCustomerSchema), metadata: z.object({ count: z.number(), validUntil: z.string() }) });

export type OrganizationStructureItem = z.infer<typeof OrganizationStructureCustomerSchema>;
export type OrganizationStructureRegion = z.infer<typeof OrganizationStructureRegionSchema>;
export type OrganizationStructureStore = z.infer<typeof OrganizationStructureStoreSchema>;

export type OrganizationStructure = z.infer<typeof OrganizationStructureSchema>;
