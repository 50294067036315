import { DateTime } from 'shared/luxon/luxon';
import { EnergyMetrics } from 'model/energyMetrics/schema';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';

interface chartData {
    [index: string]: string | number | null;
    xDataKey: number | string;
}

const useEnergyChartData = (energyMetricsData?: EnergyMetrics) => {
    const meterNames = energyMetricsData && energyMetricsData.data.meters.map(({ name }) => name);

    const data: Record<string, chartData> = {};

    const getIntervalXDataKey = (bucket: string) => {
        const intervalArray = bucket.split(DateTimeFormat.DATE_INTERVAL_SEPARATOR);
        const from = DateTime.fromJSDate(new Date(intervalArray[0])).toFormat(DateTimeFormat.DATE_FORMAT());
        const to = DateTime.fromJSDate(new Date(intervalArray[1])).toFormat(DateTimeFormat.DATE_FORMAT());
        return `${from}${DateTimeFormat.DATE_INTERVAL_SEPARATOR}${to}`;
    };

    energyMetricsData &&
        energyMetricsData.data.meters.forEach((meter) => {
            meter.buckets.forEach((bucket) => {
                const bucketIsInterval = bucket.bucket.includes(DateTimeFormat.DATE_INTERVAL_SEPARATOR);
                const xDataKey = bucketIsInterval ? getIntervalXDataKey(bucket.bucket) : DateTime.fromJSDate(new Date(bucket.bucket.includes(':') ? bucket.bucket + '+00' : bucket.bucket)).toMillis();
                if (data[xDataKey]) {
                    data[xDataKey][meter.name] = bucket.value;
                } else {
                    data[xDataKey] = {
                        xDataKey,
                        [meter.name]: bucket.value,
                    };
                }
            });
        });
    return {
        meterNames,
        data: Object.values(data),
    };
};

export default useEnergyChartData;
