export enum ReportType {
    energyConsumption = 'energy-consumption',
    energyPower = 'energy-power',
    foodSafetyStatistics = 'food-safety-statistics',
    foodSafetyStatisticsWeekly = 'food-safety-statistics-weekly',
    foodSafetyStatisticsMonthly = 'food-safety-statistics-monthly',
    foodSafetyStatisticsQuarterly = 'food-safety-statistics-quarterly',
    foodSafetyReportDaily = 'food-safety-reporting-daily',
    foodSafetyReport = 'food-safety-report',
}
