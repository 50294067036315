import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { IconButton } from '@material-ui/core';
import { ReactComponent as PageFirstIcon } from 'assets/icons/page-first.svg';
import { ReactComponent as PageLastIcon } from 'assets/icons/page-last.svg';
import { PaginationProps } from 'components/table/components/pagination/paginationProps';
import { useStyles } from 'components/table/stylesTable';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Paragraph from 'components/typography/paragraph/paragraph';
import React from 'react';

const Pagination: React.FC<PaginationProps> = ({ canPreviousPage, canNextPage, gotoPage, pageCount, previousPage, nextPage, pageIndex, pageOptions }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (pageOptions.length === 0) {
        return null;
    }
    return (
        <Box alignItems="center" display="flex" justifyContent="center" py={2}>
            <IconButton disabled={!canPreviousPage} size="small" onClick={() => gotoPage(0)}>
                <PageFirstIcon className={classes.svgIcon} />
            </IconButton>
            <IconButton disabled={!canPreviousPage} size="small" onClick={() => previousPage()}>
                <ChevronLeftIcon className={classes.svgIcon} />
            </IconButton>
            <Box mx={2}>
                <Paragraph>
                    {t('pagination.page')}&nbsp;
                    <strong>{t('pagination.currentOfTotal', { current: pageIndex + 1, total: pageOptions.length })}</strong>
                </Paragraph>
            </Box>
            <IconButton disabled={!canNextPage} onClick={() => nextPage()}>
                <ChevronRightIcon className={classes.svgIcon} />
            </IconButton>
            <IconButton disabled={!canNextPage} size="small" onClick={() => gotoPage(pageCount - 1)}>
                <PageLastIcon className={classes.svgIcon} />
            </IconButton>
        </Box>
    );
};

export default Pagination;
