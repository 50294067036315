import * as z from 'zod';
import { AppState } from 'store';
import { Box } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ContactFormProps } from './contactFormProps';
import { contactUsErrorCodes, ContactUsParams, mutateContactUs } from 'api/mutations/contactUs';
import { customValidationMessages, useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { ReactComponent as EmailSendIcon } from 'assets/icons/email-send.svg';
import { handleSimpleSpecificEndpointErrorsToForm } from 'shared/errors/handleStandardApiErrors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validateOptionalTelephoneRegex } from 'shared/useHandledForm/rules';
import Button from 'components/button/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useLanguages from 'shared/useLanguages/useLanguages';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        fill: theme.customPalette.colors.brand.light,
    },
    text: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        color: theme.customPalette.text.secondary,
        '& a': {
            color: theme.customPalette.colors.brand.light,
            textDecoration: 'none',
            '&:hover, &:focus': {
                color: theme.customPalette.colors.brand.dark,
            },
        },
    },
}));

const formValuesSchema = z.object({
    name: z.string().nonempty(),
    emailAddress: z.string().email(),
    phoneNumber: z.string().regex(validateOptionalTelephoneRegex, customValidationMessages.invalidPhoneNumber),
    subject: z.string().nonempty(),
    message: z.string().nonempty(),
});

const ContactForm: React.FC<ContactFormProps> = ({ handleClose }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { handleSubmit, setError, control, getValues, reset } = useHandledForm(formValuesSchema);
    const myProfile = useSelector((state: AppState) => state.auth.myProfile);
    const { mutate, isSuccess, isError, error, isLoading } = useHandledMutation(mutateContactUs);
    const { getLanguageIdByCode } = useLanguages();
    const onSubmit = async (values: ContactUsParams) => {
        await mutate({ ...values, languageId: getLanguageIdByCode(i18n.language) });
    };
    React.useEffect(() => {
        if (myProfile) {
            reset({
                name: `${myProfile.firstName} ${myProfile.lastName}`,
                emailAddress: myProfile.emailAddress,
            });
        }
    }, [myProfile, reset]);
    React.useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t('contactForm.successSubmit'));
            handleClose();
        }
        if (isError) {
            handleSimpleSpecificEndpointErrorsToForm(error, enqueueSnackbar, t, contactUsErrorCodes, Object.keys(getValues()), setError);
        }
    }, [isSuccess, isError, enqueueSnackbar, t, error, getValues, setError, handleClose]);

    const classes = useStyles();
    const emailAddress = 'webadmin.eservice@carrier.com';
    return (
        <>
            <Dialog open={true} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{t('contactForm.title')}</DialogTitle>
                    <DialogContent>
                        <Box mt={1} />
                        <div className={classes.text}>
                            {t('contactForm.messageWillBeSentTo')} <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                        </div>
                        <Box mt={3} />
                        <TextFieldRHF autoFocus control={control} label={t('contactForm.name.label')} name="name" size="medium" />
                        <Box mt={1} />
                        <TextFieldRHF control={control} label={t('contactForm.emailAddress.label')} name="emailAddress" size="medium" />
                        <Box mt={1} />
                        <TextFieldRHF control={control} label={`${t('contactForm.phoneNumber.label')} (${t('contactForm.optional')})`} name="phoneNumber" size="medium" />
                        <Box mt={1} />
                        <TextFieldRHF control={control} label={t('contactForm.subject.label')} name="subject" size="medium" />
                        <Box mt={1} />
                        <TextFieldRHF control={control} label={t('contactForm.message.label')} multiline name="message" rows={isSmUp ? 7 : 3} size="medium" />
                        <Box mt={3} />
                    </DialogContent>
                    <DialogActions>
                        <Button icon={<CloseIcon className={classes.closeIcon} />} onClick={handleClose}>
                            {t('contactForm.cancel')}
                        </Button>
                        <Button icon={<EmailSendIcon />} isLoading={isLoading} isPrimary={true} type="submit">
                            {t('contactForm.send')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default ContactForm;
