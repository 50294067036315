import { ReportingStoreLiveStructureStoreAsset } from '../../model/reportingStoreLive/schema';
import React from 'react';
import settings from '../../settings';
import { Group, Path, Rect, Text, Image } from 'react-konva';
import { TEXT_INSIDE_RECT_PADDING, UNASSIGNED_ITEM_HEIGHT, UNASSIGNED_ITEM_WIDTH, CORNER_RADIUS, TOOLTIP_ARROW_WIDTH, TOOLTIP_ARROW_HEIGHT } from './consts';
import useImage from 'use-image';
import { useTranslation } from 'react-i18next';

const ItemToRender = ({
    width: originWidth,
    height: originHeight,
    x,
    y,
    asset,
    hasAlarm,
    alarmContent,
    alarmEndOn,
    right,
    bottom,
    longName,
    shortName,
    temperatureMerged,
    highlightedAsset,
    assetId,
    onItemClick,
    customColor,
    altHeaderText,
    alarmPriority,
    isSmall,
    zone,
}: {
    width: number;
    height: number;
    x: number;
    y: number;
    asset?: ReportingStoreLiveStructureStoreAsset | null;
    hasAlarm?: boolean;
    alarmPriority?: number;
    right: boolean;
    alarmContent?: string;
    bottom: boolean;
    longName?: string;
    shortName?: string;
    temperatureMerged?: string;
    highlightedAsset?: number | null;
    assetId: number | null;
    onItemClick?: (assetId: number | null) => void;
    customColor?: string;
    altHeaderText?: string;
    alarmEndOn?: string | null;
    isSmall?: boolean;
    zone?: string;
}) => {
    const { t } = useTranslation();
    const textRef = React.useRef<any>();
    const [iconLayoutWater] = useImage('/images/layout-water.svg');
    const [iconLayoutDoorOpen] = useImage('/images/layout-door-open.svg');

    const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
    const [tooltipDimensions, setTooltipDimensions] = React.useState<{ width: number; height: number }>({ width: 0, height: 0 });

    const hasOpenDoor = asset?.dataseries.Door?.value === 1;

    React.useEffect(() => {
        if (textRef && textRef.current) {
            setTooltipDimensions({ width: textRef.current.textWidth, height: textRef.current.textHeight });
        }
    }, [textRef]);

    const isInactive = !asset;

    let defrostOn = undefined;
    let temperature = undefined;
    let setPointAltOn = undefined;
    let isDefrosting = false;
    let displayWarning = false;
    let displayError = false;
    let setPointToCompare;

    if (!isInactive && asset) {
        defrostOn = asset.dataseries?.Defrost?.value || null;
        temperature = asset.dataseries?.Temp?.value || null;
        setPointAltOn = asset.dataseries?.AltSetpoints?.value || null;

        isDefrosting = (temperature === null && defrostOn && defrostOn > 0) || (defrostOn && defrostOn > settings.defrostOnThreshold) || false;

        const setPoint = asset.setpoint;
        const setPointAlt = asset.altSetpoint;

        if (setPoint !== null && setPointAlt !== null) {
            setPointToCompare = setPointAltOn && setPointAltOn > settings.setPointAltThreshold ? setPointAlt : setPoint;
            // @ts-ignore
            const differenceBetweenTemperatureAndSetPoint = Math.abs(temperature - setPointToCompare);
            if (differenceBetweenTemperatureAndSetPoint > settings.temperatureDiffThresholdWarning && differenceBetweenTemperatureAndSetPoint <= settings.temperatureDiffThresholdError) {
                displayWarning = true;
            } else if (differenceBetweenTemperatureAndSetPoint > settings.temperatureDiffThresholdError) {
                displayError = true;
            }
        }
    }

    let headerFill = '#00F000';
    let bodyFill = customColor || '#267DFF';
    let headerTextColor = 'rgba(0, 0, 0, 0.88)';

    if (isInactive) {
        headerFill = 'grey';
        bodyFill = 'grey';
        headerTextColor = 'white';
    }

    // >1k
    if (displayWarning) {
        headerFill = '#00C58A';
        bodyFill = '#26B1FF';
        headerTextColor = '#FFF';
    }

    // >4k
    if (displayError) {
        bodyFill = '#26B1FF';
        headerFill = '#008080';
        headerTextColor = '#FFF';
    }

    if (alarmPriority && alarmPriority === 1 && !alarmEndOn) {
        headerFill = '#FF993A';
        headerTextColor = 'rgba(0, 0, 0, 0.88)';
    }

    const tooltipContentArray = [
        longName,
        shortName,
        temperatureMerged,
        setPointToCompare && `${t('setPoint')}: ${setPointToCompare} °C`,
        hasOpenDoor && t('hasOpenDoor'),
        isDefrosting && t('isDefrosting'),
        zone,
        hasAlarm && t('hasAlarm'),
        hasAlarm && alarmContent && ` - ${alarmContent}`,
    ].filter((item) => item);

    const tooltipText = tooltipContentArray.join('\n');

    const tooltipHeight = tooltipDimensions.height * tooltipContentArray.length + 2 * TEXT_INSIDE_RECT_PADDING * 2.5;

    const isTouchedOnRightColumn = assetId && assetId === highlightedAsset;

    const scale = isTouchedOnRightColumn ? 2 : 1;
    const headerText = asset && asset?.dataseries?.Temp?.value && asset?.dataseries?.Temp?.unit ? `${asset.dataseries.Temp.value} ${asset.dataseries.Temp.unit}` : altHeaderText;

    let width = originWidth;
    let height = originHeight;

    if (originWidth < 21 || originHeight < 21) {
        width = UNASSIGNED_ITEM_WIDTH;
        height = UNASSIGNED_ITEM_HEIGHT;
    }

    return (
        <Group
            height={height}
            scaleX={scale}
            scaleY={scale}
            width={width}
            x={isTouchedOnRightColumn ? x - width / 2 : x}
            y={isTouchedOnRightColumn ? y - height / 2 : y}
            zIndex={tooltipOpen || isTouchedOnRightColumn ? 999 : isDefrosting ? 900 : 1}
        >
            {/* header */}
            <Rect
                cornerRadius={isSmall ? [CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS, CORNER_RADIUS] : [CORNER_RADIUS, CORNER_RADIUS, 0, 0]}
                fill={headerFill}
                height={isSmall ? UNASSIGNED_ITEM_HEIGHT : UNASSIGNED_ITEM_HEIGHT / 2}
                width={width}
                x={0}
                y={0}
            />

            {/* body */}
            <Rect
                cornerRadius={[0, 0, CORNER_RADIUS, CORNER_RADIUS]}
                fill={bodyFill}
                height={isSmall ? (height - UNASSIGNED_ITEM_HEIGHT < 1 ? 0 : height - UNASSIGNED_ITEM_HEIGHT) : height - UNASSIGNED_ITEM_HEIGHT / 2}
                width={width}
                x={0}
                y={isSmall ? UNASSIGNED_ITEM_HEIGHT : UNASSIGNED_ITEM_HEIGHT / 2}
            />

            {/* header text */}
            <Text
                // ellipsis={true}
                fill={headerTextColor}
                fontFamily="Fira Sans"
                fontSize={isSmall ? 20 : 12}
                fontStyle="600"
                height={20}
                text={headerText}
                width={width - TEXT_INSIDE_RECT_PADDING * 3}
                x={isSmall ? TEXT_INSIDE_RECT_PADDING * 2 : TEXT_INSIDE_RECT_PADDING}
                y={(isSmall ? TEXT_INSIDE_RECT_PADDING * 2.75 : TEXT_INSIDE_RECT_PADDING) + 1}
            />

            {/*prio1*/}
            {alarmPriority && alarmPriority === 1 && !alarmEndOn && (
                <>
                    <Rect cornerRadius={CORNER_RADIUS} height={height + 2} stroke="#E90038" strokeWidth={!isSmall ? 2 : 4} width={width + 2} x={-1} y={-1} />
                    <Rect cornerRadius={CORNER_RADIUS} height={height} stroke="#FFFFFF" strokeWidth={!isSmall ? 1 : 2} width={width} x={0} y={0} />
                </>
            )}

            {/*prio2*/}
            {alarmPriority && alarmPriority === 2 && !alarmEndOn && (
                <>
                    <Rect cornerRadius={CORNER_RADIUS} height={height + 2} stroke="#FF5C00" strokeWidth={!isSmall ? 2 : 4} width={width + 2} x={-1} y={-1} />
                    <Rect cornerRadius={CORNER_RADIUS} height={height} stroke="#FFFFFF" strokeWidth={!isSmall ? 1 : 2} width={width} x={0} y={0} />
                </>
            )}

            {/*water icon*/}
            {!isSmall && isDefrosting && <Image image={iconLayoutWater} x={width - 12 - TEXT_INSIDE_RECT_PADDING} y={-10} zIndex={5000} />}

            {/*door open */}
            {!isSmall && hasOpenDoor && <Image image={iconLayoutDoorOpen} x={width - 12 - TEXT_INSIDE_RECT_PADDING} y={height - 14} />}

            {!isSmall && asset && (
                <Text fill="white" fontFamily="Fira Sans" fontSize={12} fontStyle="600" text={asset.shortName} x={TEXT_INSIDE_RECT_PADDING} y={UNASSIGNED_ITEM_HEIGHT / 2 + TEXT_INSIDE_RECT_PADDING} />
            )}

            {/* tooltip bg */}
            {longName && (
                <Rect
                    cornerRadius={CORNER_RADIUS}
                    fill="#1f1f1f"
                    height={tooltipHeight}
                    visible={tooltipOpen}
                    width={tooltipDimensions.width + 2 * TEXT_INSIDE_RECT_PADDING * 2.5}
                    x={!right ? 0 : -(2 * TEXT_INSIDE_RECT_PADDING * 2.5) - tooltipDimensions.width + width}
                    y={!bottom ? height + TOOLTIP_ARROW_HEIGHT * 2 - 1 : -tooltipHeight - TOOLTIP_ARROW_HEIGHT * 2 + 1}
                />
            )}

            {/*tooltip content*/}
            {longName && (
                <Text
                    fill="#FFFFFF"
                    fontFamily="Fira Sans"
                    fontSize={12 * 2.5}
                    fontStyle="600"
                    ref={textRef}
                    text={tooltipText}
                    visible={tooltipOpen}
                    x={!right ? TEXT_INSIDE_RECT_PADDING * 2.5 : -(2 * TEXT_INSIDE_RECT_PADDING * 2.5) - tooltipDimensions.width + width + TEXT_INSIDE_RECT_PADDING * 2.5}
                    y={!bottom ? TEXT_INSIDE_RECT_PADDING * 2.5 + height + TOOLTIP_ARROW_HEIGHT * 2 : -(tooltipHeight + TOOLTIP_ARROW_HEIGHT * 2 - TEXT_INSIDE_RECT_PADDING * 3)}
                />
            )}
            {longName && (
                <Path
                    data="M6.90731 2.19621C8.15706 3.1267 9.32999 4 12 4C14.67 4 15.8429 3.1267 17.0927 2.19621C18.5176 1.13528 20.0424 0 24 0H12H0C3.95756 0 5.48237 1.13528 6.90731 2.19621Z"
                    fill="#1f1f1f"
                    height={TOOLTIP_ARROW_HEIGHT}
                    scaleY={!bottom ? -1 : 1}
                    visible={tooltipOpen}
                    width={TOOLTIP_ARROW_WIDTH}
                    x={!right ? width / 2 - TOOLTIP_ARROW_WIDTH / 2 : width - tooltipDimensions.width / 2 - TOOLTIP_ARROW_WIDTH}
                    y={!bottom ? height + TOOLTIP_ARROW_HEIGHT * 2 : -TOOLTIP_ARROW_HEIGHT * 2}
                />
            )}

            <Rect
                cornerRadius={[CORNER_RADIUS, CORNER_RADIUS, 0, 0]}
                height={height}
                style={{ cursor: 'pointer' }}
                width={width}
                x={0}
                y={0}
                onClick={() => onItemClick && onItemClick(assetId)}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
            />
        </Group>
    );
};

export default ItemToRender;
