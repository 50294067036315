import { SegmentedControlProps, SegmentProps } from './segmentedControlProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Theme, withStyles } from '@material-ui/core';
import Button from 'components/button/button';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
    },
    segmentedButton: {
        border: '0px',
    },
    segmentedButtonLabel: {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.48)',
        borderRadius: '4px',
        background: '#fff',
    },
    toggleButtonGroupContainer: {
        padding: 0,
        margin: 0,
    },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        height: '24px',
        padding: 0,
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const SegmentedControlGroup: React.FC<SegmentedControlProps> = ({ segments, size = 'small', onChange, ariaLabel, value }) => {
    const handleOnChange = (newValue: string) => {
        if (newValue !== null) {
            onChange(newValue);
        }
    };

    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <StyledToggleButtonGroup aria-label={ariaLabel} className={classes.toggleButtonGroupContainer} exclusive size={size} value={value}>
                {segments.map((segment: SegmentProps) => {
                    return (
                        <Button
                            key={`${segment.value} ${value}`}
                            aria-label={segment.label}
                            className={classes.segmentedButton}
                            isPrimary={segment.value === value}
                            onClick={() => handleOnChange(segment.value)}
                        >
                            <span className={classes.segmentedButtonLabel}>{segment.label}</span>
                        </Button>
                    );
                })}
            </StyledToggleButtonGroup>
        </Box>
    );
};

export default SegmentedControlGroup;
