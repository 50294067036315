import { TFunction } from 'react-i18next';

const getFixedSize = (unit: 'minute' | 'percent', value: number) => {
    if (unit === 'percent') {
        return 0;
    }

    if (value >= 100) {
        return 0;
    }

    return 1;
};

export const formatNumber = (input: number, unit: 'minute' | 'percent', t: TFunction, withoutSign = false) => {
    const fixedSize = getFixedSize(unit, input);
    const value = parseFloat(input.toFixed(fixedSize));

    const getSign = (input: number) => {
        if (input === 0) {
            return '';
        }

        return input < 0 ? '-' : '+';
    };

    const sign = withoutSign ? '' : getSign(input);

    return unit === 'minute' ? `${value}m` : `${sign}${withoutSign ? value : Math.abs(value)}%`;
};
