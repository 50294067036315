export enum TableViewAccessors {
    assetName = 'assetName', // Cooling position
    zone = 'zone', // Case
    position = 'position',
    can = 'can',
    priority = 'priority',
    event = 'event',
    startOn = 'startOn',
    endOn = 'endOn',
}
