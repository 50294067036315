import { DateTime } from 'shared/luxon/luxon';
import { OneLineAxisTickProps } from 'components/chart/components/oneLineAxisTick/oneLineAxisTickProps';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import React from 'react';

const OneLineAxisTick: React.FC<OneLineAxisTickProps> = ({ x, y, payload, withoutSeconds }) => {
    if (typeof payload.value !== 'number') {
        return null;
    }
    const isSunday = DateTime.fromJSDate(new Date(payload.value)).weekday === 7;
    return (
        <g transform={`translate(${x},${y + (withoutSeconds ? 50 : 40)}) rotate(270, -10, 10)`}>
            <text dy={24} fill={isSunday ? '#999' : '#000'} fontSize="12" x={0} y={0}>
                <tspan textAnchor="middle" x="0">
                    {DateTime.fromMillis(payload.value).toFormat(withoutSeconds ? DateTimeFormat.FULL_24_WITHOUT_SECONDS() : DateTimeFormat.DATE_FORMAT())}
                </tspan>
            </text>
        </g>
    );
};

export default OneLineAxisTick;
