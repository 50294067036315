import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, SortParameter } from 'api/types';
import { FoodSafetyReportItem, FoodSafetyReportSchema } from 'model/foodSafetyReport/schema';
import { makeQuery } from 'api/api';

export const getFoodSafetyReport = async (storeId: number, date: string, sort?: SortParameter, filter?: FilterParameter) => {
    const response = await makeQuery(ApiEndpoints.getFoodSafetyReport(storeId, date), true, sort, filter);
    const parsedResponse = FoodSafetyReportSchema.parse(response);

    const getHours = (item: FoodSafetyReportItem) => {
        const hours: Record<string, Object | undefined> = {};
        for (let i = 0; i <= 23; i++) {
            hours[`h${i}`] = item.buckets.find(({ bucket }) => parseInt(bucket.substring(11, 13)) === i);
        }
        return hours;
    };
    const data = parsedResponse.data.map((item) => {
        return {
            ...item,
            hours: getHours(item),
        };
    });

    return {
        data,
        metadata: parsedResponse.metadata,
    };
};
