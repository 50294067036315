import * as z from 'zod';
import { Box } from '@material-ui/core';
import { clearStoreId } from 'localStorage/store';
import { clearToken } from 'localStorage/auth';
import { clearUserLoggedIn } from 'cookies/auth';
import { Credentials, mutateSignIn, signInErrorCodes } from 'api/mutations/signIn';
import { handleSimpleSpecificEndpointErrorsToForm } from 'shared/errors/handleStandardApiErrors';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'routes/routes';
import { signIn } from 'store/auth/actions';
import { storeId } from 'store/store/actions';
import { useDispatch } from 'react-redux';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: '100%',
        width: theme.typography.pxToRem(120),
        height: 'auto',
    },
    contactFormLink: {
        height: '100%',
        color: theme.customPalette.colors.brand.light,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'none',
        textDecoration: 'none',
        padding: theme.spacing(1),
        margin: theme.spacing(-1),
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.customPalette.colors.brand.dark,
        },
    },
}));

const formValuesSchema = z.object({
    username: z.string().nonempty(),
    password: z.string().nonempty(),
});

const SignInForm: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, setError, control, getValues } = useHandledForm(formValuesSchema);
    const history = useHistory();
    const dispatch = useDispatch();

    const { mutate, data, status, error } = useHandledMutation(mutateSignIn, undefined, signInErrorCodes);

    const handleResetPassword = () => {
        history.push(routes.forgottenPassword.path());
    };

    React.useEffect(() => {
        queryClient.clear();
    }, [queryClient]);

    React.useEffect(() => {
        clearToken();
        clearUserLoggedIn();
        clearStoreId();
    }, []);

    React.useEffect(() => {
        if (status === 'success' && data) {
            dispatch(storeId(null));
            signIn(data.data.token);
            enqueueSnackbar(t('signInForm.successSubmit'));
        } else if (status === 'error') {
            handleSimpleSpecificEndpointErrorsToForm(error, enqueueSnackbar, t, signInErrorCodes, Object.keys(getValues()), setError);
        }
    }, [status, data, enqueueSnackbar, t, error, setError, dispatch, getValues]);

    const onSubmit = async ({ username, password }: Credentials) => {
        await mutate({
            username,
            password,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldRHF control={control} id="test-username" label={t('signIn.username.label')} name="username" />
            <Box mb={2} />
            <TextFieldRHF control={control} id="test-password" label={t('signIn.password.label')} name="password" type="password" />
            <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <div className={classes.contactFormLink} id="test-forgot-password" tabIndex={0} onClick={handleResetPassword}>
                    {t('forgotPassword.question')}
                </div>
                <Button id="test-submit" isLoading={status === 'loading'} isPrimary size="large" type="submit">
                    {t('signIn.submit')}
                </Button>
            </Box>
        </form>
    );
};

export default SignInForm;
