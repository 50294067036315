import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.customPalette.background.white,
        boxShadow: theme.customElevation.default,
        margin: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(1)}`,
        order: 3,
        width: '100%',
    },
}));

const Content: React.FC = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.root}>{children}</div>;
};

export default Content;
