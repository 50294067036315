import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const UserActiveStatusSchema = z.object({
    success: z.literal(true),
});

export interface PostUserActiveStatusParams {
    userId: number;
    isDisabled: boolean;
}

export const mutatePostUserActiveStatus = async (values: PostUserActiveStatusParams) => {
    const { userId, isDisabled } = values;

    const response = await makeMutation(isDisabled ? ApiEndpoints.postEnableUser(userId) : ApiEndpoints.postDisableUser(userId), undefined, true, 'POST');
    return UserActiveStatusSchema.parse(response);
};

export const changeUserActiveStatusErrorCodes = {
    USER_NOT_FOUND: {
        code: 'USER_NOT_FOUND',
        translation: (t: TFunction) => t('changeUserActiveStatus.USER_NOT_FOUND'),
    },
};
