import { AppState } from 'store';
import { Column } from 'react-table';
import { QueryKeys } from 'api/queryKeys';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import Header from 'components/header/header';
import LoadingBar from 'components/loadingBar/loadingBar';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import { fetchReportFiles } from '../api/fetchers/reportFiles';
import { ReportsTableAccessors } from '../types/reports/reportsTableAccessors';
import { ReportFile } from '../model/reportFiles/schema';
import ReportsTable from '../components/table/instances/reportsTable/reportsTable';
import ExportSingleButton from '../components/exportButtonSingle/exportButtonSingle';
import { fetchReportFileDownload } from '../api/fetchers/reportFileDownload';
import * as FileSaver from 'file-saver';
import { useQueryClient } from 'react-query';
import { ExportButtonSingleType } from '../components/exportButtonSingle/exportButtonSingleProps';
import { DateTime } from '../shared/luxon/luxon';
import DateTimeFormat from '../shared/luxon/DateTimeFormat';

const DownloadComponent = ({ hash, fileFormat, storeId }: { hash: string; storeId: number; fileFormat: string }) => {
    const { data, remove, isLoading, refetch } = useHandledQuery([QueryKeys.storeReportFileDownload(), hash], () => fetchReportFileDownload(storeId, hash), { enabled: false, staleTime: Infinity });
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    React.useEffect(() => {
        return () => {
            remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (data) {
            const file = new Blob([data.blob]);
            FileSaver.saveAs(file, data.fileName);
            queryClient.removeQueries([QueryKeys.storeReportFileDownload(), hash], { exact: true });
        }
    }, [data, queryClient]);

    let type = undefined;
    if (fileFormat.match(/xls/i)) {
        type = ExportButtonSingleType.excel;
    }
    if (fileFormat.match(/pdf/i)) {
        type = ExportButtonSingleType.pdf;
    }
    if (fileFormat.match(/csv/i)) {
        type = ExportButtonSingleType.csv;
    }

    return (
        <>
            <ExportSingleButton
                button={{
                    text: t('reports.table.download'),
                    type,
                    isLoading,
                    action: () => {
                        refetch();
                    },
                }}
                disabled={false}
                target="_blank"
            />
        </>
    );
};

const ReportsScreen: React.FC = () => {
    const { t } = useTranslation();

    const { storeId } = useSelector((state: AppState) => state.store);

    const { data, isFetching } = useHandledQuery([QueryKeys.storeReportFiles(), storeId as number], () => (storeId ? fetchReportFiles(storeId) : undefined), {
        keepPreviousData: true,
        enabled: Boolean(storeId),
    });

    const columns = React.useMemo<Column<ReportFile>[]>(
        () => [
            {
                Header: t('reports.table.heading.name').toString(),
                accessor: ReportsTableAccessors.name,
                disableFilters: false,
                disableSortBy: false,
            },
            {
                Header: t('reports.table.heading.category').toString(),
                accessor: ReportsTableAccessors.category,
                disableFilters: false,
                disableSortBy: false,
            },
            {
                Header: t('reports.table.heading.date').toString(),
                accessor: ReportsTableAccessors.date,
                disableFilters: true,
                disableSortBy: false,
                Cell: ({ value }) => {
                    return DateTime.fromJSDate(new Date(value)).toFormat(DateTimeFormat.DATE_FORMAT());
                },
            },
            {
                Header: '',
                accessor: ReportsTableAccessors.hash,
                Cell: ({ value, row }) => {
                    const { file } = row.original;
                    const fileNameAsArray = file.split('.');
                    const fileFormat = fileNameAsArray[fileNameAsArray.length - 1];
                    return <>{storeId && <DownloadComponent fileFormat={fileFormat} hash={value} storeId={storeId} />}</>;
                },
                disableSortBy: false,
            },
        ],
        [t],
    );

    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <Content>
                    <LoadingBar isFetching={isFetching} />
                    <ReportsTable<ReportFile> columns={columns} data={data ? data.data : []} isFetching={isFetching} />
                </Content>
            </Main>
        </>
    );
};

export default ReportsScreen;
