import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, SortParameter } from 'api/types';
import { FoodSafetyStatisticsSchema } from 'model/foodSafetyStatistics/schema';
import { makeQuery } from 'api/api';

export const getFoodSafetyStatistics = async (storeId: number, year: number, week: number, sort?: SortParameter, filter?: FilterParameter) => {
    const response = await makeQuery(ApiEndpoints.getFoodSafetyStatistics(storeId, year, week), true, sort, filter);
    const parsedResponse = FoodSafetyStatisticsSchema.parse(response);
    return {
        data: parsedResponse.data.map((item) => {
            return {
                ...item,
                monday: item.days.monday || undefined,
                tuesday: item.days.tuesday || undefined,
                wednesday: item.days.wednesday || undefined,
                thursday: item.days.thursday || undefined,
                friday: item.days.friday || undefined,
                saturday: item.days.saturday || undefined,
                sunday: item.days.sunday || undefined,
            };
        }),
        metadata: parsedResponse.metadata,
    };
};
