const settings = {
    apiVersion: '/v1/',
    setPointAltThreshold: 0.5,
    temperatureDiffThresholdWarning: 2,
    temperatureDiffThresholdError: 4,
    defrostOnThreshold: 0.5,
    defrostOnThresholdAssetChart: 0.25,
    oldAppLink: 'https://www.carrier-e-service.com/',
    pageSize: 20,
};

export default settings;
