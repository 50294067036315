import { Box, LinearProgress } from '@material-ui/core';
import { LoadingBarProps } from 'components/loadingBar/loadingBarProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            height: theme.typography.pxToRem(2),
            background: 'transparent',
        },
        barColorPrimary: {
            background: `linear-gradient(90deg, transparent 0%, ${theme.customPalette.colors.brand.light} 50%, transparent 100%)`,
        },
    };
});
const LoadingBar: React.FC<LoadingBarProps> = ({ isFetching }) => {
    const classes = useStyles();

    return (
        <>
            {!isFetching && <Box className={classes.root} />}
            {isFetching && (
                <LinearProgress
                    classes={{
                        root: classes.root,
                        barColorPrimary: classes.barColorPrimary,
                    }}
                />
            )}
        </>
    );
};

export default LoadingBar;
