import * as z from 'zod';

export const AlarmStoreSchema = z.object({
    id: z.number(),
    customer: z.string(),
    region: z.string(),
    name: z.string(),
    street: z.string(),
    town: z.string(),
    zipCode: z.string(),
    anlage: z.string(),
    logo: z.string().nullable(),
    summary: z.object({
        activeAlarms: z.number(),
        deviation: z.number(),
        overallAverage: z.number(),
        storeAverage: z.number(),
    }),
});

export type AlarmStore = z.infer<typeof AlarmStoreSchema>;
