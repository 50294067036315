import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const PostUserSchema = z.object({
    success: z.literal(true),
    data: z.object({ id: z.number() }),
});

export interface PostUserParams {
    username: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    role: string;
}

export const mutatePostUser = async (values: PostUserParams) => {
    const payload = {
        ...values,
        organizationId: null,
        isMarketingConsent: true,
        timeZone: 'UTC',
    };
    const response = await makeMutation(ApiEndpoints.postUser(), payload, true, 'POST');
    return PostUserSchema.parse(response);
};

export const postUserErrorCodes = {
    USERNAME_ALREADY_EXISTS: {
        code: 'USERNAME_ALREADY_EXISTS',
        translation: (t: TFunction) => t('postUser.USERNAME_ALREADY_EXISTS'),
    },
    EMAIL_ALREADY_REGISTERED: {
        code: 'EMAIL_ALREADY_REGISTERED',
        translation: (t: TFunction) => t('postUser.EMAIL_ALREADY_REGISTERED'),
    },
};
