import * as FileSaver from 'file-saver';
import { AppState } from 'store';
import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import { Column, Filters, SortingRule } from 'react-table';
import { DateTime } from 'shared/luxon/luxon';
import { ExportButtonType } from 'components/exportButtons/exportButtonProps';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOff } from 'assets/icons/eye-off.svg';
import { foodSafetyReportingDate } from 'store/filters/actions';
import { formatDateForAPI, transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { getFoodSafetyReport } from 'api/fetchers/foodSafetyReport';
import { getFoodSafetyReportMultipleCsv } from 'api/fetchers/foodSafetyReportMultipleCsv';
import { getFoodSafetyReportMultiplePdfs } from 'api/fetchers/foodSafetyReportMultiplePdfs';
import { getFoodSafetyReportPdf } from 'api/fetchers/foodSafetyReportPdf';
import { getFoodSafetyReportXls } from 'api/fetchers/foodSafetyReportXls';
import { Link } from 'react-router-dom';
import { mutatePatchAssetsVisibility, patchAssetPositionErrorCodes } from 'api/mutations/patchAssetsVisibility';
import { pull } from 'lodash';
import { QueryKeys } from 'api/queryKeys';
import { ReportingTableAccessors } from 'types/reporting/reportingTableAccessors';
import { ReportingTableHoursAccessors } from 'types/reporting/reportingTableHoursAccessors';
import { routes } from 'routes/routes';
import { StatusType } from 'components/status/statusType';
import { TableReportingItem } from 'types/reporting/tableReportingItem';
import { useDispatch, useSelector } from 'react-redux';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Button from 'components/button/button';
import Content from 'components/content/content';
import ContentFilter from 'components/contentFilter/contentFilter';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import DatePickerWithArrows from 'components/datePickerWithArrows/datePickerWithArrows';
import ExportButtons from 'components/exportButtons/exportButtons';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import IntervalPickerDialog from 'components/intervalPickerDialog/intervalPickerDialog';
import LoadingBar from 'components/loadingBar/loadingBar';
import React from 'react';
import ReportingMobileList from 'components/reportingMobileList/reportingMobileList';
import ReportingTable from 'components/table/instances/reportingTable/reportingTable';
import SelectColumnFilter from 'components/table/components/selectColumnFilter/selectColumnFilter';
import Status from 'components/status/status';
import Temperature from 'components/table/components/temperature/temperature';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    eye: {
        fill: theme.customPalette.colors.brand.light,
        width: theme.spacing(2),
        height: theme.spacing(2),
        cursor: 'pointer',
        display: 'block',
    },
}));

const Reporting: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [intervalPickerPdfOpen, setIntervalPickerPdfOpen] = React.useState<boolean>(false);
    const [intervalPickerCsvOpen, setIntervalPickerCsvOpen] = React.useState<boolean>(false);
    const [intervalDateToDownloadMultiplePdfs, setIntervalDateToDownloadMultiplePdfs] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const [intervalDateToDownloadMultipleCsv, setIntervalDateToDownloadMultipleCsv] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const [availableData, setAvailableData] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const { storeId } = useSelector((state: AppState) => state.store);
    const { date } = useSelector((state: AppState) => state.filters.foodSafetyReporting);
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const [assetsToHide, setAssetsToHide] = React.useState<number[]>([]);
    const [assetsToShow, setAssetsToShow] = React.useState<number[]>([]);
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const { mutate, isSuccess: mutateIsSuccess, isLoading: mutateIsLoading } = useHandledMutation(mutatePatchAssetsVisibility, undefined, patchAssetPositionErrorCodes);
    const classes = useStyles();

    const addOrRemoveAssetToHide = (idAsset: number) => {
        const newAssetsToHide = [...assetsToHide];
        if (newAssetsToHide.includes(idAsset)) {
            pull(newAssetsToHide, idAsset);
        } else {
            newAssetsToHide.push(idAsset);
        }
        setAssetsToHide(newAssetsToHide);
    };

    const addOrRemoveAssetToShow = (idAsset: number) => {
        const newAssetsToShow = [...assetsToShow];
        if (newAssetsToShow.includes(idAsset)) {
            pull(newAssetsToShow, idAsset);
        } else {
            newAssetsToShow.push(idAsset);
        }
        setAssetsToShow(newAssetsToShow);
    };

    const handleDiscardChanges = async () => {
        setAssetsToShow([]);
        setAssetsToHide([]);
    };

    const handleOnSubmit = async () => {
        await mutate({ visible: assetsToShow, hidden: assetsToHide });
    };

    const visibilityOptions = [
        { label: t('foodSafety.reporting.table.visible.yes'), value: '0' },
        { label: t('foodSafety.reporting.table.visible.no'), value: '1' },
    ];

    const queryClient = useQueryClient();
    const columns = React.useMemo<Column<TableReportingItem>[]>(() => {
        const temperatureProperties = {
            Cell: Temperature,
            customStyles: {
                width: 'calc(50% / 24)',
                textAlign: 'right',
            },
        };
        return [
            {
                Header: '',
                id: ReportingTableAccessors.drag,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '2%',
                },
            },
            {
                Header: t('foodSafety.reporting.table.heading.visible').toString(),
                accessor: ReportingTableAccessors.hidden,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '5%',
                },
                Filter: ({ column }) => <SelectColumnFilter column={column} options={visibilityOptions} />,
                Cell: ({ value, row }) => (
                    <div
                        className={classes.eye}
                        title={value ? t('foodSafety.reporting.table.setVisible') : t('foodSafety.reporting.table.setHidden')}
                        onClick={() => {
                            if (value) {
                                addOrRemoveAssetToShow(row.original.id);
                            } else {
                                addOrRemoveAssetToHide(row.original.id);
                            }
                        }}
                    >
                        {!value && <>{assetsToHide.includes(row.original.id) ? <EyeOff /> : <Eye />}</>}
                        {value && <>{assetsToShow.includes(row.original.id) ? <Eye /> : <EyeOff />}</>}
                    </div>
                ),
            },
            {
                Header: t('foodSafety.reporting.table.heading.coolingPosition').toString(),
                accessor: ReportingTableAccessors.longName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '20%',
                },
                Cell: ({ value, row }) => (
                    <Link to={{ state: { dateFrom: date.toISO(), dateTo: date.toISO(), goBackPath: routes.foodSafetyReporting.path() }, pathname: routes.asset.path(row.original.id) }}>{value}</Link>
                ),
            },
            {
                Header: t('foodSafety.reporting.table.heading.case').toString(),
                accessor: ReportingTableAccessors.zone,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '5%',
                    minWidth: theme.typography.pxToRem(80),
                },
            },
            {
                Header: t('foodSafety.reporting.table.heading.position').toString(),
                accessor: ReportingTableAccessors.shortName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '5%',
                    minWidth: theme.typography.pxToRem(80),
                },
            },
            {
                Header: t('foodSafety.reporting.table.heading.setPoint').toString(),
                accessor: ReportingTableAccessors.setPoint,
                disableSortBy: false,
                customStyles: {
                    width: '8%',
                },
            },
            {
                Header: t('foodSafety.reporting.table.heading.alt').toString(),
                accessor: ReportingTableAccessors.setPointAlt,
                disableSortBy: false,
                customStyles: {
                    width: '8%',
                },
            },
            {
                Header: t('foodSafety.reporting.table.heading.avgTemp').toString(),
                customStyles: {
                    width: '9%',
                },
                columns: [
                    {
                        Header: '00',
                        accessor: ReportingTableHoursAccessors.h00,
                        ...temperatureProperties,
                    },
                    {
                        Header: '01',
                        accessor: ReportingTableHoursAccessors.h01,
                        ...temperatureProperties,
                    },
                    {
                        Header: '02',
                        accessor: ReportingTableHoursAccessors.h02,
                        ...temperatureProperties,
                    },
                    {
                        Header: '03',
                        accessor: ReportingTableHoursAccessors.h03,
                        ...temperatureProperties,
                    },
                    {
                        Header: '04',
                        accessor: ReportingTableHoursAccessors.h04,
                        ...temperatureProperties,
                    },
                    {
                        Header: '05',
                        accessor: ReportingTableHoursAccessors.h05,
                        ...temperatureProperties,
                    },
                    {
                        Header: '06',
                        accessor: ReportingTableHoursAccessors.h06,
                        ...temperatureProperties,
                    },
                    {
                        Header: '07',
                        accessor: ReportingTableHoursAccessors.h07,
                        ...temperatureProperties,
                    },
                    {
                        Header: '08',
                        accessor: ReportingTableHoursAccessors.h08,
                        ...temperatureProperties,
                    },
                    {
                        Header: '09',
                        accessor: ReportingTableHoursAccessors.h09,
                        ...temperatureProperties,
                    },
                    {
                        Header: '10',
                        accessor: ReportingTableHoursAccessors.h10,
                        ...temperatureProperties,
                    },
                    {
                        Header: '11',
                        accessor: ReportingTableHoursAccessors.h11,
                        ...temperatureProperties,
                    },
                    {
                        Header: '12',
                        accessor: ReportingTableHoursAccessors.h12,
                        ...temperatureProperties,
                    },
                    {
                        Header: '13',
                        accessor: ReportingTableHoursAccessors.h13,
                        ...temperatureProperties,
                    },
                    {
                        Header: '14',
                        accessor: ReportingTableHoursAccessors.h14,
                        ...temperatureProperties,
                    },
                    {
                        Header: '15',
                        accessor: ReportingTableHoursAccessors.h15,
                        ...temperatureProperties,
                    },
                    {
                        Header: '16',
                        accessor: ReportingTableHoursAccessors.h16,
                        ...temperatureProperties,
                    },
                    {
                        Header: '17',
                        accessor: ReportingTableHoursAccessors.h17,
                        ...temperatureProperties,
                    },
                    {
                        Header: '18',
                        accessor: ReportingTableHoursAccessors.h18,
                        ...temperatureProperties,
                    },
                    {
                        Header: '19',
                        accessor: ReportingTableHoursAccessors.h19,
                        ...temperatureProperties,
                    },
                    {
                        Header: '20',
                        accessor: ReportingTableHoursAccessors.h20,
                        ...temperatureProperties,
                    },
                    {
                        Header: '21',
                        accessor: ReportingTableHoursAccessors.h21,
                        ...temperatureProperties,
                    },
                    {
                        Header: '22',
                        accessor: ReportingTableHoursAccessors.h22,
                        ...temperatureProperties,
                    },
                    {
                        Header: '23',
                        accessor: ReportingTableHoursAccessors.h23,
                        ...temperatureProperties,
                    },
                ],
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, date, assetsToHide, assetsToShow]);

    const queryParams = {
        storeId,
        date: formatDateForAPI(date),
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: filters ? transformReactTableFiltersForAPI(filters) : undefined,
    };

    const {
        data: foodSafetyReportData,
        isFetching,
        isError,
        isSuccess,
        refetch: foodSafetyReportRefetch,
    } = useHandledQuery(
        [QueryKeys.foodSafetyReport(), sortingRule, filters && filters.length > 0 ? filters : null, date.toLocaleString()],
        () => (queryParams.storeId ? getFoodSafetyReport(queryParams.storeId, queryParams.date, queryParams.sort, queryParams.filter) : undefined),
        { keepPreviousData: true, enabled: Boolean(storeId) && date.isValid },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const {
        data: foodSafetyReportPdfData,
        refetch: foodSafetyReportPdfRefetch,
        remove: foodSafetyReportPdfRemove,
        isLoading: foodSafetyReportPdfIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyReportPdf(),
        () => (queryParams.storeId ? getFoodSafetyReportPdf(queryParams.storeId, queryParams.date, queryParams.sort, queryParams.filter) : undefined),
        {
            enabled: false,
        },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const {
        data: foodSafetyReportXlsData,
        refetch: foodSafetyReportXlsRefetch,
        remove: foodSafetyReportXlsRemove,
        isLoading: foodSafetyReportXlsIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyReportXls(),
        () => (queryParams.storeId ? getFoodSafetyReportXls(queryParams.storeId, queryParams.date, queryParams.sort, queryParams.filter) : undefined),
        {
            enabled: false,
        },
    );

    const {
        data: foodSafetyReportMultiplePdfsData,
        isLoading: foodSafetyReportMultiplePdfsDataIsLoading,
        remove: foodSafetyReportMultiplePdfsDataRemove,
    } = useHandledQuery(
        [QueryKeys.foodSafetyReportMultiplePdfsData(), intervalDateToDownloadMultiplePdfs],
        () =>
            queryParams.storeId && intervalDateToDownloadMultiplePdfs
                ? getFoodSafetyReportMultiplePdfs(queryParams.storeId, formatDateForAPI(intervalDateToDownloadMultiplePdfs.from), formatDateForAPI(intervalDateToDownloadMultiplePdfs.to))
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultiplePdfs),
        },
    );

    const {
        data: foodSafetyReportMultipleCsvData,
        isLoading: foodSafetyReportMultipleCsvDataIsLoading,
        remove: foodSafetyReportMultipleCsvDataRemove,
    } = useHandledQuery(
        [QueryKeys.foodSafetyReportMultiplePdfsData(), intervalDateToDownloadMultipleCsv],
        () =>
            queryParams.storeId && intervalDateToDownloadMultipleCsv
                ? getFoodSafetyReportMultipleCsv(queryParams.storeId, formatDateForAPI(intervalDateToDownloadMultipleCsv.from), formatDateForAPI(intervalDateToDownloadMultipleCsv.to))
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultipleCsv),
        },
    );

    React.useEffect(() => {
        if (mutateIsSuccess) {
            enqueueSnackbar(t('patchAssetsVisibility.success'));
            foodSafetyReportRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateIsSuccess]);

    React.useEffect(() => {
        return () => {
            foodSafetyReportPdfRemove();
            foodSafetyReportXlsRemove();
            foodSafetyReportMultiplePdfsDataRemove();
            foodSafetyReportMultipleCsvDataRemove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (foodSafetyReportData) {
            setAvailableData({
                from: DateTime.fromJSDate(new Date(foodSafetyReportData.metadata.availableDates.from)),
                to: DateTime.fromJSDate(new Date(foodSafetyReportData.metadata.availableDates.to)),
            });
            if (isSuccess) {
                setAssetsToShow([]);
                setAssetsToHide([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foodSafetyReportData]);

    React.useEffect(() => {
        if (availableData) {
            if (date < availableData.from) {
                dispatch(foodSafetyReportingDate(availableData.from));
            }
            if (date > availableData.to) {
                dispatch(foodSafetyReportingDate(availableData.to.minus({ days: 1 })));
            }
        }
    }, [availableData, date, dispatch]);

    React.useEffect(() => {
        if (foodSafetyReportPdfData) {
            const file = new Blob([foodSafetyReportPdfData.blob]);
            FileSaver.saveAs(file, foodSafetyReportPdfData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyReportPdf(), { exact: true });
        }
    }, [foodSafetyReportPdfData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyReportXlsData) {
            const file = new Blob([foodSafetyReportXlsData.blob]);
            FileSaver.saveAs(file, foodSafetyReportXlsData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyReportXls(), { exact: true });
        }
    }, [foodSafetyReportXlsData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyReportMultiplePdfsData) {
            const file = new Blob([foodSafetyReportMultiplePdfsData.blob]);
            FileSaver.saveAs(file, foodSafetyReportMultiplePdfsData.fileName);
            setIntervalDateToDownloadMultiplePdfs(null);
            queryClient.removeQueries(QueryKeys.foodSafetyReportMultiplePdfsData(), { exact: true });
        }
    }, [foodSafetyReportMultiplePdfsData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyReportMultipleCsvData) {
            const file = new Blob([foodSafetyReportMultipleCsvData.blob]);
            FileSaver.saveAs(file, foodSafetyReportMultipleCsvData.fileName);
            setIntervalDateToDownloadMultiplePdfs(null);
            queryClient.removeQueries(QueryKeys.foodSafetyReportMultipleCsvData(), { exact: true });
        }
    }, [foodSafetyReportMultipleCsvData, queryClient]);

    React.useEffect(() => {
        if (intervalDateToDownloadMultiplePdfs === null) {
            setIntervalPickerPdfOpen(false);
        }
    }, [intervalDateToDownloadMultiplePdfs]);

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const minDate = foodSafetyReportData ? DateTime.fromJSDate(new Date(foodSafetyReportData.metadata.availableDates.from)) : false;
    const maxDate = foodSafetyReportData ? DateTime.fromJSDate(new Date(foodSafetyReportData.metadata.availableDates.to)) : false;

    return (
        <>
            {availableData && (
                <ContentFilter text={t('foodSafety.reporting.dataAvailableIntervalText', { from: availableData.from.toLocaleString(), to: availableData.to.toLocaleString() })}>
                    <DatePickerWithArrows availableData={availableData} date={date} setDate={(input) => dispatch(foodSafetyReportingDate(input))} />
                </ContentFilter>
            )}
            <Content>
                <LoadingBar isFetching={isFetching} />
                <ContentHeader>
                    <ContentLegend>
                        <Status text={t('foodSafety.reporting.legend.defrosting')} type={StatusType.defrosting} />
                        <Status isSquare text={t('foodSafety.reporting.legend.temperatureOver2k')} type={StatusType.warning} />
                        <Status isSquare text={t('foodSafety.reporting.legend.temperatureOver4k')} type={StatusType.error} />
                    </ContentLegend>
                    <ExportButtons
                        buttons={[
                            { type: ExportButtonType.email, text: t('exportButtons.email'), action: () => history.push(routes.setupEmailReportConfig.path()), key: 'email' },
                            {
                                type: ExportButtonType.excel,
                                text: t('exportButtons.currentDay'),
                                action: () => foodSafetyReportXlsRefetch(),
                                isLoading: foodSafetyReportXlsIsLoading,
                                key: 'currentDayExcel',
                            },
                            { type: ExportButtonType.csv, text: t('exportButtons.timespanCsv'), action: () => setIntervalPickerCsvOpen(true), key: 'timespanCsv' },
                            {
                                type: ExportButtonType.pdf,
                                text: t('exportButtons.currentDay'),
                                action: () => foodSafetyReportPdfRefetch(),
                                isLoading: foodSafetyReportPdfIsLoading,
                                key: 'currentDayPdf',
                            },
                            { type: ExportButtonType.pdf, text: t('exportButtons.timespanPdfs'), action: () => setIntervalPickerPdfOpen(true), key: 'timespanPdfs' },
                        ]}
                        disabled={isError}
                    />
                    {minDate && maxDate && (
                        <>
                            <IntervalPickerDialog
                                handleClose={() => setIntervalPickerPdfOpen(false)}
                                handleSubmit={(from: DateTime, to: DateTime) => {
                                    setIntervalDateToDownloadMultiplePdfs({ from, to });
                                }}
                                maxDate={maxDate}
                                minDate={minDate}
                                open={intervalPickerPdfOpen}
                                submitIsLoading={foodSafetyReportMultiplePdfsDataIsLoading}
                                submitText={t('download')}
                            />
                            <IntervalPickerDialog
                                handleClose={() => setIntervalPickerCsvOpen(false)}
                                handleSubmit={(from: DateTime, to: DateTime) => {
                                    setIntervalDateToDownloadMultipleCsv({ from, to });
                                }}
                                maxDate={maxDate}
                                minDate={minDate}
                                open={intervalPickerCsvOpen}
                                submitIsLoading={foodSafetyReportMultipleCsvDataIsLoading}
                                submitText={t('download')}
                            />
                        </>
                    )}
                </ContentHeader>
                <HorizontalSeparator />
                {isSmUp ? (
                    <ReportingTable
                        columns={columns}
                        data={foodSafetyReportData ? foodSafetyReportData.data : []}
                        isFetching={isFetching}
                        refetch={foodSafetyReportRefetch}
                        onFilter={handleFilters}
                        onSort={handleSort}
                    />
                ) : (
                    <ReportingMobileList data={foodSafetyReportData ? foodSafetyReportData : null} />
                )}

                {(assetsToShow.length > 0 || assetsToHide.length > 0) && (
                    <BottomBar>
                        <Box display="flex">
                            <Button isLoading={mutateIsLoading} isPrimary={true} size="medium" onClick={handleOnSubmit}>
                                {t('organizationStructure.bar.saveChanges')}
                            </Button>
                            <Box alignItems="center" display="flex" justifyContent="space-between" ml={1.5}>
                                <Button isPrimary={false} size="medium" onClick={handleDiscardChanges}>
                                    {t('organizationStructure.bar.discard')}
                                </Button>
                            </Box>
                        </Box>
                    </BottomBar>
                )}
            </Content>
        </>
    );
};

export default Reporting;
