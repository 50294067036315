import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CenteredLayout from 'components/centeredLayout/centeredLayout';
import ContactForm from 'components/contactForm/contactForm';
import Logo from 'assets/images/logo.svg';
import Paper from 'components/paper/paper';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: '100%',
        width: theme.typography.pxToRem(120),
        height: 'auto',
    },
    contactFormLink: {
        height: '100%',
        color: theme.customPalette.colors.brand.light,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'none',
        textDecoration: 'none',
        padding: theme.spacing(1),
        margin: theme.spacing(-1),
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.customPalette.colors.brand.dark,
        },
    },
}));

const PreApplicationBox: React.FC = ({ children }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [contactFormIsOpen, setContactFormIsOpen] = React.useState<boolean>(false);

    const handleOpenContactForm = () => {
        setContactFormIsOpen(true);
    };

    const handleCloseContactForm = () => {
        setContactFormIsOpen(false);
    };
    return (
        <CenteredLayout>
            <Paper>
                <Box display="flex" justifyContent="center" mt={1}>
                    <img alt={t('app.name')} className={classes.logo} src={Logo} />
                </Box>
                <Box mb={4} />
                {children}
            </Paper>
            <Box alignItems="center" display="flex" justifyContent="center" mt={2}>
                <div className={classes.contactFormLink} tabIndex={0} onClick={handleOpenContactForm}>
                    {t('contactForm.title')}
                </div>
            </Box>
            {contactFormIsOpen && <ContactForm handleClose={handleCloseContactForm} />}
        </CenteredLayout>
    );
};

export default PreApplicationBox;
