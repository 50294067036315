import { AppState } from 'store';
import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/forum.svg';
import { logout } from 'store/auth/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ReactComponent as MenuDownIcon } from 'assets/icons/menu-down.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as MyProfileIcon } from 'assets/icons/account.svg';
import { Role } from 'model/myProfile/role';
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { UserBoxProps } from 'components/userBox/userBoxProps';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/avatar/avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MuiButton from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        cursor: 'pointer',
        padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(3)}`,
        alignItems: 'center',
        height: '100%',
        borderLeftWidth: theme.typography.pxToRem(1),
        borderLeftColor: theme.customPalette.border,
        borderLeftStyle: 'solid',
        position: 'relative',
    },
    text: {
        marginLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
    },
    icon: {
        fill: theme.customPalette.colors.brand.light,
        transform: ({ menuOpen }: StylesProps) => (menuOpen ? 'rotate(180deg)' : 'none'),
    },
    menuOverlay: {
        position: 'absolute',
        top: theme.spacing(6),
        padding: theme.spacing(2),
        right: 0,
        left: 0,
        bottom: 0,
        background: theme.customPalette.background.dark,
        zIndex: 10,
    },
    listItemMobile: {
        margin: `0 -${theme.spacing(0.75)}`,
        width: `calc(100% + ${theme.spacing(1.5)})`,
        color: theme.customPalette.colors.brand.light,
    },
    listItemIconMobile: {
        marginRight: theme.spacing(1.5),
    },
}));

interface StylesProps {
    menuOpen: boolean;
}

const UserBox: React.FC<UserBoxProps> = ({ openContactForm }) => {
    const theme = useTheme();
    const history = useHistory();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

    const classes = useStyles({ menuOpen: Boolean(anchorEl) });
    const { t } = useTranslation();
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = React.useCallback(() => {
        logout();
    }, []);

    const toggleMenuOpen = React.useCallback(() => {
        setIsMenuOpen((prev) => !prev);
    }, []);

    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
        setIsMenuOpen(false);
    }, []);

    const myProfile = useSelector((state: AppState) => state.auth.myProfile);
    const loggedUserIsAdmin = myProfile && myProfile.role === Role.administrator;

    const menuItems = [
        {
            id: 'test-my-profile',
            icon: <MyProfileIcon />,
            text: t('myProfile'),
            onClick: () => {
                history.push(routes.myProfileUserSettings.path());
                closeMenu();
            },
        },
        {
            id: 'test-logout',
            icon: <ExitIcon />,
            text: t('logout'),
            onClick: handleLogout,
        },
    ];

    const mobileMenuItems = [
        loggedUserIsAdmin
            ? {
                  id: 'adminUsers.title',
                  icon: <ForumIcon />,
                  text: t('adminUsers.title'),
                  onClick: () => {
                      history.push(routes.adminUsers.path());
                      closeMenu();
                  },
              }
            : {
                  id: 'contactForm.title',
                  icon: <MyProfileIcon />,
                  text: t('contactForm.title'),
                  onClick: openContactForm,
              },
        ...menuItems,
    ];

    const avatarWithName = myProfile && (
        <>
            <Avatar firstName={myProfile.firstName} lastName={myProfile.lastName} />
            <div className={classes.text}>
                {myProfile.firstName}&nbsp;{myProfile.lastName}
            </div>
        </>
    );

    return (
        <>
            {isSmUp && (
                <>
                    <MuiButton className={classes.root} id="test-user-box" onClick={handleClick}>
                        {avatarWithName}
                        <MenuDownIcon className={classes.icon} />
                    </MuiButton>
                    <Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        id="simple-menu"
                        keepMounted
                        open={Boolean(anchorEl)}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handleClose}
                    >
                        <List component="nav">
                            {menuItems.map(({ id, onClick, text, icon }) => (
                                <ListItem key={id} button id={id} onClick={onClick}>
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText>{text}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Popover>
                </>
            )}
            {!isSmUp && (
                <>
                    {isMenuOpen ? (
                        <IconButton aria-label={t('menu.close')} onClick={toggleMenuOpen}>
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <IconButton aria-label={t('menu.open')} onClick={toggleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    {isMenuOpen && (
                        <div className={classes.menuOverlay}>
                            <Box alignItems="center" display="flex">
                                {avatarWithName}
                            </Box>
                            <List component="nav">
                                {mobileMenuItems.map(({ id, onClick, text, icon }) => (
                                    <ListItem key={id} button className={classes.listItemMobile} onClick={onClick}>
                                        <ListItemIcon className={classes.listItemIconMobile}>{icon}</ListItemIcon>
                                        <ListItemText>{text}</ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default UserBox;
