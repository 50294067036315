import { AppState } from 'store';
import { Cell, Column } from 'react-table';
import { useTheme } from '@material-ui/core';
import { StatisticsTableAccessors } from 'types/statistics/statisticsTableAccessors';
import { StatusType } from 'components/status/statusType';
import { TableStatisticsItem } from 'types/statistics/tableStatisticsItem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import DayCell from 'components/table/components/dayCell/dayCell';
import DayHeadingWithIcons from 'components/table/components/dayHeadingWithIcons/dayHeadingWithIcons';
import React from 'react';
import Status from 'components/status/status';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueWithPercent from 'components/table/components/valueWithPercent/valueWithPercent';
import useMonths from 'shared/useMonths/useMonths';
import { formatMinutes } from '../../shared/utils/formatMinutes';
import { getMonthNameInQuarterOverview, getValueInQuarterOverview } from './statisticsUtils';

export const useStatisticsColumns = () => {
    const { weekAndYear, monthAndYear, qsAndYear } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);
    const months = useMonths();
    const { t } = useTranslation();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const currentMonthAndYear = monthAndYear ? `${months[monthAndYear.month - 1]} ${monthAndYear.year}` : '';
    const columns = React.useMemo<Column<TableStatisticsItem>[]>(
        () => [
            {
                Header: t('foodSafety.statistics.table.heading.case').toString(),
                accessor: StatisticsTableAccessors.zone,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: isLgUp ? 'calc(4%)' : 'unset',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.position').toString(),
                accessor: StatisticsTableAccessors.shortName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: isLgUp ? 'calc(7%)' : 'unset',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.defrostHrs').toString(),
                accessor: StatisticsTableAccessors.defrostHours,
                disableSortBy: false,
                customStyles: {
                    width: isLgUp ? 'calc(7%)' : 'unset',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.between2kAnd4k').toString(),
                accessor: StatisticsTableAccessors.deviation2kCount,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return (
                        <ValueWithPercent
                            count={row.original.deviation2kCount}
                            percent={row.original.deviation2kPercent === undefined ? row.original.deviation2kPercentage : row.original.deviation2kPercent}
                        />
                    );
                },
                customStyles: {
                    width: isLgUp ? 'calc(7%)' : 'unset',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.greaterThan4k').toString(),
                accessor: StatisticsTableAccessors.deviation4kCount,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return (
                        <ValueWithPercent
                            count={row.original.deviation4kCount}
                            percent={row.original.deviation4kPercent === undefined ? row.original.deviation4kPercentage : row.original.deviation4kPercent}
                        />
                    );
                },
                customStyles: {
                    width: isLgUp ? 'calc(7%)' : 'unset',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.status').toString(),
                accessor: 'status',
                disableSortBy: true,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return (
                        <Box my={-0.75}>
                            <Status type={row.original.status} />
                        </Box>
                    );
                },
            },
            /* Weeks */

            {
                Header: <DayHeadingWithIcons text={t('days.monday.long')} />,
                accessor: StatisticsTableAccessors.monday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.monday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.tuesday.long')} />,
                accessor: StatisticsTableAccessors.tuesday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.tuesday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.wednesday.long')} />,
                accessor: StatisticsTableAccessors.wednesday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.wednesday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.thursday.long')} />,
                accessor: StatisticsTableAccessors.thursday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.thursday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.friday.long')} />,
                accessor: StatisticsTableAccessors.friday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.friday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.saturday.long')} />,
                accessor: StatisticsTableAccessors.saturday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.saturday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },
            {
                Header: <DayHeadingWithIcons text={t('days.sunday.long')} />,
                accessor: StatisticsTableAccessors.sunday,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return <DayCell data={row.original.sunday} />;
                },
                customStyles: {
                    width: 'calc(6%)',
                },
            },

            /* Month */
            {
                Header: currentMonthAndYear,
                accessor: StatisticsTableAccessors.monthGoodStatus,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    if (!monthAndYear || !row.original[monthAndYear.month]) {
                        return null;
                    }
                    const value = row.original[monthAndYear.month].deviation0kPercentage;
                    return (
                        <Box my={-0.75}>
                            <Status text={Math.trunc(value) + ' %'} type={StatusType.success} />
                        </Box>
                    );
                },
                customStyles: {
                    width: 'calc(8%)',
                },
            },
            {
                Header: '',
                accessor: StatisticsTableAccessors.monthWarningStatus,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    if (!monthAndYear || !row.original[monthAndYear.month]) {
                        return null;
                    }
                    const value = row.original[monthAndYear.month].deviation2kMinutes;
                    return (
                        <Box my={-0.75}>
                            <Status opacity={!value ? 0.5 : 1} text={formatMinutes(value)} type={StatusType.warning} />
                        </Box>
                    );
                },
                customStyles: {
                    width: 'calc(8%)',
                },
            },
            {
                Header: '',
                accessor: StatisticsTableAccessors.monthErrorStatus,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    if (!monthAndYear || !row.original[monthAndYear.month]) {
                        return null;
                    }
                    const value = row.original[monthAndYear.month].deviation4kMinutes;
                    return (
                        <Box my={-0.75}>
                            <Status opacity={!value ? 0.5 : 1} text={formatMinutes(value)} type={StatusType.error} />
                        </Box>
                    );
                },
                customStyles: {
                    width: 'calc(8%)',
                },
            },

            /* Quarter */
            {
                Header: getMonthNameInQuarterOverview(1, qsAndYear?.quarter),
                accessor: StatisticsTableAccessors.firstMonthOfQuarter,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return getValueInQuarterOverview(1, row, qsAndYear?.quarter);
                },
                customStyles: {
                    width: 'calc(10%)',
                },
            },
            {
                Header: getMonthNameInQuarterOverview(2, qsAndYear?.quarter),
                accessor: StatisticsTableAccessors.secondMonthOfQuarter,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return getValueInQuarterOverview(2, row, qsAndYear?.quarter);
                },
                customStyles: {
                    width: 'calc(10%)',
                },
            },
            {
                Header: getMonthNameInQuarterOverview(3, qsAndYear?.quarter),
                accessor: StatisticsTableAccessors.thirdMonthOfQuarter,
                Cell: ({ row }: Cell<TableStatisticsItem>) => {
                    return getValueInQuarterOverview(3, row, qsAndYear?.quarter);
                },
                customStyles: {
                    width: 'calc(10%)',
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, isLgUp, weekAndYear?.week, weekAndYear?.year, monthAndYear?.month, monthAndYear?.year, qsAndYear?.quarter],
    );
    return columns;
};
