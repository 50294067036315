import SegmentedControl from 'components/segmentedControl';
import React from 'react';
import { QueryKeys } from 'api/queryKeys';
import { getAlarmTypes } from 'api/fetchers/alarmTypes';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';

type Segment = {
    label: string;
    value: string;
};

type Props = {
    selectedAlarmType: string | undefined;
    onChange: (value: string) => void;
};

const AlarmType: React.FC<Props> = ({ selectedAlarmType, onChange }) => {
    const { data: alarmTypes, isLoading } = useHandledQuery(QueryKeys.getAlarmTypes('dashboard'), () => getAlarmTypes('dashboard'));

    React.useEffect(() => {
        if (selectedAlarmType !== undefined) {
            return;
        }

        if (!alarmTypes || alarmTypes.length === 0) {
            return;
        }

        const indexOfFirst = 0;
        const firstAlarmType = alarmTypes[indexOfFirst];

        onChange(firstAlarmType.code);
    }, [alarmTypes, onChange]);

    if (isLoading || !alarmTypes || !selectedAlarmType) {
        return null;
    }

    const segments: Segment[] = alarmTypes.map((item) => ({
        label: item.label,
        value: item.code,
    }));

    return <SegmentedControl segments={segments} name="alarmType" value={selectedAlarmType} onChange={onChange} />;
};

export default AlarmType;
