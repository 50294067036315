import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.typography.pxToRem(1),
        backgroundColor: theme.customPalette.border,
    },
}));

const HorizontalSeparator: React.FC = () => {
    const classes = useStyles();
    return <div className={classes.root} />;
};

export default HorizontalSeparator;
