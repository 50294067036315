import { Box } from '@material-ui/core';
import { ReactComponent as CsvIcon } from 'assets/icons/csv.svg';
import { ReactComponent as EmailSendIcon } from 'assets/icons/email-send.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg';
import { ExportButtonsProps, ExportButtonType } from 'components/exportButtons/exportButtonProps';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'unset',
        },
    },
    label: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.secondary,
        padding: `${theme.spacing(0.75)} 0`,
        marginRight: theme.spacing(3 / 8),
        [theme.breakpoints.down('xs')]: {
            display: 'none ',
        },
    },
    shopIcon: {
        fill: theme.customPalette.colors.brand.light,
    },
    pdfIcon: {
        fill: theme.customPalette.colors.error,
    },
    excelIcon: {
        fill: theme.customPalette.colors.success,
    },
    csvIcon: {
        fill: theme.customPalette.colors.success,
    },
    disabledIcon: {
        fill: theme.customPalette.text.secondary,
        cursor: 'not-allowed',
    },
}));

const ExportButtons: React.FC<ExportButtonsProps> = ({ buttons, disabled }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    if (buttons.length === 0) {
        return null;
    }

    return (
        <Grid item xs={6}>
            <Box className={classes.root} display="flex">
                <div className={classes.label}>{t('exportButtons.exportTo')}</div>
                {buttons.map(({ type, text, key, action, isLoading }) => {
                    let icon = undefined;
                    if (type === ExportButtonType.email) {
                        icon = <EmailSendIcon className={clsx(classes.shopIcon, disabled && classes.disabledIcon)} />;
                    }
                    if (type === ExportButtonType.excel) {
                        icon = <ExcelIcon className={clsx(classes.excelIcon, disabled && classes.disabledIcon)} />;
                    }
                    if (type === ExportButtonType.csv) {
                        icon = <CsvIcon className={clsx(classes.csvIcon, disabled && classes.disabledIcon)} />;
                    }
                    if (type === ExportButtonType.pdf) {
                        icon = <PdfIcon className={clsx(classes.pdfIcon, disabled && classes.disabledIcon)} />;
                    }
                    return (
                        <Box
                            key={key}
                            ml={1}
                            my={0.25}
                            onClick={() => {
                                if (!disabled) {
                                    action();
                                }
                            }}
                        >
                            <Button disabled={disabled} icon={icon} id={`test-${key}`} isLoading={isLoading}>
                                {text}
                            </Button>
                        </Box>
                    );
                })}
            </Box>
        </Grid>
    );
};

export default ExportButtons;
