import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        margin: 0,
    },
}));

const HeadingOne: React.FC = ({ children }) => {
    const classes = useStyles();
    return <h1 className={classes.root}>{children}</h1>;
};

export default HeadingOne;
