import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const PostNotificationSchema = z.object({
    success: z.literal(true),
});

export interface PostNotificationParams {
    contact: string;
    storeId: number;
    userId: number;
}

export const mutatePostNotification = async (values: PostNotificationParams) => {
    const { contact, storeId, userId } = values;
    const response = await makeMutation(ApiEndpoints.postNotification(storeId, userId), { contact }, true, 'POST');
    return PostNotificationSchema.parse(response);
};

export const postNotificationErrorCodes = {
    INVALID_CONTACT: {
        code: 'INVALID_CONTACT',
        translation: (t: TFunction) => t('postNotification.INVALID_CONTACT'),
    },
    CONTACT_EXISTS: {
        code: 'CONTACT_EXISTS',
        translation: (t: TFunction) => t('postNotification.CONTACT_EXISTS'),
    },
};
