import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './auth/reducer';
import filtersReducer from './filters/reducer';
import storeReducer from './store/reducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    store: storeReducer,
    filters: filtersReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeWithDevTools());
