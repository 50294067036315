const AUTH_TOKEN = 'authToken';

export const setToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
};
export const hasToken = () => Boolean(localStorage.getItem(AUTH_TOKEN));
export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const clearToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};
