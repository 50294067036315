import * as FileSaver from 'file-saver';
import { Box } from '@material-ui/core';
import { DateTime } from 'shared/luxon/luxon';
import { ExportButtonType } from 'components/exportButtons/exportButtonProps';
import { formatDateForAPI } from 'shared/network/helpers';
import { getAsset } from 'api/fetchers/asset';
import { getAssetCsv } from 'api/fetchers/assetCsv';
import { mutateAssetPdf } from 'api/mutations/assetPdf';
import { QueryKeys } from 'api/queryKeys';
import { Unit } from 'shared/enums/unit';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AssetChart from 'components/chart/assetChart/assetChart';
import AssetHeader from 'components/assetHeader/assetHeader';
import Content from 'components/content/content';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import ExportButtons from 'components/exportButtons/exportButtons';
import Grid from '@material-ui/core/Grid';
import Header from 'components/header/header';
import HeadingTwo from 'components/typography/headingTwo/headingTwo';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import IntervalPickerDialog from 'components/intervalPickerDialog/intervalPickerDialog';
import LegendLabel from 'components/legendLabel/legendLabel';
import LoadingBar from 'components/loadingBar/loadingBar';
import Main from 'components/main/main';
import NoResults from 'components/noResults/noResults';
import React, { useEffect } from 'react';
import StaticHorizontalTable from 'components/staticHorizontalTable/staticHorizontalTable';
import useAssetChartData from 'components/chart/useAssetChartData';
import useDownloadFile from 'shared/useDownloadFile/useDownloadFile';
import { getDateDiffInDays } from '../shared/luxon/utils';
import { useSnackbar } from 'notistack';

// settings
const MAX_RANGE_DAYS = 40;

const AssetScreen: React.FC = () => {
    const theme = useTheme();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [intervalPickerCsvOpen, setIntervalPickerCsvOpen] = React.useState<boolean>(false);
    const [intervalDateToDownloadMultipleCsv, setIntervalDateToDownloadMultipleCsv] = React.useState<{ from: DateTime; to: DateTime } | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defrostingChartRef = React.useRef<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const highTemperatureChartRef = React.useRef<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lineChartRef = React.useRef<any>(null);
    const { state: historyState } = useLocation<{
        dateFrom?: string;
        dateTo?: string;
        goBackPath?: string;
    }>();

    const [dateFrom, setDateFrom] = React.useState<DateTime>((historyState && historyState.dateFrom && DateTime.fromISO(historyState.dateFrom)) || DateTime.now().minus({ days: 1 }));
    const [dateTo, setDateTo] = React.useState<DateTime>((historyState && historyState.dateTo && DateTime.fromISO(historyState.dateTo)) || DateTime.now());
    const [goBackPathState] = React.useState<string | undefined>(historyState && historyState.goBackPath);

    const queryParams = {
        id: parseInt(id),
        dateFrom: formatDateForAPI(dateFrom),
        dateTo: formatDateForAPI(dateTo),
    };
    const daysDiffCount = dateFrom && dateTo && parseInt(dateTo.startOf('day').diff(dateFrom.startOf('day')).toFormat('d'));

    const { data: assetData, isFetching: assetDataIsFetching } = useHandledQuery(
        [QueryKeys.asset(queryParams.id), queryParams.dateFrom, queryParams.dateTo],
        () => (queryParams.id && queryParams.dateFrom && queryParams.dateTo ? getAsset(queryParams.id, queryParams.dateFrom, queryParams.dateTo) : undefined),
        { enabled: Boolean(id) && daysDiffCount <= MAX_RANGE_DAYS, keepPreviousData: true },
    );

    const { mutate: assetPdfMutate, isLoading: assetPdfIsLoading, data: assetPdfData } = useHandledMutation(mutateAssetPdf);

    const {
        data: assetDataCsv,
        remove: assetDataCsvRemove,
        isLoading: assetDataCsvIsLoading,
    } = useHandledQuery(
        [QueryKeys.assetCsv(), intervalDateToDownloadMultipleCsv],
        () =>
            queryParams.id && intervalDateToDownloadMultipleCsv
                ? getAssetCsv(queryParams.id, formatDateForAPI(intervalDateToDownloadMultipleCsv.from), formatDateForAPI(intervalDateToDownloadMultipleCsv.to))
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultipleCsv),
        },
    );

    React.useEffect(() => {
        return () => {
            assetDataCsvRemove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (assetDataCsv) {
            const file = new Blob([assetDataCsv.blob]);
            FileSaver.saveAs(file, assetDataCsv.fileName);
            setIntervalDateToDownloadMultipleCsv(null);
            queryClient.removeQueries(QueryKeys.assetCsv(), { exact: true });
        }
    }, [assetDataCsv, queryClient]);

    const { data, tickInterval } = useAssetChartData(dateFrom, dateTo, assetData);
    useDownloadFile(undefined, assetPdfData);

    const handleInputErrors = (dateFromInput: DateTime, dateToInput: DateTime): boolean => {
        const errorMaxRange = getDateDiffInDays(dateFromInput, dateToInput) >= MAX_RANGE_DAYS;
        const errorInvalidFromTo = dateFromInput > dateToInput;
        if (errorMaxRange) {
            enqueueSnackbar(t('datepickerErrors.maxRangeDaysError'), { preventDuplicate: true });
        }
        if (errorInvalidFromTo) {
            enqueueSnackbar(t('datepickerErrors.errorInvalidFromTo'), { preventDuplicate: true });
        }
        return errorMaxRange || errorInvalidFromTo;
    };

    useEffect(() => {
        if (dateFrom && dateTo) {
            if (handleInputErrors(dateFrom, dateTo)) {
                const modifiedDateTo = dateFrom.plus({ days: MAX_RANGE_DAYS - 1 });
                setDateTo(modifiedDateTo);
            }
        }
    }, [dateFrom]);

    useEffect(() => {
        if (dateFrom && dateTo) {
            if (handleInputErrors(dateFrom, dateTo)) {
                const modifiedDateFrom = dateTo.minus({ days: MAX_RANGE_DAYS - 1 });
                setDateFrom(modifiedDateFrom);
            }
        }
    }, [dateTo]);

    if (!assetData) {
        return null;
    }

    const legendLabels = [
        {
            name: t('asset.legend.pilot'),
            color: theme.customPalette.colors.brand.dark,
        },
        {
            name: t('asset.legend.defrosting'),
            color: theme.customPalette.colors.brand.light,
        },
        {
            name: t('asset.legend.highTemperature'),
            color: theme.customPalette.colors.error,
        },
    ];

    const firstBucketSetPoint = assetData.data.buckets && assetData.data.buckets.length > 0 && assetData.data.buckets[0] && assetData.data.buckets[0].setPoint;
    const firstBucketSetPointAlt = assetData.data.buckets && assetData.data.buckets.length > 0 && assetData.data.buckets[0] && assetData.data.buckets[0].setPointAlt;

    const propertiesTableData = [
        {
            heading: t('asset.properties.coolingPosition'),
            value: assetData.data.longName,
        },
        {
            heading: t('asset.properties.canAddress'),
            value: assetData.data.canAddress,
        },
        {
            heading: t('asset.properties.position'),
            value: assetData.data.shortName,
        },
        {
            heading: t('asset.properties.case'),
            value: assetData.data.zone,
        },

        {
            heading: t('asset.properties.setPoint'),
            value: firstBucketSetPoint === null || firstBucketSetPoint === undefined || firstBucketSetPoint === false ? '-' : firstBucketSetPoint,
        },

        {
            heading: t('asset.properties.setPointAlt'),
            value: firstBucketSetPointAlt === null || firstBucketSetPointAlt === undefined || firstBucketSetPointAlt === false ? '-' : firstBucketSetPointAlt,
        },
    ];

    const rechartsFix = (input: string) => {
        return input.replace(/\[object Object\],/g, '');
    };

    const getBase64FromSvgChart = (input: Node) => {
        const serializedSvg = new XMLSerializer().serializeToString(input);
        const base64Data = btoa(unescape(encodeURIComponent(rechartsFix(serializedSvg))));
        return `data:image/svg+xml;base64,${base64Data}`;
    };

    const requestPDF = async () => {
        const lineChartBase64 = getBase64FromSvgChart(lineChartRef.current.container.children[0]);
        const highTemperatureChartBase64 = highTemperatureChartRef.current ? getBase64FromSvgChart(highTemperatureChartRef.current.container.children[0]) : null;
        const defrostingChartBase64 = defrostingChartRef.current ? getBase64FromSvgChart(defrostingChartRef.current.container.children[0]) : null;
        assetPdfMutate({
            assetId: queryParams.id,
            dateFrom: queryParams.dateFrom,
            dateTo: queryParams.dateTo,
            lineChart: lineChartBase64,
            highTemperature: highTemperatureChartBase64,
            defrosting: defrostingChartBase64,
        });
    };
    return (
        <>
            <Header />
            <AssetHeader
                activeAssetId={assetData.data.id}
                availableAssets={assetData.metadata.availableAssets}
                dateFrom={dateFrom}
                dateTo={dateTo}
                goBackPath={goBackPathState}
                longName={assetData.data.longName}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                shortName={assetData.data.shortName}
            />
            <Main>
                <Content>
                    <LoadingBar isFetching={assetDataIsFetching} />
                    <ContentHeader>
                        <ContentLegend>{legendLabels && legendLabels.map(({ name, color }) => <LegendLabel key={name} color={color} text={name} />)}</ContentLegend>
                        <ExportButtons
                            buttons={[
                                { type: ExportButtonType.pdf, text: t('exportButtons.pdf'), isLoading: assetPdfIsLoading, action: () => requestPDF(), key: 'pdf' },
                                {
                                    type: ExportButtonType.csv,
                                    text: t('exportButtons.timespanCsv'),
                                    isLoading: assetDataCsvIsLoading,
                                    action: () => setIntervalPickerCsvOpen(true),
                                    key: 'csv',
                                },
                            ]}
                            disabled={false}
                        />
                        <IntervalPickerDialog
                            handleClose={() => setIntervalPickerCsvOpen(false)}
                            handleSubmit={(from: DateTime, to: DateTime) => {
                                setIntervalDateToDownloadMultipleCsv({ from, to });
                            }}
                            maxDate={DateTime.now()}
                            minDate={DateTime.fromMillis(0)}
                            open={intervalPickerCsvOpen}
                            submitIsLoading={assetDataCsvIsLoading}
                            submitText={t('download')}
                        />
                    </ContentHeader>
                    <HorizontalSeparator />
                    <Box mt={3} />
                    {assetData.data.buckets.length > 0 ? (
                        <AssetChart
                            data={data}
                            daysDiffCount={daysDiffCount}
                            defrostingChartRef={defrostingChartRef}
                            highTemperatureChartRef={highTemperatureChartRef}
                            lineChartRef={lineChartRef}
                            tickInterval={tickInterval}
                            unit={Unit.celsius}
                        />
                    ) : (
                        <NoResults />
                    )}
                    <Box mt={3} />
                    <HorizontalSeparator />
                    <Box ml={5.5} p={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={5} xs={12}>
                                <HeadingTwo>{t('asset.properties.title')}</HeadingTwo>
                                <Box mt={2} />
                                <StaticHorizontalTable data={propertiesTableData} />
                            </Grid>
                        </Grid>
                    </Box>
                </Content>
            </Main>
        </>
    );
};

export default AssetScreen;
