import { AppState } from 'store';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import DatePickerRHF from '../datePicker/datePickerRHF';
import { energyConsumptionFilter } from '../../store/filters/actions';
import useSelectTypes from './useSelectTypes';
import { useEnergyConstumptionPickerStyles } from './styles';
import { getDateDiffInDays } from '../../shared/luxon/utils';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';

// form keys
const INPUT_NAME_METRIC_TYPE = 'metricType';
const INPUT_NAME_DATE_INTERVAL_TYPE = 'dateIntervalType';
const INPUT_NAME_DATE_FROM = 'dateFrom';
const INPUT_NAME_DATE_TO = 'dateTo';

// settings
const DEFAULT_RANGE_DAYS = 30;
const MAX_RANGE_DAYS = 90;

const EnergyConsumptionPicker: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { energyMetricType, dateIntervalType, availableDateFrom, availableDateTo, dateFrom, dateTo } = useSelector((state: AppState) => state.filters.energyConsumption);
    const classes = useEnergyConstumptionPickerStyles();
    const { control, watch, reset, setValue } = useHandledForm();
    const { metricTypesArray, intervalTypesArray } = useSelectTypes();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        // available data are known, so we can set default dateTo and dateFrom
        // default state is that dateTo is equal to availableDateTo and interval is 30 days
        if (availableDateFrom && availableDateTo && !dateFrom && !dateTo) {
            const deductedDateFrom = availableDateTo.minus({ days: DEFAULT_RANGE_DAYS });
            reset({
                [INPUT_NAME_DATE_FROM]: deductedDateFrom > availableDateFrom ? deductedDateFrom : availableDateFrom,
                [INPUT_NAME_DATE_TO]: availableDateTo,
                [INPUT_NAME_METRIC_TYPE]: energyMetricType,
                [INPUT_NAME_DATE_INTERVAL_TYPE]: dateIntervalType,
            });
        }
    }, [availableDateTo]);

    // subscribe to RHF values
    const metricTypeValue = watch(INPUT_NAME_METRIC_TYPE);
    const dateIntervalTypeValue = watch(INPUT_NAME_DATE_INTERVAL_TYPE);
    const dateFromValue = watch(INPUT_NAME_DATE_FROM);
    const dateToValue = watch(INPUT_NAME_DATE_TO);

    React.useEffect(() => {
        // Data in RHF are changed

        // Check if all values are defined
        if (dateFromValue && dateToValue && metricTypeValue && dateIntervalTypeValue) {
            // Check if date range does not exceed 90 days
            if (getDateDiffInDays(dateFromValue, dateToValue) < MAX_RANGE_DAYS) {
                // dispatch current state to redux and let them be subscribed in other component for fetching page data
                dispatch(energyConsumptionFilter(dateFromValue, dateToValue, metricTypeValue, dateIntervalTypeValue));
            }
        }
    }, [dateFromValue, dateToValue, metricTypeValue, dateIntervalTypeValue]);

    const handleInputErrors = (dateFromInput: DateTime, dateToInput: DateTime): boolean => {
        const errorMaxRange = getDateDiffInDays(dateFromInput, dateToInput) >= MAX_RANGE_DAYS;
        const errorInvalidFromTo = dateFromInput > dateToInput;
        if (errorMaxRange) {
            enqueueSnackbar(t('datepickerErrors.maxRangeDaysError'), { preventDuplicate: true });
        }
        if (errorInvalidFromTo) {
            enqueueSnackbar(t('datepickerErrors.errorInvalidFromTo'), { preventDuplicate: true });
        }
        return errorMaxRange || errorInvalidFromTo;
    };

    React.useEffect(() => {
        // Date from value was changed
        // check if range does not exceed 90 days. If so, handle dateTo to be valid

        // check if both vales are defined
        if (dateFromValue && dateToValue && availableDateTo) {
            // Check if date range exceeds 90 days or if dateFrom is not newer than dateTo
            if (handleInputErrors(dateFromValue, dateToValue)) {
                // handle DateTo in RHF input
                const modifiedDateTo = dateFromValue.plus({ days: MAX_RANGE_DAYS - 1 });
                // check if modifiedDate to is not older than availableDateTo
                setValue(INPUT_NAME_DATE_TO, modifiedDateTo < availableDateTo ? modifiedDateTo : availableDateTo);
            }
        }
    }, [dateFromValue]);

    React.useEffect(() => {
        // Date to value was changed
        // check if range does not exceed 90 days. If so, handle dateTo to be valid

        // check if both vales are defined
        if (dateFromValue && dateToValue && availableDateFrom) {
            // Check if date range exceeds 90 days or if dateTo is not older tan dateFrom
            if (handleInputErrors(dateFromValue, dateToValue)) {
                // handle dateFrom in RHF input
                const modifiedDateFrom = dateToValue.minus({ days: MAX_RANGE_DAYS - 1 });
                // check if modifiedDateFrom is older than availableDateFrom
                setValue(INPUT_NAME_DATE_FROM, modifiedDateFrom > availableDateFrom ? modifiedDateFrom : availableDateFrom);
            }
        }
    }, [dateToValue]);

    if (!availableDateTo) {
        return null;
    }

    return (
        <Box alignItems="center" display="flex">
            <Box display="flex" flexWrap="wrap">
                <div className={classes.metricTypeOuter}>
                    <TextFieldRHF control={control} defaultValue={energyMetricType} name={INPUT_NAME_METRIC_TYPE} select size="small">
                        {metricTypesArray.map(({ key, value }) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </TextFieldRHF>
                </div>
                <div className={classes.intervalTypeOuter}>
                    <TextFieldRHF control={control} defaultValue={dateIntervalType} name={INPUT_NAME_DATE_INTERVAL_TYPE} select size="small">
                        {intervalTypesArray.map(({ key, value }) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </TextFieldRHF>
                </div>
                {availableDateFrom && availableDateTo && (
                    <>
                        <div className={classes.dateFromOuter}>
                            <DatePickerRHF
                                cancelLabel={t('datePicker.cancel')}
                                control={control}
                                defaultValue={dateFrom}
                                label={t('intervalPickerDialog.from')}
                                maxDate={availableDateTo}
                                minDate={availableDateFrom.startOf('day')}
                                name={INPUT_NAME_DATE_FROM}
                            />
                        </div>
                        <div className={classes.dateToOuter}>
                            <DatePickerRHF
                                cancelLabel={t('datePicker.cancel')}
                                control={control}
                                defaultValue={dateTo}
                                label={t('intervalPickerDialog.to')}
                                maxDate={availableDateTo}
                                minDate={availableDateFrom}
                                name={INPUT_NAME_DATE_TO}
                            />
                        </div>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default EnergyConsumptionPicker;
