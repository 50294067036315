import * as FileSaver from 'file-saver';
import { AppState } from 'store';
import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { Cell, Column, Filters, SortingRule } from 'react-table';
import { DateTime } from 'shared/luxon/luxon';
import { ExportButtonType } from 'components/exportButtons/exportButtonProps';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOff } from 'assets/icons/eye-off.svg';
import { foodSafetyStatisticsMonthAndYear, foodSafetyStatisticsQsAndYear, foodSafetyStatisticsWeekAndYear } from 'store/filters/actions';
import { formatDateForAPI, transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { getFoodSafetyStatistics } from 'api/fetchers/foodSafetyStatistics';
import { getFoodSafetyStatisticsMultipleCsv } from 'api/fetchers/foodSafetyStatisticsMultipleCsv';
import { getFoodSafetyStatisticsPdf } from 'api/fetchers/foodSafetyStatisticsPdf';
import { getFoodSafetyStatisticsXls } from 'api/fetchers/foodSafetyStatisticsXls';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import { mutatePatchAssetsVisibility, patchAssetPositionErrorCodes } from 'api/mutations/patchAssetsVisibility';
import { pull } from 'lodash';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { StatisticsTableAccessors } from 'types/statistics/statisticsTableAccessors';
import { StatusType } from 'components/status/statusType';
import { TableStatisticsItem } from 'types/statistics/tableStatisticsItem';
import { useDispatch, useSelector } from 'react-redux';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Box from '@material-ui/core/Box';
import Button from 'components/button/button';
import Content from 'components/content/content';
import ContentFilter from 'components/contentFilter/contentFilter';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import ExportButtons from 'components/exportButtons/exportButtons';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import IntervalPickerDialog from 'components/intervalPickerDialog/intervalPickerDialog';
import LoadingBar from 'components/loadingBar/loadingBar';
import React from 'react';
import SelectColumnFilter from 'components/table/components/selectColumnFilter/selectColumnFilter';
import StatisticsMobileList from 'components/statisticsMobileList/statisticsMobileList';
import StatisticsTable from 'components/table/instances/statisticsTable/statisticsTable';
import Status from 'components/status/status';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomDatePickerWithArrows from 'components/CustomDatePickerWithArrows/CustomDatePickerWithArrows';
import { getFoodSafetyStatisticsMonthly } from '../../api/fetchers/foodSafetyStatisticsMonthly';
import { useStatisticsColumns } from './useStatisticsColumns';
import {getFoodSafetyStatisticsMonthlyPdf} from "../../api/fetchers/foodSafetyStatisticsMonthlyPdf";

const useStyles = makeStyles((theme) => ({
    eye: {
        fill: theme.customPalette.colors.brand.light,
        width: theme.spacing(2),
        height: theme.spacing(2),
        cursor: 'pointer',
        display: 'block',
    },
}));

const Statistics: React.FC = () => {
    const history = useHistory();
    const [intervalPickerCsvOpen, setIntervalPickerCsvOpen] = React.useState<boolean>(false);
    const [intervalDateToDownloadMultipleCsv, setIntervalDateToDownloadMultipleCsv] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const [availableData, setAvailableData] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { storeId } = useSelector((state: AppState) => state.store);
    const { weekAndYear, monthAndYear, qsAndYear, dateFilterType } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);

    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const [assetsToHide, setAssetsToHide] = React.useState<number[]>([]);
    const [assetsToShow, setAssetsToShow] = React.useState<number[]>([]);
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const { mutate, isSuccess: mutateIsSuccess, isLoading: mutateIsLoading } = useHandledMutation(mutatePatchAssetsVisibility, undefined, patchAssetPositionErrorCodes);
    const classes = useStyles();
    const extendedColumns = useStatisticsColumns();

    const addOrRemoveAssetToHide = (idAsset: number) => {
        const newAssetsToHide = [...assetsToHide];
        if (newAssetsToHide.includes(idAsset)) {
            pull(newAssetsToHide, idAsset);
        } else {
            newAssetsToHide.push(idAsset);
        }
        setAssetsToHide(newAssetsToHide);
    };

    const addOrRemoveAssetToShow = (idAsset: number) => {
        const newAssetsToShow = [...assetsToShow];
        if (newAssetsToShow.includes(idAsset)) {
            pull(newAssetsToShow, idAsset);
        } else {
            newAssetsToShow.push(idAsset);
        }
        setAssetsToShow(newAssetsToShow);
    };

    const handleDiscardChanges = async () => {
        setAssetsToShow([]);
        setAssetsToHide([]);
    };

    const handleOnSubmit = async () => {
        await mutate({ visible: assetsToShow, hidden: assetsToHide });
    };

    const visibilityOptions = [
        { label: t('foodSafety.reporting.table.visible.yes'), value: '0' },
        { label: t('foodSafety.reporting.table.visible.no'), value: '1' },
    ];

    const columns = React.useMemo<Column<TableStatisticsItem>[]>(
        () => [
            {
                Header: '',
                id: StatisticsTableAccessors.drag,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '2%',
                },
            },
            {
                Header: t('foodSafety.statistics.table.heading.visible').toString(),
                accessor: StatisticsTableAccessors.hidden,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '5%',
                },
                Filter: ({ column }: Cell<TableStatisticsItem>) => <SelectColumnFilter column={column} options={visibilityOptions} />,
                Cell: ({ value, row }: Cell<TableStatisticsItem>) => (
                    <div
                        className={classes.eye}
                        title={value ? t('foodSafety.reporting.table.setVisible') : t('foodSafety.reporting.table.setHidden')}
                        onClick={() => {
                            if (value) {
                                addOrRemoveAssetToShow(row.original.id);
                            } else {
                                addOrRemoveAssetToHide(row.original.id);
                            }
                        }}
                    >
                        {!value && <>{assetsToHide.includes(row.original.id) ? <EyeOff /> : <Eye />}</>}
                        {value && <>{assetsToShow.includes(row.original.id) ? <Eye /> : <EyeOff />}</>}
                    </div>
                ),
            },
            {
                Header: t('foodSafety.statistics.table.heading.coolingPosition').toString(),
                accessor: StatisticsTableAccessors.longName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: isLgUp ? 'calc(10%)' : 'unset',
                },
                Cell: ({ value, row }: Cell<TableStatisticsItem>) => {
                    const dateAsLuxonDateTime = DateTime.fromObject({ weekNumber: weekAndYear?.week, weekYear: weekAndYear?.year });
                    const dateFrom = dateAsLuxonDateTime.startOf('week').toISO();
                    const dateTo = dateAsLuxonDateTime.endOf('week').toISO();
                    return (
                        <Link
                            className="test-asset-link"
                            to={{
                                state: { dateFrom, dateTo, goBackPath: routes.foodSafetyStatistics.path() },
                                pathname: routes.asset.path(row.original.id),
                            }}
                        >
                            {value}
                        </Link>
                    );
                },
            },
            ...extendedColumns,
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, isLgUp, weekAndYear?.week, weekAndYear?.year, assetsToHide, assetsToShow],
    );

    const queryParams = {
        storeId,
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: filters ? transformReactTableFiltersForAPI(filters) : undefined,
    };

    const onSuccess = (data: any) => {
        if (data) {
            if (!availableData) {
                setAvailableData({
                    from: DateTime.fromJSDate(new Date(data.metadata.availableDates.from)),
                    to: DateTime.fromJSDate(new Date(data.metadata.availableDates.to)),
                });
            }
            setAssetsToShow([]);
            setAssetsToHide([]);
        }
    };

    /* week data */
    const {
        data: foodSafetyStatisticsData,
        isFetching,
        isError: mutateIsError,
        refetch: foodSafetyStatisticsRefetch,
    } = useHandledQuery(
        [QueryKeys.foodSafetyStatistics(), sortingRule, filters && filters.length > 0 ? filters : null, weekAndYear, dateFilterType],
        () => {
            if (queryParams.storeId && dateFilterType === 'weeks' && weekAndYear) {
                return getFoodSafetyStatistics(queryParams.storeId, weekAndYear.year, weekAndYear.week, queryParams.sort, queryParams.filter);
            }
            return undefined;
        },
        {
            keepPreviousData: true,
            enabled: Boolean(storeId) && weekAndYear && Boolean(weekAndYear.year) && Boolean(weekAndYear.week) && Boolean(filters),
            onSuccess,
            staleTime: 0,
        },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    /*month data*/
    const {
        data: foodSafetyStatisticsMonthData,
        isFetching: foodSafetyStatisticsMonthIsFetching,
        isError: foodSafetyStatisticsMonthMutateIsError,
        refetch: foodSafetyStatisticsMonthRefetch,
    } = useHandledQuery(
        [QueryKeys.getFoodSafetyStatisticsMonthly(), sortingRule, filters && filters.length > 0 ? filters : null, monthAndYear, dateFilterType],
        () => {
            if (queryParams.storeId && dateFilterType === 'months' && monthAndYear) {
                return getFoodSafetyStatisticsMonthly(queryParams.storeId, monthAndYear.year, monthAndYear.month, monthAndYear.month, queryParams.sort, queryParams.filter);
            }
            return undefined;
        },
        { keepPreviousData: true, onSuccess, staleTime: 0, enabled: Boolean(storeId) && monthAndYear && Boolean(monthAndYear.year) && Boolean(monthAndYear.month) && Boolean(filters) },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    /*quarters data*/
    const {
        data: foodSafetyStatisticsQuartersData,
        isFetching: foodSafetyStatisticsQuartersIsFetching,
        isError: foodSafetyStatisticsQuartersMutateIsError,
        refetch: foodSafetyStatisticsQuartersRefetch,
    } = useHandledQuery(
        [QueryKeys.getFoodSafetyStatisticsQuarters(), sortingRule, filters && filters.length > 0 ? filters : null, qsAndYear, dateFilterType],
        () => {
            if (queryParams.storeId && dateFilterType === 'qs' && qsAndYear) {
                return getFoodSafetyStatisticsMonthly(queryParams.storeId, qsAndYear.year, qsAndYear.quarter * 3 - 2, qsAndYear.quarter * 3, queryParams.sort, queryParams.filter);
            }
            return undefined;
        },
        { keepPreviousData: true, onSuccess, staleTime: 0, enabled: Boolean(storeId) && qsAndYear && Boolean(qsAndYear.year) && Boolean(qsAndYear.quarter) && Boolean(filters) },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const {
        data: foodSafetyStatisticsXlsData,
        refetch: foodSafetyStatisticsXlsRefetch,
        remove: foodSafetyStatisticsXlsRemove,
        isLoading: foodSafetyStatisticsXlsIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyStatisticsXls(),
        () =>
            queryParams.storeId && weekAndYear && weekAndYear.year && weekAndYear.week
                ? getFoodSafetyStatisticsXls(queryParams.storeId, weekAndYear.year, weekAndYear.week, queryParams.sort, queryParams.filter)
                : undefined,
        {
            enabled: false,
        },
    );

    const {
        data: foodSafetyStatisticsPdfData,
        refetch: foodSafetyStatisticsPdfRefetch,
        remove: foodSafetyStatisticsPdfRemove,
        isLoading: foodSafetyStatisticsPdfIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyStatisticsPdf(),
        () =>
            queryParams.storeId && weekAndYear && weekAndYear.year && weekAndYear.week
                ? getFoodSafetyStatisticsPdf(queryParams.storeId, weekAndYear.year, weekAndYear.week, queryParams.sort, queryParams.filter)
                : undefined,
        {
            enabled: false,
        },
    );

    const {
        data: foodSafetyStatisticsMonthlyPdfData,
        refetch: foodSafetyStatisticsMonthlyPdfRefetch,
        remove: foodSafetyStatisticsMonthlyPdfRemove,
        isLoading: foodSafetyStatisticsMonthlyPdfIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyStatisticsMonthlyPdf(),
        () =>
            queryParams.storeId && monthAndYear && monthAndYear.year && monthAndYear.month
                ? getFoodSafetyStatisticsMonthlyPdf(queryParams.storeId, monthAndYear.year, monthAndYear.month, monthAndYear.month, queryParams.sort, queryParams.filter)
                : undefined,
        {
            enabled: false,
        },
    );

    const {
        data: foodSafetyStatisticsQuarterlyPdfData,
        refetch: foodSafetyStatisticsQuarterlyPdfRefetch,
        remove: foodSafetyStatisticsQuarterlyPdfRemove,
        isLoading: foodSafetyStatisticsQuarterlyPdfIsLoading,
    } = useHandledQuery(
        QueryKeys.foodSafetyStatisticsQuarterlyPdf(),
        () =>
            queryParams.storeId && qsAndYear && qsAndYear.year && qsAndYear.quarter
                ? getFoodSafetyStatisticsMonthlyPdf(queryParams.storeId, qsAndYear.year, qsAndYear.quarter * 3 - 2, qsAndYear.quarter * 3, queryParams.sort, queryParams.filter)
                : undefined,
        {
            enabled: false,
        },
    );

    const {
        data: foodSafetyStatisticsMultipleCsvData,
        isLoading: foodSafetyStatisticsMultipleCsvDataIsLoading,
        remove: foodSafetyStatisticsMultipleCsvDataRemove,
    } = useHandledQuery(
        [QueryKeys.foodSafetyStatisticsMultipleCsvData(), intervalDateToDownloadMultipleCsv],
        () =>
            queryParams.storeId && intervalDateToDownloadMultipleCsv
                ? getFoodSafetyStatisticsMultipleCsv(queryParams.storeId, formatDateForAPI(intervalDateToDownloadMultipleCsv.from), formatDateForAPI(intervalDateToDownloadMultipleCsv.to))
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultipleCsv),
        },
    );

    React.useEffect(() => {
        if (mutateIsSuccess) {
            enqueueSnackbar(t('patchAssetsVisibility.success'));
            foodSafetyStatisticsRefetch();
            foodSafetyStatisticsMonthRefetch();
            foodSafetyStatisticsQuartersRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateIsSuccess]);

    React.useEffect(() => {
        return () => {
            foodSafetyStatisticsXlsRemove();
            foodSafetyStatisticsPdfRemove();
            foodSafetyStatisticsMonthlyPdfRemove();
            foodSafetyStatisticsQuarterlyPdfRemove();
            foodSafetyStatisticsMultipleCsvDataRemove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (foodSafetyStatisticsPdfData) {
            const file = new Blob([foodSafetyStatisticsPdfData.blob]);
            FileSaver.saveAs(file, foodSafetyStatisticsPdfData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyStatisticsPdf(), { exact: true });
        }
    }, [foodSafetyStatisticsPdfData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyStatisticsMonthlyPdfData) {
            const file = new Blob([foodSafetyStatisticsMonthlyPdfData.blob]);
            FileSaver.saveAs(file, foodSafetyStatisticsMonthlyPdfData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyStatisticsMonthlyPdf(), { exact: true });
        }
    }, [foodSafetyStatisticsMonthlyPdfData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyStatisticsQuarterlyPdfData) {
            const file = new Blob([foodSafetyStatisticsQuarterlyPdfData.blob]);
            FileSaver.saveAs(file, foodSafetyStatisticsQuarterlyPdfData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyStatisticsQuarterlyPdf(), { exact: true });
        }
    }, [foodSafetyStatisticsQuarterlyPdfData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyStatisticsXlsData) {
            const file = new Blob([foodSafetyStatisticsXlsData.blob]);
            FileSaver.saveAs(file, foodSafetyStatisticsXlsData.fileName);
            queryClient.removeQueries(QueryKeys.foodSafetyStatisticsXls(), { exact: true });
        }
    }, [foodSafetyStatisticsXlsData, queryClient]);

    React.useEffect(() => {
        if (foodSafetyStatisticsMultipleCsvData) {
            const file = new Blob([foodSafetyStatisticsMultipleCsvData.blob]);
            FileSaver.saveAs(file, foodSafetyStatisticsMultipleCsvData.fileName);
            setIntervalDateToDownloadMultipleCsv(null);
            queryClient.removeQueries(QueryKeys.foodSafetyStatisticsMultipleCsvData(), { exact: true });
        }
    }, [foodSafetyStatisticsMultipleCsvData, queryClient]);

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const minDate = foodSafetyStatisticsData ? DateTime.fromJSDate(new Date(foodSafetyStatisticsData.metadata.availableDates.from)) : false;
    const maxDate = foodSafetyStatisticsData ? DateTime.fromJSDate(new Date(foodSafetyStatisticsData.metadata.availableDates.to)) : false;

    const getStatisticsTableData = () => {
        if (dateFilterType === 'weeks') {
            return foodSafetyStatisticsData ? foodSafetyStatisticsData.data : [];
        }
        if (dateFilterType === 'months') {
            return foodSafetyStatisticsMonthData ? foodSafetyStatisticsMonthData.data : [];
        }
        if (dateFilterType === 'qs') {
            return foodSafetyStatisticsQuartersData ? foodSafetyStatisticsQuartersData.data : [];
        }
        return [];
    };

    return (
        <>
            {availableData && (
                <ContentFilter text={t('foodSafety.statistics.dataAvailableIntervalText', { from: availableData.from.toLocaleString(), to: availableData.to.toLocaleString() })}>
                    <CustomDatePickerWithArrows
                        availableData={availableData}
                        date={{
                            weekAndYear,
                            monthAndYear,
                            qsAndYear,
                        }}
                        dateFilterType={dateFilterType}
                        setDate={({ weekAndYearDate, monthAndYearDate, qsAndYearDate }) => {
                            if (weekAndYearDate?.week && weekAndYearDate.year && (weekAndYear?.week !== weekAndYearDate.week || weekAndYear?.year !== weekAndYearDate.year)) {
                                dispatch(foodSafetyStatisticsWeekAndYear(weekAndYearDate.week, weekAndYearDate.year));
                            }
                            if (monthAndYearDate?.month && monthAndYearDate?.year && (monthAndYear?.month !== monthAndYearDate.month || monthAndYear?.year !== monthAndYearDate.year)) {
                                dispatch(foodSafetyStatisticsMonthAndYear(monthAndYearDate.month, monthAndYearDate.year));
                            }
                            if (qsAndYearDate?.quarter && qsAndYearDate?.year && (qsAndYear?.quarter !== qsAndYearDate.quarter || qsAndYear?.year !== qsAndYearDate.year)) {
                                dispatch(foodSafetyStatisticsQsAndYear(qsAndYearDate.quarter, qsAndYearDate.year));
                            }
                        }}
                    />
                </ContentFilter>
            )}
            <Content>
                <LoadingBar isFetching={isFetching || foodSafetyStatisticsMonthIsFetching || foodSafetyStatisticsQuartersIsFetching} />
                <ContentHeader>
                    <ContentLegend>
                        <Status text={t('foodSafety.statistics.legend.over95')} type={StatusType.success} />
                        <Status text={t('foodSafety.statistics.legend.over80')} type={StatusType.warning} />
                        <Status text={t('foodSafety.statistics.legend.less80')} type={StatusType.error} />
                    </ContentLegend>
                    {dateFilterType === 'weeks' && (
                        <ExportButtons
                            buttons={[
                                { type: ExportButtonType.email, text: t('exportButtons.email'), action: () => history.push(routes.setupEmailReportConfig.path()), key: 'email' },
                                { type: ExportButtonType.csv, text: t('exportButtons.timespanCsv'), action: () => setIntervalPickerCsvOpen(true), key: 'timespanCsv' },
                                {
                                    type: ExportButtonType.excel,
                                    text: t('exportButtons.excel'),
                                    isLoading: foodSafetyStatisticsXlsIsLoading,
                                    action: () => foodSafetyStatisticsXlsRefetch(),
                                    key: 'excel',
                                },
                                { type: ExportButtonType.pdf, text: t('exportButtons.pdf'), isLoading: foodSafetyStatisticsPdfIsLoading, action: () => foodSafetyStatisticsPdfRefetch(), key: 'pdf' },
                            ]}
                            disabled={mutateIsError || foodSafetyStatisticsMonthMutateIsError || foodSafetyStatisticsQuartersMutateIsError}
                        />
                    )}
                    {dateFilterType === 'months' && (
                        <ExportButtons
                            buttons={[
                                { type: ExportButtonType.pdf, text: t('exportButtons.pdf'), isLoading: foodSafetyStatisticsMonthlyPdfIsLoading, action: () => foodSafetyStatisticsMonthlyPdfRefetch(), key: 'pdf' },
                                { type: ExportButtonType.email, text: t('exportButtons.email'), action: () => history.push(routes.setupEmailReportConfig.path()), key: 'email' },
                            ]}
                            disabled={mutateIsError}
                        />
                    )}
                    {dateFilterType === 'qs' && (
                        <ExportButtons
                            buttons={[
                                { type: ExportButtonType.pdf, text: t('exportButtons.pdf'), isLoading: foodSafetyStatisticsQuarterlyPdfIsLoading, action: () => foodSafetyStatisticsQuarterlyPdfRefetch(), key: 'pdf' },
                                { type: ExportButtonType.email, text: t('exportButtons.email'), action: () => history.push(routes.setupEmailReportConfig.path()), key: 'email' },
                            ]}
                            disabled={mutateIsError}
                        />
                    )}
                    {minDate && maxDate && (
                        <>
                            <IntervalPickerDialog
                                handleClose={() => setIntervalPickerCsvOpen(false)}
                                handleSubmit={(from: DateTime, to: DateTime) => {
                                    setIntervalDateToDownloadMultipleCsv({ from, to });
                                }}
                                maxDate={maxDate}
                                minDate={minDate}
                                open={intervalPickerCsvOpen}
                                submitIsLoading={foodSafetyStatisticsMultipleCsvDataIsLoading}
                                submitText={t('download')}
                            />
                        </>
                    )}
                </ContentHeader>
                <HorizontalSeparator />
                {isSmUp ? (
                    <StatisticsTable columns={columns} data={getStatisticsTableData()} isFetching={isFetching} refetch={foodSafetyStatisticsRefetch} onFilter={handleFilters} onSort={handleSort} />
                ) : (
                    <StatisticsMobileList data={getStatisticsTableData()} />
                )}
                {(assetsToShow.length > 0 || assetsToHide.length > 0) && (
                    <BottomBar>
                        <Box display="flex">
                            <Button isLoading={mutateIsLoading} isPrimary={true} size="medium" onClick={handleOnSubmit}>
                                {t('organizationStructure.bar.saveChanges')}
                            </Button>
                            <Box alignItems="center" display="flex" justifyContent="space-between" ml={1.5}>
                                <Button isPrimary={false} size="medium" onClick={handleDiscardChanges}>
                                    {t('organizationStructure.bar.discard')}
                                </Button>
                            </Box>
                        </Box>
                    </BottomBar>
                )}
            </Content>
        </>
    );
};

export default Statistics;
