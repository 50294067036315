import { Box, makeStyles, useTheme } from '@material-ui/core';
import ChartTooltip from './chartTooltip';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import LoaderDots from 'components/loaderDots/loaderDots';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    loaderContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: -30,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export type Item = {
    miliseconds: number;
    unit: 'minute' | 'percent';
    store?: number;
    overall?: number;
};

type Props = {
    isLoading: boolean;
    unit: 'minute' | 'percent';
    data: Item[];
};

const Chart: React.FC<Props> = ({ data: dataProp, unit, isLoading }) => {
    const height = 280;
    const classes = useStyles();
    const theme = useTheme();
    const primaryColor = theme.customPalette.alarmDashboard.primary;
    const secondaryColor = theme.customPalette.alarmDashboard.secondary;
    const borderColor = theme.customPalette.border;
    const unitLabel = unit === 'minute' ? 'm' : '%';

    const data = React.useMemo(() => {
        return dataProp.map((item, index) => {
            if (index === dataProp.length - 1) {
                return {
                    ...item,
                    store: undefined,
                    overall: undefined,
                    storeToday: item.store,
                    overallToday: item.overall,
                };
            }

            return item;
        });
    }, [dataProp]);

    const days = data.length;
    const showTooltip = days > 30 ? false : true;

    const getDotConfig = (isPrimary: boolean, days: number) => {
        if (days >= 30 && isPrimary === false) {
            return false;
        }

        if (days > 30 && isPrimary) {
            return false;
        }

        return {
            fill: isPrimary ? primaryColor : secondaryColor,
            strokeWidth: 0,
            r: 4,
        };
    };

    const getStrokeConfig = (days: number) => {
        if (days === 30) {
            return '2.5 2';
        }

        if (days > 30) {
            return '2 1';
        }
        return '5 3';
    };

    const getXaxisConfig = (days: number) => {
        if (data.length === 0) {
            return {};
        }

        const left = data[0].miliseconds;
        const right = data[data.length - 1].miliseconds;

        if (days >= 30) {
            return {
                type: 'number' as const,
                tickCount: 3,
                domain: [left, right],
            };
        }

        return {};
    };

    return (
        <Box className={classes.container}>
            <ResponsiveContainer width="100%" height={height}>
                <LineChart
                    width={500}
                    height={height}
                    data={data}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 10,
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <XAxis
                        dataKey="miliseconds"
                        tickLine={false}
                        style={{
                            fontSize: 14,
                        }}
                        padding={{ left: 17, right: 17 }}
                        axisLine={{ stroke: borderColor }}
                        {...getXaxisConfig(days)}
                        tickFormatter={(v) => {
                            if (typeof v !== 'number') {
                                return '';
                            }

                            return DateTime.fromMillis(v).toFormat('d.M.');
                        }}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickCount={8}
                        tickMargin={16}
                        style={{
                            fontSize: 12,
                        }}
                        unit={unitLabel}
                        allowDecimals={unit === 'percent' ? false : true}
                        tickFormatter={(value) => {
                            if (unit === 'minute' && value > 0 && value < 100) {
                                return parseFloat(value.toFixed(1));
                            }

                            return value;
                        }}
                    />

                    {!isLoading && (
                        <>
                            {showTooltip && <Tooltip cursor={{ stroke: borderColor }} content={<ChartTooltip />} />}
                            <Line
                                type="linear"
                                dataKey="overall"
                                stroke={secondaryColor}
                                strokeWidth={2}
                                dot={getDotConfig(false, days)}
                                strokeDasharray={getStrokeConfig(days)}
                                isAnimationActive={false}
                            />
                            <Line type="linear" dataKey="store" stroke={primaryColor} strokeWidth={2} dot={getDotConfig(true, days)} isAnimationActive={false} />

                            <Line type="linear" dataKey="overallToday" stroke={secondaryColor} strokeWidth={2} dot={{ strokeWidth: 2, r: 3 }} isAnimationActive={false} />
                            <Line type="linear" dataKey="storeToday" stroke={primaryColor} strokeWidth={2} dot={{ strokeWidth: 2, r: 3 }} isAnimationActive={false} />
                        </>
                    )}
                </LineChart>
            </ResponsiveContainer>
            {isLoading && (
                <Box className={classes.loaderContainer}>
                    <LoaderDots />
                </Box>
            )}
        </Box>
    );
};

export default Chart;
