import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { FilterProps } from 'react-table';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import TextField from 'components/textField/textField';

const useStyles = makeStyles((theme) => ({
    adornment: {
        '& svg': {
            color: theme.customPalette.colors.brand.light,
            width: theme.typography.pxToRem(12),
            height: theme.typography.pxToRem(12),
        },
    },
}));

const DefaultColumnFilter = <T extends Record<string, unknown>>({ column }: FilterProps<T>) => {
    const { id, filterValue, setFilter } = column;
    const [value, setValue] = React.useState(filterValue || '');
    const classes = useStyles();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const debouncedSetFilter = React.useMemo(
        () =>
            _debounce((value: string) => {
                setFilter(value || '');
            }, 200),
        [setFilter],
    );

    React.useEffect(() => {
        debouncedSetFilter(value);
        /*  with debouncedSetFilter in deps not working */ // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleClear = () => {
        setValue('');
    };

    return (
        <TextField
            autoComplete="off"
            clearable={Boolean(value)}
            endAdornment={
                Boolean(value) ? (
                    <InputAdornment className={classes.adornment} position="end">
                        <IconButton size="small" onClick={handleClear}>
                            <CancelIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }
            InputLabelProps={{ htmlFor: id }}
            name={id}
            size="small"
            value={value}
            onChange={handleChange}
        />
    );
};

export default DefaultColumnFilter;
