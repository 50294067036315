import * as z from 'zod';
import { StatusType } from 'components/status/statusType';

export const DaySchema = z
    .object({
        day: z.number().nullable(),
        night: z.number().nullable(),
    })
    .optional();

export const metadataSchema = z.object({
    availableDates: z.object({
        from: z.string(),
        to: z.string(),
    }),
});

export const FoodSafetyStatisticsItemSchema = z.object({
    monday: DaySchema,
    tuesday: DaySchema,
    wednesday: DaySchema,
    thursday: DaySchema,
    friday: DaySchema,
    saturday: DaySchema,
    sunday: DaySchema,
    shortName: z.string(),
    longName: z.string(),
    canAddress: z.number(),
    zone: z.string(),
    defrostHours: z.number(),
    deviation2kCount: z.number(),
    deviation2kPercent: z.number(),
    deviation4kCount: z.number(),
    deviation4kPercent: z.number(),
    status: z.nativeEnum(StatusType),
    id: z.number(),
    hidden: z.boolean(),
    position: z.number(),
    days: z.object({
        monday: DaySchema,
        tuesday: DaySchema,
        wednesday: DaySchema,
        thursday: DaySchema,
        friday: DaySchema,
        saturday: DaySchema,
        sunday: DaySchema,
    }),
});

export const FoodSafetyStatisticsSchema = z.object({
    data: z.array(FoodSafetyStatisticsItemSchema),
    metadata: metadataSchema,
});

export type FoodSafetyStatisticsItem = z.infer<typeof FoodSafetyStatisticsItemSchema>;

export type FoodSafetyStatistics = z.infer<typeof FoodSafetyStatisticsSchema>;
