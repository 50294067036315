import { CustomizedDotProps } from 'components/chart/assetChart/customizedDot/customizedDotProps';
import React from 'react';

const CustomizedDot: React.FC<CustomizedDotProps> = (props: CustomizedDotProps) => {
    const { cx, cy, value, fill } = props;
    if (!value) {
        return null;
    }
    return (
        <svg fill={fill} height={12} viewBox="0 0 4 12" width={4} x={cx - 2} y={cy - 6}>
            <rect height="12" width="4" />
        </svg>
    );
};

export default CustomizedDot;
