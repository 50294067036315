import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        margin: 0,
        color: theme.customPalette.text.secondary,
    },
}));

const FootNote: React.FC = ({ children }) => {
    const classes = useStyles();
    return <p className={classes.root}>{children}</p>;
};

export default FootNote;
