import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    container: {},
    dot: {
        width: 8,
        height: 8,
        borderRadius: 8,
        background: theme.customPalette.text.primary,
    },
    opacityHigh: {
        opacity: 1,
    },
    opacityMedium: {
        opacity: 0.6,
    },
    opacityLow: {
        opacity: 0.2,
    },
}));

const LoaderDots: React.FC = () => {
    const classes = useStyles();
    const [opacityIndex, setOpacityIndex] = React.useState(2);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setOpacityIndex((prev) => (prev + 1) % 3);
        }, 200);

        return () => clearInterval(interval);
    }, []);

    const opacities = [classes.opacityHigh, classes.opacityMedium, classes.opacityLow];

    return (
        <Box display={'flex'} gridGap={8}>
            <div className={clsx(classes.dot, opacities[(opacityIndex + 1) % 3])} />
            <div className={clsx(classes.dot, opacities[(opacityIndex + 2) % 3])} />
            <div className={clsx(classes.dot, opacities[opacityIndex % 3])} />
        </Box>
    );
};

export default LoaderDots;
