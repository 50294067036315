import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

const UserSchema = z.object({
    success: z.literal(true),
});

export interface PutUserParams {
    userId: number;
    firstName: string;
    lastName: string;
    username: string;
    emailAddress: string;
    role: string;
    languageId: number;
}

export const mutatePutUser = async (values: PutUserParams) => {
    const { userId, ...rest } = values;
    const payload = {
        ...rest,
        isMarketingConsent: true,
        timeZone: 'UTC',
    };
    const response = await makeMutation(ApiEndpoints.putUser(userId), payload, true, 'PUT');
    return UserSchema.parse(response);
};

export const putUserErrorCodes = {
    INVALID_CREDENTIALS: {
        code: 'USERNAME_ALREADY_EXISTS',
        translation: (t: TFunction) => t('putUser.USERNAME_ALREADY_EXISTS'),
    },
};
