import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ColumnInstance } from 'react-table';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'shared/luxon/luxon';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    clear: {
        marginLeft: theme.spacing(1),
        '& svg': {
            fill: theme.customPalette.colors.brand.light,
            width: theme.typography.pxToRem(12),
            height: theme.typography.pxToRem(12),
        },
    },
    input: {
        '& > div': {
            background: theme.customPalette.background.white,
        },
        '& input': {
            width: theme.typography.pxToRem(60),
            height: theme.spacing(3),
            padding: `0 ${theme.spacing(1)}`,
            fontSize: theme.typography.pxToRem(12),
        },
        '& button': {
            color: theme.customPalette.colors.brand.light,
            margin: theme.spacing(-2),
        },
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none',
        },
    },
}));

const DatepickerColumnFilter = <T extends Record<string, unknown>>({ column }: { column: ColumnInstance<T> }) => {
    const { filterValue, setFilter } = column;
    const classes = useStyles();
    const [value, setValue] = React.useState(filterValue || '');
    const { t } = useTranslation();

    React.useEffect(() => {
        setFilter(value);
        /*  with setFilter in deps not working */ // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleClear = () => {
        setValue('');
    };

    return (
        <Box display="flex">
            <DatePicker
                cancelLabel={t('datePicker.cancel')}
                className={classes.input}
                format={DateTimeFormat.DATE_FORMAT()}
                inputVariant="outlined"
                maxDate={DateTime.now().startOf('day')}
                okLabel={t('datePicker.ok')}
                value={filterValue || null}
                onChange={(date) => (date ? setValue(date) : undefined)}
            />
            {Boolean(value) && (
                <IconButton className={classes.clear} size="small" onClick={handleClear}>
                    <CancelIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default DatepickerColumnFilter;
