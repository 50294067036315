const FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER = 'FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER';

interface FoodSafetyStatisticsQSAndYearFilter {
    quarter: number;
    year: number;
}

export const setFoodSafetyStatisticsQSAndYearFilter = (filter: FoodSafetyStatisticsQSAndYearFilter) => {
    localStorage.setItem(FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER, JSON.stringify(filter));
};
export const hasFoodSafetyStatisticsQSAndYearFilter = () => Boolean(localStorage.getItem(FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER));
export const getFoodSafetyStatisticsQSAndYearFilter = () => {
    const value = localStorage.getItem(FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearFoodSafetyStatisticsQSAndYearFilter = () => {
    localStorage.removeItem(FOOD_SAFETY_STATISTICS_QS_AND_YEAR_FILTER);
};
