export const UNASSIGNED_ITEM_WIDTH = 58;
export const UNASSIGNED_ITEM_HEIGHT = 40;
export const UNASSIGNED_ITEM_GAP = 9;
export const UNPLACED_POSITIONS_TEXT_WIDTH = 100;
export const CORNER_RADIUS = 5;
export const TEXT_INSIDE_RECT_PADDING = 4;
export const STORE_PADDING = 16;
export const UI_DEBUG = false;

export const TOOLTIP_ARROW_WIDTH = 24;
export const TOOLTIP_ARROW_HEIGHT = 4;
