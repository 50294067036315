import { makeStyles } from '@material-ui/core';
import { UserStateProps } from 'components/userState/userStateProps';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        color: ({ isDisabled }: UserStateProps) => (isDisabled ? theme.customPalette.colors.warning : theme.customPalette.colors.success),
    },
}));

const UserState: React.FC<UserStateProps> = ({ isDisabled }) => {
    const { t } = useTranslation();
    const classes = useStyles({ isDisabled });
    return <div className={classes.root}>{isDisabled ? t('userState.disabled') : t('userState.enabled')}</div>;
};

export default UserState;
