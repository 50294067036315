import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React, { useEffect } from 'react';
import AlarmListView from 'screens/alarm/listView';
import AlarmTableView from 'screens/alarm/tableView';
import AlarmHeader from 'screens/alarm/alarmHeader';
import AlertHeader from 'screens/alarm/alertHeader';
import { QueryKeys } from 'api/queryKeys';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { getAlarmReportActiveCount } from 'api/fetchers/alarmReportActiveCount';
import { formatDateForAPI } from 'shared/network/helpers';
import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { alarmReportCount } from 'store/filters/actions';
import { DateTime } from 'luxon';

const AlarmScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { storeId } = useSelector((state: AppState) => state.store);
    const { dataRefreshed } = useSelector((state: AppState) => state.filters.alarmReport);
    const queryClient = useQueryClient();

    const items = [
        {
            id: 'listView',
            path: routes.alarmListView.path(),
            text: t('alarm.listView.title'),
        },
        {
            id: 'tableView',
            path: routes.alarmTableView.path(),
            text: t('alarm.tableView.title'),
        },
    ];

    const currentYear = DateTime.now().year;
    const firstDayOfYear = DateTime.fromObject({ year: currentYear, month: 1, day: 1 });
    const lastDayOfYear = DateTime.fromObject({ year: currentYear + 1, month: 1, day: 1 }).minus({ days: 1 });
    const { data: alarmReportData, refetch: refetchAlarmCount } = useHandledQuery(
        [QueryKeys.getAlarmReportCount(), firstDayOfYear, lastDayOfYear],
        () =>
            storeId
                ? getAlarmReportActiveCount({
                      storeId,
                      dateFrom: formatDateForAPI(firstDayOfYear),
                      dateTo: formatDateForAPI(lastDayOfYear),
                  })
                : undefined,
        { enabled: Boolean(storeId) },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    useEffect(() => {
        if (alarmReportData) {
            const countWithAlarm = alarmReportData.data.alarms.filter((item) => item.priority > 0 && !item.endOn).length;
            if (countWithAlarm) {
                dispatch(alarmReportCount(countWithAlarm));
            }
        }
        return () => {
            dispatch(alarmReportCount(0));
        };
    }, [alarmReportData]);

    const handleRefreshData = () => {
        refetchAlarmCount();
        queryClient.refetchQueries([QueryKeys.getAlarmReportListView()], { fetching: false, inactive: false });
    };

    return (
        <>
            <Header />
            <Navigation />
            <AlertHeader />
            <Main>
                <AlarmHeader dataRefreshed={dataRefreshed} isFetching={false} items={items} refetch={handleRefreshData} />
                <Switch>
                    <Route component={AlarmListView} exact path={routes.alarmListView.pattern} />
                    <Route component={AlarmTableView} exact path={routes.alarmTableView.pattern} />
                </Switch>
            </Main>
        </>
    );
};

export default AlarmScreen;
