export enum ReportingTableAccessors {
    coolingPosition = 'coolingPosition',
    case = 'case',
    position = 'position',
    setPoint = 'setPoint',
    setPointAlt = 'setPointAlt',
    avgTemp = 'avgTemp',
    longName = 'longName',
    shortName = 'shortName',
    zone = 'zone',
    hidden = 'hidden',
    buckets = 'buckets',
    drag = 'drag',
}
