import { Box, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { AlarmPriorities } from 'types/alarm/priorities';
import useSelectAlarmFilter from 'components/alarmFilter/useSelectAlarmFilter';
import { IAlarmFilter } from 'components/alarmFilter/type';

const AlarmFilter = ({ selectedPriority, onChange }: IAlarmFilter) => {
    const {
        control,
        watch,
        formState: { isDirty },
    } = useHandledForm();
    const { alarmFilterArray } = useSelectAlarmFilter();
    const priority = watch('alarmFilter');

    useEffect(() => {
        if (isDirty) {
            onChange(parseInt(priority));
        }
    }, [priority, isDirty]);

    return (
        <Box alignItems="center" display="flex">
            <div>
                <TextFieldRHF control={control} defaultValue={selectedPriority || AlarmPriorities.AllAlarmPriorities.toString()} name="alarmFilter" select size="small">
                    {alarmFilterArray.map(({ key, value }) => {
                        return (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        );
                    })}
                </TextFieldRHF>
            </div>
        </Box>
    );
};

export default AlarmFilter;
