import { MonthAndYear, QSAndYear, WeekAndYear } from 'components/CustomDatePickerWithArrows/CustomDatePickerWithArrowsProps';
import { Filters, SortingRule } from 'react-table';
import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';
import { DateTime } from 'shared/luxon/luxon';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { EnergyMeterMetricType } from 'types/energyMeter/energyMeterMetricType';
import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';

export const SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE = 'SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE';
export const SET_FOOD_SAFETY_REPORTING_DATE = 'SET_FOOD_SAFETY_REPORTING_DATE';
export const SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR = 'SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR';
export const SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR = 'SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR';
export const SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR = 'SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR';
export const SET_ENERGY_CONSUMPTION_FILTER = 'SET_ENERGY_CONSUMPTION_FILTER';
export const SET_ENERGY_POWER_FILTER = 'SET_ENERGY_POWER_FILTER';
export const SET_ALARM_DASHBOARD_FILTER = 'SET_ALARM_DASHBOARD_FILTER';
export const SET_ALL_FILTERS_RESET = 'SET_ALL_FILTERS_RESET';
export const SET_ENERGY_CONSUMPTION_AVAILABLE_DATES = 'SET_ENERGY_CONSUMPTION_AVAILABLE_DATES';
export const SET_ENERGY_POWER_AVAILABLE_DATES = 'SET_ENERGY_POWER_AVAILABLE_DATES';
export const SET_ALARM_DURATION = 'SET_ALARM_DURATION';
export const SET_ALARM_PRIORITY_LIST_VIEW = 'SET_ALARM_PRIORITY_LIST_VIEW';
export const SET_ALARM_PRIORITY_TABLE_VIEW = 'SET_ALARM_PRIORITY_TABLE_VIEW';
export const SET_ALARM_DATE_FROM = 'SET_ALARM_DATE_FROM';
export const SET_ALARM_DATE_TO = 'SET_ALARM_DATE_TO';
export const SET_ALARM_COUNT = 'SET_ALARM_COUNT';
export const SET_ALARM_DATA_REFRESHED = 'SET_ALARM_DATA_REFRESHED';

export const SET_ALARM_FILTER_LIST_VIEW = 'SET_ALARM_FILTER_LIST_VIEW';
export const SET_ALARM_FILTER_TABLE_VIEW = 'SET_ALARM_FILTER_TABLE_VIEW';
export const SET_ALARM_SORTING_RULE_LIST_VIEW = 'SET_ALARM_SORTING_RULE_LIST_VIEW';
export const SET_ALARM_SORTING_RULE_TABLE_VIEW = 'SET_ALARM_SORTING_RULE_TABLE_VIEW';

export interface FiltersState {
    alarmReport: {
        duration: number;
        dateFrom: DateTime;
        dateTo: DateTime;
        alarmCount: number;
        dataRefreshed: string | null;
        listView: {
            filters?: Filters<{}>;
            sortingRule?: SortingRule<string>;
            priority?: number;
        };
        tableView: {
            filters?: Filters<{}>;
            sortingRule?: SortingRule<string>;
            priority?: number;
        };
    };
    foodSafetyReporting: {
        date: DateTime;
    };
    foodSafetyStatistics: {
        dateFilterType: keyof typeof FoodSafetySegments;
        weekAndYear?: WeekAndYear;
        monthAndYear?: MonthAndYear;
        qsAndYear?: QSAndYear;
    };
    energyConsumption: {
        dateFrom?: DateTime;
        dateTo?: DateTime;
        availableDateFrom?: DateTime;
        availableDateTo?: DateTime;
        energyMetricType: EnergyMeterMetricType;
        dateIntervalType: EnergyMeterDateInterval;
    };
    energyPower: {
        dateFrom?: DateTime;
        dateTo?: DateTime;
        availableDateFrom?: DateTime;
        availableDateTo?: DateTime;
        energyMetricType: EnergyMeterMetricType;
    };
    alarmDashboard: {
        dataSource: DashboardDataSource;
        period: DashboardPeriod;
        priority: DashboardAlarmPriority;
        alarmType: string | undefined;
        searchPhrase: string;
        sortDirection: 'asc' | 'desc';
    };
}

export interface SetFoodSafetyReportingDateActionTypes {
    type: typeof SET_FOOD_SAFETY_REPORTING_DATE;
    date: DateTime;
}

export interface SetFoodSafetyStatisticDateFilterTypeActionTypes {
    type: typeof SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE;
    dateFilterType: keyof typeof FoodSafetySegments;
}

export interface SetFoodSafetyStatisticsWeekAndYearActionTypes {
    type: typeof SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR;
    weekAndYear: WeekAndYear;
}

export interface SetFoodSafetyStatisticsMonthAndYearActionTypes {
    type: typeof SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR;
    monthAndYear: MonthAndYear;
}

export interface SetFoodSafetyStatisticsQSAndYearActionTypes {
    type: typeof SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR;
    qsAndYear: QSAndYear;
}

export interface SetEnergyConsumptionFilterActionTypes {
    type: typeof SET_ENERGY_CONSUMPTION_FILTER;
    dateFrom: DateTime;
    dateTo: DateTime;
    energyMetricType: EnergyMeterMetricType;
    dateIntervalType: EnergyMeterDateInterval;
}

export interface SetEnergyConsumptionAvailableDatesActionTypes {
    type: typeof SET_ENERGY_CONSUMPTION_AVAILABLE_DATES;
    availableDateFrom: DateTime;
    availableDateTo: DateTime;
}

export interface SetEnergyPowerFilterActionTypes {
    type: typeof SET_ENERGY_POWER_FILTER;
    dateFrom: DateTime;
    dateTo: DateTime;
    energyMetricType: EnergyMeterMetricType;
}

export interface SetEnergyPowerAvailableDatesActionTypes {
    type: typeof SET_ENERGY_POWER_AVAILABLE_DATES;
    availableDateFrom: DateTime;
    availableDateTo: DateTime;
}

export interface SetAlarmDashboardFilterActionTypes {
    type: typeof SET_ALARM_DASHBOARD_FILTER;
    dataSource: DashboardDataSource;
    period: DashboardPeriod;
    priority: DashboardAlarmPriority;
    alarmType: string | undefined;
    searchPhrase: string;
    sortDirection: 'asc' | 'desc';
}

export interface SetAllFiltersResetActionTypes {
    type: typeof SET_ALL_FILTERS_RESET;
}

export interface SetAlarmDurationActionTypes {
    type: typeof SET_ALARM_DURATION;
    duration: number;
}

export interface SetAlarmPriorityListViewActionTypes {
    type: typeof SET_ALARM_PRIORITY_LIST_VIEW;
    priority: number;
}

export interface SetAlarmPriorityTableViewActionTypes {
    type: typeof SET_ALARM_PRIORITY_TABLE_VIEW;
    priority: number;
}

export interface SetAlarmDateFromActionTypes {
    type: typeof SET_ALARM_DATE_FROM;
    dateFrom: DateTime;
}

export interface SetAlarmDateToActionTypes {
    type: typeof SET_ALARM_DATE_TO;
    dateTo: DateTime;
}

export interface SetAlarmCountActionTypes {
    type: typeof SET_ALARM_COUNT;
    alarmCount: number;
}

export interface SetAlarmDataRefreshed {
    type: typeof SET_ALARM_DATA_REFRESHED;
    dataRefreshed: string;
}

export interface SetAlarmFilterListView {
    type: typeof SET_ALARM_FILTER_LIST_VIEW;
    filters?: Filters<{}>;
}

export interface SetAlarmFilterTableView {
    type: typeof SET_ALARM_FILTER_TABLE_VIEW;
    filters?: Filters<{}>;
}

export type FiltersActionTypes =
    | SetFoodSafetyReportingDateActionTypes
    | SetFoodSafetyStatisticsWeekAndYearActionTypes
    | SetFoodSafetyStatisticsMonthAndYearActionTypes
    | SetFoodSafetyStatisticsQSAndYearActionTypes
    | SetFoodSafetyStatisticDateFilterTypeActionTypes
    | SetEnergyConsumptionFilterActionTypes
    | SetEnergyConsumptionAvailableDatesActionTypes
    | SetEnergyPowerFilterActionTypes
    | SetAlarmDashboardFilterActionTypes
    | SetEnergyPowerAvailableDatesActionTypes
    | SetAllFiltersResetActionTypes
    | SetAlarmDurationActionTypes
    | SetAlarmPriorityListViewActionTypes
    | SetAlarmPriorityTableViewActionTypes
    | SetAlarmDateFromActionTypes
    | SetAlarmDateToActionTypes
    | SetAlarmCountActionTypes
    | SetAlarmDataRefreshed
    | SetAlarmFilterListView
    | SetAlarmFilterTableView;
