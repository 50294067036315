import { EnergyMeterMetricType } from '../../types/energyMeter/energyMeterMetricType';
import { EnergyMeterDateInterval } from '../../types/energyMeter/energyMeterDateInterval';
import { useTranslation } from 'react-i18next';

const useSelectTypes = () => {
    const { t } = useTranslation();
    const metricTypesArray = [
        {
            key: EnergyMeterMetricType.consumptionElectricity,
            value: t('energyConsumptionPicker.metricType.electricity'),
        },
        {
            key: EnergyMeterMetricType.consumptionHeating,
            value: t('energyConsumptionPicker.metricType.heating'),
        },
        {
            key: EnergyMeterMetricType.consumptionWater,
            value: t('energyConsumptionPicker.metricType.water'),
        },
    ];

    const intervalTypesArray = [
        {
            key: EnergyMeterDateInterval.day,
            value: t('energyConsumptionPicker.intervalType.day'),
        },
        {
            key: EnergyMeterDateInterval.week,
            value: t('energyConsumptionPicker.intervalType.week'),
        },
        {
            key: EnergyMeterDateInterval.month,
            value: t('energyConsumptionPicker.intervalType.month'),
        },
        {
            key: EnergyMeterDateInterval.quarter,
            value: t('energyConsumptionPicker.intervalType.quarter'),
        },
        {
            key: EnergyMeterDateInterval.year,
            value: t('energyConsumptionPicker.intervalType.year'),
        },
    ];

    return {
        metricTypesArray,
        intervalTypesArray,
    };
};

export default useSelectTypes;
