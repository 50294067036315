import { useTranslation } from 'react-i18next';
import { AlarmPriorities } from 'types/alarm/priorities';

const useSelectAlarmFilter = () => {
    const { t } = useTranslation();
    const alarmFilterArray = [
        {
            key: AlarmPriorities.AllAlarmPriorities,
            value: t('alarm.filter.allAlarmProperties'),
        },
        {
            key: AlarmPriorities.Prio1,
            value: t('alarm.filter.prio1'),
        },
        {
            key: AlarmPriorities.Prio2,
            value: t('alarm.filter.prio2'),
        },
    ];

    return { alarmFilterArray };
};

export default useSelectAlarmFilter;
