import { ConfirmationDialogProps } from './confirmationDialogProps';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paragraph from 'components/typography/paragraph/paragraph';
import React from 'react';

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, submitIsLoading, question, handleSubmit, handleClose, submitText }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t('confirmationDialog.title')}</DialogTitle>
            <DialogContent>
                <Paragraph>{question}</Paragraph>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('confirmationDialog.cancel')}</Button>
                <Button id="test-confirmation-submit" isLoading={submitIsLoading} isPrimary onClick={handleSubmit}>
                    {submitText ? submitText : t('confirmationDialog.submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
