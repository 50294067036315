import * as z from 'zod';
import { Box } from '@material-ui/core';
import { Capabilities } from 'shared/useCapabilities/capabilities';
import { fetchMyProfile } from 'api/fetchers/myProfile';
import { handleSimpleSpecificEndpointErrorsToForm } from 'shared/errors/handleStandardApiErrors';
import { mutatePutMyProfile, putMyProfileErrorCodes, PutMyProfileParams } from 'api/mutations/putMyProfile';
import { postUserErrorCodes } from 'api/mutations/postUser';
import { QueryKeys } from 'api/queryKeys';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validateNameInput } from 'shared/useHandledForm/rules';
import Button from 'components/button/button';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import useCapabilities from 'shared/useCapabilities/useCapabilities';

import { useQueryClient } from 'react-query';
import MenuItem from '@material-ui/core/MenuItem';
import useLanguages from 'shared/useLanguages/useLanguages';
import useRoles from 'shared/useRoles/useRoles';

const formValuesSchema = z.object({
    firstName: z.string().min(1).max(64).regex(validateNameInput),
    lastName: z.string().min(1).max(64).regex(validateNameInput),
    username: z.string().min(1).max(64),
    emailAddress: z.string().email(),
    role: z.string().nonempty(),
    languageId: z.number(),
});

const MyProfileForm: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { isAuthorized } = useCapabilities();
    const queryClient = useQueryClient();
    const { data, refetch, isSuccess: fetchIsSuccess } = useHandledQuery(QueryKeys.myProfile(), fetchMyProfile);
    const { languages } = useLanguages();

    const { mutate, isSuccess: mutateIsSuccess, isError: mutateIsError, error: mutateError, isLoading: mutateIsLoading } = useHandledMutation(mutatePutMyProfile, undefined, putMyProfileErrorCodes);

    const {
        handleSubmit,
        reset,
        control,
        formState: { isDirty },
        getValues,
        setError,
    } = useHandledForm(formValuesSchema);

    React.useEffect(() => {
        if (fetchIsSuccess) {
            reset(data);
        }
    }, [data, reset, fetchIsSuccess]);

    const discardChanges = () => {
        reset(data);
    };

    React.useEffect(() => {
        if (mutateIsError) {
            handleSimpleSpecificEndpointErrorsToForm(mutateError, enqueueSnackbar, t, postUserErrorCodes, Object.keys(getValues()), setError);
        }
    }, [mutateIsSuccess, mutateIsError, refetch, enqueueSnackbar, t, mutateError, getValues, setError]);

    const onSubmit = async (values: PutMyProfileParams) => {
        await mutate(values, {
            onSuccess: () => {
                if (values) {
                    queryClient.refetchQueries([QueryKeys.myProfile(), true]);
                    enqueueSnackbar(t('userDetail.form.successSubmit'));
                    refetch();
                }
            },
        });
    };

    const roles = useRoles();

    if (!data) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldRHF control={control} label={t('userDetail.form.firstName.label')} name="firstName" />
            <Box mb={2} />
            <TextFieldRHF control={control} id="test-lastname" label={t('userDetail.form.lastName.label')} name="lastName" />
            <Box mb={2} />
            <TextFieldRHF control={control} disabled={!isAuthorized(Capabilities.UPDATE_USER)} label={t('userDetail.form.username.label')} name="username" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.emailAddress.label')} name="emailAddress" />
            <Box mb={2} />
            <TextFieldRHF control={control} disabled label={t('userDetail.form.role.label')} name="role" select>
                {roles.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('userDetail.form.language.label')} name="languageId" select>
                {languages.map(({ languageId, label }) => (
                    <MenuItem key={languageId} value={languageId}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldRHF>
            <Box mb={3} />
            <Box alignItems="center" display="flex" mt={2}>
                <Button id="test-submit" isLoading={mutateIsLoading} isPrimary size="large" type="submit">
                    {t('saveChanges')}
                </Button>
                {isDirty && (
                    <Box ml={2}>
                        <Button size="large" onClick={discardChanges}>
                            {t('discard')}
                        </Button>
                    </Box>
                )}
            </Box>
        </form>
    );
};

export default MyProfileForm;
