import palette from 'theme/palette';

const chartColors = Object.values({
    0: palette.colors.brand.light,
    1: 'rgba(242, 24, 246, 1)',
    2: palette.colors.success,
    3: palette.colors.warning,
    4: palette.colors.error,
    5: palette.colors.brand.dark,
    6: palette.text.primary,
    7: palette.text.secondary,
    8: '#6200ea',
    9: '#827717',
    10: '#ffd600',
    11: '#795548',
    12: '#c6ff00',
    13: '#00b8d4',
    14: '#FF7043',
    15: '#FF4081',
});

export default chartColors;
