import { DayTemperatureProps } from 'components/statisticsMobileListItem/parts/dayTemperature/DayTemperatureProps';
import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { makeStyles } from '@material-ui/core/styles';
import LightSeparator from 'components/table/components/lightSeparator/lightSeparator';
import React from 'react';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

const useStyles = makeStyles((theme) => ({
    value: {
        color: theme.customPalette.text.primary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
    },
    label: {
        color: theme.customPalette.text.secondary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(8),
    },
}));

const DayTemperature: React.FC<DayTemperatureProps> = ({ day }) => {
    const classes = useStyles();
    return (
        <td>
            <span className={classes.value}>
                <ValueDisplay value={(day && day.day) || null} />
            </span>
            <LightSeparator type={LightSeparatorType.horizontal} />
            <span className={classes.value}>
                <ValueDisplay value={(day && day.night) || null} />
            </span>
        </td>
    );
};

export default DayTemperature;
