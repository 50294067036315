import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { mutatePatchAssetPosition, patchAssetPositionErrorCodes } from 'api/mutations/patchAssetPosition';
import { QueryKeys } from 'api/queryKeys';
import { StatisticsTableAccessors } from 'types/statistics/statisticsTableAccessors';
import { TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useStyles } from 'components/table/stylesTable';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DayCellTablet from 'components/table/components/dayCellTablet/dayCellTablet';
import DayHeadingTablet from 'components/table/components/dayHeadingTablet/dayHeadingTablet';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import MovableRowComponent from 'components/table/components/movableRowComponent/movableRowComponent';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import update from 'immutability-helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    refetch: () => void;
}

const StatisticsTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching, onFilter, refetch }: React.PropsWithChildren<TableProperties<T>>) => {
    const [tableRecords, setTableRecords] = React.useState<T[]>([]);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const { t } = useTranslation();
    const { dateFilterType } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);

    const {
        mutate: patchAssetPosition,
        isSuccess: patchAssetPositionIsSuccess,
        isError: patchAssetPositionIsError,
    } = useHandledMutation(mutatePatchAssetPosition, undefined, patchAssetPositionErrorCodes);

    React.useEffect(() => {
        if (patchAssetPositionIsSuccess) {
            queryClient.removeQueries([QueryKeys.foodSafetyReport()]);
            enqueueSnackbar(t('patchAssetPosition.success'));
            refetch();
        }
    }, [patchAssetPositionIsSuccess, enqueueSnackbar, t, queryClient, refetch]);

    React.useEffect(() => {
        if (patchAssetPositionIsError) {
            queryClient.refetchQueries([QueryKeys.foodSafetyReport()]);
            setTableRecords(data);
        }
    }, [patchAssetPositionIsError, data, queryClient]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters },
        prepareRow,
        setHiddenColumns,
        visibleColumns,
    } = useTable<T>(
        {
            columns,
            data: tableRecords,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            initialState: {
                filters: [{ id: 'hidden', value: '0' }],
            },
            manualSortBy: true,
            manualFilters: true,
            getRowId,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        if (data) {
            setTableRecords(data);
        }
    }, [data]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    React.useEffect(() => {
        if (isXsDown) {
            setHiddenColumns([
                // Weeks
                StatisticsTableAccessors.sunday,
                StatisticsTableAccessors.monday,
                StatisticsTableAccessors.tuesday,
                StatisticsTableAccessors.wednesday,
                StatisticsTableAccessors.thursday,
                StatisticsTableAccessors.friday,
                StatisticsTableAccessors.saturday,

                // Month
                StatisticsTableAccessors.monthGoodStatus,
                StatisticsTableAccessors.monthWarningStatus,
                StatisticsTableAccessors.monthErrorStatus,

                // Quarter
                StatisticsTableAccessors.firstMonthOfQuarter,
                StatisticsTableAccessors.secondMonthOfQuarter,
                StatisticsTableAccessors.thirdMonthOfQuarter,
            ]);
        } else {
            if (dateFilterType === FoodSafetySegments.weeks) {
                setHiddenColumns([
                    // Month
                    StatisticsTableAccessors.monthGoodStatus,
                    StatisticsTableAccessors.monthWarningStatus,
                    StatisticsTableAccessors.monthErrorStatus,

                    // Quarter
                    StatisticsTableAccessors.firstMonthOfQuarter,
                    StatisticsTableAccessors.secondMonthOfQuarter,
                    StatisticsTableAccessors.thirdMonthOfQuarter,
                ]);
                if (isMdDown) {
                    setHiddenColumns([
                        // Weeks
                        StatisticsTableAccessors.sunday,
                        StatisticsTableAccessors.monday,
                        StatisticsTableAccessors.tuesday,
                        StatisticsTableAccessors.wednesday,
                        StatisticsTableAccessors.thursday,
                        StatisticsTableAccessors.friday,
                        StatisticsTableAccessors.saturday,

                        // Month
                        StatisticsTableAccessors.monthGoodStatus,
                        StatisticsTableAccessors.monthWarningStatus,
                        StatisticsTableAccessors.monthErrorStatus,

                        // Quarter
                        StatisticsTableAccessors.firstMonthOfQuarter,
                        StatisticsTableAccessors.secondMonthOfQuarter,
                        StatisticsTableAccessors.thirdMonthOfQuarter,
                    ]);
                }
            }
            if (dateFilterType === FoodSafetySegments.months) {
                setHiddenColumns([
                    // Weeks
                    StatisticsTableAccessors.sunday,
                    StatisticsTableAccessors.monday,
                    StatisticsTableAccessors.tuesday,
                    StatisticsTableAccessors.wednesday,
                    StatisticsTableAccessors.thursday,
                    StatisticsTableAccessors.friday,
                    StatisticsTableAccessors.saturday,

                    // Quarter
                    StatisticsTableAccessors.firstMonthOfQuarter,
                    StatisticsTableAccessors.secondMonthOfQuarter,
                    StatisticsTableAccessors.thirdMonthOfQuarter,
                ]);
            }
            if (dateFilterType === FoodSafetySegments.qs) {
                setHiddenColumns([
                    // Weeks
                    StatisticsTableAccessors.sunday,
                    StatisticsTableAccessors.monday,
                    StatisticsTableAccessors.tuesday,
                    StatisticsTableAccessors.wednesday,
                    StatisticsTableAccessors.thursday,
                    StatisticsTableAccessors.friday,
                    StatisticsTableAccessors.saturday,

                    // Months
                    StatisticsTableAccessors.monthGoodStatus,
                    StatisticsTableAccessors.monthWarningStatus,
                    StatisticsTableAccessors.monthErrorStatus,
                ]);
            }
        }
    }, [dateFilterType, columns, setHiddenColumns, isMdDown, isSmDown]);

    const classes = useStyles();
    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const dragRecord = tableRecords[dragIndex];
        setTableRecords(
            update(tableRecords, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            }),
        );
        if (dragRecord && typeof dragRecord.id === 'number') {
            patchAssetPosition({ assetId: dragRecord.id, position: tableRecords[hoverIndex].position as number });
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} movable />
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td colSpan={headerGroups[0].headers.length}>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <MovableRowComponent<T> index={index} moveRow={moveRow} row={row} {...row.getRowProps()}>
                                {!isLgUp && dateFilterType === 'weeks' && (
                                    <tr>
                                        <td colSpan={visibleColumns.length - 1}>
                                            <table className={classes.weekTable}>
                                                <thead>
                                                    <tr>
                                                        <DayHeadingTablet text={t('days.sunday.long')} />
                                                        <DayHeadingTablet text={t('days.monday.long')} />
                                                        <DayHeadingTablet text={t('days.tuesday.long')} />
                                                        <DayHeadingTablet text={t('days.wednesday.long')} />
                                                        <DayHeadingTablet text={t('days.thursday.long')} />
                                                        <DayHeadingTablet text={t('days.friday.long')} />
                                                        <DayHeadingTablet text={t('days.saturday.long')} />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <DayCellTablet data={row.original.sunday} />
                                                        <DayCellTablet data={row.original.monday} />
                                                        <DayCellTablet data={row.original.tuesday} />
                                                        <DayCellTablet data={row.original.wednesday} />
                                                        <DayCellTablet data={row.original.thursday} />
                                                        <DayCellTablet data={row.original.friday} />
                                                        <DayCellTablet data={row.original.saturday} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                            </MovableRowComponent>
                        );
                    })}
                </tbody>
            </table>
        </DndProvider>
    );
};

export default StatisticsTable;
