import { Box, useTheme } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import ContentTabs from 'components/contentTabs/contentTabs';
import FoodSafetyReporting from './foodSafety/reporting';
import FoodSafetyStatistics from './foodSafety/statistics';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import Paragraph from 'components/typography/paragraph/paragraph';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SegmentProps } from 'components/segmentedControl/segmentedControlProps';
import SegmentedControl from 'components/segmentedControl';
import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';
import { useDispatch, useSelector } from 'react-redux';
import { foodSafetyStatisticsDateFilterType } from 'store/filters/actions';
import { AppState } from 'store';

const FoodSafetyScreen: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { dateFilterType } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);

    const handleOnChange = (value: string) => {
        dispatch(foodSafetyStatisticsDateFilterType(value as keyof typeof FoodSafetySegments));
    };

    const items = [
        {
            id: 'foodSafetyReporting',
            path: routes.foodSafetyReporting.path(),
            text: t('foodSafety.reporting.title'),
        },
        {
            id: 'foodSafetyStatistics',
            path: routes.foodSafetyStatistics.path(),
            text: t('foodSafety.statistics.title'),
        },
    ];

    const segments: SegmentProps[] = [
        {
            label: t('foodSafety.segments.weeks'),
            value: FoodSafetySegments.weeks,
        },
        {
            label: t('foodSafety.segments.months'),
            value: FoodSafetySegments.months,
        },
        {
            label: t('foodSafety.segments.qs'),
            value: FoodSafetySegments.qs,
        },
    ];

    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <ContentTabs
                    items={items}
                    rightAccessory={
                        pathname === routes.foodSafetyStatistics.path() ? <SegmentedControl name="Testing" segments={segments} value={dateFilterType} onChange={handleOnChange} /> : undefined
                    }
                />
                <Switch>
                    <Route component={FoodSafetyStatistics} exact path={routes.foodSafetyStatistics.pattern} />
                    <Route component={FoodSafetyReporting} exact path={routes.foodSafetyReporting.pattern} />
                </Switch>
            </Main>
            <Box pb={3} pl={5} pr={5} pt={isSmDown ? 3 : 0}>
                <Paragraph>{t('foodSafety.footerInfo')}</Paragraph>
            </Box>
        </>
    );
};

export default FoodSafetyScreen;
