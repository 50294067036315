import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Heading from 'components/statisticsMobileListItem/parts/heading/heading';
import React from 'react';
import Status from 'components/status/status';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import ValueWithPercent from 'components/table/components/valueWithPercent/valueWithPercent';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import useMonths from '../../shared/useMonths/useMonths';
import { StatisticsMobileListMonthlyItemProps } from './statisticsMobileListMonthlyItemProps';
import { StatusType } from '../status/statusType';
import { formatMinutes } from '../../shared/utils/formatMinutes';
import { getLanguageShortCode } from '../../localStorage/language';
import { Info, Settings } from 'luxon';
import ProgressBar from '../progressBar/progressBar';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottomColor: theme.customPalette.border,
        borderBottomWidth: theme.typography.pxToRem(1),
        borderBottomStyle: 'solid',
    },
    value: {
        color: theme.customPalette.text.primary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
    },
    label: {
        color: theme.customPalette.text.secondary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(8),
    },
    weekTable: {
        borderCollapse: 'collapse',
        lineHeight: 0.8,
        '& th': {
            width: 'calc(100% / 7)',
            color: theme.customPalette.text.secondary,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(10),
            paddingBottom: theme.spacing(1),
        },
        '& td': {
            textAlign: 'center',
            padding: `0 ${theme.spacing(0.5)}`,
        },
    },
}));

const StatisticsMobileListMonthlyItem: React.FC<StatisticsMobileListMonthlyItemProps> = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { id, longName, zone, shortName, status, defrostHours, deviation2kCount, deviation4kCount, deviation2kPercentage, deviation4kPercentage } = data;

    const months = useMonths();
    const { dateFilterType, qsAndYear, monthAndYear } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);
    const currentMonthAndYear = monthAndYear ? `${months[monthAndYear.month - 1]} ${monthAndYear.year}` : '';

    // @ts-ignore
    const currentMonthDeviation0kPercentage = data[monthAndYear.month] && monthAndYear && data[monthAndYear.month].deviation0kPercentage;
    // @ts-ignore
    const currentMonthDeviation2kMinutes = data[monthAndYear.month] && monthAndYear && data[monthAndYear.month].deviation2kMinutes;
    // @ts-ignore
    const currentMonthDeviation4kMinutes = data[monthAndYear.month] && monthAndYear && data[monthAndYear.month].deviation4kMinutes;

    const getMonthNameInQuarterOverview = (count: number, quarter?: number) => {
        if (!quarter) {
            return '';
        }
        const currentLang = getLanguageShortCode();
        Settings.defaultLocale = currentLang || 'en';
        return Info.months()[(quarter - 1) * 3 + count - 1];
    };

    const getValueInQuarterOverview = (count: number, quarter?: number) => {
        if (!quarter) {
            return '';
        }
        const monthNumber = (quarter - 1) * 3 + count;
        // @ts-ignore
        const currentMonthInData = data[monthNumber];
        if (currentMonthInData) {
            return (
                <ProgressBar badPercent={currentMonthInData.deviation2kPercentage} goodPercent={currentMonthInData.deviation0kPercentage} veryBadPercent={currentMonthInData.deviation4kPercentage} />
            );
        }
        return '-';
    };

    return (
        <InView triggerOnce={false}>
            {({ ref, inView }) => (
                // @ts-ignore
                <Box className={classes.root} p={2} ref={ref}>
                    {inView ? (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div className={classes.label}>{t('foodSafety.statistics.table.heading.coolingPosition')}</div>
                                <div className={classes.value}>
                                    <Link to={routes.asset.path(id)}>{longName}</Link>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    <Heading label={t('foodSafety.statistics.table.heading.case')}>
                                        <ValueDisplay value={zone} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.position')}>
                                        <ValueDisplay value={shortName} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.status')}>
                                        <Status type={status} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.defrostHrs')}>
                                        <ValueDisplay value={defrostHours} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.between2kAnd4k')}>
                                        <ValueWithPercent count={deviation2kCount} percent={deviation2kPercentage} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.greaterThan4k')}>
                                        <ValueWithPercent count={deviation4kCount} percent={deviation4kPercentage} />
                                    </Heading>
                                    {dateFilterType === 'months' && monthAndYear && (
                                        <>
                                            <Heading label={currentMonthAndYear}>
                                                <Status opacity={!currentMonthDeviation0kPercentage ? 0.5 : 1} text={Math.trunc(currentMonthDeviation0kPercentage) + ' %'} type={StatusType.success} />
                                            </Heading>
                                            <Heading label={currentMonthAndYear}>
                                                <Status opacity={!currentMonthDeviation2kMinutes ? 0.5 : 1} text={formatMinutes(currentMonthDeviation2kMinutes)} type={StatusType.warning} />
                                            </Heading>
                                            <Heading label={currentMonthAndYear}>
                                                <Status opacity={!currentMonthDeviation4kMinutes ? 0.5 : 1} text={formatMinutes(currentMonthDeviation4kMinutes)} type={StatusType.error} />
                                            </Heading>
                                        </>
                                    )}
                                    {dateFilterType === 'qs' && qsAndYear && (
                                        <>
                                            <Heading label={getMonthNameInQuarterOverview(1, qsAndYear.quarter)}>{getValueInQuarterOverview(1, qsAndYear.quarter)}</Heading>
                                            <Heading label={getMonthNameInQuarterOverview(2, qsAndYear.quarter)}>{getValueInQuarterOverview(2, qsAndYear.quarter)}</Heading>
                                            <Heading label={getMonthNameInQuarterOverview(3, qsAndYear.quarter)}>{getValueInQuarterOverview(3, qsAndYear.quarter)}</Heading>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div style={{ height: 184 }}></div>
                    )}
                </Box>
            )}
        </InView>
    );
};

export default StatisticsMobileListMonthlyItem;
