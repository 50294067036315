import { AvatarProps } from './avatarProps';
import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(1)} 0`,
        margin: `${theme.spacing(-1)} 0`,
    },
    circle: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: theme.typography.pxToRem(11),
        color: theme.customPalette.background.white,
        textTransform: 'uppercase',
        fontWeight: 600,
    },
}));

const getInitialsByFullName = ({ firstName, lastName }: AvatarProps): string | undefined => {
    if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }
};

const getAvatarBackgroundByFullName = ({ firstName, lastName }: AvatarProps) => {
    // inspired by https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    const input = `${lastName}${firstName}`;
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();
    return `#${'00000'.substring(0, 6 - c.length) + c}`;
};

const Avatar: React.FC<AvatarProps> = ({ firstName, lastName }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <MuiAvatar
                className={classes.circle}
                style={{
                    background: getAvatarBackgroundByFullName({ firstName, lastName }),
                }}
            >
                {getInitialsByFullName({ firstName, lastName })}
            </MuiAvatar>
        </div>
    );
};

export default Avatar;
