const FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER = 'FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER';

interface FoodSafetyStatisticsMonthAndYearFilter {
    month: number;
    year: number;
}

export const setFoodSafetyStatisticsMonthAndYearFilter = (filter: FoodSafetyStatisticsMonthAndYearFilter) => {
    localStorage.setItem(FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER, JSON.stringify(filter));
};
export const hasFoodSafetyStatisticsMonthAndYearFilter = () => Boolean(localStorage.getItem(FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER));
export const getFoodSafetyStatisticsMonthAndYearFilter = () => {
    const value = localStorage.getItem(FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearFoodSafetyStatisticsMonthAndYearFilter = () => {
    localStorage.removeItem(FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR_FILTER);
};
