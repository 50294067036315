import { Box } from '@material-ui/core';
import Content from 'components/content/content';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import UserCreateForm from 'components/userCreateForm/userCreateForm';

const UserCreate: React.FC = () => {
    return (
        <Content>
            <Box p={3}>
                <Grid container>
                    <Grid item md={5} sm={8} xs={12}>
                        <UserCreateForm />
                    </Grid>
                </Grid>
            </Box>
        </Content>
    );
};

export default UserCreate;
