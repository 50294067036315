import * as z from 'zod';

export const BucketSchema = z.object({
    bucket: z.string(),
    temperature: z.number().nullable(),
    defrostOn: z.number(),
    setPointAltOn: z.number(),
});

export const FoodSafetyReportItemSchema = z.object({
    shortName: z.string(),
    longName: z.string(),
    canAddress: z.number(),
    zone: z.string(),
    setPoint: z.number().nullable(),
    setPointAlt: z.number().nullable(),
    hours: z.any().optional(),
    buckets: z.array(BucketSchema),
    id: z.number(),
    hidden: z.boolean(),
    position: z.number(),
});

export const metadataSchema = z.object({
    availableDates: z.object({
        from: z.string(),
        to: z.string(),
    }),
});

export const FoodSafetyReportSchema = z.object({
    data: z.array(FoodSafetyReportItemSchema),
    metadata: metadataSchema,
});

export type FoodSafetyReportItem = z.infer<typeof FoodSafetyReportItemSchema>;

export type FoodSafetyReport = z.infer<typeof FoodSafetyReportSchema>;
