import { Box, Grid } from '@material-ui/core';
import { fetchMyProfile } from 'api/fetchers/myProfile';
import { QueryKeys } from 'api/queryKeys';
import Content from 'components/content/content';
import MyProfileForm from 'components/myProfileForm/myProfileForm';
import HeadingOne from 'components/typography/headingOne/headingOne';
import React from 'react';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';

const UserSettings: React.FC = () => {
    const { data } = useHandledQuery(QueryKeys.myProfile(), fetchMyProfile);

    return (
        <>
            <Content>
                <Box p={3}>
                    <Grid container>
                        <Grid item md={3} sm={8} xs={12}>
                            <HeadingOne>
                                {data?.firstName} {data?.lastName}
                            </HeadingOne>
                            <Box mt={3} />
                            <MyProfileForm />
                        </Grid>
                    </Grid>
                </Box>
            </Content>
        </>
    );
};

export default UserSettings;
