import { ApiEndpoints } from 'api/endpoints';
import { makeFileMutation } from 'api/api';

export interface AssetPdfParams {
    assetId: number;
    dateFrom: string;
    dateTo: string;
    lineChart: string;
    defrosting: string | null;
    highTemperature: string | null;
}

export const mutateAssetPdf = async (values: AssetPdfParams) => {
    const { assetId, dateFrom, dateTo, ...payload } = values;
    return await makeFileMutation(ApiEndpoints.assetPdf(assetId, dateFrom, dateTo), payload, true, 'POST');
};

export const assetPdfErrorCodes = {};
