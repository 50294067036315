import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        margin: 0,
    },
}));

const HeadingTwo: React.FC = ({ children }) => {
    const classes = useStyles();
    return <h2 className={classes.root}>{children}</h2>;
};

export default HeadingTwo;
