import { ApiResponseErrorSchema } from 'shared/network/apiResponseSchema';
import { EndpointSpecificErrors } from 'api/api';
import { errorCodes } from 'shared/network/errorCodes';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { History } from 'history';
import { logout } from 'store/auth/actions';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { routes } from 'routes/routes';
import { TFunction } from 'i18next';
import StandardApiError from './standardApiError';

export const getParsedStandardApiErrors = (input: StandardApiError | null) => {
    if (!input) {
        return null;
    }
    const parsedResponse = ApiResponseErrorSchema.safeParse(input.originalError);
    if (parsedResponse.success && parsedResponse.data.success === false) {
        return parsedResponse.data;
    }
    return null;
};

export const handleStandardApiErrors = (
    standardApiError: StandardApiError,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    t: TFunction,
    history: History<unknown>,
    endpointSpecificErrors?: EndpointSpecificErrors,
    showErrorInSnackbar?: boolean,
) => {
    const parsedResponse = getParsedStandardApiErrors(standardApiError);
    if (parsedResponse) {
        let output = '';
        let existsEndpointSpecificErrors = false;
        parsedResponse.errors.forEach((error) => {
            if (error.errorCode === errorCodes.NOT_FOUND) {
                output = t('itemNotFound');
            } else if (error.errorCode === errorCodes.UNAUTHORIZED) {
                logout();
                history.push(routes.default.path());
                output = t('notAuthorized');
            }
            if (endpointSpecificErrors && Object.keys(endpointSpecificErrors).indexOf(error.errorCode) >= 0) {
                if (showErrorInSnackbar) {
                    enqueueSnackbar(endpointSpecificErrors[error.errorCode].translation(t));
                }
                existsEndpointSpecificErrors = true;
            }
        });
        if (!existsEndpointSpecificErrors) {
            enqueueSnackbar(output || t('generalError'));
        }
    } else {
        enqueueSnackbar(t('generalError'));
    }
};

export const handleSimpleSpecificEndpointErrors = (
    standardApiError: StandardApiError | null,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    t: TFunction,
    endpointSpecificErrors: EndpointSpecificErrors,
) => {
    const parsedResponse = getParsedStandardApiErrors(standardApiError);
    if (parsedResponse) {
        let output = '';
        parsedResponse.errors.forEach((error) => {
            if (Object.keys(endpointSpecificErrors).indexOf(error.errorCode) >= 0) {
                output = endpointSpecificErrors[error.errorCode].translation(t);
            }
        });
        if (output) {
            enqueueSnackbar(output);
        }
    }
};

export const handleSimpleSpecificEndpointErrorsToForm = (
    standardApiError: StandardApiError | null,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    t: TFunction,
    endpointSpecificErrors: EndpointSpecificErrors,
    formElementNames: Array<string>,
    setError: UseFormSetError<FieldValues>,
) => {
    const parsedResponse = getParsedStandardApiErrors(standardApiError);
    if (parsedResponse) {
        let output = '';
        parsedResponse.errors.forEach((error) => {
            if (Object.keys(endpointSpecificErrors).indexOf(error.errorCode) >= 0) {
                const message = endpointSpecificErrors[error.errorCode].translation(t);
                if (error.path && typeof error.path === 'string' && formElementNames.indexOf(error.path) >= 0) {
                    setError(formElementNames[formElementNames.indexOf(error.path)], { message });
                } else {
                    output = message;
                }
            }
        });
        if (output) {
            enqueueSnackbar(output);
        }
    }
};
