import * as FileSaver from 'file-saver';
import { AppState } from 'store';
import { Column } from 'react-table';
import { DateTime } from 'shared/luxon/luxon';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { EnergyMeterMetricType } from 'types/energyMeter/energyMeterMetricType';
import { EnergyMeterPowerTableAccessors } from 'types/energyMeter/energyMeterPowerTableAccessors';
import { EnergyMeterPowerTableItem } from 'types/energyMeter/energyMeterPowerTableItem';
import { energyPowerAvailableDates } from 'store/filters/actions';
import { ExportButtonType } from 'components/exportButtons/exportButtonProps';
import { formatDateForAPI } from 'shared/network/helpers';
import { getEnergyMetrics } from 'api/fetchers/energyMetrics';
import { getEnergyMetricsCsv } from 'api/fetchers/energyMetricsCsv';
import { getEnergyMetricsXls } from 'api/fetchers/energyMetricsXls';
import { PowerStats } from 'model/energyMetrics/schema';
import { QueryKeys } from 'api/queryKeys';
import { Unit } from 'shared/enums/unit';
import { useDispatch, useSelector } from 'react-redux';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import chartColors from 'theme/chartColors';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import EnergyMeterTable from 'components/table/instances/energyMeterTable/energyMeterTable';
import ExportButtons from 'components/exportButtons/exportButtons';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import IntervalPickerDialog from 'components/intervalPickerDialog/intervalPickerDialog';
import LegendLabel from 'components/legendLabel/legendLabel';
import LineChart from 'components/chart/lineChart/lineChart';
import LoadingBar from 'components/loadingBar/loadingBar';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import useEnergyChartData from 'components/chart/useEnergyChartData';
import { getDateDiffInDays, isLongerThanWeek } from '../../shared/luxon/utils';

const Power: React.FC = () => {
    // component base
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [intervalPickerXlsOpen, setIntervalPickerXlsOpen] = React.useState<boolean>(false);
    const [intervalDateToDownloadMultipleXls, setIntervalDateToDownloadMultipleXls] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const [intervalPickerCsvOpen, setIntervalPickerCsvOpen] = React.useState<boolean>(false);
    const [intervalDateToDownloadMultipleCsv, setIntervalDateToDownloadMultipleCsv] = React.useState<{ from: DateTime; to: DateTime } | null>(null);
    const { storeId } = useSelector((state: AppState) => state.store);
    const [shownMeterNamesIds, setShownMeterNamesIds] = React.useState<number[]>([]);

    // reading stuff from redux
    const { energyMetricType, dateFrom, dateTo, availableDateTo, availableDateFrom } = useSelector((state: AppState) => state.filters.energyPower);

    // get available data range
    const { refetch: refetchAvailableDatesData, data: availableDatesData } = useHandledQuery(
        [QueryKeys.energyMetricsPower(), 'availableDates', storeId],
        () => {
            return storeId && energyMetricType
                ? getEnergyMetrics(storeId, queryParams.energyMetricType, queryParams.dateIntervalType, formatDateForAPI(DateTime.local()), formatDateForAPI(DateTime.local()))
                : undefined;
        },
        {
            enabled: false,
            onSuccess: (data) => {
                if (data && data.metadata) {
                    const {
                        availableDates: { from, to },
                    } = data.metadata;
                    if (from && to) {
                        dispatch(energyPowerAvailableDates(DateTime.fromJSDate(new Date(from)), DateTime.fromJSDate(new Date(to))));
                    }
                }
            },
        },
    );
    React.useEffect(() => {
        if (!availableDateTo && !availableDateFrom) {
            refetchAvailableDatesData();
        }
    }, []);

    // prepare params object, will be used as RQ key as well
    const queryParams = {
        storeId,
        energyMetricType,
        dateIntervalType: dateFrom && dateTo && isLongerThanWeek(dateFrom, dateTo) ? EnergyMeterDateInterval.hour : EnergyMeterDateInterval.default,
        dateFrom: dateFrom && formatDateForAPI(dateFrom),
        dateTo: dateTo && formatDateForAPI(dateTo),
    };

    // get energy data
    const {
        data: energyMetricsData,
        isFetching: energyMetricsIsFetching,
        isError: energyMetricsIsError,
    } = useHandledQuery(
        [QueryKeys.energyMetricsPower(), queryParams],
        () =>
            queryParams.storeId && queryParams.energyMetricType && queryParams.dateIntervalType && queryParams.dateFrom && queryParams.dateTo
                ? getEnergyMetrics(queryParams.storeId, queryParams.energyMetricType, queryParams.dateIntervalType, queryParams.dateFrom, queryParams.dateTo)
                : undefined,
        {
            enabled: Boolean(dateFrom && dateTo),
            keepPreviousData: true,
        },
    );

    // queries for file upload
    const { remove: energyMetricsDataXlsRemove, isLoading: energyMetricsDataXlsIsLoading } = useHandledQuery(
        [QueryKeys.energyMetricsPowerXls(), intervalDateToDownloadMultipleXls],
        () =>
            queryParams.storeId && queryParams.energyMetricType && intervalDateToDownloadMultipleXls
                ? getEnergyMetricsXls(
                      queryParams.storeId,
                      queryParams.energyMetricType,
                      EnergyMeterDateInterval.default,
                      formatDateForAPI(intervalDateToDownloadMultipleXls.from),
                      formatDateForAPI(intervalDateToDownloadMultipleXls.to),
                  )
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultipleXls),
            onSuccess: (energyMetricsDataXls) => {
                if (energyMetricsDataXls) {
                    const file = new Blob([energyMetricsDataXls.blob]);
                    FileSaver.saveAs(file, energyMetricsDataXls.fileName);
                    setIntervalDateToDownloadMultipleXls(null);
                    queryClient.removeQueries(QueryKeys.energyMetricsPowerXls(), { exact: true });
                }
            },
        },
    );
    const { remove: energyMetricsDataCsvRemove, isLoading: energyMetricsDataCsvIsLoading } = useHandledQuery(
        [QueryKeys.energyMetricsPowerCsv(), intervalDateToDownloadMultipleCsv],
        () =>
            queryParams.storeId && queryParams.energyMetricType && intervalDateToDownloadMultipleCsv
                ? getEnergyMetricsCsv(
                      queryParams.storeId,
                      queryParams.energyMetricType,
                      formatDateForAPI(intervalDateToDownloadMultipleCsv.from),
                      formatDateForAPI(intervalDateToDownloadMultipleCsv.to),
                  )
                : undefined,
        {
            enabled: Boolean(intervalDateToDownloadMultipleCsv),
            onSuccess: (energyMetricsDataCsv) => {
                if (energyMetricsDataCsv) {
                    const file = new Blob([energyMetricsDataCsv.blob]);
                    FileSaver.saveAs(file, energyMetricsDataCsv.fileName);
                    setIntervalDateToDownloadMultipleCsv(null);
                    queryClient.removeQueries(QueryKeys.energyMetricsPowerCsv(), { exact: true });
                }
            },
        },
    );
    React.useEffect(() => {
        return () => {
            energyMetricsDataXlsRemove();
            energyMetricsDataCsvRemove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // prepare some stuff for displaying data
    const { meterNames, data } = useEnergyChartData(energyMetricsData);
    const unit = energyMetricType === EnergyMeterMetricType.powerWater ? Unit.liter : Unit.kilowatt;
    const defaultColumnStyles = React.useMemo(
        () => ({
            customStyles: {
                width: '150px',
            },
        }),
        [],
    );
    const tableColumns = React.useMemo<Column<EnergyMeterPowerTableItem>[]>(
        () => [
            {
                Header: t('chartEnergyMeter.table.heading.meterName').toString(),
                accessor: EnergyMeterPowerTableAccessors.meterName,
                ...defaultColumnStyles,
            },
            {
                Header: t('chartEnergyMeter.table.heading.peak').toString(),
                accessor: EnergyMeterPowerTableAccessors.peak,
                Cell: ({ row }) => `${row.values.peak.toLocaleString()} ${unit}`,
                ...defaultColumnStyles,
            },
            {
                Header: t('chartEnergyMeter.table.heading.date').toString(),
                accessor: EnergyMeterPowerTableAccessors.date,
                Cell: ({ row }) => DateTime.fromJSDate(new Date(row.values.date)).toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS()),
                ...defaultColumnStyles,
            },
            {
                Header: t('chartEnergyMeter.table.heading.count').toString(),
                accessor: EnergyMeterPowerTableAccessors.count,
            },
        ],
        [t, defaultColumnStyles, unit],
    );

    const tableData =
        energyMetricsData &&
        energyMetricsData.data.meters.map((meter) => {
            return {
                meterName: meter.name,
                peak: (meter.stats as PowerStats).peak,
                count: meter.stats.count,
                date: (meter.stats as PowerStats).date || '',
            };
        });

    const availableDatesExists = availableDatesData && availableDatesData.metadata.availableDates.from && availableDatesData.metadata.availableDates.to;

    if (availableDatesData && (!availableDatesExists || (energyMetricsData && energyMetricsData.data.meters.length === 0))) {
        return <NoResults />;
    }

    if (!dateFrom || !dateTo || !availableDateFrom || !availableDateTo) {
        return <LoadingBar isFetching={true} />;
    }

    return (
        <>
            <LoadingBar isFetching={energyMetricsIsFetching} />
            {energyMetricsData && energyMetricsData.data.meters.length > 0 && (
                <>
                    <ContentHeader>
                        <ContentLegend>{meterNames && meterNames.map((meterName, i) => <LegendLabel key={meterName} color={chartColors[i]} text={meterName} />)}</ContentLegend>
                        <ExportButtons
                            buttons={[
                                {
                                    type: ExportButtonType.excel,
                                    text: t('exportButtons.excel'),
                                    action: () => setIntervalPickerXlsOpen(true),
                                    key: 'excel',
                                },
                                {
                                    type: ExportButtonType.csv,
                                    text: t('exportButtons.timespanCsv'),
                                    action: () => setIntervalPickerCsvOpen(true),
                                    key: 'csv',
                                },
                            ]}
                            disabled={energyMetricsIsError}
                        />
                        <IntervalPickerDialog
                            handleClose={() => setIntervalPickerCsvOpen(false)}
                            handleSubmit={(from: DateTime, to: DateTime) => {
                                setIntervalDateToDownloadMultipleCsv({ from, to });
                            }}
                            maxDate={availableDateTo}
                            minDate={availableDateFrom}
                            open={intervalPickerCsvOpen}
                            submitIsLoading={energyMetricsDataCsvIsLoading}
                            submitText={t('download')}
                        />
                        <IntervalPickerDialog
                            handleClose={() => setIntervalPickerXlsOpen(false)}
                            handleSubmit={(from: DateTime, to: DateTime) => {
                                setIntervalDateToDownloadMultipleXls({ from, to });
                            }}
                            maxDate={availableDateTo}
                            minDate={availableDateFrom}
                            open={intervalPickerXlsOpen}
                            submitIsLoading={energyMetricsDataXlsIsLoading}
                            submitText={t('download')}
                        />
                    </ContentHeader>
                    <HorizontalSeparator />
                    {meterNames && (
                        <LineChart
                            data={data}
                            dateIntervalType={queryParams.dateIntervalType}
                            daysDiffCount={getDateDiffInDays(dateFrom, dateTo)}
                            meterNames={meterNames}
                            shownMeterNamesIds={shownMeterNamesIds}
                            unit={unit}
                        />
                    )}
                    <HorizontalSeparator />
                    {tableData && (
                        <EnergyMeterTable<EnergyMeterPowerTableItem> columns={tableColumns} data={tableData} handleShownMeterNamesIds={setShownMeterNamesIds} shownMeterNamesIds={shownMeterNamesIds} />
                    )}
                </>
            )}
        </>
    );
};

export default Power;
