import { ValueDisplayProps } from './valueDisplayProps';
import NoData from 'components/noData/noData';
import React from 'react';

const ValueDisplay: React.FC<ValueDisplayProps> = ({ value }) => {
    if (value === null) {
        return <NoData />;
    }
    return <>{value}</>;
};

export default ValueDisplay;
