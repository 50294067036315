import { getStoreId } from 'localStorage/store';
import { SET_STORE_ID, StoreActionTypes, StoreState } from 'store/store/types';

const storeIdFromLocalStorage = getStoreId();

const initialState: StoreState = {
    storeId: storeIdFromLocalStorage ? parseInt(storeIdFromLocalStorage) : null,
};

const reducer = (state = initialState, action: StoreActionTypes): StoreState => {
    switch (action.type) {
        case SET_STORE_ID: {
            return {
                ...state,
                storeId: action.storeId,
            };
        }
        default:
            return state;
    }
};

export default reducer;
