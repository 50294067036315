import { routes } from 'routes/routes';
import { TableOptions, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { UsersTableAccessors } from 'types/users/usersTableAccessors';
import { useStyles } from 'components/table/stylesTable';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import Pagination from 'components/table/components/pagination/pagination';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    pageCount: number;
}

const UsersTable = <T extends Record<string, unknown>>({ pageCount, columns, data, isFetching, onSort, onFilter, onPageIndexChange }: React.PropsWithChildren<TableProperties<T>>) => {
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters, pageIndex },
        prepareRow,
        setHiddenColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            manualFilters: true,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [{ id: 'username', desc: false }],
            },
            manualPagination: true,
            pageCount,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const handleGoToUserDetail = (id: number) => {
        history.push(routes.userDetail.path(id));
    };

    React.useEffect(() => {
        onPageIndexChange(pageIndex);
    }, [pageIndex, onPageIndexChange]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    React.useEffect(() => {
        if (isXsDown) {
            setHiddenColumns([UsersTableAccessors.isDisabled, UsersTableAccessors.firstName, UsersTableAccessors.lastName]);
        } else {
            setHiddenColumns([]);
        }
    }, [isXsDown, columns, setHiddenColumns]);

    const classes = useStyles();

    return (
        <>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} />
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td colSpan={headerGroups[0].headers.length}>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row, i) => {
                        prepareRow(row);
                        const { key, role } = row.getRowProps();
                        return (
                            <React.Fragment key={key}>
                                <tr className={clsx(classes.tableRow, classes.tableRowClickable)} role={role} onClick={() => handleGoToUserDetail(row.original.id)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={classes.tableCell}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                previousPage={previousPage}
            />
        </>
    );
};

export default UsersTable;
