import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';

const PatchNotificationDisableSchema = z.object({
    success: z.literal(true),
});

export interface PatchNotificationDisableParams {
    contactId: number;
}

export const mutatePatchNotificationDisable = async (values: PatchNotificationDisableParams) => {
    const { contactId } = values;
    const response = await makeMutation(ApiEndpoints.patchNotificationDisable(contactId), undefined, true, 'PATCH');
    return PatchNotificationDisableSchema.parse(response);
};

export const patchNotificationDisableErrorCodes = {};
