import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';
import { TFunction } from 'i18next';

export interface Credentials {
    userId: number;
    token: string;
    newPassword: string;
}

export const mutateResetPassword = async (values: Credentials) => {
    const response = await makeMutation(ApiEndpoints.resetPassword(), values);
    return { success: response.success };
};

export const resetPasswordErrorCodes = {
    USER_NOT_FOUND: {
        code: 'USER_NOT_FOUND',
        translation: (t: TFunction) => t('resetForm.USER_NOT_FOUND'),
    },
    TOKEN_NOT_FOUND: {
        code: 'TOKEN_NOT_FOUND',
        translation: (t: TFunction) => t('resetForm.TOKEN_NOT_FOUND'),
    },
    TOKEN_EXPIRED: {
        code: 'TOKEN_EXPIRED',
        translation: (t: TFunction) => t('resetForm.TOKEN_EXPIRED'),
    },
};
