import { TableOptions, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { ReportsTableAccessors } from '../../../../types/reports/reportsTableAccessors';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
}

const ReportsTable = <T extends Record<string, unknown>>({ columns, data, isFetching }: React.PropsWithChildren<TableProperties<T>>) => {
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            initialState: {
                sortBy: [{ id: 'date', desc: true }],
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    React.useEffect(() => {
        if (isXsDown) {
            setHiddenColumns([ReportsTableAccessors.date, ReportsTableAccessors.category]);
        } else {
            setHiddenColumns([]);
        }
    }, [isXsDown, columns, setHiddenColumns]);

    const classes = useStyles();

    return (
        <>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} />
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td colSpan={headerGroups[0].headers.length}>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row) => {
                        prepareRow(row);
                        const { key, role } = row.getRowProps();
                        return (
                            <React.Fragment key={key}>
                                <tr className={clsx(classes.tableRow)} role={role}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className={classes.tableCell}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default ReportsTable;
