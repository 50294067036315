import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import ContentTabs from 'components/contentTabs/contentTabs';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import UserCreate from 'screens/userCreate/userCreate';

const UserCreateScreen: React.FC = () => {
    const { t } = useTranslation();

    const items = [
        {
            id: 'signUp',
            path: routes.userCreate.pattern,
            text: t('userCreate.title'),
        },
    ];

    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Content>
                    <Switch>
                        <Route component={UserCreate} exact path={routes.userCreate.pattern} />
                    </Switch>
                </Content>
            </Main>
        </>
    );
};

export default UserCreateScreen;
