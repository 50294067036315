import { ApiEndpoints } from 'api/endpoints';
import { makeQuery } from 'api/api';
import { NotificationsSchema } from 'model/notifications/schema';
import { SortParameter } from 'api/types';

export const fetchNotifications = async (storeId: number, sort?: SortParameter) => {
    const response = await makeQuery(ApiEndpoints.getNotifications(storeId), true, sort);
    const parsedResponse = NotificationsSchema.parse(response.data);
    return parsedResponse.map((item) => ({
        ...item,
        ...item.reports,
    }));
};
