import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import { makeStyles } from '@material-ui/core/styles';
import { SearchBoxProps } from 'components/organizationStructure/searchBox/searchBoxProps';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import Input from '@material-ui/core/Input';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'relative',
            zIndex: 1,
            background: theme.customPalette.background.white,
            boxShadow: theme.customElevation.default,
            height: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(32),
            display: 'flex',
            margin: theme.spacing(3),
            padding: `0 ${theme.typography.pxToRem(8)}`,
            borderRadius: theme.typography.pxToRem(4),
            [theme.breakpoints.up('sm')]: {
                padding: `0 ${theme.spacing(3)}`,
            },
            [theme.breakpoints.up('lg')]: {},
        },
        form: {
            display: 'flex',
            width: '100%',
        },
        input: {
            fontSize: theme.typography.pxToRem(14),
            position: 'static',
            width: '100%',
            '&:before': {
                display: 'none',
            },
            '&:after': {
                borderBottomWidth: 2,
                borderBottomColor: theme.customPalette.colors.brand.light,
            },
        },
        icon: {
            width: theme.typography.pxToRem(20),
            marginRight: theme.typography.pxToRem(-16),
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
            },
        },
    };
});

const SearchBox: React.FC<SearchBoxProps> = ({ setFilter }) => {
    const { control, watch } = useForm();
    const watchedInput = watch('input');
    const classes = useStyles();
    const { t } = useTranslation();

    const debouncedSetFilter = React.useMemo(
        () =>
            _debounce((watchedInput) => {
                setFilter(watchedInput);
            }, 300),
        [setFilter],
    );

    React.useEffect(() => {
        debouncedSetFilter(watchedInput);
    }, [watchedInput, debouncedSetFilter]);

    return (
        <div className={classes.root}>
            <form className={classes.form}>
                <Controller
                    control={control}
                    defaultValue=""
                    name="input"
                    render={({ field }) => <Input autoFocus classes={{ root: classes.input }} fullWidth id="test-search" placeholder={t('organizationStructure.searchbox.placeholder')} {...field} />}
                />
                <Box alignItems="center" className={classes.icon} display="flex" mr={-2}>
                    <MagnifierIcon fill={'lightgrey'} />
                </Box>
            </form>
        </div>
    );
};

export default SearchBox;
