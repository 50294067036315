import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.customPalette.alarmDashboard.skeleton,
        borderRadius: 4,
        height: 392,
        padding: theme.spacing(2),
    },
    opacityMedium: {
        opacity: '60%',
    },
    opacityLow: {
        opacity: '20%',
    },
}));

type Props = {
    opacity: 'high' | 'medium' | 'low';
};

const CardSkeleton: React.FC<Props> = ({ opacity }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, opacity === 'medium' && classes.opacityMedium, opacity === 'low' && classes.opacityLow)}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} gridGap={16}>
                    <Skeleton variant="rect" width={40} height={40} />

                    <Box display={'flex'} gridGap={4} flexDirection={'column'}>
                        <Skeleton variant="rect" width={163} height={20} />
                        <Skeleton variant="rect" width={88} height={16} />
                    </Box>
                </Box>

                <Box display={'flex'} alignItems={'flex-end'}>
                    <Skeleton variant="rect" width={40} height={24} />
                </Box>
            </Box>

            <Box mt={2} pl={7} display={'flex'} justifyContent={'space-between'}>
                <Box gridGap={16} display={'flex'}>
                    <Box display={'flex'} gridGap={4} flexDirection={'column'}>
                        <Skeleton variant="rect" width={71} height={12} />
                        <Skeleton variant="rect" width={94} height={20} />
                    </Box>

                    <Box display={'flex'} gridGap={4} flexDirection={'column'}>
                        <Skeleton variant="rect" width={71} height={12} />
                        <Skeleton variant="rect" width={94} height={20} />
                    </Box>
                </Box>

                <Box display={'flex'} alignItems={'flex-end'}>
                    <Skeleton variant="rect" width={40} height={20} />
                </Box>
            </Box>
        </div>
    );
};

export default CardSkeleton;
