import * as z from 'zod';

export const StoreSchema = z.object({
    id: z.number(),
    customer: z.string(),
    region: z.string(),
    name: z.string(),
    street: z.string(),
    town: z.string(),
    zipCode: z.string(),
    liveData: z.boolean(),
    hasReports: z.boolean().optional(),
    anlage: z.string().optional(),
});

export type Store = z.infer<typeof StoreSchema>;
