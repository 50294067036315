import { DayCellTabletProps } from './dayCellTabletProps';
import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { makeStyles } from '@material-ui/core';
import LightSeparator from 'components/table/components/lightSeparator/lightSeparator';
import React from 'react';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: theme.customPalette.text.secondary,
    },
    value: {
        width: theme.typography.pxToRem(15),
    },
}));

const DayCellTablet: React.FC<DayCellTabletProps> = ({ data }) => {
    const classes = useStyles();
    return (
        <td>
            <span className={classes.root}>
                <span className={classes.value}>{<ValueDisplay value={(data && data.day) || null} />}</span>
                <LightSeparator type={LightSeparatorType.vertical} />
                <span className={classes.value}>{<ValueDisplay value={(data && data.night) || null} />}</span>
            </span>
        </td>
    );
};

export default DayCellTablet;
