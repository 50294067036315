import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import React from 'react';
import SearchBoxUI, { useStylesForInput as useStyles } from './searchBoxUI';

type Props = {
    value: string;
    onChange: (value: string) => void;
};

const SearchBoxControlled: React.FC<Props> = ({ value, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <SearchBoxUI>
            <Input
                value={value}
                autoComplete="off"
                autoFocus
                classes={{ root: classes.input }}
                fullWidth
                id="test-search"
                placeholder={t('searchBox.placeholder')}
                onChange={(event) => onChange(event.target.value)}
            />
        </SearchBoxUI>
    );
};

export default SearchBoxControlled;
