import * as z from 'zod';

export const AlarmStoreRecordSchema = z.object({
    date: z.string(),
    store: z.object({
        value: z.number(),
        unit: z.string(),
    }),
    overall: z.object({
        value: z.number(),
        unit: z.string(),
    }),
});

export type AlarmStoreRecord = z.infer<typeof AlarmStoreRecordSchema>;
