import SegmentedControl from 'components/segmentedControl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardAlarmPriority } from 'screens/dashboard/types';

type Segment = {
    label: string;
    value: string;
};

type Props = {
    selectedPriority: DashboardAlarmPriority;
    onChange: (value: DashboardAlarmPriority) => void;
};

const Priority: React.FC<Props> = ({ selectedPriority, onChange }) => {
    const { t } = useTranslation();

    const segments: Segment[] = [
        {
            label: t('dashboard.prioriy.priority1Label'),
            value: '1',
        },
        {
            label: t('dashboard.prioriy.priority2Label'),
            value: '2',
        },
    ];

    const handleChange = (value: string) => {
        if (value === '1') {
            onChange(1);
        }

        if (value === '2') {
            onChange(2);
        }
    };

    return <SegmentedControl segments={segments} name="priority" value={selectedPriority.toString()} onChange={handleChange} />;
};

export default Priority;
