const LANGUAGE_SHORT_CODE = 'languageShortCode';

export const setLanguageShortCode = (shortCode: string) => {
    localStorage.setItem(LANGUAGE_SHORT_CODE, shortCode);
};
export const hasLanguageShortCode = () => Boolean(localStorage.getItem(LANGUAGE_SHORT_CODE));
export const getLanguageShortCode = () => localStorage.getItem(LANGUAGE_SHORT_CODE);
export const clearLanguageShortCode = () => {
    localStorage.removeItem(LANGUAGE_SHORT_CODE);
};
