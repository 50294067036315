import { Info, Settings } from 'luxon';
import { getLanguageShortCode } from 'localStorage/language';

const useMonths = () => {
    const currentLang = getLanguageShortCode();
    Settings.defaultLocale = currentLang || 'en';
    const months = Info.months();
    return months;
};

export default useMonths;
