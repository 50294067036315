import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        borderRadius: theme.typography.pxToRem(8),
        boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(4)} rgba(0, 0, 0, 0.240542)`,
        padding: theme.spacing(3),
        background: theme.customPalette.background.white,
    },
}));

const Paper: React.FC = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.root}>{children}</div>;
};

export default Paper;
