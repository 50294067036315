import { Controller, useForm } from 'react-hook-form';
import { SearchBoxProps } from 'components/organizationStructure/searchBox/searchBoxProps';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import Input from '@material-ui/core/Input';
import React from 'react';
import SearchBoxUI, { useStylesForInput as useStyles } from './searchBoxUI';

const SearchBox: React.FC<SearchBoxProps> = ({ setFilter }) => {
    const { control, watch } = useForm();
    const watchedInput = watch('input');
    const classes = useStyles();
    const { t } = useTranslation();

    const debouncedSetFilter = React.useMemo(
        () =>
            _debounce((watchedInput) => {
                setFilter(watchedInput);
            }, 300),
        [setFilter],
    );

    React.useEffect(() => {
        debouncedSetFilter(watchedInput);
    }, [watchedInput, debouncedSetFilter]);

    return (
        <SearchBoxUI>
            <Controller
                control={control}
                defaultValue=""
                name="input"
                render={({ field }) => <Input autoComplete="off" autoFocus classes={{ root: classes.input }} fullWidth id="test-search" placeholder={t('searchBox.placeholder')} {...field} />}
            />
        </SearchBoxUI>
    );
};

export default SearchBox;
