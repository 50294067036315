import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as NoResultIcon } from 'assets/icons/selection-search.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    noResultWrapper: {
        fontSize: theme.typography.pxToRem(12),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(4),
    },
    circleWrapper: {
        background: theme.customPalette.background.light,
        borderRadius: '50%',
        width: theme.spacing(11),
        height: theme.spacing(11),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.customElevation.default,
    },
    text: {
        marginTop: theme.spacing(2),
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
    },
    icon: {
        fill: theme.customPalette.colors.brand.light,
    },
}));

const NoResults: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.noResultWrapper} p={4}>
            <div className={classes.circleWrapper}>
                <NoResultIcon className={classes.icon} />
            </div>
            <div className={classes.text}>{t('noResults.title')}</div>
        </Box>
    );
};

export default NoResults;
