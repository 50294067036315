import { makeStyles } from '@material-ui/core';
import { ValueWithPercentProps } from 'components/table/components/valueWithPercent/valueWithPercentProps';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    percent: {
        color: theme.customPalette.text.secondary,
    },
}));

const ValueWithPercent: React.FC<ValueWithPercentProps> = ({ count, percent }) => {
    const classes = useStyles();
    return (
        <>
            {count}
            <span className={classes.percent}>&nbsp;&#40;{percent}&nbsp;&#37;&#41;</span>
        </>
    );
};

export default ValueWithPercent;
