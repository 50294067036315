import ForgottenPasswordForm from 'components/forgottenPasswordForm/forgottenPasswordForm';
import PreApplicationBox from 'components/preApplicationBox/preAplicationBox';
import React from 'react';

const ForgottenPasswordScreen: React.FC = () => {
    return (
        <PreApplicationBox>
            <ForgottenPasswordForm />
        </PreApplicationBox>
    );
};

export default ForgottenPasswordScreen;
