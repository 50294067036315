import { Box, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import useSelectTimeDuration from 'components/alarmTimeDurationPicker/useSelectTimeDuration';
import { AlarmTimeDuration } from 'types/alarm/alarmTimeDuration';
import { useDispatch, useSelector } from 'react-redux';
import { alarmReportDuration } from 'store/filters/actions';
import { AppState } from 'store';

const DEFAULT_TIME_DURATION = AlarmTimeDuration.last12;

const AlarmTimeDurationPicker = () => {
    const {
        control,
        watch,
        setValue,
        formState: { isDirty },
    } = useHandledForm();
    const { timeDurationArray } = useSelectTimeDuration();
    const timeDuration = watch('timeDuration');
    const { duration } = useSelector((state: AppState) => state.filters.alarmReport);
    const dispatch = useDispatch();

    useEffect(() => {
        if (timeDuration && isDirty) {
            dispatch(alarmReportDuration(parseInt(timeDuration)));
        }
    }, [timeDuration, isDirty]);

    useEffect(() => {
        if (duration) {
            setValue('timeDuration', duration);
        }
    }, [duration]);

    return (
        <Box alignItems="center" display="flex">
            <div>
                <TextFieldRHF control={control} defaultValue={DEFAULT_TIME_DURATION} name="timeDuration" select size="small">
                    {timeDurationArray.map(({ key, value }) => {
                        return (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        );
                    })}
                </TextFieldRHF>
            </div>
        </Box>
    );
};

export default AlarmTimeDurationPicker;
