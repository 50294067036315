import { Box } from '@material-ui/core';
import { ReactComponent as CsvIcon } from 'assets/icons/csv.svg';
import { ReactComponent as EmailSendIcon } from 'assets/icons/email-send.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg';
import { ExportButtonSingleProps, ExportButtonSingleType } from 'components/exportButtonSingle/exportButtonSingleProps';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import Button from 'components/button/button';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'unset',
        },
    },
    label: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.secondary,
        padding: `${theme.spacing(0.75)} 0`,
        marginRight: theme.spacing(3 / 8),
        [theme.breakpoints.down('xs')]: {
            display: 'none ',
        },
    },
    shopIcon: {
        fill: theme.customPalette.colors.brand.light,
    },
    pdfIcon: {
        fill: theme.customPalette.colors.error,
    },
    excelIcon: {
        fill: theme.customPalette.colors.success,
    },
    csvIcon: {
        fill: theme.customPalette.colors.success,
    },
    disabledIcon: {
        fill: theme.customPalette.text.secondary,
        cursor: 'not-allowed',
    },
}));

const ExportButtonSingle: React.FC<ExportButtonSingleProps> = ({ button, disabled, href, target }) => {
    const classes = useStyles();
    if (!button) {
        return null;
    }
    const { type, text, action, isLoading } = button;

    let icon = undefined;
    if (type === ExportButtonSingleType.email) {
        icon = <EmailSendIcon className={clsx(classes.shopIcon, disabled && classes.disabledIcon)} />;
    }
    if (type === ExportButtonSingleType.excel) {
        icon = <ExcelIcon className={clsx(classes.excelIcon, disabled && classes.disabledIcon)} />;
    }
    if (type === ExportButtonSingleType.csv) {
        icon = <CsvIcon className={clsx(classes.csvIcon, disabled && classes.disabledIcon)} />;
    }
    if (type === ExportButtonSingleType.pdf) {
        icon = <PdfIcon className={clsx(classes.pdfIcon, disabled && classes.disabledIcon)} />;
    }

    return (
        <Box className={classes.root} display="flex">
            <Box
                ml={1}
                my={0.25}
                onClick={() => {
                    if (!disabled) {
                        action();
                    }
                }}
            >
                <Button disabled={disabled} href={href} icon={icon} isLoading={isLoading} target={target}>
                    {text}
                </Button>
            </Box>
        </Box>
    );
};

export default ExportButtonSingle;
