import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { AlarmStoresSchema } from 'model/alarmStores/schema';
import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';

export const getAlarmStores = async (
    alarmType: string,
    priority: DashboardAlarmPriority,
    period: DashboardPeriod,
    dataSource: DashboardDataSource,
    searchPhrase: string,
    sortDirection: 'asc' | 'desc',
) => {
    const sort = [{ column: 'summary.storeAverage', direction: sortDirection }];
    const filter = undefined;
    const requestHeaders = {};
    const queryParams = [`dataSource=${dataSource}`, `alarmType=${alarmType}`, `period=${period}`, `priority=${priority}`];

    const response = await makeQuery(ApiEndpoints.getAlarmStores(), true, sort, filter, searchPhrase, requestHeaders, queryParams);
    return AlarmStoresSchema.parse(response.data);
};
