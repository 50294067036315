import { Cell, Row } from 'react-table';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { IconButton, useTheme } from '@material-ui/core';
import { ReportingTableAccessors } from 'types/reporting/reportingTableAccessors';
import { useDrag, useDrop } from 'react-dnd';
import { useStyles } from 'components/table/stylesTable';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { InView } from 'react-intersection-observer';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const DND_ITEM_TYPE = 'row';
const MovableRowComponent = <T extends Record<string, unknown>>({
    row,
    index,
    moveRow,
    children,
}: {
    row: Row<T>;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    children?: React.ReactNode;
}) => {
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const dropRef = React.useRef<HTMLTableRowElement>(null);

    const { t } = useTranslation();
    const classes = useStyles();

    const [, drop] = useDrop<{ index: number }, unknown, unknown>({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef || !dropRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = dropRef?.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) {
                return;
            }
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: () => ({ row }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (draggedItem) => {
            // @ts-ignore
            moveRow(draggedItem.row.index, draggedItem.index);
        },
    });

    preview(drop(dropRef));

    return (
        <InView triggerOnce={false}>
            {({ ref, inView }) => (
                // @ts-ignore
                <>
                    <div ref={ref} />
                    {inView ? (
                        <>
                            <tr className={classes.tableRow} ref={dropRef} style={{ opacity: isDragging ? 0 : 1 }}>
                                {row.cells.map((cell: Cell<T>) => {
                                    return (
                                        <React.Fragment>
                                            {cell.column.id === ReportingTableAccessors.drag && (
                                                <td className={classes.tableCell} ref={drag}>
                                                    <IconButton className={classes.dragIcon} size="small" title={t('move')}>
                                                        <DragIcon />
                                                    </IconButton>
                                                </td>
                                            )}
                                            {cell.column.id !== ReportingTableAccessors.drag && (
                                                <td {...cell.getCellProps()} className={classes.tableCell} style={cell.column.customStyles}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                            {children}
                        </>
                    ) : (
                        <div style={{ height: isLgUp ? 47 : 131 }}></div>
                    )}
                </>
            )}
        </InView>
    );
};

export default MovableRowComponent;
