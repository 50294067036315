import * as z from 'zod';

const PowerStatsSchema = z.object({ peak: z.number(), date: z.string().nullable(), count: z.number() });
export type PowerStats = z.infer<typeof PowerStatsSchema>;

const ConsumptionStatsSchema = z.object({ total: z.number(), average: z.number(), count: z.number() });
export type ConsumptionStats = z.infer<typeof ConsumptionStatsSchema>;

const StatsUnionSchema = z.union([ConsumptionStatsSchema, PowerStatsSchema]);

export const EnergyMetricsDataSchema = z.object({
    meters: z.array(
        z.object({
            stats: StatsUnionSchema,
            name: z.string(),
            buckets: z.array(z.object({ bucket: z.string(), value: z.number().nullable() })),
        }),
    ),
    rollup: StatsUnionSchema,
});

export const metadataSchema = z.object({
    availableDates: z.object({
        from: z.string().nullable(),
        to: z.string().nullable(),
    }),
});

export const EnergyMetricsSchema = z.object({
    data: EnergyMetricsDataSchema,
    metadata: metadataSchema,
});

export type EnergyMetrics = z.infer<typeof EnergyMetricsSchema>;
