import { getLanguageShortCode } from '../../localStorage/language';
import { Info, Settings } from 'luxon';
import { Row } from 'react-table';
import { TableStatisticsItem } from '../../types/statistics/tableStatisticsItem';
import ProgressBar from '../../components/progressBar/progressBar';

export const getMonthNameInQuarterOverview = (count: number, quarter?: number) => {
    if (!quarter) {
        return '';
    }
    const currentLang = getLanguageShortCode();
    Settings.defaultLocale = currentLang || 'en';
    return Info.months()[(quarter - 1) * 3 + count - 1];
};

export const getValueInQuarterOverview = (count: number, row: Row<TableStatisticsItem>, quarter?: number) => {
    if (!quarter) {
        return '—';
    }
    const monthNumber = (quarter - 1) * 3 + count;
    const currentMonthInData = row.original[monthNumber];
    if (currentMonthInData) {
        return <ProgressBar badPercent={currentMonthInData.deviation2kPercentage} goodPercent={currentMonthInData.deviation0kPercentage} veryBadPercent={currentMonthInData.deviation4kPercentage} />;
    }
    return '—';
};
