import * as z from 'zod';
import { Box } from '@material-ui/core';
import { Credentials, mutateResetPassword, resetPasswordErrorCodes } from 'api/mutations/resetPassword';
import { customValidationMessages, useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { handleSimpleSpecificEndpointErrors } from 'shared/errors/handleStandardApiErrors';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'routes/routes';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: '100%',
        width: theme.typography.pxToRem(120),
        height: 'auto',
    },
    contactFormLink: {
        height: '100%',
        color: theme.customPalette.colors.brand.light,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'none',
        textDecoration: 'none',
        padding: theme.spacing(1),
        margin: theme.spacing(-1),
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.customPalette.colors.brand.dark,
        },
    },
}));

const ResetPasswordForm: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const formValuesSchema = z
        .object({
            newPassword: z.string().min(5).max(20),
            newPasswordConfirmation: z.string(),
        })
        .refine((data) => data.newPassword === data.newPasswordConfirmation, {
            message: customValidationMessages.passwordsMustBeSame,
            path: ['newPasswordConfirmation'],
        });
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, setError, control } = useHandledForm(formValuesSchema);

    const searchParams = new URLSearchParams(location.search);
    const userId = parseInt(searchParams.get('userId') || '', 10);
    const token = searchParams.get('token');

    const { mutate, data, status, error, isLoading } = useHandledMutation(mutateResetPassword, undefined, resetPasswordErrorCodes);

    const handleGoToLogIn = () => {
        history.push(routes.default.path());
    };

    React.useEffect(() => {
        if (!token || !userId) {
            history.push(routes.default.path());
        }
    }, [token, userId, history]);

    React.useEffect(() => {
        if (status === 'success' && data) {
            enqueueSnackbar(t('resetForm.successSubmit'));
            history.push(routes.default.path());
        } else if (status === 'error') {
            handleSimpleSpecificEndpointErrors(error, enqueueSnackbar, t, resetPasswordErrorCodes);
        }
    }, [status, data, enqueueSnackbar, t, error, setError, history]);

    const onSubmit = async ({ newPassword }: Credentials) => {
        if (token) {
            await mutate({
                token,
                userId,
                newPassword,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldRHF control={control} label={t('resetPassword.password.label')} name="newPassword" type="password" />
            <Box mb={2} />
            <TextFieldRHF control={control} label={t('resetPassword.passwordConfirmation.label')} name="newPasswordConfirmation" type="password" />
            <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <div className={classes.contactFormLink} tabIndex={0} onClick={handleGoToLogIn}>
                    {t('resetPassword.logIn')}
                </div>
                <Button isLoading={isLoading} isPrimary size="large" type="submit">
                    {t('resetPassword.submit')}
                </Button>
            </Box>
        </form>
    );
};

export default ResetPasswordForm;
