import * as z from 'zod';
import { Box } from '@material-ui/core';
import { Credentials, mutateRequestPasswordReset, requestPasswordResetErrorCodes } from 'api/mutations/requestPasswordReset';
import { getParsedStandardApiErrors, handleSimpleSpecificEndpointErrors } from 'shared/errors/handleStandardApiErrors';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'routes/routes';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: '100%',
        width: theme.typography.pxToRem(120),
        height: 'auto',
    },
    infoText: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.customPalette.text.secondary,
    },
    link: {
        height: '100%',
        color: theme.customPalette.colors.brand.light,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'none',
        textDecoration: 'none',
        padding: theme.spacing(1),
        margin: theme.spacing(-1),
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.customPalette.colors.brand.dark,
        },
    },
}));

const formValuesSchema = z.object({
    username: z.string().nonempty(),
});

const ForgottenPasswordForm: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, setError, control } = useHandledForm(formValuesSchema);
    const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] = React.useState<boolean>(false);
    const history = useHistory();
    const { mutate, data, status, error } = useHandledMutation(mutateRequestPasswordReset, undefined, requestPasswordResetErrorCodes);

    const handleLogIn = () => {
        history.push(routes.default.path());
    };

    React.useEffect(() => {
        queryClient.clear();
    }, [queryClient]);

    React.useEffect(() => {
        if (status === 'success') {
            setFormSuccessfullySubmitted(true);
        } else if (status === 'error') {
            const parsedResponse = getParsedStandardApiErrors(error);
            if (parsedResponse?.errors[0].errorCode === requestPasswordResetErrorCodes.USER_NOT_FOUND.code) {
                setError('username', {
                    message: requestPasswordResetErrorCodes.USER_NOT_FOUND.translation(t),
                });
            } else {
                handleSimpleSpecificEndpointErrors(error, enqueueSnackbar, t, requestPasswordResetErrorCodes);
            }
        }
    }, [status, data, enqueueSnackbar, t, error, setError]);

    const onSubmit = async ({ username }: Credentials) => {
        await mutate({
            username,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {formSuccessfullySubmitted ? (
                <Box alignItems="center" display="flex" flexDirection="column" id="test-success-message">
                    <div className={classes.infoText}> {t('forgotPassword.emailSent.infoText1')}</div>
                    <div className={classes.infoText}> {t('forgotPassword.emailSent.infoTex2t')}</div>
                    <Box mt={2} />
                    <div className={classes.link} tabIndex={0} onClick={handleLogIn}>
                        {t('forgotPassword.logIn')}
                    </div>
                </Box>
            ) : (
                <>
                    <div className={classes.infoText}> {t('forgotPassword.infoText')}</div>
                    <Box mb={3} />
                    <TextFieldRHF control={control} id="test-username" label={t('forgottenPassword.username.label')} name="username" />
                    <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                        <div className={classes.link} tabIndex={0} onClick={handleLogIn}>
                            {t('forgotPassword.logIn')}
                        </div>
                        <Button id="test-submit" isLoading={status === 'loading'} isPrimary size="large" type="submit">
                            {t('forgotPassword.restorePassword')}
                        </Button>
                    </Box>
                </>
            )}
        </form>
    );
};

export default ForgottenPasswordForm;
