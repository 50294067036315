import { StaticPolyItem } from '../../model/reportingStoreLive/schema';
import React from 'react';
import { Group, Line } from 'react-konva';

const StaticPolyItemToRender = (props: StaticPolyItem) => {
    const { position, points, style } = props;
    return (
        <Group x={position.x} y={position.y} zIndex={10000}>
            <Line closed={true} fill={style.fillcolor} points={points.flatMap((point) => [point.x, point.y])} stroke={style.outlinecolor} strokeWidth={style.outlinewidth} x={0} y={0} />
        </Group>
    );
};

export default StaticPolyItemToRender;
