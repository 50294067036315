import * as z from 'zod';
import { StatusType } from 'components/status/statusType';

export const MonthSchema = z
    .object({
        year: z.string(),
        month: z.string(),
        totalRecords: z.number(),
        deviation0kCount: z.number(),
        deviation0kMinutes: z.number(),
        deviation0kPercentage: z.number(),
        deviation2kCount: z.number(),
        deviation2kMinutes: z.number(),
        deviation2kPercentage: z.number(),
        deviation4kCount: z.number(),
        deviation4kMinutes: z.number(),
        deviation4kPercentage: z.number(),
    })
    .optional();

export const metadataSchema = z.object({
    availableDates: z.object({
        from: z.string(),
        to: z.string(),
    }),
});

export const FoodSafetyStatisticsMonthlyItemSchema = z.object({
    "1": MonthSchema,
    "2": MonthSchema,
    "3": MonthSchema,
    "4": MonthSchema,
    "5": MonthSchema,
    "6": MonthSchema,
    "7": MonthSchema,
    "8": MonthSchema,
    "9": MonthSchema,
    "10": MonthSchema,
    "11": MonthSchema,
    "12": MonthSchema,
    shortName: z.string(),
    longName: z.string(),
    canAddress: z.number(),
    zone: z.string(),
    defrostHours: z.number(),
    deviation2kCount: z.number(),
    deviation2kPercentage: z.number(),
    deviation4kCount: z.number(),
    deviation4kPercentage: z.number(),
    status: z.nativeEnum(StatusType),
    id: z.number(),
    hidden: z.boolean(),
    position: z.number(),
    months: z.object({
        "1": MonthSchema,
        "2": MonthSchema,
        "3": MonthSchema,
        "4": MonthSchema,
        "5": MonthSchema,
        "6": MonthSchema,
        "7": MonthSchema,
        "8": MonthSchema,
        "9": MonthSchema,
        "10": MonthSchema,
        "11": MonthSchema,
        "12": MonthSchema,
    }),
});

export const FoodSafetyStatisticsMonthlySchema = z.object({
    data: z.array(FoodSafetyStatisticsMonthlyItemSchema),
    metadata: metadataSchema,
});

export type FoodSafetyStatisticsMonthlyItem = z.infer<typeof FoodSafetyStatisticsMonthlyItemSchema>;

export type FoodSafetyStatisticsMonthly = z.infer<typeof FoodSafetyStatisticsMonthlySchema>;
