import { getPriorityText } from 'components/table/instances/alarmListViewTable/utils';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { routes } from 'routes/routes';
import { formatToDateTime } from 'shared/network/helpers';
import { TableViewAccessors } from 'types/alarm/tableViewAccessor';
import { TableViewItem } from 'types/alarm/tableViewTableItem';

const AlarmTableViewColumns = () => {
    const { t } = useTranslation();

    const columns = React.useMemo<Column<TableViewItem>[]>(() => {
        return [
            {
                Header: t('alarm.tableView.table.heading.coolingPosition').toString(),
                accessor: TableViewAccessors.position,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
                Cell: ({ value, row }) => {
                    if (row.original.hiddenPosition) {
                        return <span>{value}</span>;
                    }
                    return (
                        <Link to={{ state: { goBackPath: routes.alarmTableView.pattern }, pathname: routes.asset.path(row.original.assetId) }}>
                            <span style={{ color: '#1891F6' }}>{value}</span>
                        </Link>
                    );
                },
            },
            {
                Header: t('alarm.tableView.table.heading.case').toString(),
                disableSortBy: false,
                disableFilters: false,
                accessor: TableViewAccessors.zone,
                customStyles: {
                    width: 100,
                },
            },
            {
                Header: t('alarm.tableView.table.heading.position').toString(),
                accessor: TableViewAccessors.assetName,
                disableSortBy: false,
                disableFilters: false,
                filter: '',
                customStyles: {
                    width: 150,
                },
            },
            {
                Header: t('alarm.tableView.table.heading.alarmPriority').toString(),
                accessor: TableViewAccessors.priority,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
                Cell: ({ value }) => {
                    return <>{getPriorityText(value)}</>;
                },
            },
            {
                Header: t('alarm.tableView.table.heading.alarm').toString(),
                accessor: TableViewAccessors.event,
                disableSortBy: false,
                customStyles: {
                    width: 250,
                },
            },
            {
                Header: t('alarm.tableView.table.heading.start').toString(),
                accessor: TableViewAccessors.startOn,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
                Cell: ({ value }) => {
                    return <>{value ? formatToDateTime(DateTime.fromISO(value)) : '-'}</>;
                },
            },
            {
                Header: t('alarm.tableView.table.heading.end').toString(),
                accessor: TableViewAccessors.endOn,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
                Cell: ({ value, row }) => {
                    return <>{value ? formatToDateTime(DateTime.fromISO(value)) : '-'}</>;
                },
            },
        ];
    }, [t]);

    return columns;
};

export default AlarmTableViewColumns;
