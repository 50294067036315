import { ReportingMobileListProps } from 'components/reportingMobileList/reportingMobileListProps';
import Box from '@material-ui/core/Box';
import React from 'react';
import ReportingMobileListItem from 'components/reportingMobileListItem/reportingMobileListItem';

const ReportingMobileList: React.FC<ReportingMobileListProps> = ({ data }) => {
    if (!data) {
        return null;
    }
    return (
        <Box display="flex" flexDirection="column">
            {data.data.map((bucket) => {
                const key = `${bucket.longName}${bucket.shortName}${bucket.zone}${bucket.shortName}`;
                return <ReportingMobileListItem key={key} data={bucket} />;
            })}
        </Box>
    );
};

export default ReportingMobileList;
