export enum AlarmStatus {
    Prio1 = 1,
    Prio2 = 2,
    Closed = 'closed',
}

export interface Date {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface AlarmProps {
    title: string;
    description: string;
    status: AlarmStatus;
    assetId: number | null;
    handleHighlightedAsset: (value: number | null) => void;
    startOn: string | null;
    endOn: string | null;
    shortName?: string;
    longName?: string;
    temperature?: number;
    comment: string | null;
}
