import PreApplicationBox from 'components/preApplicationBox/preAplicationBox';
import React from 'react';
import ResetPasswordForm from 'components/resetPasswordForm/resetPasswordForm';

const ResetPasswordScreen: React.FC = () => {
    return (
        <PreApplicationBox>
            <ResetPasswordForm />
        </PreApplicationBox>
    );
};

export default ResetPasswordScreen;
