import { Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { LineChart as RechartsLineChart } from 'recharts';
import { TimelineProps } from 'components/chart/assetChart/timeline/timelineProps';
import { useTheme } from '@material-ui/core';
import CustomizedDot from 'components/chart/assetChart/customizedDot/customizedDot';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Timeline = React.forwardRef<any, TimelineProps>(({ data, color, tickCSSProperties, width }, ref) => {
    const theme = useTheme();
    return (
        <ResponsiveContainer height={22} width={width || '100%'}>
            <RechartsLineChart
                data={data}
                height={22}
                margin={{
                    top: 0,
                    right: 16,
                    left: 16,
                    bottom: 0,
                }}
                ref={ref}
                width={500}
            >
                <XAxis allowDecimals dataKey="xDataKey" domain={['dataMin', 'dataMax']} hide interval={2} stroke={theme.customPalette.border} type={'number'} />
                <YAxis stroke={'transparent'} tick={tickCSSProperties} type="category" />
                <Line
                    dataKey="expression"
                    dot={(props) => {
                        const { cx, cy, value, fill, payload } = props;
                        return <CustomizedDot key={payload.xDataKey} cx={cx} cy={cy} fill={fill} value={value} />;
                    }}
                    fill={color}
                    isAnimationActive={false}
                    stroke={color}
                    strokeWidth={12}
                />
            </RechartsLineChart>
        </ResponsiveContainer>
    );
});

export default Timeline;
