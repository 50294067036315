import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { store } from './store';
import { useTranslation } from 'react-i18next';
import configuredHistory from 'system/history/history';
import CssBaseline from '@material-ui/core/CssBaseline';
import LuxonUtils from '@date-io/luxon';
import React from 'react';
import RoutesSwitcher from 'routes/routesSwitcher';
import theme from 'theme/theme';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            staleTime: 5000,
        },
    },
});

const useStyles = makeStyles((theme) => ({
    snackbarContainerRoot: {
        bottom: theme.spacing(9),
    },
}));

const App: React.FC = () => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    return (
        <ReduxProvider store={store}>
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{
                        containerRoot: classes.snackbarContainerRoot,
                    }}
                >
                    <CssBaseline />
                    <QueryClientProvider client={queryClient}>
                        <MuiPickersUtilsProvider locale={i18n.language} utils={LuxonUtils}>
                            <ReactQueryDevtools initialIsOpen={false} />
                            <Router history={configuredHistory}>
                                <RoutesSwitcher />
                            </Router>
                        </MuiPickersUtilsProvider>
                    </QueryClientProvider>
                </SnackbarProvider>
            </MuiThemeProvider>
        </ReduxProvider>
    );
};

export default App;
