import { AssetChartProps } from 'components/chart/assetChart/assetChartProps';
import { CartesianGrid, Line, LineChart as RechartsLineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import ChartTooltip from 'components/chart/components/chartTooltip/chartTooltip';
import OneLineAxisTick from 'components/chart/components/oneLineAxisTick/oneLineAxisTick';
import React, { memo } from 'react';
import settings from 'settings';
import Timeline from 'components/chart/assetChart/timeline/timeline';
import usePrepareAssetChartDescriptionData from 'components/chart/usePrepareAssetChartDescriptionData';

const AssetChart: React.FC<AssetChartProps> = ({ tickInterval, daysDiffCount, data, unit, lineChartRef, defrostingChartRef, highTemperatureChartRef }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { sundays, ticks, tickCSSProperties, chartMargin } = usePrepareAssetChartDescriptionData(data, daysDiffCount);
    if (!data || !data.length) {
        return null;
    }

    const DEFROSTING_SHORT_TITLE = t('asset.legend.defrostingShort');
    const HIGH_TEMPERATURE_SHORT_TITLE = t('asset.legend.highTemperatureShort');

    const defrostingData = data.map((item) => {
        return {
            xDataKey: item.xDataKey,
            expression: item.defrostOn && item.defrostOn >= settings.defrostOnThresholdAssetChart ? DEFROSTING_SHORT_TITLE : null,
            modified: false,
        };
    });

    defrostingData.forEach((item, key) => {
        let modified = false;
        let { expression } = item;
        const previous = key > 0 ? defrostingData[key - 1] : undefined;
        if (previous) {
            const { expression: previousExpression, modified: previousModified } = previous;
            if (previousExpression !== null && expression === null && previousModified === false) {
                modified = true;
                expression = DEFROSTING_SHORT_TITLE;
            }
            defrostingData[key] = {
                ...item,
                expression,
                modified,
            };
        }
    });

    const highTemperatureData = data.map((item) => {
        const setPointToCompare: number|null = item.setPointAltOn !== null && item.setPointAltOn > settings.setPointAltThreshold ? item.setPointAlt : item.setPoint;
        if (item.temperature === null || setPointToCompare === null) {
            return {
                xDataKey: item.xDataKey,
                expression: null,
            };
        }
        const bucketDate: Date = new Date(item.bucket);
        const absDate: Date = new Date('2023-11-07T00:00:00');
        const useAbs: boolean = bucketDate < absDate;
        const differenceBetweenTemperatureAndSetPoint: number = item.temperature - setPointToCompare;
        const temperatureToCompare: number = useAbs
            ? Math.abs(differenceBetweenTemperatureAndSetPoint)
            : differenceBetweenTemperatureAndSetPoint;
        return {
            xDataKey: item.xDataKey,
            expression: temperatureToCompare > settings.temperatureDiffThresholdError ? HIGH_TEMPERATURE_SHORT_TITLE : null,
            modified: false,
        };
    });

    highTemperatureData.forEach((item, key) => {
        let modified = false;
        let { expression } = item;
        const previous = key > 0 ? highTemperatureData[key - 1] : undefined;
        if (previous) {
            const { expression: previousExpression, modified: previousModified } = previous;
            if (previousExpression !== null && expression === null && previousModified === false) {
                modified = true;
                expression = HIGH_TEMPERATURE_SHORT_TITLE;
            }
            highTemperatureData[key] = {
                ...item,
                expression,
                modified,
            };
        }
    });

    const colorDefrosting = theme.customPalette.colors.brand.light;
    const colorHighTemperature = theme.customPalette.colors.error;

    return (
        <>
            {highTemperatureData.find(({ expression }) => expression !== null) && <Timeline color={colorHighTemperature} data={highTemperatureData} tickCSSProperties={tickCSSProperties} />}
            {defrostingData.find(({ expression }) => expression !== null) && <Timeline color={colorDefrosting} data={defrostingData} tickCSSProperties={tickCSSProperties} />}
            <ResponsiveContainer height={400} width="100%">
                <RechartsLineChart data={data} height={500} margin={chartMargin} width={500}>
                    <CartesianGrid fill="rgba(248, 248, 249, 1)" stroke={theme.customPalette.border} strokeDasharray="4 4" vertical={false} />
                    {sundays.map((sunday) => {
                        const x1 = sunday.startOf('day').toMillis();
                        const x2 = sunday.endOf('day').toMillis();
                        return <ReferenceArea key={sunday.toString()} fill="black" fillOpacity={0.08} ifOverflow="extendDomain" x1={x1} x2={x2} />;
                    })}
                    <XAxis
                        allowDecimals
                        dataKey="xDataKey"
                        domain={['dataMin', 'dataMax']}
                        interval={tickInterval}
                        stroke={theme.customPalette.border}
                        tick={({ x, y, payload }) => <OneLineAxisTick payload={payload} withoutSeconds={true} x={x} y={y} />}
                        ticks={ticks}
                        type={'number'}
                    />
                    <YAxis stroke={theme.customPalette.border} tick={tickCSSProperties} tickFormatter={(value) => `${value}\u00A0${unit}`} type="number" />
                    <Tooltip content={(props) => <ChartTooltip props={props} unit={unit} />} />
                    <Line dataKey="temperature" dot={{ r: 1 }} fill={theme.customPalette.colors.brand.dark} isAnimationActive={false} stroke={theme.customPalette.colors.brand.dark} />
                </RechartsLineChart>
            </ResponsiveContainer>
            <Box display="none">
                {highTemperatureData.find(({ expression }) => expression !== null) && (
                    <Timeline color={colorHighTemperature} data={highTemperatureData} ref={highTemperatureChartRef} tickCSSProperties={tickCSSProperties} width={1100} />
                )}
                {defrostingData.find(({ expression }) => expression !== null) && (
                    <Timeline color={colorDefrosting} data={defrostingData} ref={defrostingChartRef} tickCSSProperties={tickCSSProperties} width={1100} />
                )}
                <RechartsLineChart data={data} height={500} margin={chartMargin} ref={lineChartRef} width={1100}>
                    <CartesianGrid fill="rgba(248, 248, 249, 1)" stroke={theme.customPalette.border} strokeDasharray="4 4" vertical={false} />
                    {sundays.map((sunday) => {
                        const x1 = sunday.startOf('day').toMillis();
                        const x2 = sunday.endOf('day').toMillis();
                        return <ReferenceArea key={sunday.toString()} fill="black" fillOpacity={0.08} ifOverflow="extendDomain" x1={x1} x2={x2} />;
                    })}
                    <XAxis
                        allowDecimals
                        dataKey="xDataKey"
                        domain={['dataMin', 'dataMax']}
                        interval={tickInterval}
                        stroke={theme.customPalette.border}
                        tick={({ x, y, payload }) => <OneLineAxisTick payload={payload} withoutSeconds={true} x={x} y={y} />}
                        ticks={ticks}
                        type={'number'}
                    />
                    <YAxis stroke={theme.customPalette.border} tick={tickCSSProperties} tickFormatter={(value) => `${value}\u00A0${unit}`} type="number" />
                    <Line dataKey="temperature" dot={{ r: 1 }} fill={theme.customPalette.colors.brand.dark} isAnimationActive={false} stroke={theme.customPalette.colors.brand.dark} />
                </RechartsLineChart>
            </Box>
        </>
    );
};

const areEqual = (prevProps: AssetChartProps, nextProps: AssetChartProps) => {
    const firstItemPrev = JSON.stringify(prevProps.data[0]);
    const lastItemPrev = JSON.stringify(prevProps.data[prevProps.data.length - 1]);
    const firstItemNext = JSON.stringify(nextProps.data[0]);
    const lastItemNext = JSON.stringify(nextProps.data[nextProps.data.length - 1]);
    if (firstItemPrev === firstItemNext && lastItemPrev === lastItemNext) {
        return true;
    } else {
        return false;
    }
};

export default memo(AssetChart, areEqual);
