import { Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { DateTime } from 'shared/luxon/luxon';
import { IntervalPickerDialogProps } from 'components/intervalPickerDialog/intervalPickerDialogProps';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const IntervalPickerDialog: React.FC<IntervalPickerDialogProps> = ({ submitIsLoading, open, handleSubmit, handleClose, submitText, maxDate, minDate }) => {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = React.useState<DateTime | null>(null);
    const [dateTo, setDateTo] = React.useState<DateTime | null>(null);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t('intervalPickerDialog.title')}</DialogTitle>
            <DialogContent>
                <Box mt={2} />
                <DatePicker
                    cancelLabel={t('datePicker.cancel')}
                    format={DateTimeFormat.DATE_FORMAT()}
                    id="test-date-from"
                    inputVariant="outlined"
                    label={t('intervalPickerDialog.from')}
                    maxDate={dateTo && dateTo < maxDate ? dateTo.startOf('day') : maxDate}
                    minDate={minDate}
                    okLabel={t('datePicker.ok')}
                    value={dateFrom}
                    onChange={(date) => (date ? setDateFrom(date) : undefined)}
                />
                <Box mt={2} />
                <DatePicker
                    cancelLabel={t('datePicker.cancel')}
                    format={DateTimeFormat.DATE_FORMAT()}
                    id="test-date-to"
                    inputVariant="outlined"
                    label={t('intervalPickerDialog.to')}
                    maxDate={maxDate}
                    minDate={dateFrom && dateFrom > minDate ? dateFrom.startOf('day') : minDate}
                    okLabel={t('datePicker.ok')}
                    value={dateTo}
                    onChange={(date) => (date ? setDateTo(date) : undefined)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('intervalPickerDialog.cancel')}</Button>
                {dateFrom && dateTo && (
                    <Button isLoading={submitIsLoading} isPrimary onClick={() => handleSubmit(dateFrom, dateTo)}>
                        {submitText ? submitText : t('intervalPickerDialog.submit')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default IntervalPickerDialog;
