import { Box, IconButton, MenuItem } from '@material-ui/core';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ColumnInstance } from 'react-table';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from 'components/textField/textField';

const useStyles = makeStyles((theme) => ({
    clear: {
        marginLeft: theme.spacing(1),
        '& svg': {
            fill: theme.customPalette.colors.brand.light,
            width: theme.typography.pxToRem(12),
            height: theme.typography.pxToRem(12),
        },
    },
}));

const SelectColumnFilter = <T extends Record<string, unknown>>({ column, options }: { column: ColumnInstance<T>; options: { label: string; value: string }[] }) => {
    const { id, filterValue, setFilter } = column;
    const classes = useStyles();
    const [value, setValue] = React.useState(filterValue || '');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    React.useEffect(() => {
        setFilter(value);
        /*  with setFilter in deps not working */ // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleClear = () => {
        setValue('');
    };

    return (
        <Box display="flex">
            <TextField clearable={Boolean(value)} id={id} select SelectProps={{ displayEmpty: true }} size="small" value={value} onChange={handleChange}>
                <MenuItem value="">&nbsp;</MenuItem>
                {options.map(({ label, value }) => {
                    return (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </TextField>
            {Boolean(value) && (
                <IconButton className={classes.clear} size="small" onClick={handleClear}>
                    <CancelIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default SelectColumnFilter;
