import { makeStyles } from '@material-ui/core/styles';
import { StaticHorizontalTableProps } from 'components/staticHorizontalTable/staticHorizontalTableProps';
import clsx from 'clsx';
import React from 'react';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        fontSize: theme.typography.pxToRem(12),
        boxShadow: theme.customElevation.default,
    },
    tr: {
        '&:not(:last-child)': {
            borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.customPalette.border}`,
        },
    },
    th: {
        textAlign: 'left',
        fontWeight: 400,
    },
    td: {
        textAlign: 'right',
        fontWeight: 600,
    },
    cell: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
}));

const StaticHorizontalTable: React.FC<StaticHorizontalTableProps> = ({ data }) => {
    const classes = useStyles();
    return (
        <table className={classes.table}>
            <tbody>
                {data.map(({ heading, value }) => {
                    return (
                        <tr key={heading} className={classes.tr}>
                            <th className={clsx(classes.cell, classes.th)}>{heading}</th>
                            <td className={clsx(classes.cell, classes.td)}>
                                <ValueDisplay value={value} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default StaticHorizontalTable;
