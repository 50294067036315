import { CartesianGrid, Line, LineChart as RechartsLineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CommonChartProps } from 'components/chart/commonChartProps';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { useTheme } from '@material-ui/core';
import chartColors from 'theme/chartColors';
import ChartTooltip from 'components/chart/components/chartTooltip/chartTooltip';
import OneLineAxisTick from 'components/chart/components/oneLineAxisTick/oneLineAxisTick';
import React, { memo } from 'react';
import TwoLinesAxisTick from 'components/chart/components/twoLinesAxisTick/twoLinesAxisTick';
import usePrepareChartDescriptionData from 'components/chart/usePrepareChartDescriptionData';

const LineChart: React.FC<CommonChartProps> = ({ dateIntervalType, daysDiffCount, data, meterNames, shownMeterNamesIds, unit }) => {
    const theme = useTheme();
    const { sundays, ticks, tickCSSProperties, chartMargin, dateHasIntervalFormat } = usePrepareChartDescriptionData(data, daysDiffCount);
    if (!data || !data.length) {
        return null;
    }
    const ticksShownCount = 15;
    const tickInterval = Math.floor(data.length / ticksShownCount);
    return (
        <ResponsiveContainer height={400} width="100%">
            <RechartsLineChart data={data} height={500} margin={chartMargin} width={500}>
                <CartesianGrid fill="rgba(248, 248, 249, 1)" stroke={theme.customPalette.border} strokeDasharray="4 4" vertical={false} />
                {!dateHasIntervalFormat &&
                    sundays.map((sunday) => {
                        const x1 = sunday.startOf('day').toMillis();
                        const x2 = sunday.endOf('day').toMillis();
                        return <ReferenceArea key={sunday.toString()} fill="black" fillOpacity={0.08} ifOverflow="extendDomain" x1={x1} x2={x2} />;
                    })}
                <XAxis
                    allowDecimals
                    dataKey="xDataKey"
                    domain={dateHasIntervalFormat ? [0, 'auto'] : ['dataMin', 'dataMax']}
                    interval={tickInterval}
                    stroke={theme.customPalette.border}
                    tick={({ x, y, payload }) =>
                        dateHasIntervalFormat ? (
                            <TwoLinesAxisTick payload={payload} x={x} y={y} />
                        ) : (
                            <OneLineAxisTick payload={payload} withoutSeconds={dateIntervalType === EnergyMeterDateInterval.default} x={x} y={y} />
                        )
                    }
                    ticks={ticks}
                    type={dateHasIntervalFormat ? 'category' : 'number'}
                />
                <YAxis stroke={theme.customPalette.border} tick={tickCSSProperties} tickFormatter={(value) => `${value}\u00A0${unit}`} type="number" />
                <Tooltip content={(props) => <ChartTooltip props={props} unit={unit} />} />
                {shownMeterNamesIds.map((i) => (
                    <Line key={meterNames[i]} connectNulls={true} dataKey={meterNames[i]} dot={{ r: 1 }} fill={chartColors[i]} isAnimationActive={false} stroke={chartColors[i]} />
                ))}
            </RechartsLineChart>
        </ResponsiveContainer>
    );
};

const areEqual = (prevProps: CommonChartProps, nextProps: CommonChartProps) => {
    return (
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
        JSON.stringify(prevProps.meterNames) === JSON.stringify(nextProps.meterNames) &&
        JSON.stringify(prevProps.shownMeterNamesIds) === JSON.stringify(nextProps.shownMeterNamesIds) &&
        prevProps.dateIntervalType === nextProps.dateIntervalType &&
        prevProps.daysDiffCount === nextProps.daysDiffCount &&
        prevProps.unit === nextProps.unit
    );
};

export default memo(LineChart, areEqual);
