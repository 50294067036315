import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, SortParameter } from 'api/types';
import { makeQuery } from 'api/api';
import { UsersSchema } from 'model/users/schema';

export const fetchUsers = async (dataRange: { from: number; to: number }, sort?: SortParameter, filter?: FilterParameter) => {
    const { from, to } = dataRange;
    const headers = {
        Range: `items=${from}-${to}`,
    };
    const response = await makeQuery(ApiEndpoints.getUsers(), true, sort, filter, undefined, headers);
    return UsersSchema.parse(response);
};
