import { allFiltersReset } from 'store/filters/actions';
import { ChooseStoreTableAccessors } from 'types/chooseStore/chooseStoreTableAccessors';
import { clearEnergyConsumptionFilter } from 'localStorage/energyConsumptionFilter';
import { clearEnergyPowerFilter } from 'localStorage/energyPowerFilter';
import { clearFoodSafetyReportingFilter } from 'localStorage/foodSafetyReportingFilter';
import { clearFoodSafetyStatisticsFilter } from 'localStorage/foodSafetyStatisticsFilter';
import { routes } from 'routes/routes';
import { SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { storeId } from 'store/store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useStyles } from 'components/table/stylesTable';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
}

const ChooseStoreTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching }: React.PropsWithChildren<TableProperties<T>>) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const queryClient = useQueryClient();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy },
        prepareRow,
        setHiddenColumns,
    } = useTable<T>(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    const handleRowClick = (id: number) => {
        clearFoodSafetyReportingFilter();
        clearFoodSafetyStatisticsFilter();
        clearEnergyConsumptionFilter();
        clearEnergyPowerFilter();
        dispatch(allFiltersReset());
        dispatch(storeId(id));
        queryClient.clear();
        history.push(routes.default.path());
    };

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    React.useEffect(() => {
        if (isXsDown) {
            setHiddenColumns([ChooseStoreTableAccessors.street]);
        } else {
            setHiddenColumns([]);
        }
    }, [isXsDown, columns, setHiddenColumns]);

    const classes = useStyles();

    return (
        <table {...getTableProps()} className={classes.root}>
            <TableHead headerGroups={headerGroups} />
            <tbody {...getTableBodyProps()}>
                {rows.length === 0 && !isFetching && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                            <NoResults />
                        </td>
                    </tr>
                )}
                {rows.map((row, i) => {
                    prepareRow(row);
                    const { key, role } = row.getRowProps();
                    return (
                        <React.Fragment key={key}>
                            <tr className={clsx(classes.tableRow, classes.tableRowClickable)} id={`test-row-${row.original.id}`} role={role} onClick={() => handleRowClick(row.original.id)}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()} className={classes.tableCell}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ChooseStoreTable;
