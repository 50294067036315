import CardUI from './cardUI';
import { getAlarmStoreRecords } from 'api/fetchers/alarmStoreRecords';
import { QueryKeys } from 'api/queryKeys';
import { AlarmStore } from 'model/alarmStore/schema';
import React from 'react';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { DateTime } from 'luxon';
import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';
import { useInView } from 'react-intersection-observer';

type Props = {
    alarmStore: AlarmStore;
    alarmType: string;
    period: DashboardPeriod;
    dataSource: DashboardDataSource;
    priority: DashboardAlarmPriority;
};

const Card: React.FC<Props> = ({ alarmStore, alarmType, dataSource, period, priority }) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    const queryKey = QueryKeys.getAlarmStoreRecords(alarmStore.id, alarmType, priority, period, dataSource);
    const queryFn = () => getAlarmStoreRecords(alarmStore.id, alarmType, priority, period, dataSource);
    const { data: alarmStoreRecords, isLoading } = useHandledQuery(queryKey, queryFn, { enabled: inView });

    const unit = dataSource === 'duration' ? 'minute' : 'percent';

    const data = React.useMemo(() => {
        if (!alarmStoreRecords) {
            return [];
        }

        return alarmStoreRecords.map((item) => {
            const dateTime = DateTime.fromISO(item.date);

            const store = unit === 'minute' ? item.store.value / 60 : item.store.value;
            const overall = unit === 'minute' ? item.overall.value / 60 : item.overall.value;

            return {
                miliseconds: dateTime.toMillis(),
                unit: unit as 'minute' | 'percent',
                store,
                overall,
            };
        });
    }, [alarmStoreRecords]);

    const store = unit === 'minute' ? alarmStore.summary.storeAverage / 60 : alarmStore.summary.storeAverage;
    const overall = unit === 'minute' ? alarmStore.summary.overallAverage / 60 : alarmStore.summary.overallAverage;

    return (
        <CardUI
            ref={ref}
            unit={unit}
            isLoading={isLoading}
            data={data}
            region={alarmStore.region}
            zipCode={alarmStore.zipCode}
            town={alarmStore.town}
            street={alarmStore.street}
            notificationCount={alarmStore.summary.activeAlarms}
            overall={overall}
            store={store}
            deviation={alarmStore.summary.deviation}
            base64icon={alarmStore.logo ?? undefined}
            system={alarmStore.anlage}
        />
    );
};

export default Card;
