import { ApiEndpoints } from 'api/endpoints';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { EnergyMeterMetricType } from 'types/energyMeter/energyMeterMetricType';
import { EnergyMetricsSchema } from 'model/energyMetrics/schema';
import { makeQuery } from 'api/api';

export const getEnergyMetrics = async (storeId: number, energyMeterMetricType: EnergyMeterMetricType, dateInterval: EnergyMeterDateInterval, dateForm: string, dateTo: string) => {
    const response = await makeQuery(ApiEndpoints.getEnergyMetrics(storeId, energyMeterMetricType, dateForm, dateTo, dateInterval), true);
    return EnergyMetricsSchema.parse(response);
};
