import Content from 'components/content/content';
import ContentFilter from 'components/contentFilter/contentFilter';
import EnergyConsumptionPicker from 'components/energyConsumptionPicker/energyConsumptionPicker';
import React from 'react';
import EnergyConsumptionBody from '../../components/energyConsumptionBody/energyConsumptionBody';

const Consumption: React.FC = () => {
    return (
        <>
            <ContentFilter>
                <EnergyConsumptionPicker />
            </ContentFilter>
            <Content>
                <EnergyConsumptionBody />
            </Content>
        </>
    );
};

export default Consumption;
