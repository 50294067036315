import { AppState } from 'store';
import { Column, Filters, SortingRule } from 'react-table';
import { fetchStoreUsers } from 'api/fetchers/storeUsers';
import { QueryKeys } from 'api/queryKeys';
import { transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { User } from 'model/user/schema';
import { UsersTableAccessors } from 'types/users/usersTableAccessors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import Header from 'components/header/header';
import LoadingBar from 'components/loadingBar/loadingBar';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import SelectColumnFilter from 'components/table/components/selectColumnFilter/selectColumnFilter';
import settings from 'settings';
import useRoles, { getTranslatedRoleByRoleId } from 'shared/useRoles/useRoles';
import UsersTable from 'components/table/instances/usersTable/usersTable';
import UserState from 'components/userState/userState';

const UsersScreen: React.FC = () => {
    const { t } = useTranslation();
    const [pageIndex, setPageIndex] = React.useState<number>(0);
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const roles = useRoles();

    const { storeId } = useSelector((state: AppState) => state.store);

    const queryParams = {
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: transformReactTableFiltersForAPI([...(filters ? filters : [])]),
        dataRange: {
            from: pageIndex * settings.pageSize,
            to: pageIndex * settings.pageSize + (settings.pageSize - 1),
        },
        pageIndex,
    };

    const { data, isFetching } = useHandledQuery(
        [QueryKeys.users(), storeId as number, queryParams],
        () => (storeId ? fetchStoreUsers(storeId, false, queryParams.sort, queryParams.filter) : undefined),
        {
            keepPreviousData: true,
            enabled: Boolean(storeId),
        },
    );

    const columns = React.useMemo<Column<User>[]>(
        () => [
            {
                Header: t('users.table.heading.state').toString(),
                accessor: UsersTableAccessors.isDisabled,
                Cell: ({ value }) => {
                    return <UserState isDisabled={value} />;
                },
                disableSortBy: false,
                customStyles: {
                    width: '10%',
                },
            },
            {
                Header: t('users.table.heading.username').toString(),
                accessor: UsersTableAccessors.username,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.firstName').toString(),
                accessor: UsersTableAccessors.firstName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.lastName').toString(),
                accessor: UsersTableAccessors.lastName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.email').toString(),
                accessor: UsersTableAccessors.emailAddress,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '18%',
                },
            },
            {
                Header: t('users.table.heading.role').toString(),
                accessor: UsersTableAccessors.role,
                disableFilters: false,
                disableSortBy: false,
                Filter: ({ column }) => <SelectColumnFilter column={column} options={roles} />,
                Cell: ({ value }) => getTranslatedRoleByRoleId(value, t),
                customStyles: {
                    width: '18%',
                },
            },
        ],
        /*  with roles in deps not working */ // eslint-disable-next-line react-hooks/exhaustive-deps
        [t],
    );

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const handlePageIndexChange = (pageIndex: number) => {
        setPageIndex(pageIndex);
    };

    return (
        <>
            <Header />
            <Navigation />
            <Main>
                <Content>
                    <LoadingBar isFetching={isFetching} />
                    <UsersTable<User>
                        columns={columns}
                        data={data ? data.data : []}
                        isFetching={isFetching}
                        pageCount={data ? Math.ceil(data.metadata.count / settings.pageSize) : 0}
                        onFilter={handleFilters}
                        onPageIndexChange={handlePageIndexChange}
                        onSort={handleSort}
                    />
                </Content>
            </Main>
        </>
    );
};

export default UsersScreen;
