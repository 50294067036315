import { MyProfile } from 'model/myProfile/schema';

export const SET_AUTH = 'SET_AUTH';
export const SET_MY_PROFILE = 'SET_MY_PROFILE';

export interface AuthState {
    authenticated: boolean;
    myProfile?: MyProfile;
}

export interface SetAuthActionTypes {
    type: typeof SET_AUTH;
    authenticated: boolean;
}

export interface SetMyProfileActionTypes {
    type: typeof SET_MY_PROFILE;
    myProfile?: MyProfile;
}

export type AuthActionTypes = SetAuthActionTypes | SetMyProfileActionTypes;
