import { makeStyles } from '@material-ui/core/styles';
import { TextFieldProps } from './textFieldProps';
import clsx from 'clsx';
import MuiTextField from '@material-ui/core/TextField';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 500,
        color: theme.customPalette.text.primary,
        margin: 0,
        padding: 0,
        fontSize: theme.typography.pxToRem(16),
        '& $notchedOutline': {
            borderColor: theme.customPalette.border,
            borderRadius: theme.spacing(0.5),
        },
        '&:hover $notchedOutline': {
            borderColor: theme.customPalette.text.secondary,
        },
        '& fieldset': {
            fontSize: 'inherit',
        },
    },
    darkerBorder: {
        '& $notchedOutline': {
            borderColor: theme.customPalette.borderDarker,
        },
    },
    notchedOutline: {
        borderColor: theme.customPalette.border,
    },
    focused: {},
    input: {
        background: theme.customPalette.background.light,
        '&$focused $notchedOutline': {
            borderColor: theme.customPalette.colors.brand.light,
        },
        '&.multiline': {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
        },
    },
    inputNative: {
        padding: `${theme.spacing(2.65)} ${theme.spacing(2)}`,
    },
    small: {
        fontFamily: theme.typography.fontFamily,
        padding: `${theme.spacing(2 / 8)} ${theme.spacing(1)} ${theme.spacing(1 / 8)}`,
        fontSize: theme.typography.pxToRem(12),
        background: theme.customPalette.background.white,
        height: 'auto',
        minHeight: 'auto',
    },
    medium: {
        fontFamily: theme.typography.fontFamily,
        padding: `${theme.spacing(1.125)} ${theme.spacing(2)}`,
        fontSize: theme.typography.pxToRem(12),
        background: theme.customPalette.background.white,
        '& + fieldset': {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    mediumFormHelperText: {
        fontSize: theme.typography.pxToRem(12),
    },
    label: {
        color: theme.customPalette.placeholder,
        marginLeft: theme.typography.pxToRem(2),
        marginTop: theme.typography.pxToRem(2),
        '&.shrink': {
            color: theme.customPalette.text.secondary,
            marginTop: 0,
        },
    },
    labelMedium: {
        marginTop: theme.typography.pxToRem(-1),
        marginLeft: 0,
        fontSize: theme.typography.pxToRem(12),
    },
    labelSmall: {
        marginTop: theme.typography.pxToRem(-10),
        marginLeft: theme.typography.pxToRem(-6),
        fontSize: theme.typography.pxToRem(12),
    },
    adornedEnd: {
        paddingRight: theme.spacing(1),
        backgroundColor: 'transparent',
        '& svg': {
            fill: theme.customPalette.colors.brand.light,
        },
    },
    clearable: {
        '& $input, & $input input': {
            background: theme.customPalette.background.dark,
            fontWeight: 500,
            paddingRight: theme.typography.pxToRem(2),
        },
        '& .MuiSelect-root': {
            background: 'transparent',
        },
        '& $notchedOutline': {
            borderColor: theme.customPalette.border,
        },
    },
}));

const TextField: React.FC<TextFieldProps> = ({ children, clearable, endAdornment, size, id, label, type, ...rest }) => {
    const classes = useStyles();

    const isTypePassword = type === 'password';

    return (
        <MuiTextField
            className={clsx(classes.root, size === 'small' && classes.darkerBorder, clearable && classes.clearable)}
            FormHelperTextProps={{
                classes: {
                    root: clsx(size === 'medium' && classes.mediumFormHelperText),
                },
            }}
            fullWidth
            id={id}
            InputLabelProps={{
                classes: {
                    root: clsx(classes.label, size === 'medium' && classes.labelMedium, size === 'small' && classes.labelSmall),
                    shrink: 'shrink',
                },
            }}
            InputProps={{
                endAdornment,
                classes: {
                    root: classes.input,
                    input: clsx(classes.inputNative, size === 'small' && classes.small, size === 'medium' && classes.medium),
                    notchedOutline: classes.notchedOutline,
                    adornedEnd: classes.adornedEnd,
                    focused: classes.focused,
                    multiline: 'multiline',
                },
            }}
            label={label}
            size={size === 'medium' ? 'small' : undefined}
            type={isTypePassword ? 'password' : 'text'}
            variant="outlined"
            {...rest}
        >
            {children}
        </MuiTextField>
    );
};

export default TextField;
