import { clearToken, setToken } from 'localStorage/auth';
import { MyProfile } from 'model/myProfile/schema';
import { SET_AUTH, SET_MY_PROFILE, SetAuthActionTypes, SetMyProfileActionTypes } from 'store/auth/types';
import { setUserLoggedIn } from 'cookies/auth';
import { store } from 'store';

const auth = (authenticated: boolean): SetAuthActionTypes => {
    return {
        type: SET_AUTH,
        authenticated,
    };
};

export const myProfile = (myProfile?: MyProfile): SetMyProfileActionTypes => {
    return {
        type: SET_MY_PROFILE,
        myProfile,
    };
};

export const signIn = (token: string) => {
    setToken(token);
    setUserLoggedIn();
    store.dispatch(auth(true));
};

export const logout = () => {
    clearToken();
    store.dispatch(auth(false));
    store.dispatch(myProfile(undefined));
};
