import { changeUserActiveStatusErrorCodes, mutatePostUserActiveStatus } from 'api/mutations/postUserActiveStatus';
import { EditUserActiveStatusButtonProps } from 'components/editUserActiveStatusButton/editUserActiveStatusButtonProps';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import React from 'react';

const EditUserActiveStatusButton: React.FC<EditUserActiveStatusButtonProps> = ({ user }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isError, isSuccess, reset, isLoading } = useHandledMutation(mutatePostUserActiveStatus, undefined, changeUserActiveStatusErrorCodes);

    const { id, isDisabled } = user;

    const [isUserDisabled, setIsUserDisabled] = React.useState<boolean>(isDisabled);

    React.useEffect(() => {
        if (isSuccess) {
            reset();
            setIsUserDisabled(!isUserDisabled);
            enqueueSnackbar(isUserDisabled ? t('editUserActiveStatusButton.successSubmitUserEnabled') : t('editUserActiveStatusButton.successSubmitUserDisabled'));
        }
        if (isError) {
            enqueueSnackbar(t('editUserActiveStatusButton.error'));
        }
    }, [isSuccess, enqueueSnackbar, isUserDisabled, reset, t, isError]);

    const handleSubmit = () => {
        mutate({ userId: id, isDisabled: isUserDisabled });
    };

    return (
        <Button isLoading={isLoading} size="large" onClick={handleSubmit}>
            {isUserDisabled ? t('editUserActiveStatusButton.enable') : t('editUserActiveStatusButton.disable')}
        </Button>
    );
};

export default EditUserActiveStatusButton;
