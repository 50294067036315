import { makeStyles } from '@material-ui/core/styles';

export const useEnergyPowerPickerStyles = makeStyles((theme) => ({
    metricTypeOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginRight: `calc(50% + ${theme.spacing(1)})`,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: theme.typography.pxToRem(110),
            marginBottom: 'auto',
            marginRight: 'auto',
        },
    },
    dateFromOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginRight: theme.spacing(1),
        '& p': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: theme.typography.pxToRem(110),
        },
    },
    dateToOuter: {
        width: `calc(50% - ${theme.spacing(1)})`,
        marginLeft: theme.spacing(1),
        '& p': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            width: theme.typography.pxToRem(110),
        },
    },
}));
