import { AppState } from 'store';
import { Box, useTheme } from '@material-ui/core';
import { getUser } from 'api/fetchers/getUser';
import { QueryKeys } from 'api/queryKeys';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import EditUserActiveStatusButton from 'components/editUserActiveStatusButton/editUserActiveStatusButton';
import Grid from '@material-ui/core/Grid';
import HeadingOne from 'components/typography/headingOne/headingOne';
import Paragraph from 'components/typography/paragraph/paragraph';
import React from 'react';
import ResetPasswordByAdmin from 'components/resetPasswordByAdmin/resetPasswordByAdmin';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UserForm from 'components/userForm/userForm';

const UserDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { data } = useHandledQuery(QueryKeys.userDetail(parseInt(id)), () => getUser({ id: parseInt(id) }), { enabled: !!id });
    const myProfile = useSelector((state: AppState) => state.auth.myProfile);

    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

    if (!data || !myProfile) {
        return null;
    }

    const userEditingHimself = myProfile.id === parseInt(id);

    return (
        <>
            <Content>
                <Box p={3}>
                    <Grid container>
                        <Grid item lg={5} md={6} sm={12} xs={12}>
                            <Grid container>
                                <Grid item sm={6} xs={12}>
                                    <HeadingOne>
                                        {data.firstName} {data.lastName}
                                    </HeadingOne>
                                    <Paragraph>{userEditingHimself ? t('user.userSettings.youAreEditingYourself') : t('user.userSettings.youAreEditingAnotherUser')}</Paragraph>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Box display="flex" justifyContent="flex-end" mt={isXsDown ? 2 : 0}>
                                        <ResetPasswordByAdmin user={data} />
                                        {!userEditingHimself && (
                                            <Box ml={1}>
                                                <EditUserActiveStatusButton user={data} />
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box mt={3} />
                            <UserForm />
                        </Grid>
                    </Grid>
                </Box>
            </Content>
        </>
    );
};

export default UserDetail;
