import * as z from 'zod';

export const AssetBucketSchema = z.object({
    bucket: z.string(),
    setPoint: z.number().nullable(),
    setPointAlt: z.number().nullable(),
    temperature: z.number().nullable(),
    defrostOn: z.number().nullable(),
    setPointAltOn: z.number().nullable(),
});

export const AssetSchema = z.object({
    data: z.object({ shortName: z.string(), longName: z.string(), canAddress: z.number(), zone: z.string(), id: z.number(), buckets: z.array(AssetBucketSchema) }),
    metadata: z.object({ availableAssets: z.array(z.object({ id: z.number(), name: z.string() })) }),
});

export type AssetBucket = z.infer<typeof AssetBucketSchema>;
export type Asset = z.infer<typeof AssetSchema>;
