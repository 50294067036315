import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as ZoomInIcon } from 'assets/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg';
import { Layer, Rect, Stage, Text } from 'react-konva';
import { makeStyles } from '@material-ui/core/styles';
import { ReportingStoreLiveStructureStore } from '../../model/reportingStoreLive/schema';
import Button from '../../components/button/button';
import Konva from 'konva';
import React from 'react';
import useDebouncedResizeObserver from '../../hooks/useDebouncesObserverResize';
import { UNASSIGNED_ITEM_WIDTH, UNASSIGNED_ITEM_HEIGHT, UNASSIGNED_ITEM_GAP, UNPLACED_POSITIONS_TEXT_WIDTH, STORE_PADDING, UI_DEBUG } from './consts';
import ItemToRender from './itemToRender';
import TextField from '../../components/textField/textField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../routes/routes';
import StaticPolyItemToRender from './staticPolyItemToRender';
import StaticTextItemToRender from './staticTextItemToRender';

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            fill: '#267dff',
            marginRight: -8,
        },
        controlButton: {
            marginRight: 8,
            background: '#fff',
        },
    };
});

const LayoutCanvas = React.forwardRef(
    (
        {
            store,
            getDefaultScale,
            toggleFullscreen,
            onWheel,
            currentZoom,
            zoomFunction,
            isOffline,
            isFullscreen,
            highlightedAsset,
        }: {
            store: ReportingStoreLiveStructureStore;
            onWheel: (e: Konva.KonvaEventObject<WheelEvent>) => void;
            getDefaultScale: (scale: number) => void;
            toggleFullscreen: () => void;
            currentZoom: number;
            zoomFunction: (ratio: number, relative: boolean) => void;
            isOffline: boolean;
            isFullscreen: boolean;
            highlightedAsset: number | null;
        },
        stageRef: any,
    ) => {
        const { t } = useTranslation();
        const history = useHistory();
        const classes = useStyles();
        const { ref: canvasOuterRef, width: canvasOuterWidth, height: canvasOuterHeight } = useDebouncedResizeObserver(500);
        const testTextRef = React.useRef<any>();
        const canvasRef = React.useRef<any>();
        const cursorOverlayRef = React.useRef<any>();

        React.useEffect(() => {
            if (canvasRef && canvasRef.current) {
                canvasRef.current.imageSmoothingEnabled(false);
            }
        }, [canvasOuterRef]);

        if (!store.layout) {
            return null;
        }

        const { items, canvas } = store.layout;
        const assetsWithAlarmsIds = store.alarms.flatMap((item) => item.assetId);
        const assetIdsConnectedToItems = items.flatMap((item) => item.assetId);
        const assetsUnassigned = store.assets.filter((asset) => !assetIdsConnectedToItems.includes(asset.assetId));

        const possibleItemsInLine = Math.floor((canvas.dimensions.width - STORE_PADDING * 2) / (UNASSIGNED_ITEM_WIDTH + UNASSIGNED_ITEM_GAP));
        const possibleUnassignedItemsInLine = Math.floor((canvas.dimensions.width - UNPLACED_POSITIONS_TEXT_WIDTH - STORE_PADDING * 2) / (UNASSIGNED_ITEM_WIDTH + UNASSIGNED_ITEM_GAP));

        const unassignedItemsRowCount = Math.ceil(assetsUnassigned.length / possibleUnassignedItemsInLine);

        const countedWidth = items.length > 0 ? Math.max(...items.map(({ position, dimensions }) => position.x + dimensions.width)) : 2300;
        const countedHeight = items.length > 0 ? Math.max(...items.map(({ position, dimensions }) => position.y + dimensions.height)) : 400;

        const originalDimensions = {
            width: countedWidth,
            height: countedHeight,
        };
        const extendedDimensions = {
            width: originalDimensions.width,
            height: originalDimensions.height + unassignedItemsRowCount * (UNASSIGNED_ITEM_HEIGHT + UNASSIGNED_ITEM_GAP) + STORE_PADDING * 2 - UNASSIGNED_ITEM_GAP,
        };

        const originalStageHeight = extendedDimensions.height + 2 * STORE_PADDING;
        const originalStageWidth = extendedDimensions.width + 2 * STORE_PADDING;

        const finalStageWidth = originalStageWidth < canvasOuterWidth ? canvasOuterWidth : originalStageWidth;
        const finalStageHeight = originalStageHeight < canvasOuterHeight ? canvasOuterHeight : originalStageHeight;

        const heightScale = canvasOuterHeight / originalStageHeight;
        const widthScale = canvasOuterWidth / originalStageWidth;
        const scale = heightScale < widthScale ? heightScale : widthScale;

        getDefaultScale(scale);

        const onMouseDown = () => {
            if (cursorOverlayRef && cursorOverlayRef.current) {
                cursorOverlayRef.current.style.cursor = 'grabbing';
            }
        };
        const onMouseUp = () => {
            if (cursorOverlayRef && cursorOverlayRef.current) {
                cursorOverlayRef.current.style.cursor = 'grab';
            }
        };

        const onItemClick = (assetId: number | null) => {
            if (assetId !== null) {
                history.push(routes.asset.path(assetId), { goBackPath: routes.layout.path() });
            }
        };

        const zoomData = [Math.round(currentZoom * 100), 80, 100, 125, 150, 200];

        const { staticItems } = store.layout;

        const isSmall = 14 * currentZoom * scale < 9;

        return (
            <Box style={{ opacity: isOffline ? 0.5 : 1 }} sx={{ width: '100%', height: `calc(100vh - ${isFullscreen ? '120px' : '230px'})` }}>
                <div ref={cursorOverlayRef} style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative', cursor: 'grab' }} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                    <div style={{ position: 'absolute', right: 8, top: 16, zIndex: 1, display: 'flex' }}>
                        <Button className={classes.controlButton} icon={<ZoomOutIcon className={classes.icon} />} onClick={() => zoomFunction(0.9, true)} />
                        <Box mr={1} style={{ width: 75 }}>
                            <TextField
                                className={classes.controlButton}
                                select={true}
                                size="small"
                                value={zoomData[0].toString()}
                                onChange={(e) => zoomFunction(parseInt(e.target.value) / 100, false)}
                            >
                                {zoomData.map((item, i) => (
                                    <MenuItem key={item} style={{ display: i === 0 ? 'none' : 'block' }} value={item}>
                                        {item + ' %'}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        <Button className={classes.controlButton} icon={<ZoomInIcon className={classes.icon} />} onClick={() => zoomFunction(1.1, true)} />
                        <Button className={classes.controlButton} icon={<ExpandIcon className={classes.icon} />} onClick={toggleFullscreen} />
                    </div>
                    <div ref={canvasOuterRef} style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <Stage draggable={true} fillPattern="green" height={finalStageHeight} ref={stageRef} scaleX={scale} scaleY={scale} width={finalStageWidth} onWheel={onWheel}>
                            <Layer ref={canvasRef} x={STORE_PADDING} y={STORE_PADDING}>
                                <Text align="left" fontFamily="Fira Sans" fontSize={14} ref={testTextRef} text={''} width={2} x={0} y={0} />
                                <Rect
                                    fill="orange"
                                    height={originalDimensions.height + 2 * STORE_PADDING}
                                    visible={UI_DEBUG}
                                    width={originalDimensions.width + 2 * STORE_PADDING}
                                    x={-STORE_PADDING}
                                    y={-STORE_PADDING}
                                />
                                <Rect fill="red" height={originalDimensions.height} visible={UI_DEBUG} width={originalDimensions.width} x={0} y={0} />
                                {staticItems?.map((item, i) => {
                                    if (item.type === 'BackgroundElement') {
                                        return <StaticPolyItemToRender {...item} key={i} />;
                                    }
                                    if (item.type === 'Label') {
                                        return <StaticTextItemToRender {...item} key={i} />;
                                    }
                                    return null;
                                })}
                                {/*- pokud mam layout ale nemam k nemu storeID. assetid===null, tak vykraslim tu pozici jako sedivou (neaktivni)*/}
                                {items.map((item, i) => {
                                    const asset = store.assets.find((asset) => asset.assetId === item.assetId);
                                    const {
                                        dimensions: { width, height },
                                        position: { x, y },
                                        assetId,
                                    } = item;

                                    const alarm = item.assetId && store.alarms.find(({ assetId }) => assetId === item.assetId);
                                    const altHeaderText = item.shortname || undefined;
                                    return (
                                        <ItemToRender
                                            key={i}
                                            alarmContent={(alarm && alarm.event) || undefined}
                                            alarmEndOn={(alarm && alarm.endOn) || undefined}
                                            alarmPriority={(alarm && alarm.priority) || undefined}
                                            altHeaderText={altHeaderText}
                                            asset={asset}
                                            assetId={item.assetId}
                                            bottom={y > finalStageHeight / 2}
                                            customColor={item.style.color}
                                            hasAlarm={assetId ? assetsWithAlarmsIds.includes(assetId) : false}
                                            height={height}
                                            highlightedAsset={highlightedAsset}
                                            isSmall={isSmall}
                                            longName={asset?.longName}
                                            right={x > finalStageWidth / 2}
                                            shortName={asset?.shortName}
                                            temperatureMerged={asset?.dataseries?.Temp && asset?.dataseries?.Temp.value ? `${asset.dataseries.Temp.value} ${asset.dataseries.Temp.unit}` : undefined}
                                            width={width}
                                            x={x}
                                            y={y}
                                            zone={asset?.zone}
                                            onItemClick={onItemClick}
                                        />
                                    );
                                })}
                            </Layer>
                            <Layer x={UNPLACED_POSITIONS_TEXT_WIDTH + UNASSIGNED_ITEM_GAP + STORE_PADDING} y={originalDimensions.height + STORE_PADDING * 3}>
                                <Rect
                                    fill="purple"
                                    height={originalDimensions.height + 2 * STORE_PADDING}
                                    visible={UI_DEBUG}
                                    width={originalDimensions.width + 2 * STORE_PADDING}
                                    x={-STORE_PADDING - UNPLACED_POSITIONS_TEXT_WIDTH - UNASSIGNED_ITEM_GAP}
                                    y={-STORE_PADDING}
                                />
                                <Rect
                                    fill="red"
                                    height={extendedDimensions.height - originalDimensions.height - STORE_PADDING * 2}
                                    visible={UI_DEBUG}
                                    width={originalDimensions.width}
                                    x={-UNASSIGNED_ITEM_GAP - UNPLACED_POSITIONS_TEXT_WIDTH}
                                    y={0}
                                />
                                <Text
                                    align="left"
                                    fontFamily="Fira Sans"
                                    fontSize={14}
                                    text={t('unplacedPositions')}
                                    width={UNPLACED_POSITIONS_TEXT_WIDTH}
                                    x={-UNPLACED_POSITIONS_TEXT_WIDTH - UNASSIGNED_ITEM_GAP}
                                    y={4}
                                />
                                {assetsUnassigned.map((item, i) => {
                                    const row = Math.floor(i / possibleUnassignedItemsInLine);
                                    const { assetId } = item;
                                    const alarm = assetId && store.alarms.find(({ assetId }) => assetId === item.assetId);
                                    return (
                                        <ItemToRender
                                            alarmContent={(alarm && alarm.event) || undefined}
                                            alarmEndOn={(alarm && alarm.endOn) || undefined}
                                            alarmPriority={(alarm && alarm.priority) || undefined}
                                            asset={item}
                                            assetId={assetId}
                                            bottom={true}
                                            hasAlarm={assetId ? assetsWithAlarmsIds.includes(assetId) : false}
                                            height={UNASSIGNED_ITEM_HEIGHT}
                                            highlightedAsset={highlightedAsset}
                                            isSmall={isSmall}
                                            longName={item?.longName}
                                            right={i > possibleItemsInLine / 2}
                                            temperatureMerged={item?.dataseries?.Temp && item?.dataseries?.Temp.value ? `${item.dataseries.Temp.value} ${item.dataseries.Temp.unit}` : undefined}
                                            width={UNASSIGNED_ITEM_WIDTH}
                                            x={(UNASSIGNED_ITEM_WIDTH + UNASSIGNED_ITEM_GAP) * (i % possibleUnassignedItemsInLine)}
                                            y={row * (UNASSIGNED_ITEM_HEIGHT + UNASSIGNED_ITEM_GAP)}
                                            zone={item.zone}
                                        />
                                    );
                                })}
                            </Layer>
                        </Stage>
                    </div>
                </div>
            </Box>
        );
    },
);

export default LayoutCanvas;
