export enum ReportingTableHoursAccessors {
    h00 = 'hours.h0',
    h01 = 'hours.h1',
    h02 = 'hours.h2',
    h03 = 'hours.h3',
    h04 = 'hours.h4',
    h05 = 'hours.h5',
    h06 = 'hours.h6',
    h07 = 'hours.h7',
    h08 = 'hours.h8',
    h09 = 'hours.h9',
    h10 = 'hours.h10',
    h11 = 'hours.h11',
    h12 = 'hours.h12',
    h13 = 'hours.h13',
    h14 = 'hours.h14',
    h15 = 'hours.h15',
    h16 = 'hours.h16',
    h17 = 'hours.h17',
    h18 = 'hours.h18',
    h19 = 'hours.h19',
    h20 = 'hours.h20',
    h21 = 'hours.h21',
    h22 = 'hours.h22',
    h23 = 'hours.h23',
}
