import { getUser } from 'api/fetchers/getUser';
import { QueryKeys } from 'api/queryKeys';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import ContentTabs from 'components/contentTabs/contentTabs';
import Header from 'components/header/header';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import userAccesses from 'screens/user/userAccesses';
import userDetail from 'screens/user/userDetail';

const UserScreen: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id: userIdRaw } = useParams<{ id: string }>();

    const { data } = useHandledQuery(QueryKeys.userDetail(parseInt(userIdRaw)), () => getUser({ id: parseInt(userIdRaw) }), { enabled: !!userIdRaw });
    const userId = parseInt(userIdRaw);

    const items = [
        {
            id: 'userSettings',
            path: routes.userDetail.path(userId),
            text: t('user.userSettings.title'),
        },
        {
            id: 'organizationStructure',
            path: routes.userAccessesDetail.path(userId),
            parentPath: routes.userAccesses.path(userId),
            text: t('user.userAccesses.title'),
        },
    ];

    const goBackAction = () => {
        history.push(routes.users.path());
    };

    if (!data) {
        return null;
    }

    return (
        <>
            <Header goBackAction={goBackAction} goBackText={t('navigation.storeUsers')} headingText={`${data.firstName} ${data.lastName}`} />
            <Navigation />
            <Main>
                <ContentTabs items={items} />
                <Content>
                    <Switch>
                        <Route component={userDetail} path={routes.userDetail.pattern} />
                        <Route component={userAccesses} path={routes.userAccesses.pattern} />
                    </Switch>
                </Content>
            </Main>
        </>
    );
};

export default UserScreen;
