const ENERGY_POWER_AVAILABLE_DATES = 'ENERGY_POWER_AVAILABLE_DATES';

interface EnergyPowerAvailableDates {
    availableDateFrom: number;
    availableDateTo: number;
}

export const setEnergyPowerAvailableDates = (filter: EnergyPowerAvailableDates) => {
    localStorage.setItem(ENERGY_POWER_AVAILABLE_DATES, JSON.stringify(filter));
};
export const hasEnergyPowerAvailableDates = () => Boolean(localStorage.getItem(ENERGY_POWER_AVAILABLE_DATES));
export const getEnergyPowerAvailableDates = () => {
    const value = localStorage.getItem(ENERGY_POWER_AVAILABLE_DATES);
    return value ? JSON.parse(value) : undefined;
};
export const clearEnergyPowerAvailableDates = () => {
    localStorage.removeItem(ENERGY_POWER_AVAILABLE_DATES);
};
