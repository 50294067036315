const STORE_ID = 'storeId';

export const setStoreId = (token: string) => {
    localStorage.setItem(STORE_ID, token);
};
export const hasStoreId = () => Boolean(localStorage.getItem(STORE_ID));
export const getStoreId = () => localStorage.getItem(STORE_ID);
export const clearStoreId = () => {
    localStorage.removeItem(STORE_ID);
};
