import * as z from 'zod';
import { Role } from './role';

export const CapabilitySchema = z.object({
    capability: z.string(), // if BE adds one, it breaks system. Should be- z.nativeEnum(Capabilities),
    authorized: z.boolean(),
});

export const MyProfileSchema = z.object({
    id: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    username: z.string(),
    emailAddress: z.string(),
    role: z.nativeEnum(Role),
    languageId: z.number(),
    timeZone: z.string(),
    capabilities: z.array(CapabilitySchema),
});

export type MyProfile = z.infer<typeof MyProfileSchema>;
