import { Box, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { QueryKeys } from 'api/queryKeys';
import { getAlarmTypes } from 'api/fetchers/alarmTypes';
import { useTranslation } from 'react-i18next';

type Props = {
    onChange: (value: string) => void;
};

const AlarmTypes: React.FC<Props> = ({ onChange }) => {
    const { t } = useTranslation();
    const { data: alarmTypes } = useHandledQuery(QueryKeys.getAlarmTypes('alarmPage'), () => getAlarmTypes('alarmPage'));

    const {
        control,
        watch,
        formState: { isDirty },
    } = useHandledForm();
    const alarmType = watch('alarmType');

    useEffect(() => {
        if (isDirty) {
            onChange(alarmType);
        }
    }, [alarmType, isDirty]);

    if (!alarmTypes) {
        return null;
    }

    return (
        <Box alignItems="center" display="flex">
            <div>
                <TextFieldRHF control={control} defaultValue={'all'} name="alarmType" select size="small">
                    <MenuItem value={'all'}>{t('alarm.typesFilter.all')}</MenuItem>
                    {alarmTypes.map(({ code, label }) => {
                        return (
                            <MenuItem key={code} value={code}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </TextFieldRHF>
            </div>
        </Box>
    );
};

export default AlarmTypes;
