import { Box, FormControlLabel } from '@material-ui/core';
import { ListProps } from 'components/organizationStructure/list/ListProps';
import { makeStyles } from '@material-ui/core/styles';
import { OrganizationStructureItem, OrganizationStructureRegion, OrganizationStructureStore } from 'model/organizationStructure/schema';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/checkbox/checkbox';
import NoResults from 'components/noResults/noResults';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.typography.pxToRem(40),
            },
        },
        customer: {
            margin: theme.spacing(3),
            paddingLeft: theme.typography.pxToRem(19),
            lineHeight: theme.typography.pxToRem(56),
            minHeight: theme.typography.pxToRem(56),
            boxShadow: theme.customElevation.default,
            overflow: 'auto',
        },
        region: {
            marginLeft: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(56),
            minHeight: theme.typography.pxToRem(56),
            borderBottom: '1px solid ' + theme.customPalette.border,
            '&:last-child': {
                borderBottom: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.typography.pxToRem(5),
            },
        },
        store: {
            marginLeft: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(56),
            minHeight: theme.typography.pxToRem(56),
            borderBottom: '1px solid ' + theme.customPalette.border,
            '&:last-child': {
                borderBottom: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.typography.pxToRem(5),
            },
        },
        arrow: {
            margin: `0 ${theme.spacing(1)}`,
            display: 'inline-block',
        },
        label: {
            float: 'right',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
            margin: `${theme.typography.pxToRem(18)} ${theme.spacing(2)} ${theme.typography.pxToRem(18)} 0`,
            height: theme.typography.pxToRem(24),
            lineHeight: theme.typography.pxToRem(24),
            fontSize: theme.typography.pxToRem(10),
            background: theme.customPalette.background.dark,
        },
        clear: {
            clear: 'both',
            width: '100%',
        },
        checkbox: {
            marginRight: theme.spacing(1),
        },
        checkboxLabel: {
            color: theme.customPalette.text.primary,
        },
    };
});

const List: React.FC<ListProps> = ({ accessCustomers, accessRegions, accessStores, data, allowEdit, changeCustomerAccess, changeRegionAccess, changeStoreAccess }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {data?.data.length === 0 && <NoResults />}
            {data &&
                data.data.map((customer: OrganizationStructureItem) => {
                    const customerKey = `customer-${customer.id}`;
                    const customerAccess = accessCustomers ? accessCustomers.includes(customer.id) : customer.access;
                    return (
                        <Box key={customerKey} className={classes.customer}>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={customerAccess}
                                            className={classes.checkbox}
                                            disabled={!allowEdit}
                                            id={customerKey}
                                            onClick={() => {
                                                if (changeCustomerAccess) {
                                                    changeCustomerAccess(customer.id);
                                                }
                                            }}
                                        />
                                    }
                                    label={<div className={classes.checkboxLabel}>{customer.customer}</div>}
                                />
                                <span className={classes.label}> {t('organizationStructure.list.customer')}</span>
                                <div className={classes.clear} />
                            </Box>
                            {customer.regions.map((region: OrganizationStructureRegion) => {
                                const regionKey = `region-${region.id}`;
                                const regionAccess = accessRegions ? accessRegions.includes(region.id) : region.access || customerAccess;
                                return (
                                    <Box key={regionKey} className={classes.region}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={regionAccess}
                                                    className={classes.checkbox}
                                                    disabled={!allowEdit}
                                                    id={regionKey}
                                                    onClick={() => {
                                                        if (changeRegionAccess) {
                                                            changeRegionAccess(region.id);
                                                        }
                                                    }}
                                                />
                                            }
                                            label={
                                                <div className={classes.checkboxLabel}>
                                                    {customer.customer}
                                                    <div className={classes.arrow}>
                                                        <ArrowRightIcon />
                                                    </div>
                                                    {region.region}
                                                </div>
                                            }
                                        />
                                        <span className={classes.label}> {t('organizationStructure.list.region')}</span>
                                        <div className={classes.clear} />
                                        {region.stores.map((store: OrganizationStructureStore) => {
                                            const storeKey = `store-${store.id}`;
                                            const storeAccess = accessStores ? accessStores.includes(store.id) : store.access || regionAccess || (customerAccess && !regionAccess);
                                            return (
                                                <Box key={storeKey} className={classes.store}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={storeAccess}
                                                                className={classes.checkbox}
                                                                disabled={!allowEdit}
                                                                id={storeKey}
                                                                onClick={() => {
                                                                    if (changeStoreAccess) {
                                                                        changeStoreAccess(store.id);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <div className={classes.checkboxLabel}>
                                                                {customer.customer}
                                                                <div className={classes.arrow}>
                                                                    <ArrowRightIcon />
                                                                </div>
                                                                {region.region}
                                                                <div className={classes.arrow}>
                                                                    <ArrowRightIcon />
                                                                </div>
                                                                {store.name}
                                                                {" | "}
                                                                {store.anlage}
                                                            </div>
                                                        }
                                                    />
                                                    <span className={classes.label}> {t('organizationStructure.list.store')}</span>
                                                    <div className={classes.clear} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                );
                            })}
                        </Box>
                    );
                })}
        </div>
    );
};

export default List;
