const ENERGY_POWER_FILTER = 'ENERGY_POWER_FILTER';

interface EnergyPowerFilter {
    dateTo: number;
    dateFrom: number;
    energyMetricType: string;
}

export const setEnergyPowerFilter = (filter: EnergyPowerFilter) => {
    localStorage.setItem(ENERGY_POWER_FILTER, JSON.stringify(filter));
};
export const hasEnergyPowerFilter = () => Boolean(localStorage.getItem(ENERGY_POWER_FILTER));
export const getEnergyPowerFilter = () => {
    const value = localStorage.getItem(ENERGY_POWER_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearEnergyPowerFilter = () => {
    localStorage.removeItem(ENERGY_POWER_FILTER);
};
