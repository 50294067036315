import Card from '../card/card';
import CardSkeleton from '../card/components/cardSkeleton';
import { Grid } from '@material-ui/core';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { QueryKeys } from 'api/queryKeys';
import { getAlarmStores } from 'api/fetchers/alarmStores';
import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';
import { useDispatch } from 'react-redux';
import { storeId } from 'store/store/actions';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useQueryClient } from 'react-query';

type Props = {
    alarmType: string;
    period: DashboardPeriod;
    dataSource: DashboardDataSource;
    priority: DashboardAlarmPriority;
    searchPhrase: string;
    sortDirection: 'asc' | 'desc';
};

const CardList: React.FC<Props> = ({ alarmType, dataSource, period, priority, searchPhrase, sortDirection }) => {
    const queryKey = QueryKeys.getAlarmStores(alarmType, priority, period, dataSource, searchPhrase, sortDirection);
    const queryFn = () => getAlarmStores(alarmType, priority, period, dataSource, searchPhrase, sortDirection);
    const dispatch = useDispatch();
    const history = useHistory();
    const queryClient = useQueryClient();

    const { data: alarmStores, isLoading: alarmStoresLoading } = useHandledQuery(queryKey, queryFn);

    const handleClick = (id: number) => {
        dispatch(storeId(id));
        queryClient.clear();
        history.push(routes.alarmListView.path());
    };

    return (
        <Grid container spacing={3}>
            {alarmStores &&
                alarmStores.map((item, index) => (
                    <Grid key={index} item md={4} xs={12} sm={6} onClick={() => handleClick(item.id)}>
                        <Card alarmStore={item} alarmType={alarmType} dataSource={dataSource} period={period} priority={priority} />
                    </Grid>
                ))}

            {alarmStoresLoading && (
                <>
                    <Grid item md={4} xs={12} sm={6}>
                        <CardSkeleton opacity="high" />
                    </Grid>
                    <Grid item md={4} xs={12} sm={6}>
                        <CardSkeleton opacity="medium" />
                    </Grid>
                    <Grid item md={4} xs={12} sm={6}>
                        <CardSkeleton opacity="low" />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default CardList;
