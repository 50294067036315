import { StatisticsMobileListProps } from 'components/statisticsMobileList/statisticsMobileListProps';
import Box from '@material-ui/core/Box';
import React from 'react';
import StatisticsMobileListItem from 'components/statisticsMobileListItem/statisticsMobileListItem';
import StatisticsMobileListMonthlyItem from 'components/StatisticsMobileListMonthlyItem/statisticsMobileListMonthlyItem';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

const StatisticsMobileList: React.FC<StatisticsMobileListProps> = ({ data }) => {
    const { dateFilterType } = useSelector((state: AppState) => state.filters.foodSafetyStatistics);
    if (!data) {
        return null;
    }
    return (
        <Box display="flex" flexDirection="column">
            {data.map((item) => {
                const key = item.longName + item.shortName + item.zone;
                if (dateFilterType === 'weeks') {
                    // @ts-ignore
                    return <StatisticsMobileListItem key={key} data={item} />;
                } else {
                    // @ts-ignore
                    return <StatisticsMobileListMonthlyItem key={key} data={item} />;
                }
            })}
        </Box>
    );
};

export default StatisticsMobileList;
