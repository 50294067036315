import { EnergyMeterMetricType } from '../../types/energyMeter/energyMeterMetricType';
import { useTranslation } from 'react-i18next';

const useSelectTypes = () => {
    const { t } = useTranslation();
    const metricTypesArray = [
        {
            key: EnergyMeterMetricType.powerElectricity,
            value: t('energyPowerPicker.metricType.electricity'),
        },
        {
            key: EnergyMeterMetricType.powerHeating,
            value: t('energyPowerPicker.metricType.heating'),
        },
        {
            key: EnergyMeterMetricType.powerWater,
            value: t('energyPowerPicker.metricType.water'),
        },
    ];

    return {
        metricTypesArray,
    };
};

export default useSelectTypes;
