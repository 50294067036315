import { AppState } from 'store';
import { useSelector } from 'react-redux';

const useCapabilities = () => {
    const data = useSelector((state: AppState) => state.auth.myProfile);
    const isAuthorized = (checkCapability: string): boolean | undefined => {
        return data?.capabilities.some((capability) => capability.capability === checkCapability && capability.authorized);
    };

    return {
        isAuthorized,
    };
};

export default useCapabilities;
