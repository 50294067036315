import { LightSeparatorType } from 'components/table/components/lightSeparator/lightSeparatorType';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { routes } from 'routes/routes';
import { StatisticsMobileListItemProps } from 'components/statisticsMobileListItem/statisticsMobileListItemProps';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import DayTemperature from 'components/statisticsMobileListItem/parts/dayTemperature/dayTemperature';
import Grid from '@material-ui/core/Grid';
import Heading from 'components/statisticsMobileListItem/parts/heading/heading';
import LightSeparator from 'components/table/components/lightSeparator/lightSeparator';
import React from 'react';
import Status from 'components/status/status';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import ValueWithPercent from 'components/table/components/valueWithPercent/valueWithPercent';
import { InView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottomColor: theme.customPalette.border,
        borderBottomWidth: theme.typography.pxToRem(1),
        borderBottomStyle: 'solid',
    },
    value: {
        color: theme.customPalette.text.primary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
    },
    label: {
        color: theme.customPalette.text.secondary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(8),
    },
    weekTable: {
        borderCollapse: 'collapse',
        lineHeight: 0.8,
        '& th': {
            width: 'calc(100% / 7)',
            color: theme.customPalette.text.secondary,
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(10),
            paddingBottom: theme.spacing(1),
        },
        '& td': {
            textAlign: 'center',
            padding: `0 ${theme.spacing(0.5)}`,
        },
    },
}));

const StatisticsMobileListItem: React.FC<StatisticsMobileListItemProps> = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        id,
        longName,
        zone,
        shortName,
        status,
        defrostHours,
        deviation2kCount,
        deviation4kCount,
        deviation2kPercent,
        deviation4kPercent,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
    } = data;
    return (
        <InView triggerOnce={false}>
            {({ ref, inView }) => (
                // @ts-ignore
                <Box className={classes.root} p={2} ref={ref}>
                    {inView ? (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div className={classes.label}>{t('foodSafety.statistics.table.heading.coolingPosition')}</div>
                                <div className={classes.value}>
                                    <Link to={routes.asset.path(id)}>{longName}</Link>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    <Heading label={t('foodSafety.statistics.table.heading.case')}>
                                        <ValueDisplay value={zone} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.position')}>
                                        <ValueDisplay value={shortName} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.status')}>
                                        <Status type={status} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.defrostHrs')}>
                                        <ValueDisplay value={defrostHours} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.between2kAnd4k')}>
                                        <ValueWithPercent count={deviation2kCount} percent={deviation2kPercent} />
                                    </Heading>
                                    <Heading label={t('foodSafety.statistics.table.heading.greaterThan4k')}>
                                        <ValueWithPercent count={deviation4kCount} percent={deviation4kPercent} />
                                    </Heading>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Box alignItems="flex-end" display="flex" flexDirection="column" height="100%" justifyContent="flex-end">
                                    <SunIcon />
                                    <LightSeparator type={LightSeparatorType.horizontal} />
                                    <MoonIcon />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <table className={classes.weekTable}>
                                    <thead>
                                        <tr>
                                            <th>{t('days.sunday.short')}</th>
                                            <th>{t('days.monday.short')}</th>
                                            <th>{t('days.tuesday.short')}</th>
                                            <th>{t('days.wednesday.short')}</th>
                                            <th>{t('days.thursday.short')}</th>
                                            <th>{t('days.friday.short')}</th>
                                            <th>{t('days.saturday.short')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <DayTemperature day={sunday} />
                                            <DayTemperature day={monday} />
                                            <DayTemperature day={tuesday} />
                                            <DayTemperature day={wednesday} />
                                            <DayTemperature day={thursday} />
                                            <DayTemperature day={friday} />
                                            <DayTemperature day={saturday} />
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    ) : (
                        <div style={{ height: 184 }}></div>
                    )}
                </Box>
            )}
        </InView>
    );
};

export default StatisticsMobileListItem;
