import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { AlarmReportSchema } from 'model/alarmReport/schema';

interface Props {
    storeId: number;
    dateFrom: string;
    dateTo: string;
}

export const getAlarmReportActiveCount = async ({ storeId, dateFrom, dateTo }: Props) => {
    const response = await makeQuery(ApiEndpoints.getAlarmReport(storeId, dateFrom, dateTo), true);
    return AlarmReportSchema.parse(response);
};
