import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
    },
    dateRange: {
        display: 'flex',
        flexDiretion: 'row',
        gap: 4,
    },
    date: {
        position: 'relative',
        top: 0,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.15,
        letterSpacing: '0.24px',
    },
    oldDate: {
        background: '#FE1414',
        borderRadius: 4,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        color: 'white',
    },
    indicator: {
        display: 'none',
    },
    tabs: {
        minHeight: 'auto',
        flex: 1,
        order: 1,
    },
    tab: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minHeight: 0,
        minWidth: 'auto',
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(12),
        margin: `${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(1)} 0 ${theme.typography.pxToRem(1)}`,
        fontWeight: 500,
        color: theme.customPalette.colors.brand.light,
        '&$selected': {
            color: theme.customPalette.text.primary,
        },
    },
    selected: {
        background: theme.customPalette.background.white,
        boxShadow: theme.customElevation.default,
    },
    tabWrapper: {
        flexDirection: 'row',
        '& svg': {
            marginTop: theme.typography.pxToRem(6),
            marginRight: theme.spacing(1),
        },
    },
    icon: {
        fill: '#267dff',
    },
    dateRefreshedWrapper: {
        order: 3,
        flex: 1,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: 16,
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    gridWrapper: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 8,
        },
    },
    gridTabWrapper: {
        order: 2,
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
    },
    gridTimeDurationWrapper: {
        order: 1,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.only('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    gridDataRefreshWrapper: {
        order: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    gridDatePickersWrapper: {
        order: 10,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.between('sm', 'md')]: {
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.down('xs')]: {
            order: 1,
        },
    },
    input: {
        width: '100%',
        '& > div': {
            background: theme.customPalette.background.white,
        },
        '& input': {
            width: '100%',
            height: theme.spacing(3),
            padding: `0 ${theme.spacing(1)}`,
            fontSize: theme.typography.pxToRem(12),
        },
        '& button': {
            color: theme.customPalette.colors.brand.light,
            margin: theme.spacing(-2),
        },
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none',
        },
        '& .MuiInputLabel-outlined': {
            fontSize: theme.typography.pxToRem(14),
            marginLeft: theme.spacing(-6 / 8),
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,.08)',
        },
        '& legend': {
            marginLeft: theme.spacing(-4 / 8),
        },
    },
}));
