import { AppState } from 'store';
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PreApplicationBox from 'components/preApplicationBox/preAplicationBox';
import React from 'react';
import SignInForm from 'components/signInForm/signInForm';

const SignInScreen: React.FC = () => {
    const history = useHistory();
    const { authenticated } = useSelector((state: AppState) => state.auth);
    React.useEffect(() => {
        if (!authenticated) {
            history.push(routes.default.path());
        }
    }, [authenticated, history]);
    return (
        <PreApplicationBox>
            <SignInForm />
        </PreApplicationBox>
    );
};

export default SignInScreen;
