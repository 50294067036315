import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { DatePickerRHFProps } from './datePickerRHFProps';
import DateTimeFormat from '../../shared/luxon/DateTimeFormat';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        '& > div': {
            background: theme.customPalette.background.white,
        },
        '& input': {
            width: '100%',
            height: theme.spacing(3),
            padding: `0 ${theme.spacing(1)}`,
            fontSize: theme.typography.pxToRem(12),
        },
        '& button': {
            color: theme.customPalette.colors.brand.light,
            margin: theme.spacing(-2),
        },
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none',
        },
        '& .MuiInputLabel-outlined': {
            fontSize: theme.typography.pxToRem(14),
            marginLeft: theme.spacing(-6 / 8),
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,.08)',
        },
        '& legend': {
            marginLeft: theme.spacing(-4 / 8),
        },
    },
}));

const DatePickerRHF: React.FC<DatePickerRHFProps> = ({ control, name, defaultValue, ...rest }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue: defaultValue || '',
    });
    return (
        <DatePicker
            {...inputProps}
            cancelLabel={t('datePicker.cancel')}
            className={classes.input}
            error={!!error}
            format={DateTimeFormat.DATE_FORMAT()}
            inputVariant="outlined"
            okLabel={t('datePicker.ok')}
            {...rest}
        />
    );
};

export default DatePickerRHF;
