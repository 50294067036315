import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as ArrowSortDownIcon } from 'assets/icons/arrow-sort-down.svg';
import { ReactComponent as ArrowSortUpIcon } from 'assets/icons/arrow-sort-up.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        cursor: 'pointer',
    },
    emphasize: {
        fontWeight: 700,
    },
    icon: {
        width: 24,
        height: 24,
    },
}));

type Props = {
    order: 'asc' | 'desc';
    label: string;
    onClick: () => void;
};

const OrderBy: React.FC<Props> = ({ order, label, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const icon = order === 'asc' ? <ArrowSortUpIcon className={classes.icon} /> : <ArrowSortDownIcon className={classes.icon} />;

    return (
        <Box className={classes.container} onClick={onClick}>
            <div>
                {t('dashboard.orderBy')} <span className={classes.emphasize}>{label}</span> {order}
            </div>{' '}
            {icon}
        </Box>
    );
};

export default OrderBy;
