import { DashboardAlarmPriority, DashboardDataSource, DashboardPeriod } from 'screens/dashboard/types';

const ALARM_DASHBOARD_FILTER = 'ALARM_DASHBOARD_FILTER';

interface AlarmDashboardFilter {
    dataSource: DashboardDataSource;
    period: DashboardPeriod;
    priority: DashboardAlarmPriority;
    alarmType: string | undefined;
    searchPhrase: string;
    sortDirection: 'asc' | 'desc';
}

export const setAlarmDashboardFilter = (filter: AlarmDashboardFilter) => {
    localStorage.setItem(ALARM_DASHBOARD_FILTER, JSON.stringify(filter));
};
export const hasAlarmDashboardFilter = () => Boolean(localStorage.getItem(ALARM_DASHBOARD_FILTER));
export const getAlarmDashboardFilter = () => {
    const value = localStorage.getItem(ALARM_DASHBOARD_FILTER);
    return value ? JSON.parse(value) : undefined;
};
export const clearAlarmDashboardFilter = () => {
    localStorage.removeItem(ALARM_DASHBOARD_FILTER);
};
