import { BottomBarProps } from 'components/bottomBar/bottomBarProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        bar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            minHeight: theme.spacing(7),
            lineHeight: theme.spacing(7),
            background: theme.customPalette.background.light,
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`,
            boxShadow: theme.customElevation.default,
            zIndex: 100,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1.25),
                paddingRight: theme.spacing(1.25),
            },
        },
        fakeBar: {
            height: theme.spacing(7),
        },
    };
});
const BottomBar: React.FC<BottomBarProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.bar}>{children}</div>
            <div className={classes.fakeBar} />
        </>
    );
};

export default BottomBar;
