import { Role } from 'model/myProfile/role';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const getRolesArray = (t: TFunction) => {
    return [
        {
            value: Role.administrator,
            label: t('role.administrator'),
        },
        {
            value: Role.silver,
            label: t('role.silver'),
        },
        {
            value: Role.gold,
            label: t('role.gold'),
        },
    ];
};

export const getTranslatedRoleByRoleId = (roleToTranslate: Role, t: TFunction) => {
    const roles = getRolesArray(t);
    const roleFound = roles.find((role) => role.value === roleToTranslate);
    return roleFound ? roleFound.label : roleToTranslate;
};

const useRoles = () => {
    const { t } = useTranslation();
    return getRolesArray(t);
};

export default useRoles;
