import { AppState } from '../../store';
import { DateTime } from '../../shared/luxon/luxon';
import { fetchStores } from '../../api/fetchers/stores';
import { makeStyles } from '@material-ui/core/styles';
import { QueryKeys } from '../../api/queryKeys';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as UnfoldIcon } from 'assets/icons/unfold-more-vertical.svg';
import { useHandledQuery } from '../../shared/useHandledQuery/useHandledQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '../../components/button/button';
import clsx from 'clsx';
import DateTimeFormat from '../../shared/luxon/DateTimeFormat';
import React from 'react';
import StoreHeaderInformation from '../../components/storeHeaderInformation/storeHeaderInformation';
const useStyles = makeStyles((theme) => {
    return {
        date: {
            position: 'relative',
            top: 0,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.15,
            letterSpacing: '0.24px',
        },
        oldDate: {
            background: '#FE1414',
            borderRadius: 4,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            color: 'white',
        },
        icon: {
            fill: '#267dff',
        },
    };
});
const CanvasHeader = ({
    measuredOn,
    refetch,
    isOffline,
    displayAlarmsButton,
    openAlarmsColumn,
    alarmsCount,
}: {
    measuredOn: string;
    refetch: () => Promise<unknown>;
    isOffline: boolean;
    displayAlarmsButton: boolean;
    openAlarmsColumn: () => void;
    alarmsCount: number;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { storeId } = useSelector((state: AppState) => state.store);
    const { data: storesDataFilteredByStoreId } = useHandledQuery(
        [QueryKeys.store(storeId), storeId],
        () => fetchStores(undefined, storeId ? [{ column: 'id', value: storeId.toString(), operator: 'eq' }] : undefined),
        {
            enabled: Boolean(storeId),
        },
    );

    const luxonMeasuredOn = DateTime.fromISO(measuredOn, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const luxonNow = DateTime.now();
    const minutesDiff = luxonNow.diff(luxonMeasuredOn, 'minutes').minutes;

    if (!storesDataFilteredByStoreId) {
        return null;
    }

    return (
        <Box alignItems="center" display="flex" justifyContent="space-between">
            <StoreHeaderInformation arrowIcons={true} data={storesDataFilteredByStoreId[0]} />
            <Box alignItems="center" display="flex">
                {isOffline ? (
                    <div className={clsx(classes.date, classes.oldDate)}>{t('connectionLost')}</div>
                ) : (
                    <>
                        <div className={clsx(classes.date, minutesDiff > 15 && classes.oldDate)}>
                            {t('dataRefreshed')}: <strong>{luxonMeasuredOn.toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS())}</strong>
                        </div>
                        <Box ml={2}>
                            <Button icon={<RefreshIcon className={clsx(classes.icon)} />} onClick={refetch}>
                                {t('refresh')}
                            </Button>
                        </Box>
                        {displayAlarmsButton && (
                            <Box ml={2}>
                                <Button icon={<UnfoldIcon className={clsx(classes.icon)} />} onClick={openAlarmsColumn}>
                                    {t('alarms')} ({alarmsCount})
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
export default CanvasHeader;
