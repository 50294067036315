import { AuthActionTypes, AuthState, SET_AUTH, SET_MY_PROFILE } from 'store/auth/types';
import { hasToken } from 'localStorage/auth';

const initialState: AuthState = {
    authenticated: hasToken(),
    myProfile: undefined,
};

const reducer = (state = initialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case SET_AUTH: {
            return {
                ...state,
                authenticated: action.authenticated,
            };
        }
        case SET_MY_PROFILE: {
            return {
                ...state,
                myProfile: action.myProfile,
            };
        }
        default:
            return state;
    }
};

export default reducer;
