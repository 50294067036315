import { EndpointSpecificErrors } from 'api/api';
import { handleStandardApiErrors } from 'shared/errors/handleStandardApiErrors';
import { MutationFunction, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { UseMutationOptions, UseMutationResult } from 'react-query/types/react/types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React from 'react';
import StandardApiError from 'shared/errors/standardApiError';

export const useHandledMutation = <TData = unknown, TError = StandardApiError | null, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    options?: UseMutationOptions<TData, TError, TVariables, TContext>,
    endpointSpecificErrors?: EndpointSpecificErrors,
): UseMutationResult<TData, TError, TVariables, TContext> => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const mutationResult = useMutation(mutationFn, options);
    const history = useHistory();
    const { error } = mutationResult;
    React.useEffect(() => {
        if (error instanceof StandardApiError) {
            handleStandardApiErrors(error, enqueueSnackbar, t, history, endpointSpecificErrors);
        }
    }, [error, enqueueSnackbar, t, endpointSpecificErrors, history]);
    return mutationResult;
};
