import { Box } from '@material-ui/core';
import { getOrganizationStructure } from 'api/fetchers/getOrganizationStructure';
import { mutatePuAccessRights, PuAccessRightsErrorCodes } from 'api/mutations/putAccessRights';
import { OrganizationStructureFormProps } from 'components/organizationStructure/organizationStructureForm/organizationStructureFormProps';
import { pull } from 'lodash';
import { QueryKeys } from 'api/queryKeys';
import { routes } from 'routes/routes';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import BottomBar from 'components/bottomBar/bottomBar';
import Button from 'components/button/button';
import List from 'components/organizationStructure/list/list';
import LoadingBar from 'components/loadingBar/loadingBar';
import React from 'react';
import SearchBox from 'components/organizationStructure/searchBox/searchBox';

const OrganizationStructureForm: React.FC<OrganizationStructureFormProps> = () => {
    const { t } = useTranslation();
    const { id: userIdRaw } = useParams<{ id: string }>();
    const userId = parseInt(userIdRaw);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [filter, setFilter] = React.useState<string | null>(null);
    const [accessCustomers, setAccessCustomers] = React.useState<number[]>([]);
    const [accessCustomersStart, setAccessCustomersStart] = React.useState<number[]>([]);
    const [accessRegions, setAccessRegions] = React.useState<number[]>([]);
    const [accessRegionsStart, setAccessRegionsStart] = React.useState<number[]>([]);
    const [accessStores, setAccessStores] = React.useState<number[]>([]);
    const [accessStoresStart, setAccessStoresStart] = React.useState<number[]>([]);

    const { data, isFetching } = useHandledQuery([QueryKeys.getOrganizationStructure(), userId, false, filter || null], () =>
        getOrganizationStructure(userId, false, undefined, undefined, filter || undefined),
    );

    const { mutate, isSuccess: mutateIsSuccess, isLoading: mutateIsLoading } = useHandledMutation(mutatePuAccessRights, undefined, PuAccessRightsErrorCodes);

    React.useEffect(() => {
        if (data) {
            const customers: number[] = [];
            const regions: number[] = [];
            const stores: number[] = [];

            data.data.forEach((customer) => {
                if (customer.access) {
                    customers.push(customer.id);
                }
                customer.regions.forEach((region) => {
                    if (customer.access || region.access) {
                        regions.push(region.id);
                    }
                    region.stores.forEach((store) => {
                        if (customer.access || region.access || store.access) {
                            stores.push(store.id);
                        }
                    });
                });
            });
            if (accessCustomersStart.length === 0) {
                setAccessCustomersStart(customers);
            }
            if (accessRegionsStart.length === 0) {
                setAccessRegionsStart(regions);
            }
            if (accessStoresStart.length === 0) {
                setAccessStoresStart(stores);
            }
            setAccessCustomers(accessCustomers.concat(customers));
            setAccessRegions(accessRegions.concat(regions));
            setAccessStores(accessStores.concat(stores));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (mutateIsSuccess) {
            enqueueSnackbar(t('user.access.editSuccessful'));
            queryClient.removeQueries([QueryKeys.getOrganizationStructure(), userId, false, filter || null], { exact: true });
            queryClient.removeQueries([QueryKeys.users()], { exact: false });
            history.push(routes.userAccessesDetail.path(userId));
        }
    }, [mutateIsSuccess, enqueueSnackbar, t, history, userId, filter, queryClient]);

    const changeCustomerAccess = (id: number) => {
        const newAccessCustomers = [...accessCustomers];
        const newAccessRegions = [...accessRegions];
        const newAccessStores = [...accessStores];
        let pushToArray = true;
        if (accessCustomers.includes(id)) {
            pushToArray = false;
        }
        data?.data
            .filter((customer) => customer.id === id)
            .forEach((customer) => {
                customer.regions.forEach((region) => {
                    pullPushIdToArray(pushToArray, newAccessRegions, region.id);
                    region.stores.forEach((store) => {
                        pullPushIdToArray(pushToArray, newAccessStores, store.id);
                    });
                });
            });

        setAccessCustomers(pullPushIdToArray(pushToArray, newAccessCustomers, id));
        setAccessRegions(newAccessRegions);
        setAccessStores(newAccessStores);
    };

    const changeRegionAccess = (id: number) => {
        const newAccessCustomers = [...accessCustomers];
        const newAccessRegions = [...accessRegions];
        const newAccessStores = [...accessStores];
        let pushToArray = true;
        if (accessRegions.includes(id)) {
            pushToArray = false;
        }
        data?.data.forEach((customer) => {
            customer.regions
                .filter((region) => region.id === id)
                .forEach((region) => {
                    if (!pushToArray) {
                        pullPushIdToArray(pushToArray, newAccessCustomers, customer.id);
                    }
                    region.stores.forEach((store) => {
                        pullPushIdToArray(pushToArray, newAccessStores, store.id);
                    });
                });
        });

        setAccessCustomers(newAccessCustomers);
        setAccessRegions(pullPushIdToArray(pushToArray, newAccessRegions, id));
        setAccessStores(newAccessStores);
    };

    const changeStoreAccess = (id: number) => {
        const newAccessCustomers = [...accessCustomers];
        const newAccessRegions = [...accessRegions];
        const newAccessStores = [...accessStores];
        let pushToArray = true;
        if (accessStores.includes(id)) {
            pushToArray = false;
        }
        data?.data.forEach((customer) => {
            customer.regions.forEach((region) => {
                region.stores
                    .filter((store) => store.id === id)
                    .forEach((store) => {
                        if (!pushToArray) {
                            pullPushIdToArray(pushToArray, newAccessCustomers, customer.id);
                            pullPushIdToArray(pushToArray, newAccessRegions, region.id);
                        }
                    });
            });
        });

        setAccessCustomers(newAccessCustomers);
        setAccessRegions(newAccessRegions);
        setAccessStores(pullPushIdToArray(pushToArray, newAccessStores, id));
    };

    const pullPushIdToArray = (pushToArray: boolean, array: number[], id: number): number[] => {
        if (pushToArray) {
            if (!array.includes(id)) {
                array.push(id);
            }
        } else {
            pull(array, id);
        }

        return array;
    };

    const handleOnSubmit = async () => {
        const newAccessCustomers = [...accessCustomers];
        const newAccessRegions = [...accessRegions];
        const newAccessStores = [...accessStores];
        data?.data.forEach((customer) => {
            customer.regions
                .filter((region) => newAccessRegions.includes(region.id))
                .forEach((region) => {
                    region.stores.forEach((store) => {
                        pull(newAccessStores, store.id);
                    });
                });
        });
        data?.data
            .filter((customer) => newAccessCustomers.includes(customer.id))
            .forEach((customer) => {
                customer.regions.forEach((region) => {
                    pull(newAccessRegions, region.id);
                });
            });

        await mutate({ userId, data: { customers: newAccessCustomers, regions: newAccessRegions, stores: newAccessStores } });
    };
    const handleDiscardChanges = () => {
        history.push(routes.userAccessesDetail.path(userId));
    };

    const compareAccesses = (access: number[], accessStart: number[]) => {
        const accessSorted = access.sort();
        const accessStartSorted = accessStart.sort();
        return (
            access.length === accessStart.length &&
            accessSorted.every((element, index) => {
                return element === accessStartSorted[index];
            })
        );
    };

    const compareAllAccesses = () => {
        const isCustomerAccessesSame = compareAccesses(accessCustomers, accessCustomersStart);
        const isRegionAccessesSame = compareAccesses(accessRegions, accessRegionsStart);
        const isStoreAccessesSame = compareAccesses(accessStores, accessStoresStart);

        return isCustomerAccessesSame && isRegionAccessesSame && isStoreAccessesSame;
    };

    return (
        <>
            <LoadingBar isFetching={isFetching} />
            <SearchBox setFilter={setFilter} />

            <List
                accessCustomers={accessCustomers}
                accessRegions={accessRegions}
                accessStores={accessStores}
                allowEdit={true}
                changeCustomerAccess={changeCustomerAccess}
                changeRegionAccess={changeRegionAccess}
                changeStoreAccess={changeStoreAccess}
                data={data}
            />

            <BottomBar>
                {!compareAllAccesses() && (
                    <Box display="flex">
                        <Button id="test-save-changes" isLoading={mutateIsLoading} isPrimary={true} size="medium" onClick={handleOnSubmit}>
                            {t('organizationStructure.bar.saveChanges')}
                        </Button>
                        <Box alignItems="center" display="flex" justifyContent="space-between" ml={1.5}>
                            <Button isPrimary={false} size="medium" onClick={handleDiscardChanges}>
                                {t('organizationStructure.bar.discard')}
                            </Button>
                        </Box>
                    </Box>
                )}
            </BottomBar>
        </>
    );
};

export default OrganizationStructureForm;
