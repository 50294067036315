import * as z from 'zod';

export const ReportFileSchema = z.object({
    file: z.string(),
    name: z.string(),
    category: z.string(),
    date: z.string(),
    hash: z.string(),
});

export const ReportFilesSchema = z.object({ data: z.array(ReportFileSchema) });
export type ReportFile = z.infer<typeof ReportFileSchema>;
export type ReportFiles = z.infer<typeof ReportFilesSchema>;
