import { AssetBucket } from 'model/asset/schema';
import { DateTime } from 'shared/luxon/luxon';
import { useTheme } from '@material-ui/core';

export interface Data extends AssetBucket {
    xDataKey: number | string;
}

const usePrepareAssetChartDescriptionData = (data: Data[], daysDiffCount?: number) => {
    const theme = useTheme();
    const firstXDataKey = data[0].xDataKey;
    const startDateTime = typeof firstXDataKey === 'number' && DateTime.fromMillis(firstXDataKey);

    const sundays: DateTime[] = [];
    if (daysDiffCount && startDateTime) {
        for (let i = 0; i <= daysDiffCount; i++) {
            const day = startDateTime.plus({ days: i });
            if (day.weekday === 7) {
                sundays.push(day);
            }
        }
    }

    const ticks = data.map(({ xDataKey }) => xDataKey);

    const tickCSSProperties = {
        fill: theme.customPalette.text.primary,
        fontSize: 12,
    };

    const chartMargin = {
        top: 24,
        right: 16,
        left: 16,
        bottom: 80,
    };

    return {
        sundays,
        ticks,
        tickCSSProperties,
        chartMargin,
    };
};

export default usePrepareAssetChartDescriptionData;
