import { CheckboxProps } from './checkboxProps';
import { makeStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 500,
        margin: 0,
        padding: 0,
        fontSize: theme.typography.pxToRem(16),
        color: theme.customPalette.text.primary,
        '&$checked': {
            color: theme.customPalette.colors.brand.light,
        },
    },
    checked: {},
    disabled: {
        '& span': {
            color: theme.customPalette.placeholder,
        },
    },
}));

const Checkbox: React.FC<CheckboxProps> = ({ size, id, ...rest }) => {
    const classes = useStyles();

    return <MuiCheckbox classes={{ root: classes.root, checked: classes.checked, disabled: classes.disabled }} id={id} size={size === 'medium' ? 'small' : undefined} {...rest} />;
};

export default Checkbox;
