import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, SortParameter } from 'api/types';
import { FoodSafetyStatisticsMonthlySchema } from 'model/foodSafetyStatisticsMonthly/schema';
import { makeQuery } from 'api/api';

export const getFoodSafetyStatisticsMonthly = async (storeId: number, year: number, monthStart: number, monthEnd: number, sort?: SortParameter, filter?: FilterParameter) => {
    const response = await makeQuery(ApiEndpoints.getFoodSafetyStatisticsMonthly(storeId, year, monthStart, monthEnd), true, sort, filter);
    const parsedResponse = FoodSafetyStatisticsMonthlySchema.parse(response);
    return {
        data: parsedResponse.data.map((item) => {
            return {
                ...item,
                '1': item.months['1'] || undefined,
                '2': item.months['2'] || undefined,
                '3': item.months['3'] || undefined,
                '4': item.months['4'] || undefined,
                '5': item.months['5'] || undefined,
                '6': item.months['6'] || undefined,
                '7': item.months['7'] || undefined,
                '8': item.months['8'] || undefined,
                '9': item.months['9'] || undefined,
                '10': item.months['10'] || undefined,
                '11': item.months['11'] || undefined,
                '12': item.months['12'] || undefined,
            };
        }),
        metadata: parsedResponse.metadata,
    };
};
