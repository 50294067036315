import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';

const PatchAssetsVisibilitySchema = z.object({
    success: z.literal(true),
});

export interface PatchAssetPositionParams {
    visible: number[];
    hidden: number[];
}

export const mutatePatchAssetsVisibility = async (values: PatchAssetPositionParams) => {
    const response = await makeMutation(ApiEndpoints.assetsVisibility(), values, true, 'PATCH');
    return PatchAssetsVisibilitySchema.parse(response);
};

export const patchAssetPositionErrorCodes = {};
