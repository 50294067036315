import * as z from 'zod';
import { Role } from 'model/myProfile/role';

export const UserSchema = z.object({
    id: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    username: z.string(),
    emailAddress: z.string(),
    role: z.nativeEnum(Role),
    languageId: z.number(),
    timeZone: z.string(),
    isDisabled: z.boolean(),
    isMarketingConsent: z.boolean(),
    signedInAt: z.string().nullable(),
});

export type User = z.infer<typeof UserSchema>;
