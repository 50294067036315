import { ContactType } from 'shared/enums/contactType';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as FaxIcon } from 'assets/icons/fax.svg';
import { makeStyles } from '@material-ui/core';
import { NotificationContactCellProps } from 'components/table/components/notificationContactCell/notificationContactCellProps';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1),
        fill: theme.customPalette.placeholder,
    },
}));

const NotificationContactCell: React.FC<NotificationContactCellProps> = ({ value, type }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {type === ContactType.email && <EmailIcon className={classes.icon} />}
            {type === ContactType.fax && <FaxIcon className={classes.icon} />}
            <span className="test-notification-item">{value}</span>
        </div>
    );
};

export default NotificationContactCell;
