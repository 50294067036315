import { ContentTabsProps } from './contentTabsProps';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Box, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    indicator: {
        display: 'none',
    },
    tabs: {
        minHeight: 'auto',
        order: 2,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            order: 1,
            width: '50%',
        },
    },
    tab: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minHeight: 0,
        minWidth: 'auto',
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(12),
        margin: `${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(1)} 0 ${theme.typography.pxToRem(1)}`,
        fontWeight: 500,
        color: theme.customPalette.colors.brand.light,
        '&$selected': {
            color: theme.customPalette.text.primary,
        },
    },
    selected: {
        background: theme.customPalette.background.white,
        boxShadow: theme.customElevation.default,
    },
    tabWrapper: {
        flexDirection: 'row',
        '& svg': {
            marginTop: theme.typography.pxToRem(6),
            marginRight: theme.spacing(1),
        },
    },
}));

const ContentTabs: React.FC<ContentTabsProps> = ({ items, rightAccessory }) => {
    const { pathname } = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const activeItem = items.find((item) => !!matchPath(pathname, { path: item.path }) || (item.parentPath && !!matchPath(pathname, { path: item.parentPath })));

    return (
        <>
            {!isSmUp && (
                <Box display="flex" justifyContent="center" mt="24px" width="100%">
                    <Box>{rightAccessory}</Box>
                </Box>
            )}
            <Tabs
                classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                }}
                indicatorColor="primary"
                textColor="primary"
                value={activeItem && activeItem.id}
            >
                {items.map((item) => (
                    <Tab
                        key={item.id}
                        classes={{
                            wrapper: classes.tabWrapper,
                            selected: classes.selected,
                        }}
                        className={classes.tab}
                        component={Link}
                        id={`test-${item.id}`}
                        label={item.text}
                        to={item.path}
                        value={item.id}
                    />
                ))}
                {isSmUp && (
                    <Box alignSelf="center" height="100%" ml="13px" mr="13px">
                        <Box>{rightAccessory}</Box>
                    </Box>
                )}
            </Tabs>
        </>
    );
};

export default ContentTabs;
