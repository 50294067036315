import { HeadingProps } from 'components/statisticsMobileListItem/parts/heading/headingProps';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    value: {
        color: theme.customPalette.text.primary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        alignSelf: 'flex-end',
    },
    label: {
        color: theme.customPalette.text.secondary,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(8),
    },
}));

const Heading: React.FC<HeadingProps> = ({ children, label }) => {
    const classes = useStyles();
    return (
        <Grid item xs={4}>
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>{children}</div>
        </Grid>
    );
};

export default Heading;
