import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
}));

const ContentLegend: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid item xs={6}>
            <div className={classes.root}>{children}</div>
        </Grid>
    );
};

export default ContentLegend;
