import { DndProvider } from 'react-dnd';
import { Filters, SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { mutatePatchAssetPosition, patchAssetPositionErrorCodes } from 'api/mutations/patchAssetPosition';
import { QueryKeys } from 'api/queryKeys';
import { ReportingTableHoursAccessors } from 'types/reporting/reportingTableHoursAccessors';
import { useHandledMutation } from 'shared/useHandledMutation/useHandledMutation';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useStyles } from 'components/table/stylesTable';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import MovableRowComponent from 'components/table/components/movableRowComponent/movableRowComponent';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import Temperature from 'components/table/components/temperature/temperature';
import update from 'immutability-helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
    onFilter: (rule: Filters<{}> | undefined) => void;
    refetch: () => void;
}

const ReportingTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching, onFilter, refetch }: React.PropsWithChildren<TableProperties<T>>) => {
    const [tableRecords, setTableRecords] = React.useState<T[]>([]);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const { t } = useTranslation();

    const {
        mutate: patchAssetPosition,
        isSuccess: patchAssetPositionIsSuccess,
        isError: patchAssetPositionIsError,
    } = useHandledMutation(mutatePatchAssetPosition, undefined, patchAssetPositionErrorCodes);

    React.useEffect(() => {
        if (patchAssetPositionIsSuccess) {
            enqueueSnackbar(t('patchAssetPosition.success'));
            refetch();
        }
    }, [patchAssetPositionIsSuccess, enqueueSnackbar, t, refetch]);

    React.useEffect(() => {
        if (patchAssetPositionIsError) {
            queryClient.refetchQueries([QueryKeys.foodSafetyReport()]);
            setTableRecords(data);
        }
    }, [patchAssetPositionIsError, data, queryClient]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters },
        prepareRow,
        setHiddenColumns,
        visibleColumns,
    } = useTable<T>(
        {
            columns,
            data: tableRecords,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            initialState: {
                filters: [{ id: 'hidden', value: '0' }],
            },
            manualSortBy: true,
            manualFilters: true,
            getRowId,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        if (data) {
            setTableRecords(data);
        }
    }, [data]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    React.useEffect(() => {
        if (isMdDown) {
            setHiddenColumns([
                ReportingTableHoursAccessors.h00,
                ReportingTableHoursAccessors.h01,
                ReportingTableHoursAccessors.h02,
                ReportingTableHoursAccessors.h03,
                ReportingTableHoursAccessors.h04,
                ReportingTableHoursAccessors.h05,
                ReportingTableHoursAccessors.h06,
                ReportingTableHoursAccessors.h07,
                ReportingTableHoursAccessors.h08,
                ReportingTableHoursAccessors.h09,
                ReportingTableHoursAccessors.h10,
                ReportingTableHoursAccessors.h11,
                ReportingTableHoursAccessors.h12,
                ReportingTableHoursAccessors.h13,
                ReportingTableHoursAccessors.h14,
                ReportingTableHoursAccessors.h15,
                ReportingTableHoursAccessors.h16,
                ReportingTableHoursAccessors.h17,
                ReportingTableHoursAccessors.h18,
                ReportingTableHoursAccessors.h19,
                ReportingTableHoursAccessors.h20,
                ReportingTableHoursAccessors.h21,
                ReportingTableHoursAccessors.h22,
                ReportingTableHoursAccessors.h23,
            ]);
        } else {
            setHiddenColumns([]);
        }
    }, [isMdDown, columns, setHiddenColumns]);

    const classes = useStyles();

    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const dragRecord = tableRecords[dragIndex];
        setTableRecords(
            update(tableRecords, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            }),
        );
        if (dragRecord && typeof dragRecord.id === 'number' && tableRecords[hoverIndex]) {
            patchAssetPosition({ assetId: dragRecord.id, position: tableRecords[hoverIndex].position as number });
        }
    };

    return (
        <DndProvider backend={HTML5Backend} debugMode={true}>
            <table {...getTableProps()} className={classes.root}>
                <TableHead headerGroups={headerGroups} movable />
                <tbody {...getTableBodyProps()}>
                    {rows.length === 0 && !isFetching && (
                        <tr>
                            <td colSpan={headerGroups[0].headers.length + (isLgUp ? 24 : 0)}>
                                <NoResults />
                            </td>
                        </tr>
                    )}
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <MovableRowComponent<T> index={index} moveRow={moveRow} row={row} {...row.getRowProps()}>
                                {!isLgUp && (
                                    <tr>
                                        <td className={classes.hourTableOuterCell} colSpan={visibleColumns.length}>
                                            <table className={classes.hourTable}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={24}>
                                                            <span>{t('foodSafety.reporting.table.heading.avgTemp')}</span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>00</th>
                                                        <th>01</th>
                                                        <th>02</th>
                                                        <th>03</th>
                                                        <th>04</th>
                                                        <th>05</th>
                                                        <th>06</th>
                                                        <th>07</th>
                                                        <th>08</th>
                                                        <th>09</th>
                                                        <th>10</th>
                                                        <th>11</th>
                                                        <th>12</th>
                                                        <th>13</th>
                                                        <th>14</th>
                                                        <th>15</th>
                                                        <th>16</th>
                                                        <th>17</th>
                                                        <th>18</th>
                                                        <th>19</th>
                                                        <th>20</th>
                                                        <th>21</th>
                                                        <th>22</th>
                                                        <th>23</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h00]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h01]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h02]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h03]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h04]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h05]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h06]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h07]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h08]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h09]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h10]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h11]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h12]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h13]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h14]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h15]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h16]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h17]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h18]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h19]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h20]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h21]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h22]} />
                                                        </td>
                                                        <td>
                                                            <Temperature row={row} value={row.values[ReportingTableHoursAccessors.h23]} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                            </MovableRowComponent>
                        );
                    })}
                </tbody>
            </table>
        </DndProvider>
    );
};

export default ReportingTable;
