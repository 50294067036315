const ENERGY_CONSUMPTION_AVAILABLE_DATES = 'ENERGY_CONSUMPTION_AVAILABLE_DATES';

interface EnergyConsumptionAvailableDates {
    availableDateFrom: number;
    availableDateTo: number;
}

export const setEnergyConsumptionAvailableDates = (filter: EnergyConsumptionAvailableDates) => {
    localStorage.setItem(ENERGY_CONSUMPTION_AVAILABLE_DATES, JSON.stringify(filter));
};
export const hasEnergyConsumptionAvailableDates = () => Boolean(localStorage.getItem(ENERGY_CONSUMPTION_AVAILABLE_DATES));
export const getEnergyConsumptionAvailableDates = () => {
    const value = localStorage.getItem(ENERGY_CONSUMPTION_AVAILABLE_DATES);
    return value ? JSON.parse(value) : undefined;
};
export const clearEnergyConsumptionAvailableDates = () => {
    localStorage.removeItem(ENERGY_CONSUMPTION_AVAILABLE_DATES);
};
