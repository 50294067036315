import * as z from 'zod';
import { ApiEndpoints } from 'api/endpoints';
import { makeMutation } from 'api/api';

const DeleteNotificationSchema = z.object({
    success: z.literal(true),
});

export interface DeleteNotificationParams {
    contactId: number;
}

export const mutateDeleteNotification = async (values: DeleteNotificationParams) => {
    const { contactId } = values;
    const response = await makeMutation(ApiEndpoints.deleteNotification(contactId), undefined, true, 'DELETE');
    return DeleteNotificationSchema.parse(response);
};

export const deleteNotificationErrorCodes = {};
