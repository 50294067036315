import * as FileSaver from 'file-saver';
import { FileData } from 'api/api';
import { useQueryClient } from 'react-query';
import React from 'react';

const useDownloadFile = (queryKeyToRemove?: string[], data?: FileData) => {
    const queryClient = useQueryClient();
    React.useEffect(() => {
        if (data) {
            const file = new Blob([data.blob]);
            FileSaver.saveAs(file, data.fileName);
            if (queryKeyToRemove) {
                queryClient.removeQueries(queryKeyToRemove);
            }
        }
    }, [data, queryClient, queryKeyToRemove]);
    return null;
};

export default useDownloadFile;
