import { AlarmProps, AlarmStatus } from './alarmProps';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { DateTime } from '../../shared/luxon/luxon';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            borderRadius: 2,
            overflow: 'hidden',
            marginBottom: 8,
        },
        title: {
            fontSize: 12,
            padding: 4,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'space-between',
            background: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1) {
                    return '#E90038';
                }
                if (status === AlarmStatus.Prio2) {
                    return '#FF5C00';
                }
                if (status === AlarmStatus.Closed) {
                    return '#00000029';
                }
            },
            color: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1) {
                    return '#FFFFFF';
                }
                if (status === AlarmStatus.Prio2) {
                    return '#FFFFFFE0';
                }
                if (status === AlarmStatus.Closed) {
                    return '#000000A3';
                }
            },
            lineHeight: 1,
            letterSpacing: '0.24px',
        },
        description: {
            fontSize: 12,
            padding: 4,
            fontWeight: 400,
            background: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1) {
                    return '#FE14143D';
                }
                if (status === AlarmStatus.Prio2) {
                    return '#FE84143D';
                }
                if (status === AlarmStatus.Closed) {
                    return '#00000014';
                }
            },
            color: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1 || status === AlarmStatus.Prio2) {
                    return '#000';
                }
                if (status === AlarmStatus.Closed) {
                    return '#000000A3';
                }
            },
            lineHeight: 1,
            letterSpacing: '0.24px',
        },
        footer: {
            fontSize: 12,
            padding: 4,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'space-between',
            background: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1) {
                    return '#FE141429';
                }
                if (status === AlarmStatus.Prio2) {
                    return '#FE841429';
                }
                if (status === AlarmStatus.Closed) {
                    return '#0000000A';
                }
            },
            color: ({ status }: { status: AlarmStatus }) => {
                if (status === AlarmStatus.Prio1 || status === AlarmStatus.Prio2) {
                    return '#000';
                }
                if (status === AlarmStatus.Closed) {
                    return '#000000A3';
                }
            },
            lineHeight: 1,
            letterSpacing: '0.24px',
        },
        footerSmall: {
            fontSize: 10,
            fontWeight: 400,
        },
        footerIcon: {
            fontSize: 19,
            fontWeight: 400,
        },
        temperature: {
            position: 'relative',
            top: 2,
        },
        arrowIcon: {
            fill: 'currentColor',
            position: 'relative',
            marginTop: -4,
            top: 4,
        },
    };
});

const AssetHeader: React.FC<AlarmProps> = ({ comment, temperature, assetId, title, description, status, handleHighlightedAsset, startOn, endOn, shortName, longName }) => {
    const { t } = useTranslation();
    let modifiedStatus = status;

    if (endOn) {
        modifiedStatus = AlarmStatus.Closed;
    }

    const classes = useStyles({ status: modifiedStatus });

    const formattedStartOn = startOn ? DateTime.fromJSDate(new Date(startOn)) : undefined;
    const formattedEndOn = endOn ? DateTime.fromJSDate(new Date(endOn)) : undefined;

    return (
        <div className={classes.root} onMouseEnter={() => handleHighlightedAsset(assetId)} onMouseLeave={() => handleHighlightedAsset(null)}>
            <div className={classes.title}>
                <span>
                    {shortName ? shortName : null} {longName ? longName : null}
                    {!shortName && !longName ? title : null}
                </span>
                <span>{status === AlarmStatus.Prio1 ? t('alarmsBody.prio1') : t('alarmsBody.prio2')}</span>
            </div>
            <div className={classes.description}>{description}</div>
            {comment && <div className={classes.description}>{comment}</div>}
            <div className={classes.footer}>
                <span className={classes.temperature}>{temperature ? `${temperature} ˚C` : null}</span>
                <span>
                    {formattedStartOn?.toFormat('d. M. yyyy')} <span className={classes.footerSmall}>{formattedStartOn?.toFormat('HH:mm')}</span> <ArrowRightIcon className={classes.arrowIcon} />
                    {formattedEndOn ? (
                        <>
                            {formattedEndOn.toFormat('d. M. yyyy')} <span className={classes.footerSmall}>{formattedEndOn.toFormat('HH:mm')}</span>
                        </>
                    ) : (
                        <>{t('running')}</>
                    )}
                </span>
            </div>
        </div>
    );
};

export default AssetHeader;
